/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/client/user.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
import cache_utils from '../lib/utils/cache_utils';
const cached_user_info = {};
const cached_user_list = {};

export async function get_user_info(user_id = '@current') {
	const retval = await cache_utils.cache_promise(cached_user_info, user_id, async function() {
		let user_info;

		try {
			if (user_id === '@current') {
				user_info = await api.call('GET', 'user');
			} else {
				user_info = await api.call('GET', `users/${user_id}`);
			}
		} catch (user_info_error) {
			return({});
		}

		return(user_info);
	}, {
		name: 'cached_user_info'
	});

	return(retval);
}

export async function get_user_id(user_id = '@current') {
	const user_info = await get_user_info(user_id);

	return(user_info.id);
}

export async function get_user_username(user_id = '@current') {
	const user_info = await get_user_info(user_id);

	return(user_info.name);
}

export async function get_user_displayname(user_id = '@current') {
	const user_info = await get_user_info(user_id);

	return(user_info.display_name);
}

export async function get_user_list(fields = []) {
	let query = 'users';

	const query_params = [];
	if (fields.length !== 0) {
		query_params.push(`fields=${fields.join(',')}`);
	}

	if (query_params.length !== 0) {
		query = `${query}?${query_params.join('&')}`;
	}

	const retval = await cache_utils.cache_promise(cached_user_list, query, async function() {
		let user_list;
		try {
			user_list = await api.call('GET', query);
		} catch (user_list_error) {
			kaialpha.log.error('Failed to get user list:', user_list_error);
		}

		if (!user_list || !user_list.users) {
			return([]);
		}

		return(user_list.users);
	}, {
		cache_expires: 30000,
		name: 'cached_user_list'
	});

	return(retval);
}

/*
 * XXX:TODO: This could move to user_utils
 */
const user_prefs_metadata = {
	'user/buffer_save_freq': {
		type: 'number',
		units: 'seconds',
		default: 60
	}
};
export function get_user_pref(user_id, pref_id, default_value = undefined) {
	let metadata = {};
	if (user_prefs_metadata[pref_id] !== undefined) {
		metadata = user_prefs_metadata[pref_id];
	}

	if (default_value === undefined) {
		default_value = metadata.default;
	}

	const current_prefs = get_user_prefs(user_id);
	const current_pref = current_prefs[pref_id];

	let retval = current_pref;

	if (current_pref === undefined) {
		retval = default_value;
	}

	switch (metadata.type) {
		case 'number':
			retval = Number(retval);
			break;
		default:
			/* Nothing to do, pass as-is */
			break;
	}

	return(retval);
}

/*
 * XXX:TODO: This currently uses localStorage, but it should support
 *           a type of localStorage which can sync to the remote
 *           system
 */
export function get_user_prefs(user_id = '@current', items = undefined) {
	if (user_id !== '@current') {
		throw(new Error('Currently we can only get the current users preferences'));
	}

	/*
	 * Return all items, as an object
	 */
	if (items === undefined) {
		const current_prefs_str = localStorage.getItem('kaialpha/user_prefs');
		if (current_prefs_str === undefined || current_prefs_str === null) {
			return({});
		} else {
			let current_prefs = {};
			try {
				current_prefs = JSON.parse(current_prefs_str);
			} catch (ignored_error) {
				/* We currently ignore broken preferences */
			}
			return(current_prefs);
		}
	} else {
		throw(new Error('Currently unsupported'));
	}
}

export function set_user_prefs(user_id = '@current', items) {
	if (user_id !== '@current') {
		throw(new Error('Currently we can only set the current users preferences'));
	}

	const current_prefs = get_user_prefs(user_id);
	const new_prefs = {
		...current_prefs,
		...items
	};

	localStorage.setItem('kaialpha/user_prefs', JSON.stringify(new_prefs));
}

export async function logout_user(user_id = '@current') {
	const logout_info = await api.call('POST', `user/${user_id}/logout`);
	return(logout_info);
}

const _to_export = {
	get_user_info,
	get_user_id,
	get_user_username,
	get_user_displayname,
	get_user_list,
	get_user_pref,
	get_user_prefs,
	set_user_prefs,
	logout_user
};
export default _to_export;
