/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/template_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import document_utils from './document_utils';
import object_utils from './object_utils';
import recursive_object_utils from './recursive_object_utils';
const uuid = require('uuid');
const fs = require('fs');
const _testing = undefined;

async function process_template_references(user_id, body, template_id, cache_object, options = {}) {
	const reference_elements = [];

	if (cache_object === undefined || cache_object === null) {
		cache_object = {};
	}
	const body_map = cache_object;

	if (body !== undefined && body !== null) {
		body_map[template_id] = document_utils.body_element_map(body);
	}

	for (const body_element_id in body_map[template_id]) {
		const body_element = body_map[template_id][body_element_id];
		if (body_element.type === 'reference') {
			reference_elements.push(body_element);
		}
	}

	const variables = {};
	for (const reference_element of reference_elements) {
		const reference_name = reference_element.name;
		if (!reference_name) {
			continue;
		}

		const reference_variable_name = reference_name.toLowerCase();

		let target;
		if (typeof(reference_element.value) === 'string') {
			/*
			 * If the reference is a string, it is
			 * to a single element within the same
			 * template -- convert this to the canonical
			 * object form.
			 */
			target = {
				element_id: reference_element.value
			};
		} else {
			target = reference_element.value;
		}

		if (!target || target.element_id === undefined) {
			if (options.add_missing_variables) {
				variables[reference_variable_name] = options.add_missing_variables(reference_name);
			}
			continue;
		}

		if (!target.template_id) {
			target['template_id'] = template_id;
		}

		/*
		 * If the reference is to another template, we may
		 * need to fetch it
		 */
		if (body_map[target.template_id] === undefined) {
			try {
				const subtemplate = await kaialpha.lib.template.get_user_template(user_id, target.template_id, target.template_version);
				body_map[target.template_id] = document_utils.body_element_map(subtemplate.body);
			} catch (fetch_template_error) {
				/* Fetch error is ignored */
			}
		}

		if (body_map[target.template_id] === undefined || body_map[target.template_id][target.element_id] === undefined) {
			if (options.add_missing_variables) {
				variables[reference_variable_name] = options.add_missing_variables(reference_name);
			}
			continue;
		}

		const target_element = body_map[target.template_id][target.element_id];

		const replacement_values = {
			value: document_utils.get_value_from_element(target_element),
			type: target_element.type,
			parent_value: reference_element.value.parent
		};

		const reference_value = document_utils.get_reference_value(reference_element.value, reference_element.value.element_id, reference_element.name, replacement_values);

		variables[reference_variable_name] = reference_value;
	}

	return(variables);
}

function generate_numbering(counter, section_map, body, subtemplate_index, is_subtemplate) {

	for(const index in body) {
		const body_element = body[index];
		const body_element_values = Object.values(body_element)[0];

		if (body_element_values.type === 'section') {
			const element_id = Object.keys(body_element)[0];

			subtemplate_index++;

			if (is_subtemplate === false) {
				const first_digit = counter[0];
				const first_digit_as_int = parseInt(first_digit);
				const new_value = first_digit_as_int + 1;
				counter = new_value.toString();
			} else {

				if (subtemplate_index === 1) {
					counter = counter + '.0';
				}

				const counter_length = counter.length;
				const last_digit = counter[counter_length - 1];
				const last_digit_as_int = parseInt(last_digit);
				const new_value = last_digit_as_int + 1;
				counter = counter.substring(0, counter_length - 1) + new_value.toString();
			}

			section_map[element_id] = counter;
			if (body_element_values.body !== undefined) {
				const subtemplate_body = body_element_values.body;
				section_map = generate_numbering(counter, section_map, subtemplate_body, 0, true);
			}
		}
	}

	return(section_map);
}

async function process_template_style(user_id, body, template_id, cache_object, options = {}) {
	const style_elements = [];

	if (cache_object === undefined || cache_object === null) {
		cache_object = {};
	}
	const body_map = cache_object;

	if (body !== undefined && body !== null) {
		body_map[template_id] = document_utils.body_element_map(body);
	}

	for (const body_element_id in body_map[template_id]) {
		const body_element = body_map[template_id][body_element_id];
		if (body_element.type === 'style') {
			style_elements.push(body_element);
		}
	}

	const variables = {};
	let section_map = {};
	for (const style_element of style_elements) {
		const style_value = style_element.value;

		if (style_value.numbering_scheme) {
			const counter = '0';
			section_map = this.generate_numbering(counter, {}, body, 0, false);
		}

		const style_name = `${style_value.element_type}_style`;

		const target = style_name;

		if (!target) {
			if (options.add_missing_variables) {
				variables[style_name] = options.add_missing_variables(style_name);
			}
			continue;
		}

		/* Convert inches to em for certain styles */
		for (const key in style_value) {
			if (key === 'padding_left' || key === 'padding_right' || key === 'text_indent') {
				const style_value_em = style_value[key] * 6.0225;
				style_value[key] = style_value_em;
			}
		}

		variables[style_name] = style_value;
	}

	variables['section_map'] = section_map;

	return(variables);
}

async function process_template_variables(user_id, template_id, version_id, options = {}, path = [], global_name = undefined, template_path = []) {
	options = Object.assign({
		seen_ids: {},
		exclude_template_ids: [],
	}, options);
	/*
	 * Avoid loops by remembering which paths we have visited
	 */
	if (options.seen_ids[template_id]) {
		return({});
	}
	options.seen_ids[template_id] = true;

	const template = await kaialpha.lib.template.get_user_template(user_id, template_id, version_id);
	const local_variables = template.variables;
	const subtemplates = await document_utils.body_templates(template.body);
	const qualified_variables = {};
	const template_key = {
		id: template_id,
		version: version_id
	}
	const child_template_path = [...template_path, template_key];

	if (!options.exclude_template_ids.includes(template_id)) {
		for (const variable_name in local_variables) {
			const variable_info = local_variables[variable_name];
			variable_info['from'] = child_template_path;
			variable_info['__user_variable'] = true;
			recursive_object_utils.set(qualified_variables, path, variable_name, variable_info);
		}
	}

	for (const subtemplate_info of subtemplates) {
		const subtemplate = subtemplate_info.contents;
		if (!subtemplate.name) {
			continue;
		}

		const child_path = [...path, subtemplate.name]

		const subtemplate_variables = await process_template_variables(user_id, subtemplate.id, subtemplate.version, options, child_path, subtemplate.global_name, child_template_path);

		Object.assign(qualified_variables, subtemplate_variables);
	}

	/*
	 * XXX:TODO: global_name is not used !
	 */
	if (global_name !== undefined) {
		throw(new Error('global_name is currently unsupported -- developers must address this.'));
	}

	return(qualified_variables);
}

/*
 * Find the templates which include a given template
 *
 * XXX:TODO: This chould be done more efficiently with better filtering
 */
async function get_template_supers(user_id, template_id, options = {}) {
	/* NOTE: We intentionally do not make a copy of "options" here */
	const retval = [];

	if (options.templates_info === undefined) {
		kaialpha.lib.debug.log('optimization', '[DEBUG] Had to fetch all templates, including bodies');

		options.templates_info = await kaialpha.lib.template.get_user_templates(user_id, {
			fields: ['version', 'body', 'metadata'],
			auto_paginate: true
		});
	}

	const templates = options.templates_info.templates;

	for (const template_info of templates) {
		if (options.include_self !== false && template_info.id === template_id) {
			retval.push({
				id: template_info.id,
				version: template_info.version,
				metadata: template_info.metadata
			});
			continue;
		}
		const sub_templates = await document_utils.body_templates(template_info.body);
		for (const sub_template_info of sub_templates) {
			const sub_template = sub_template_info.contents;

			if (sub_template.id === template_id) {
				retval.push({
					id: template_info.id,
					version: template_info.version,
					metadata: template_info.metadata
				});
			}
		}
	}

	return(retval);
}

/*
 * Walk the template graph and determine the top-level templates which include this one
 */
async function get_template_tops(user_id, template_id, options = {}) {
	/* NOTE: We intentionally do not make a copy of "options" here */
	if (options.seen_ids === undefined) {
		options.seen_ids = {};
	}

	const retval = [];
	const super_templates_info = await get_template_supers(user_id, template_id, options);

	/*
	 * Ensure we do not traverse the graph multiple times by marking nodes we are
	 * about to process as visited
	 */
	const local_seen_ids = {...options.seen_ids};
	for (const super_template_info of super_templates_info) {
		options.seen_ids[super_template_info.id] = true;
	}

	for (const super_template_info of super_templates_info) {
		if (local_seen_ids[super_template_info.id]) {
			continue;
		}

		if (super_template_info.metadata && super_template_info.metadata.toplevel === 'true') {
			retval.push({
				id: super_template_info.id,
				version: super_template_info.version
			});
		}

		retval.push(...(await get_template_tops(user_id, super_template_info.id, options)));
	}

	return(retval);
}

/*
 * For a given template, what variables could possibly be accessed ?
 *
 * XXX: This is currently all relative to "global" (i.e., absolute), in the
*       future it might be desirable to make these relative.
 */
async function get_template_all_variables(user_id, template_id, options = {}) {
	options = Object.assign({
		exclude_template_ids: []
	}, options);

	const supers = await get_template_tops(user_id, template_id);
	const variables = {};

	for (const top_level_template of supers) {
		const top_level_template_variables = await process_template_variables(null, top_level_template.id, top_level_template.version, options);
		Object.assign(variables, top_level_template_variables);
	}

	return(variables);
}

function flatten_template_variable_tree(tree, path = []) {
	const retval = {};

	for (const key in tree) {
		const variable = tree[key];
		const variable_path = [...path, key];

		if (!(variable instanceof Object)) {
			continue;
		}

		if (variable.__user_variable === true) {
			const user_variable = object_utils.copy_object(variable);
			const user_variable_name = variable_path.join('.');

			delete user_variable['__user_variable'];

			retval[user_variable_name] = user_variable;
		}

		const sub_tree_flattened = flatten_template_variable_tree(variable, variable_path);

		Object.assign(retval, sub_tree_flattened);
	}

	return(retval);
}

function arrange_tree_in_template_order(tree, path = [], retval = {}) {
	for (const key in tree) {
		const variable = tree[key];

		if (!(variable instanceof Object)) {
			continue;
		}

		const sub_path = [...path, key];
		if (variable.__user_variable === true) {
			const tree_path = [];
			for (const template_key of variable.from) {
				const template_id = template_key.id;
				const template_version = template_key.version;

				tree_path.push(template_id);
				tree_path.push(template_version);
			}

			const user_variable = object_utils.copy_object(variable);

			delete user_variable['from'];
			delete user_variable['__user_variable'];
			user_variable['variable_path'] = sub_path;

			recursive_object_utils.set(retval, [...tree_path, '__user_variables'], key, user_variable);
		}

		arrange_tree_in_template_order(variable, sub_path, retval);
	}

	return(retval);
}

function checkOrdinalNameAndFormat(name) {
	if (name.includes("{{") && name.includes("}}")) {
		const string = name.substring(
			name.lastIndexOf("{{") + 1,
			name.lastIndexOf("}}")
		);
		if (!(string.length > 2)) {
			name = name + "_{{ordinal}}";
		}
	} else {
		name = name + "_{{ordinal}}";
	}
	return name;
}

async function render_template_processing_template_references(user_id, template_id, template_body) {
	/*
	 * XXX:TODO: This should process template references/metadata in the
	 * same way as "process_document_variables".
	 */
	const variables = {};
	variables['__current'] = await kaialpha.lib.template_utils.process_template_references(user_id, template_body, template_id);
	variables['global'] = variables['__current'];

	const nunjucks_file = await kaialpha.lib.generator.generateNunjucksFromBody(template_body, {
		user_id: user_id,
		type: 'template'
	});

	const html_file = await kaialpha.lib.generator.generateHTML(nunjucks_file, variables, {
		get_user_list_entries: async function(list_type, list_id, list_version) {
			return(await kaialpha.lib.list_utils.get_user_list_entries(user_id, list_type, list_id, list_version));
		}
	});

	fs.unlinkSync(nunjucks_file);

	return(html_file);
}

if (_testing) {

	const body = [
		{
			[uuid.v4()]: {
				type: 'title',
				title: 'A Report for Customer {{CustomerID}}'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerID'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerType'
			}
		},
		{
			[uuid.v4()]: {
				type: 'html',
				text: 'This is a report for Customer {{CustomerID}} ({{CustomerType}})'
			}
		},
		{
			[uuid.v4()]: {
				type: 'reference',
				name: 'CustomerType'
			}
		}
	];

	_testing.process_template_references = function () {
		process_template_references('user1', body, 'templateId');
		return true;
	}

	_testing.generate_numbering = function () {

		const body_elements = new Array(body);

		body_elements.push({
			[uuid.v4()]: {
				type: 'section',
				name: 'Section'
			}
		})

		const result = generate_numbering(["1"], {}, body_elements, 0, false);

		/* istanbul ignore if */
		if (Object.entries(result)[0][1] !== "2") {
			throw new Error("Has sub sections but not found");
		}
		return true;
	}

	_testing.generate_numbering_with_true = function () {

		const body_elements = new Array(body);

		const section = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section'
			}
		}

		body_elements.push(section);

		const result = generate_numbering("2.1", {}, body_elements, 1, true);

		/* istanbul ignore if */
		if (Object.entries(result)[0][1] !== "2.2") {
			throw new Error("Numbering is wrong");
		}
		return true;
	}

	_testing.generate_numbering_with_body = function () {

		const body_elements = new Array(body);

		const section = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section',
				body: {
					[uuid.v4()]: {
						type: 'section',
						name: 'Section'
					}
				}
			}
		}

		body_elements.push(section);

		const result = generate_numbering("1", {}, body_elements, 0, false);

		/* istanbul ignore if */
		if (Object.entries(result)[0][1] !== "2") {
			throw new Error("Number should be 2 but found differnet");
		}
		return true;
	}

	_testing.generate_numbering_with_nested_section_body = function () {

		const body_elements = new Array(body);

		body_elements.push({
			[uuid.v4()]: {
				type: 'section',
				name: 'Section',
				body: {}
			}
		})

		const subsection = body_elements[body_elements.length - 1];
		const subsection_values = Object.values(subsection)[0];
		const subsection_body = subsection_values['body'];

		subsection_body[uuid.v4()] = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section',
				body: {
					[uuid.v4()]: {
						type: 'html',
						text: 'Text'
					}
				}
			},
		}

		subsection_body[uuid.v4()] = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section'
			}
		}

		const result = generate_numbering("1", {}, body_elements, 1, true);

		/* istanbul ignore if */
		if (Object.entries(result)[2][1] !== "2.2") {
			throw new Error("Number should be 2.2 but found differnet");
		}
		return true;
	}

	_testing.flatten_template_variable_tree = function () {
		const variables = {
			"Var1": {
				description: "{{Var1}}",
				type: "richtextarea",
				__user_variable: true
			}
		}
		const result = flatten_template_variable_tree(variables);

		/* istanbul ignore if */
		if (result.Var1 && result.Var1.type !== 'richtextarea') {
			throw new Error("Var1 should hold in the flattern tree but not found");
		}
		return true;
	}

	_testing.arrange_tree_in_template_order = function () {
		const variables = {
			"Var1": {
				description: "{{Var1}}",
				type: "richtextarea",
				__user_variable: true,
				'from': [{
					[uuid.v4()]: {
						'id': 'tempalteId',
						'version':'tempateVersion',
					}
				}]
			}
		}

		const result = arrange_tree_in_template_order(variables);

		/* istanbul ignore if */
		if (!result) {
			throw new Error("Resulting with no variables which is not exprected");
		}
		return true;
	}

	_testing.render_template_processing_template_references = async function () {
		const result = await render_template_processing_template_references('user1', null, []);

		/* istanbul ignore if */
		if (!result) {
			throw new Error("Nothing got rendered");
		}
		return true;
	}
}

const _to_export_auto = {
	process_template_variables,
	process_template_references,
	process_template_style,
	get_template_supers,
	get_template_tops,
	get_template_all_variables,
	flatten_template_variable_tree,
	arrange_tree_in_template_order,
	generate_numbering,
	render_template_processing_template_references,
	checkOrdinalNameAndFormat,
	_testing
}
export default _to_export_auto;
