import React from 'react';
import Box from '@material-ui/core/Box';

import {
	Icon
} from '../../../lib/ui';
import LazyTreeView from '../../LazyTreeView';

import folder_lib from '../../../api/folder';
import document_lib from '../../../api/document';
import template_lib from '../../../api/template';

class FoldersTreeView extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.state = {};

		this.mounted = false;
		this.lazy_tree_view_ref = React.createRef();
	}

	componentDidMount() {
		this.mounted = true;
	}

	push_status(type, message, id = undefined) {
		this.props.statusBar.push_status(type, message, id);
	}

	_height() {
		let height = '20';
		if (this.props.height !== undefined) {
			height = this.props.height;
		}

		return(`${height}em`);
	}

	_id_to_type(id) {
		/*
		 * The "undefined" ID is semantically a folder ID for the root folder
		 */
		if (id === undefined) {
			return({
				id: undefined,
				type: 'folder'
			});
		}

		const work = id.split(',');
		if (work.length !== 2) {
			throw(new Error(`Invalid marked-up id: ${id}`));
		}

		const retval = {
			id: work[0],
			type: work[1]
		};

		if (retval.id === undefined) {
			throw(new Error(`Error while converting ${id}, got impossible result: ${JSON.stringify(retval)}`));
		}

		return(retval);
	}

	async _get_all() {
		const retval = [];
		const parts = {
			template: await template_lib.getTemplateChildren(),
			document: await document_lib.getDocumentSubdocuments()
		}

		for (const type of Object.keys(parts)) {
			const entries = parts[type];
			if (!(entries instanceof Array)) {
				continue;
			}

			retval.push(...entries.map(function(item) {
				let children;
				if (item.id === '@orphans' && type === 'template') {
					children = ['dummy'];
				}

				return({
					type: type,
					name: item.name,
					permissions: item.permissions,
					id: [item.id, type].join(','),
					version: item.version,
					children: children
				});
			}));
		}

		return(retval);
	}

	async _get_template_children(id, version) {
		const children = await template_lib.getTemplateChildren(id, version);
		for (const item of children) {
			item.id = [item.id, 'template'].join(',');
		}
		return(children);
	}

	/* XXX:TODO: Better interface would be a list of types */
	_acceptable_type(type) {
		let types = ['folder', 'template', 'document'];
		if (this.props.types !== undefined) {
			types = this.props.types;
		}

		return(types.includes(type));
	}

	async _get_folder_children(id, version) {
		if (id === '@all') {
			return(await this._get_all());
		}

		const folder_info = await folder_lib.get_user_folder(undefined, id);
		let children = folder_info.children;
		if (children === undefined) {
			children = [];
		}

		/* For the root folder, support an orphans */
		if (id === undefined && (this._acceptable_type('document') || this._acceptable_type('template'))) {
			children.push({
				name: 'All Templates and Documents',
				id: '@all,folder',
				permissions: { owners: [ '@system' ] },
				children: ['dummy']
			});
		}

		await Promise.all(children.map(async (child) => {
			if (child.children !== undefined && child.permissions !== undefined) {
				return;
			}

			/*
			 * If the child we found is not of an acceptable type,
			 * update its value to be useless for rendering which
			 * will cause it to be ignored
			 */
			if (!this._acceptable_type(child.type)) {
				child.id = undefined;
				child.permissions = undefined;
				return;
			}

			try {
				let child_info;
				switch (child.type) {
					case 'folder':
						child_info = await folder_lib.get_user_folder(undefined, child.id);
						break;
					case 'document':
						child_info = await document_lib.get_user_document(undefined, child.id);
						break;
					case 'template':
						child_info = await template_lib.get_user_template(undefined, child.id);
						break;
					default:
						console.warn('Found an unsupported child type, ignoring:', child);
						break;
				}

				child.id = [child.id, child.type].join(',');
				child.version = child_info.version;
				child.permissions = child_info.permissions;
				child.name = child_info.name;

				if (child.type === 'folder') {
					child.children = child_info.children;
				}
			} catch (child_fetch_error) {
				console.debug('Failed to fetch child information:', child_fetch_error);
			}
		}));

		return(children);
	}

	async _get_children(id_tag, version) {
		const { id, type } = this._id_to_type(id_tag);

		switch (type) {
			case 'folder':
				return(await this._get_folder_children(id, version));
			case 'template':
				return(await this._get_template_children(id, version));
			default:
				/* We don't support breaking things up anymore at this point */
				break;
		}

		return([]);
	}

	async reload() {
		await this.lazy_tree_view_ref.current.reload();
	}

	render() {
		return(
			<Box height={this._height()} style={{
				'overflowX': 'auto',
				'overflowY': 'scroll',
				'background': '#eaeaea'
			}}>
				<LazyTreeView
					ref={this.lazy_tree_view_ref}
					uniqueItems={true}
					getChildren={async (...args) => {
						const res = await this._get_children(...args);

						return(res);
					}}
					renderItemLabel={(item) => {
						const { type } = this._id_to_type(item.id);
						let label = item.name;

						if (item.owner !== 'Unknown') {
							label += ` [Owner ${item.owner}]`;
						}

						let icon_type = type;
						let variant;
						if (item.id[0] === '@') {
							variant = 'special';
							icon_type = 'folder';
						}

						return(<><Icon forType={icon_type} variant={variant}/> {label}</>);
					}}
					onClick={(id_tag, version, item) => {
						this.setState({
							selected_id: id_tag
						});

						const { id, type } = this._id_to_type(id_tag);
						let parent_info;
						if (item.parent !== undefined) {
							parent_info = this._id_to_type(item.parent.id);
						} else {
							parent_info = this._id_to_type();
						}
						const { id: parent_id, type: parent_type } = parent_info;

						if (this.props.onClick) {
							this.props.onClick(id, type, {
								...item,
								id: id,
								type: type,
								parent: {
									...item.parent,
									id: parent_id,
									type: parent_type
								}
							});
						}
					}}
				/>
			</Box>
		);
	}
}

export default FoldersTreeView;
