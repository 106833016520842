import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import UserListSelect from '../UserListSelect';
import { Button } from '../../lib/ui';
import { titleCase } from "title-case";

import general_utils from '../../lib/utils/general_utils';

class AsyncRender extends React.Component {
	static defaultProps = {
		fallback: '<Loading...>'
	}

	constructor(props, ...args) {
		super(props, ...args);

		this.state = {
			result: props.fallback
		};

		if (props.code === undefined) {
			this._promise = (async function() {
			})();
		} else {
			this._promise = props.code();
		}
	}

	async componentDidMount() {
		this.setState({
			result: await this._promise
		});
	}

	render() {
		console.debug('r = ', this.state.result);
		return(this.state.result);
	}
}

class ACLEditor extends React.Component {
	constructor(...args) {
		super(...args);

		this.state = {};
	}

	static computePermissions(permissions) {
		if (!permissions) {
			permissions = {};
		}

		if (permissions.owners === undefined) {
			permissions.owners = [];
		}

		if (permissions.roles === undefined) {
			permissions.roles = {};
		}

		if (permissions.acl === undefined) {
			permissions.acl = {};
		}

		permissions.roles = Object.assign({
			reviewers: [],
			readers: [],
			authors: []
		}, permissions.roles);

		permissions.acl = Object.assign({
			read: [],
			write: [],
			'comments:write': []
		}, permissions.acl);

		const ensure_roles = [
			{ acl: 'read', role: '@role:readers' },
			{ acl: 'read', role: '@role:reviewers' },
			{ acl: 'write', role: '@role:authors' },
			{ acl: 'comments:write', role: '@role:reviewers' }
		]

		for (const ensure_role of ensure_roles) {
			let add_role = true;

			for (const user_id of permissions.acl[ensure_role.acl]) {
				if (user_id === ensure_role.role) {
					add_role = false;
					break;
				}
			}

			if (add_role) {
				permissions.acl[ensure_role.acl].push(ensure_role.role);
			}
		}

		return(permissions);
	}

	computeCanonicalPermissions() {
		const type = this.props.type;
		const id = this.props.id;

		if (type === undefined || id === undefined) {
			return;
		}

		const key = ['type', type, 'id', id].join('_');
		if (this.state.canonicalized_permissions !== undefined && this.state.canonicalized_permissions[key] !== undefined) {
			return(this.state.canonicalized_permissions[key]);
		}

		(async () => {
			const permissions = await general_utils.get_canonical_permissions_any_type(null, type, id);
			this.setState((prevProps) => {
				return({
					canonicalized_permissions: {
						...prevProps.canonicalized_permissions,
						[key]: permissions
					}
				});
			});
		})();
	}

	buttonSave() {
		this.setState({
			canonicalized_permissions: {}
		});

		if (this.props.onSave) {
			this.props.onSave(this.props.permissions);
		}
	}

	buttonCancel() {
		this.setState({
			canonicalized_permissions: {}
		});

		if (this.props.onCancel) {
			this.props.onCancel();
		}
	}

	_manageValue(variable) {
		return({
			value: variable,
			onChange: (new_value) => {
				variable.splice(0);
				variable.push(...new_value);
				this.forceUpdate();
			}
		});
	}

	render() {
		if (!this.props.visible) {
			return(null);
		}

		const permissions = ACLEditor.computePermissions(this.props.permissions);

		let canonical_permissions_display;
		const canonical_permissions_info = this.computeCanonicalPermissions();
		if (canonical_permissions_info !== undefined && canonical_permissions_info.permissions !== undefined) {
			const canonical_permissions = canonical_permissions_info.permissions;
			if (canonical_permissions.acl === undefined) {
				canonical_permissions.acl = {};
			}

			canonical_permissions_display = (
				<>
					<Grid item xs={12} sm={2} align="right">
						<label>Effective:</label>
					</Grid>
					<Grid item xs={12} sm={10}>
						<UserListSelect value={canonical_permissions.owners} multiple={true} label={'Owners'} disabled={true}/>
						{
							Object.keys(canonical_permissions.acl).map(function(mode) {
								const users = canonical_permissions.acl[mode];
								if (!Array.isArray(users)) {
									return(undefined);
								}
								if (users.length === 0) {
									return(undefined);
								}
								return(<UserListSelect value={users} multiple={true} label={titleCase(mode)} disabled={true}/>);
							})
						}
					</Grid>
				</>
			);
		}

		let inherited_permissions_display;
		if (Array.isArray(permissions.inherit_from)) {
			inherited_permissions_display = (
				<>
					<Grid item xs={12} sm={2} align="right">
						<label>Inherited From:</label>
					</Grid>
					<Grid item xs={12} sm={10}>
						<ol>
							{
								permissions.inherit_from.map(function(item) {
									return(<li>{titleCase(item.type)} <AsyncRender key={`from_${item.type}_${item.id}`} code={async () => {
										const item_info = await general_utils.get_item_any_type(null, item.type, item.id, 'HEAD');
										const name = item_info.name;
										return(name);
									}}/></li>);
								})
							}
						</ol>
					</Grid>
				</>
			);
		}

		return(
			<Dialog
				fullWidth={true}
				open={true}
				onClose={() => {
					this.buttonCancel();
				}}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">ACL:</DialogTitle>
				<DialogContent>
					<Grid container alignItems="center">
						<Grid item xs={12} sm={2} align="right">
							<label>Owners:</label>
						</Grid>
						<Grid item xs={12} sm={10}>
							<UserListSelect
								multiple={true}
								{...this._manageValue(permissions.owners)}
							/>
						</Grid>
						<Grid item xs={12} sm={2} align="right">
							<label>Authors:</label>
						</Grid>
						<Grid item xs={12} sm={10}>
							<UserListSelect
								multiple={true}
								{...this._manageValue(permissions.roles.authors)}
							/>
						</Grid>
						<Grid item xs={12} sm={2} align="right">
							<label>Readers:</label>
						</Grid>
						<Grid item xs={12} sm={10}>
							<UserListSelect
								multiple={true}
								{...this._manageValue(permissions.roles.readers)}
							/>
						</Grid>
						<Grid item xs={12} sm={2} align="right">
							<label>Reviewers:</label>
						</Grid>
						<Grid item xs={12} sm={10}>
							<UserListSelect
								multiple={true}
								{...this._manageValue(permissions.roles.reviewers)}
							/>
						</Grid>
						{canonical_permissions_display}
						{inherited_permissions_display}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { this.buttonSave(); }} color="primary">Save</Button>
					<Button onClick={() => { this.buttonCancel(); }} color="secondary">Cancel</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ACLEditor;
