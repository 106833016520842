import React from 'react';
import './LandingPage.css';
import { Button } from '../../lib/ui';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import CardViews from '../CardViews';
import RecentViews from '../RecentsView';
import lib_template from '../../api/template';
import lib_document from '../../api/document';
import object_utils from '../../lib/utils/object_utils';
import DocumentConvertor from '../DocumentConvertor/DocumentConvertor';

class LandingPage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			uploadingDocument: false,
			items: {
				create: {
					"Template": "/activity/templateeditor",
					"Document": "/activity/doccreator"
				},
				cards: [
					{
						type: "In Draft",
						count: 22,
						name: 'Documents',
						path: '/Documents'
					},
					{
						type: "In Review",
						count: 9,
						name: 'Documents',
						path: '/Templates/Review'
					},
					{
						type: "Task Remaining",
						count: 15,
						path: '/Tasks'
					}
				]
			}
		};
	}

	componentDidMount() {
		const date = new Date();
		date.setDate(date.getDate() - 120);
		date.setHours(0,0,0,0);
		const dateString = date.getTime();

		const to_update = {
			'recent_templates': {
				promise: lib_template.get_user_templates(null, {
					filter: "@date>=" + dateString,
					fields: ["name", "metadata", "id", "version", "@date"],
					count: 10,
					orderby: '@date',
					order: 'desc'
				}),
				member: 'templates'
			},
			'recent_documents': {
				promise: lib_document.get_user_documents(null, {
					filter: "@date>=" + dateString,
					fields: ["name", "id", "version", "@date"],
					count: 10,
					orderby: '@date',
					order: 'desc'
				}),
				member: 'documents'
			},
			'template_buffers': {
				promise: lib_template.getAllTemplateBuffers()
			},
			'document_buffers': {
				promise: lib_document.getAllDocumentBuffers()
			}
		};

		for (const key in to_update) {
			(async () => {
				const member = to_update[key].member;
				const promise = to_update[key].promise;

				this.setState({
					[`${key}_loading`]: true
				});

				const result = await promise;
				let result_member;
				if (member !== undefined) {
					result_member = result[member];
				} else {
					result_member = result;
				}

				const data = this.structureItems(result_member);

				this.setState({
					[key]: data,
					[`${key}_loading`]: false
				});
			})();
		}
	}

	structureItems(items) {
		const response = {};

		if (!Array.isArray(items)) {
			return(response);
		}

		for (const item of items) {
			const id = item.id;
			response[id] = item;
		}

		return response;
	}

	handleHighlightClick(highlightedItemId, stateRef) {
		this.setState(function(prevState) {
			const obj = object_utils.copy_object(prevState[stateRef]);
			if (!obj[highlightedItemId]) {
				return({});
			}

			obj[highlightedItemId].isHighlighted = !obj[highlightedItemId].isHighlighted;
			return({
				[stateRef]: obj
			});
		});
	}

	routePath(id, type) {
		switch(type){
			case 'templates' : return "/activity/templateeditor/"+id;
			case 'documents' : return "/activity/doceditor/"+id;
			default : return ''
		}
	}

	render() {
		return(
			<div className="homepage">
				<Grid container>
					<Grid item xs={12}>
						<div className='dashboard'>
							<p className='dashboard-hdr'>Dashboard</p>
							<div style={{marginLeft:'20px'}}>
								<CardViews
									views = {this.state.items}
								></CardViews>
							</div>
						</div>
					</Grid>

					<div className='recents'>
						<p className='recents-hdr'>Recents</p>
						<div className='recents-card-views'>
							<Grid container spacing={2} wrap='nowrap'>
								<Grid item xs={4}>
									<RecentViews
										templates={this.state.recent_templates}
										loading={this.state.recent_templates_loading}
										highlightCallBack={(id) => { this.handleHighlightClick(id, 'recent_templates');}}
										type='templates'
										containerWidth='large'
										routePath = {this.routePath}
									/>
								</Grid>
								<Grid item xs={4}>
									<RecentViews
										templates={this.state.recent_documents}
										loading={this.state.recent_documents_loading}
										highlightCallBack={(item) => { this.handleHighlightClick(item, 'recent_documents');}}
										type='documents'
										containerWidth='large'
										routePath = {this.routePath}
									/>
								</Grid>
								<Grid item xs={4}>
									<RecentViews
										templates={this.state.recent_projects}
										loading={false}
										highlightCallBack={(item) => this.handleHighlightClick(item, 'recent_projects')}
										type='projects'
										containerWidth='small'
									/>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className='recents-buffers'>
						<div className='recents-card-views'>
							<Grid container spacing={2} wrap='nowrap'>
								<div className="document-buffers">
									<Grid item xs={4}>
										<RecentViews
											templates={this.state.document_buffers}
											loading={this.state.document_buffers_loading}
											highlightCallBack={(item) => this.handleHighlightClick(item, 'document_buffers')}
											type='Documents Buffer'
											containerWidth='large'
											routePath = {this.routePath}
										/>
									</Grid>
								</div>
								<div className="template-buffer">
									<Grid item xs={4}>
										<RecentViews
											templates={this.state.template_buffers}
											loading={this.state.template_buffers_loading}
											highlightCallBack={(item) => this.handleHighlightClick(item, 'template_buffer')}
											type='Templates Buffer'
											containerWidth='large'
											routePath = {this.routePath}
										/>
									</Grid>
								</div>
							</Grid>
						</div>
					</div>
				</Grid>
				<div className="links">
					<Button component={Link} to={{pathname: "/activity/templateeditor"}} className="homepage-btn" id="profile-btn">Create Template</Button>
					<Button component={Link} to={{pathname: "/activity/templateselector"}} className="homepage-btn" id="template-editor-btn">Edit Template</Button>
					<Button component={Link} to={{pathname: "/activity/doccreator"}} className="homepage-btn" id="create-doc-btn">Create Document</Button>
					<Button component={Link} to={{pathname: "/activity/workfloweditor"}} className="homepage-btn" id="workflow-btn">Workflow Editor</Button>
					<Button component={Link} to={{pathname: "/activity/docselector"}} className="homepage-btn" id="edit-doc-btn">Edit Document</Button>
					<Button component={Link} to={{pathname: "/activity/profile"}} className="homepage-btn" id="edit-doc-btn">View User Information</Button>
				</div>
				<Button component={Link} to="/activity/logout" className="home-logout-btn" id="homepage-logout-btn">Logout</Button>
				<div style={{textAlign: 'center', paddingRight: '50px', marginBottom: '24px'}}>
					<DocumentConvertor
						type="template"
						history={this.props.history}
					/>
					<DocumentConvertor
						type="document"
						history={this.props.history}
					/>
					<Button component={Link} to={{pathname: "/activity/listsselector/citation"}}>Citation List Editor</Button>
					<Button component={Link} to={{pathname: "/activity/listsselector/abbreviation"}}>Abbreviation List Editor</Button>
				</div>
			</div>
		);
	}
}

export default LandingPage;
