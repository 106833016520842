const console_methods = Object.keys(console).filter(function(method) {
	return(typeof(console[method] === 'function'));
});

const new_console = {
	old_console: console,
	_internal: {
		queue: [],
		enqueue: function(method, log_args) {
			this.queue.push({method, log_args});
			if (!this.queue_runner) {
				this.queue_runner = setTimeout(() => {
					this.queue_runner = undefined;

					const logs = this.queue;
					this.queue = [];

					post_logs(logs);
				}, 1000);
			}
		}
	}
}

async function post_logs(logs) {
	try {
		await fetch('/api/v1/client_logging', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(logs)
		});
	} catch (log_error) {
		/*
		 * Ignore errors writing to the remote log server
		 */
	}
}

// Logs to server and client
new_console.remote_log_enqueue = function(method,...args) {
	const log_args = [];
	let push_log_to_server = false;

	/*
	 * For now, only log errors
	 */
	if (method === 'error') {
		push_log_to_server = true;
	}

	if (push_log_to_server) {
		args.forEach(function(arg) {
			/*
			 * Convert PromiseRejectionEvents into their reason, which is likely
			 * an error.
			 */
			if (typeof(PromiseRejectionEvent) !== 'undefined' && arg instanceof PromiseRejectionEvent) {
				arg = arg.reason;
			}

			if (arg instanceof Error) {
				if (arg.stack) {
					log_args.push(arg.stack);

					/*
					 * If this is an error about client logging, don't
					 * try to log it, which would create a recursive loop.
					 */
					if (arg.stack.includes('/client_logging')) {
						push_log_to_server = false;
					}
				} else {
					log_args.push(arg.toString());
				}
				return;
			}

			if (arg === undefined){
				log_args.push("<undefined>");
				return;
			}

			if (arg === null){
				log_args.push("<null>");
				return;
			}

			if (arg instanceof Object) {
				log_args.push(JSON.stringify(arg));
				return;
			}

			if (arg.toString) {
				log_args.push(arg.toString());
				return;
			}

			log_args.push(arg);
		});
	}

	if (push_log_to_server) {
		this._internal.enqueue(method, log_args);
	}
};

new_console.remote_log = function(method, ...args) {
	try {
		new_console.remote_log_enqueue(method, ...args);
	} catch (log_error) {
		/*
		 * Catch any errors that occur when attempting to log remotely
		 * so they do not interfere with logging locally
		 */
	}

	// Log to client
	return(this.old_console[method](...args));
};

console_methods.forEach(function(method){
	new_console[method] = function(...args) {
		return(new_console.remote_log(method, ...args));
	}
});

/*
 * Log any uncaught promise rejection
 */
window.addEventListener("unhandledrejection", function(error) {
	new_console.remote_log_enqueue('error', 'Uncaught (in promise)', error);
});

// eslint-disable-next-line
console = new_console;
const _to_export = {
	console: new_console
};
export default _to_export;
