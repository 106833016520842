/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/buffer_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;

function assert_valid_buffer_id(buffer_id) {
	if (buffer_id.length <= 38) {
		throw(new Error(`User tried to interact with a buffer with an ID that is way too short: ${buffer_id}`));
	}
}

function get_item_id_from_buffer_id(buffer_id) {
	assert_valid_buffer_id(buffer_id);

	return buffer_id.split('_')[1];
}

function get_item_version_from_buffer_id(buffer_id) {
	assert_valid_buffer_id(buffer_id);

	return buffer_id.split('_')[2];
}

function get_buffer_id(user_id, id, version) {
	const buffer_id = `${user_id}_${id}_${version}`;
	assert_valid_buffer_id(buffer_id);

	return(buffer_id);
}

function get_user_buffers(user_id, buffers) {
	const user_buffers = [];
	buffers.forEach(buffer => {
		const user_id_from_buffer = buffer.id.split('_')[0];
		if (user_id_from_buffer === user_id) {
			user_buffers.push(buffer);
		}
	});
	return user_buffers;
}

if (_testing) {

	_testing.get_item_version_from_buffer_id = function () {
		const result = get_item_version_from_buffer_id("03065fdf-af76-4c7f-bacc-e92b659abaf5_id_version");

		/* istanbul ignore if */
		if (!result === "version") {
			throw new Error("Expected version but found different");
		}

		return true;
	}

	_testing.get_item_id_from_buffer_id = function () {
		const result = get_item_id_from_buffer_id("03065fdf-af76-4c7f-bacc-e92b659abaf5_id_version");

		/* istanbul ignore if */
		if (!result === "id") {
			throw new Error("Expected id but found different");
		}

		return true;
	}

	_testing.get_buffer_id = function () {
		const result = get_buffer_id("03065fdf-af76-4c7f-bacc-e92b659abaf5", "id", "version");

		/* istanbul ignore if */
		if (!result === "03065fdf-af76-4c7f-bacc-e92b659abaf5_id_version") {
			throw new Error("Expected userId_id_version but found different in id generation");
		}

		return true;
	}

	_testing.get_user_buffers = function () {
		const buffers = [{ 'id': '03065fdf-af76-4c7f-bacc-e92b659abaf5_id_version', 'buffer': 'template' }];

		const result = get_user_buffers('03065fdf-af76-4c7f-bacc-e92b659abaf5', buffers);

		/* istanbul ignore if */
		if (result.length > 1) {
			throw new Error("Expected to have only one element but found many");
		}

		/* istanbul ignore if */
		if (result[0].buffer !== 'template') {
			throw new Error("Expected template but found different in get_user_buffers");
		}

		return true;
	}

}

const _to_export_auto = {
	get_item_id_from_buffer_id: get_item_id_from_buffer_id,
	get_item_version_from_buffer_id: get_item_version_from_buffer_id,
	get_buffer_id: get_buffer_id,
	get_user_buffers: get_user_buffers,
	assert_valid_buffer_id: assert_valid_buffer_id,
	_testing
}
export default _to_export_auto;
