import React from 'react';
import { Typography } from '@material-ui/core';
import Upload from '../Upload/Upload';
import lib_template from '../../api/template';
import lib_document from '../../api/document';

class DocumentConvertor extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			statusText: ""
		}
	}

	async uploadDocumentCallBack(key) {
		this.setState({ statusText: "Converting .." });
		let conversionResponse = null;
		let routerExtension = null;

		try {
			if (this.props.type === 'template') {
				routerExtension = 'template';
				conversionResponse = await lib_template.convertUploadedDocToTemplate(key);
			}

			if (this.props.type === 'document') {
				routerExtension = 'doc';
				conversionResponse = await lib_document.convertUploadedDocToDocument(key);
			}

			if (conversionResponse && routerExtension) {
				this.setState({ statusText: "" });
				this.props.history.push(`/activity/${routerExtension}editor/${conversionResponse.id}`);
			}
		} catch (conversion_error) {
			this.setState({ statusText: "" });
			console.error('FAILED TO CONVERT SHOULD NOTIFY USER!', conversion_error);
			alert("Something went wrong please try agian later !");
		}
	}

	render() {
		return(
			<>
				<Typography>{this.state.statusText}</Typography>
				<Upload
					uploadType='.docx'
					uploadButtonDisplayText={`Import ${this.props.type}`}
					uploadLocation='temporary_upload'
					uploadCallback={(res) => this.uploadDocumentCallBack(res)}
				>
				</Upload>
			</>
		);
	}
}

export default DocumentConvertor;
