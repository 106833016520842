import React from 'react';
import lib_document from '../../api/document';
import { CircularProgress } from '@material-ui/core'
import { Button } from '../../lib/ui';

const uuid = require('uuid');

/**
 * Accepts three props and heavily depent on that
 * 
 * 1. displayText - Variable Text
 * 2. isUploadIsInProcess - Variable state
 * 3. uploadFile - Method
 */
class Upload extends React.Component {
	constructor() {
		super();
		this.state = {
			isUploadingInProgress: false
		}
	}

	async importDocument(event) {
		this.setState({ isUploadingInProgress: true })
		const file = event.target.files[0];
		const key = await this.uploadDocument(file);
		this.setState({ isUploadingInProgress: false });
		this.props.uploadCallback(key);
	}

	async uploadDocument(file) {
		const presigned_post_data = await lib_document.create_presigned_post(this.props.uploadLocation);
		const key = presigned_post_data.fields.Key;
		const url = presigned_post_data.url;
		const fields = presigned_post_data.fields;
		const form_data = new FormData();

		Object.keys(fields).forEach(key => form_data.append(key, fields[key]));
		form_data.append('file', file);

		const config = {
			method: 'POST',
			body: form_data
		};

		let response;
		try {
			response = await fetch(url, config);
			if (response) {
				return key.split('/').splice(-1)[0];
			}
		} catch (fetch_error) {
			throw(new Error('Failed to upload File to S3: ' + fetch_error));
		}

		return null;
	}



	render() {
		const id = uuid.v4();

		let uploadType = "file";

		if (this.props.uploadType) {
			uploadType = this.props.uploadType;
		}

		return(
			<>
				<input
					accept={uploadType}
					style={{ display: 'none' }}
					id={id}
					type="file"
					key="document"
					onChange={(e) => this.importDocument(e)}
				/>
				<label htmlFor={id}>
					<Button variant="contained" color="primary" component="span">
						{this.props.uploadButtonDisplayText}
					</Button>
					{this.state.isUploadingInProgress ? <CircularProgress size={24} style={{ margin: '0 1rem' }} /> : ''}
				</label>
			</>
		)
	}
}

export default Upload;
