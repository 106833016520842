import React from 'react';
import './DocumentSelector.css';
import TemplateDocSelector from '../TemplateDocSelector';
import lib_document from '../../api/document';

class DocumentSelector extends React.Component {
	async getDocumentChildren(parent = null, version = undefined) {
		const subdocuments = (await lib_document.getDocumentSubdocuments(parent, version)).map(function(item) {
			return({
				...item,
				children: Object.keys(item.subdocuments).map(function(child_id) {
					return({
						id: child_id
					});
				})
			});
		});

		return(subdocuments);
	}

	async deleteDocument(parent) {
		/* XXX:TODO: This should not be a window.confirm */
		if (window.confirm('Are you sure you want to delete the selected item?')) {
			let deletion_error;
			try {
				await lib_document.deleteDocument(parent);
			} catch (_deletion_error) {
				deletion_error = _deletion_error;
			}

			if (deletion_error === undefined) {
				/* XXX:TODO: This should not be an alert */
				alert("Deleted successfully");
			} else {
				/* XXX:TODO: This should not be an alert */
				alert('Delete failed: ' + deletion_error.toString());
			}
		}
	}

	render() {
		return(
			<TemplateDocSelector
				action = 'Edit'
				display_type = 'Document'
				header_type = 'Document'
				content_type = 'documents'
				getChildren = {this.getDocumentChildren}
				deleteItem = {(id) => {
					this.deleteDocument(id)
				}}
			/>
		);
	}
}

export default DocumentSelector;
