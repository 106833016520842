import React from 'react';

import tinymce from 'tinymce/tinymce';

import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/noneditable';

import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import nunjucks_utils from '../../lib/utils/nunjucks_utils';

import ExpressionBuilder from '../ExpressionBuilder';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

const uuid = require('uuid');

const Seperator = '|';
const editorClassName = 'tinymce_a3d9a401ff07cff669c0ca18636b3fec';
const variableClassNamePrefix = 'var_a3d9a401ff07cff669c0ca18636b3fec';

const id_map = {};
const temp_element = '<span id="remove">temporary element</span>';

class RichTextEditor extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.state = {
			dialog_open: false,
			style_formats: [
				{ title: 'Paragraph', format: 'paragraph' },
				{ title: 'Heading 1', format: 'heading1' },
				{ title: 'Heading 2', format: 'heading2' },
				{ title: 'Heading 3', format: 'heading3' },
				{ title: 'Heading 4', format: 'heading4' },
				{ title: 'Heading 5', format: 'heading5' },
				{ title: 'Heading 6', format: 'heading6' },
				{ title: 'Preformatted', format: 'preformatted' },
			],
			formats: {
				paragraph: { selector: 'p' },
				heading1: { block: 'h1' },
				heading2: { block: 'h2' },
				heading3: { block: 'h3' },
				heading4: { block: 'h4' },
				heading5: { block: 'h5' },
				heading6: { block: 'h6' },
				preformatted: { block: 'pre' },
			},
			symbols: [],
			consume_whitespace_left: false,
			consume_whitespace_right: false
		}

		this.id = `mce_${uuid.v4()}`;
		this.class_suffix = '_a3d9a401ff07cff669c0ca18636b3fec';

		id_map[this.id] = {
			onChange: props.onChange,
			onBlur: props.onBlur
		};

		this.comment_icon = "<svg width='15' height='15' viewBox='0 0 15 15'><path id='Icon_feather-message-circle' data-name='Icon feather-message-circle' d='M18.5,11.111a6.518,6.518,0,0,1-.7,2.956,6.611,6.611,0,0,1-5.911,3.656,6.518,6.518,0,0,1-2.956-.7L4.5,18.5l1.478-4.433a6.518,6.518,0,0,1-.7-2.956A6.611,6.611,0,0,1,8.933,5.2a6.518,6.518,0,0,1,2.956-.7h.389A6.6,6.6,0,0,1,18.5,10.722Z' transform='translate(-4 -4)' fill='none' stroke='#0052cc' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/></svg>";
		this.edit_icon = "<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'><path id='Icon_feather-edit-2' data-name='Icon feather-edit-2' d='M12.834,3.8a1.854,1.854,0,1,1,2.622,2.622L6.606,15.274,3,16.257l.983-3.606Z' transform='translate(-2.5 -2.757)' fill='none' stroke='#0052cc' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/></svg>";
		this.remove_icon = "<svg xmlns='http://www.w3.org/2000/svg' width='13.093' height='13.09' viewBox='0 0 13.093 13.09'><path id='Icon_ionic-ios-close' data-name='Icon ionic-ios-close' d='M18.707,17.287,22.993,13a1,1,0,0,0-1.42-1.42l-4.286,4.286L13,11.581A1,1,0,1,0,11.581,13l4.286,4.286-4.286,4.286A1,1,0,0,0,13,22.993l4.286-4.286,4.286,4.286a1,1,0,0,0,1.42-1.42Z' transform='translate(-10.785 -10.695)' fill='#0052cc' stroke='#fff' stroke-width='1'/></svg>";
	}

	perform_update(id) {
		const init_args = {
			base_url: '/static/tinymce',
			plugins: [
				'table', 'lists', 'image', 'link', 'noneditable'
			].join(' '),
			toolbar: [
				'undo', 'redo', 'fontselect', Seperator, 'styleselect', 'symbols',
				'expression_builder', Seperator, 'bold', 'italic', 'forecolor',
				'backcolor', Seperator, 'alignleft',
				'aligncenter', 'alignright', 'alignjustify',
				Seperator, 'bullist', 'numlist', 'outdent',
				'indent', Seperator, 'removeformat', Seperator,
				'table', Seperator, 'comment', 'edit', 'del', Seperator
			].join(' '),
			menubar: false,
			elementpath: false,
			branding: false,
			content_css: '/tinymce.css',
			skin_url: '/skin',
			block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
			font_formats: 'Andale Mono=andale mono,times; Times=times, Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
			formats: this.state.formats,
			style_formats: this.state.style_formats,
			setup: (editor) => {
				editor.ui.registry.addIcon('commentIcon', this.comment_icon);
				editor.ui.registry.addIcon('editIcon', this.edit_icon);
				editor.ui.registry.addIcon('removeIcon', this.remove_icon);

				editor.ui.registry.addButton('comment', {
					// text: "Comment",
					icon: 'commentIcon',
					onAction: function () {
						comment();
					}
				});
				editor.ui.registry.addButton('edit', {
					// text: "Edit",
					icon: 'editIcon',
					onAction: function () {
						edit();
					}
				});
				editor.ui.registry.addButton('del', {
					// text: "Del",
					icon: 'removeIcon',
					onAction: function () {
						del();
					}
				});

				/*
				 * Helper Function
				 */
				const comment = () => {
					window.alert('comment pressed');
				};

				const del = () => {
					window.alert('remove pressed');
				};

				const edit = () => {
					window.alert('edit pressed');
				}


				editor.ui.registry.addContextToolbar('textselection', {
					predicate: function (node) {
						return !editor.selection.isCollapsed();
					},
					items: 'comment edit del',
					position: 'selection',
					scope: 'node'
				});

				editor.ui.registry.addMenuButton('symbols', {
					text: 'Symbols',
					fetch: (callback) => {

						const all_items = [];
						const symbols = this.state.symbols;
						for (const symbol of symbols) {

							let style = '';

							for (const key in symbol.styles) {
								style = style + `${key}:${symbol.styles[key]};`
							}

							symbol['onAction'] = function() {
								editor.insertContent(`<div style=${style}>${symbol.glyph}<div>`)
							}

							all_items.push(symbol);
						}

						const items = all_items;
						callback(items);
					}
				});

				editor.on('Change', (event) => {
					const value = event.level.content;
					const editor_id = event.target.id;

					const send_event = {
						target: {
							value
						}
					};

					if (id_map[editor_id].onChange) {
						id_map[id].onChange({ ...send_event });
					}

					if (id_map[editor_id].onBlur) {
						id_map[id].onBlur({ ...send_event });
					}
				});

				editor.on('Paste', () => {
					setTimeout(() => {
						let content = editor.getContent();
						const all_variables = this.extract_all_variables(content);
						all_variables.forEach(current_variable => {
							content = this.modify_content_for_variable(content, current_variable, false);
						});
						content = content + temp_element;
						editor.setContent(content);
						this.set_cursor_in_editor(editor);
					}, 100);
				});

				editor.on('keypress', (event) => {
					const key = event.key;

					if (this.state.consume_whitespace_left === true) {
						this.setState({consume_whitespace_left: false});
					}

					if (this.state.consume_whitespace_right === true) {
						this.setState({consume_whitespace_right: false});
					}

					const editor_range = editor.selection.getRng();
					const node = editor_range.commonAncestorContainer;
					const node_value = node.textContent;
					const node_length = node_value.length;

					const target_char = node_value.charAt(node_length - 1);

					if (key === '{' && target_char === '{') {
						editor.execCommand('mceInsertContent', false, key);
						this.setState({dialog_open: true});
						this.get_dialog(editor, null, null, '{{');
					}

					if (key === '%' && target_char === '{') {
						editor.execCommand('mceInsertContent', false, key);
						this.setState({dialog_open: true});
						this.get_dialog(editor, null, null, '{%');
					}

				})

				editor.on('mouseup', (event) => {
					const node_id = editor.selection.getNode().id;

					const class_name = event.target.className;
					if (class_name.includes('mceNonEditable')) {
						const selected_text = event.target.textContent;
						let trigger_keys = selected_text[0] + selected_text[1];
						if (trigger_keys === '{%' && selected_text[2] === '-') {
							trigger_keys = trigger_keys + selected_text[2];
						}
						this.setState({dialog_open: true});
						this.get_dialog(editor, selected_text, node_id, trigger_keys);
					}
				})

				let variables_autocomplete_obj = [];

				/*
				 * Plugin to provide variable suggestions and autocompletion in TinyMCE
				 */
				editor.ui.registry.addAutocompleter('variables', {
					ch: '{',
					minChars: 1,
					columns: 1,
					fetch: (pattern) => {
						if (this.props.all_variables !== undefined) {
							variables_autocomplete_obj = Object.keys(this.props.all_variables).map((item) => {
								return {
									text: `${item}`,
									value: item.toLowerCase()
								}
							});
						}

						const matched_chars = variables_autocomplete_obj.filter((char) => {
							return char.value.indexOf(pattern.toLowerCase()) !== -1;
						});

						return new tinymce.util.Promise((resolve) => {
							const results = matched_chars.map((char) => {
								return {
									value: char.value,
									text: char.text,
								}
							});
							resolve(results);
						});
					},
					matches: (rng, text, pattern) => {
						if (pattern.endsWith('}}')) {
							pattern = pattern.replace('}}', '');
							if (this.is_variable_in_valid_format(pattern)) {
								let content = editor.getContent();
								content = this.modify_content_for_variable(content, pattern, true);
								editor.setContent(content);
								this.set_cursor_in_editor(editor);
							} else {
								/**
								 * Introduced an hack to make matches consistent on variable autocomplete,
								 * returning false when variable is in invalid format
								 */
								return false;
							}
						}
						return true;
					},
					onAction: (autocompleteApi, rng, value) => {
						editor.selection.setRng(rng);
						/**
						 * Find original variable casing to replace it with
						 */
						variables_autocomplete_obj.forEach(item => {
							if (item.value === value) {
								value = item.text;
							}
						})
						editor.insertContent(`{${value}}}`);
						let content = editor.getContent();
						content = this.modify_content_for_variable(content, value, true);
						editor.setContent(content);
						autocompleteApi.hide();
						this.set_cursor_in_editor(editor);
					}
				});
			}
		}

		if (id !== '') {
			tinymce.remove('#' + id);
			init_args.selector = '#' + id;
		} else {
			init_args.selector = 'textarea.' + editorClassName;
		}

		tinymce.init(init_args);
	}

	is_existing_variable(variable) {
		let variable_found = false;

		if (this.props.all_variables === undefined) {
			return(variable_found);
		}

		for (const item of Object.keys(this.props.all_variables)) {
			if (variable.toLowerCase() === item.toLowerCase()) {
				variable_found = true;
				break;
			}
		}
		return variable_found;
	}

	modify_content_for_variable(content, variable, add_temp_element) {
		/**
		 * If the content is not already inside a highlighter span,
		 * replace it with the highlighter
		 */
		const replace_html_tag = new RegExp(`<span.*?</span>|({{${variable}}})`, 'g');
		content = content.replace(replace_html_tag, (unmatched, group1) => {
			if (!group1) {
				return unmatched;
			} else {
				if (this.is_existing_variable(variable)) {
					return this.get_variable_block(variable, 'valid', add_temp_element);
				} else {
					return this.get_variable_block(variable, 'invalid', add_temp_element);
				}
			}
		});
		return content;
	}

	get_variable_block(variable, type, add_temp_element) {
		let variable_block = `<span class="${this.get_variable_class_name(variable)} ${type}${this.class_suffix} mceNonEditable">{{${variable}}}</span>`;
		if (add_temp_element === true) {
			variable_block = variable_block + temp_element;
		}
		return variable_block;
	}

	get_variable_class_name(variable) {
		return `${variableClassNamePrefix}_${variable.toLowerCase()}`;
	}

	validate_variables() {
		/**
		 * Check if each variable are in the right state
		 * if not, toggle their classes to reflect the same
		 */
		const editor = tinymce.get(this.id);
		if (editor !== null) {
			const content = editor.getContent();
			let all_variables;
			if (content) {
				all_variables = this.extract_all_variables(content);
			}

			if (all_variables && all_variables.length > 0) {
				all_variables.forEach(variable => {
					try {
						const undefinedVariables = editor.getBody().querySelectorAll(`.${this.get_variable_class_name(variable)}`);
						for (let i = 0; i < undefinedVariables.length; i++) {
							if (this.is_existing_variable(variable)) {
								editor.dom.removeClass(undefinedVariables[i], `invalid${this.class_suffix}`);
								editor.dom.addClass(undefinedVariables[i], `valid${this.class_suffix}`);
							} else {
								editor.dom.removeClass(undefinedVariables[i], `valid${this.class_suffix}`);
								editor.dom.addClass(undefinedVariables[i], `invalid${this.class_suffix}`);
							}
						}
					} catch (err) {
						//skip error for invalid variables selector
					}
				});
			}
		}
	}

	handle_close(editor, selected_text, selected_node_id, keys, consume_whitespace_left, consume_whitespace_right) {
		this.setState({dialog_open: false});

		const convert_keys = {
			'{{': '}}',
			'{%': '%}',
		}

		let opening_syntax = keys;
		let closing_syntax;

		if (convert_keys[keys] !== undefined) {
			closing_syntax = convert_keys[keys];
		}

		if ((keys === '{%' || keys === '{%-') && consume_whitespace_left === true) {
			opening_syntax = '{%-';
		}

		if ((keys === '{%' || keys === '{%-') && consume_whitespace_left === false) {
			opening_syntax = '{%';
		}

		if ((keys === '{%' || keys === '{%-') && consume_whitespace_right === true) {
			closing_syntax = '-%}';
		}

		if ((keys === '{%' || keys === '{%-') && consume_whitespace_right === false) {
			closing_syntax = '%}';
		}

		let expression;
		if (keys === '{{' && this.is_variable_in_valid_format(this.state.expression)) {
			const variable = this.state.expression;
			let type;
			if (this.is_existing_variable(variable)) {
				type = 'valid';
			} else {
				type = 'invalid';
			}

			expression = `<span class="mceNonEditable" id="${type}${this.class_suffix}_${uuid.v4()}">{{${variable}}}</span>`;
		} else {
			let expression_text;
			if (keys !== '{{') {
				expression_text = `${opening_syntax} ${this.state.expression} ${closing_syntax}`;
			} else {
				expression_text = `${opening_syntax}${this.state.expression}${closing_syntax}`;
			}

			expression = `<span class="mceNonEditable" id="${uuid.v4()}">${expression_text}</span>`;
		}

		/*
		 * Remove trigger keys from Rich Text Editor since
		 * they will be replaced by the expression.
		 */
		const trigger_keys_valid = (keys === '{{' || keys === '{%' || keys === '{%-');
		if (selected_node_id === null && trigger_keys_valid) {
			this.remove_trigger_keys(editor, keys);
		}

		/*
		 * If the user edits an element, replace selected element with
		 * new element.
		 *
		 * If the user adds an element, insert it into the Rich Text Editor
		 */
		if (selected_node_id === null) {
			editor.execCommand('mceInsertContent', false, expression);
		} else {
			this.replace_non_editable_element(editor, selected_node_id, expression);
		}
	}

	remove_trigger_keys(editor, keys) {
		const editor_range = editor.selection.getRng();
		const node = editor_range.commonAncestorContainer;
		const range = document.createRange();

		if (keys !== '{%-' && editor_range.endOffset - 2 < node.length) {
			range.selectNodeContents(node);
			range.setStart(node, editor_range.endOffset - 2);
		}

		if (keys === '{%-' && editor_range.endOffset - 3 < node.length) {
			range.selectNodeContents(node);
			range.setStart(node, editor_range.endOffset - 3);
		}

		range.setEnd(node, editor_range.endOffset);
		range.deleteContents();
		editor.focus();
	}

	replace_non_editable_element(editor, selected_node_id, expression) {
		const selected_element = editor.dom.get(selected_node_id);
		const new_element = document.createElement('span');
		new_element.innerHTML = expression;
		editor.dom.replace(new_element, selected_element, false);
	}

	get_whitespace_options(selected_text) {
		if (selected_text !== null) {
			if (selected_text.substring(0, 3) === '{%-') {
				this.setState({consume_whitespace_left: true})
			} else {
				this.setState({consume_whitespace_left: false})
			}

			if (selected_text.substring(selected_text.length - 3) === '-%}') {
				this.setState({consume_whitespace_right: true})
			} else {
				this.setState({consume_whitespace_right: false})
			}
		}

		return(
			<div className='whitespace-container' style={{display: 'contents'}}>
				<div>
					<label>Consume whitespace left: </label>
					<input key={uuid.v4()} type='checkbox' defaultChecked={this.state.consume_whitespace_left}
						onClick={() => {this.setState({consume_whitespace_left: !this.state.consume_whitespace_left})}}></input>
				</div>
				<div>
					<label>Consume whitespace right: </label>
					<input key={uuid.v4()} type='checkbox' defaultChecked={this.state.consume_whitespace_right}
						onClick={() =>{this.setState({consume_whitespace_right: !this.state.consume_whitespace_right})}}></input>
				</div>
			</div>
		)
	}

	get_dialog(editor, selected_text, selected_node_id, keys) {
		const autoCompleteConfig = [
			{
				trigger: ' ',
				options: nunjucks_utils.constants.operators,
				excludePredecessors: [...nunjucks_utils.constants.operators, undefined]
			},
			{
				trigger: '|',
				options: nunjucks_utils.constants.filters
			},
			{
				trigger: nunjucks_utils.constants.any,
				options: nunjucks_utils.filters.keys(this.props.all_variables)
			}
		]

		/*
		 * Check for consume whitespace options
		 */
		let consume_whitespace_options;
		if (keys === '{%' || keys === '{%-') {
			consume_whitespace_options = this.get_whitespace_options(selected_text);
		}

		/*
		 * Slice opening and closing syntax from selected
		 * text so only expression displays in ExpressionBuiler
		 * text field.
		 */
		if (selected_text !== null) {
			if (selected_text.substring(0, 3) === '{%-') {
				selected_text = selected_text.slice(3, selected_text.length - 3);
			} else if (selected_text.substring(selected_text.length - 3) === '-%}') {
				selected_text = selected_text.slice(2, selected_text.length - 3);
			} else {
				selected_text = selected_text.slice(2, -2);
			}
		}

		let value = '';
		if (selected_text !== null) {
			value = selected_text;
		}

		const expression_builder =
		<div>
			<Dialog onClose={() => {
				this.setState({dialog_open: false})
			}} open={this.state.dialog_open}>
				{consume_whitespace_options}
				<ExpressionBuilder
					className='editor-item-autocomplete-input'
					value={value}
					is_tiny_mce={true}
					variables={this.props.all_variables}
					autoCompleteConfig={autoCompleteConfig}
					onChange={(event) => this.setState({expression: event.target.value})}
				/>
				<Button onClick={() => {this.handle_close(editor, selected_text, selected_node_id, keys, this.state.consume_whitespace_left, this.state.consume_whitespace_right)}}>Enter</Button>
			</Dialog>
		</div>
		this.setState({expression_builder_dialog: expression_builder})
	}

	set_cursor_in_editor(editor) {
		/**
		 * cursor disappears on inserting non-editable element,
		 * hence, introduced a small hack that adds a temp element after each insert
		 * and remove it, once cursor is placed after it
		 */
		const temp_element = editor.getBody().querySelector(`#remove`);
		editor.selection.select(temp_element, true);
		editor.selection.collapse(false);
		editor.dom.remove(temp_element);
	}

	extract_all_variables(content) {
		const stringExtractor = this.extract_string(['{{', '}}']);
		let variablesInEditor = stringExtractor(content);
		if (variablesInEditor === undefined) {
			variablesInEditor = [];
		}
		const validVariablesInEditor = variablesInEditor.filter((...args) => {
			return(this.is_variable_in_valid_format(...args));
		});
		return validVariablesInEditor;
	}

	is_variable_in_valid_format(variable) {
		/**
		 * test if variable contains characters of the invalid format
		 * if so return false
		 */
		const invalid_variable_format_regexp = /[()'"|[\]]/;
		if (invalid_variable_format_regexp.test(variable) === true) {
			return false;
		} else {
			return true;
		}
	}

	extract_string([beg, end]) {
		const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
		const normalise = (str) => str.slice(beg.length, end.length * -1);
		return function (str) {
			if (str.match(matcher)) {
				return str.match(matcher).map(normalise);
			}
		}
	}

	check_for_updates() {
		const update_ids = [];
		Array(...document.getElementsByClassName(editorClassName)).forEach(function (element) {
			if (!element) {
				return;
			}

			const parentElement = element.parentElement;
			if (!parentElement) {
				return;
			}

			const iframe = parentElement.getElementsByTagName('iframe')[0];
			if (!iframe) {
				update_ids.push(element.id);
				return;
			}

			const iframeBody = iframe.contentWindow.document.getElementsByTagName('body')[0];
			if (!iframeBody) {
				update_ids.push(element.id);
				return;
			}

			if (iframeBody.className === '') {
				update_ids.push(element.id);
				/*
				 * The next return is technically useless, but
				 * we want to keep it for consistency.
				 */
				// eslint-disable-next-line
				return;
			}
		});

		update_ids.forEach((update_id) => {
			this.perform_update(update_id);
		});
	}

	componentDidMount() {
		this.check_for_updates();
		this.construct_style_format(this.props.format)
		this.construct_symbol_format(this.props.symbol)
	}

	componentDidUpdate(nextProps, nextState) {
		this.check_for_updates();
		this.validate_variables();

		if (nextProps.format !== this.props.format) {
			this.construct_style_format(this.props.format)
		}

		if (nextProps.symbol !== this.props.symbol) {
			this.construct_symbol_format(this.props.symbol)
		}
	}

	construct_symbol_format(user_symbols) {
		const all_symbols = this.state.symbols;

		for (const key in user_symbols) {
			const symbol = user_symbols[key];

			if (Object.keys(symbol).length === 0) {
				return;
			}

			const styles = {};
			let symbol_name = '';
			if (symbol.symbol_name !== undefined) {
				symbol_name = symbol.symbol_name;
			}

			let symbol_glyph = '';
			if (symbol.glyph !== undefined) {
				symbol_glyph = symbol.glyph;
			}

			const symbols = {
				type: 'menuitem',
				text: symbol_name,
				glyph: symbol_glyph,
				styles
			};

			all_symbols.push(symbols);

			this.setState({ symbols: all_symbols });
			this.perform_update(this.id);
		}
	}

	construct_style_format(user_styles) {
		const all_style_formats = this.state.style_formats;
		const all_formats = this.state.formats;

		for (const key in user_styles) {
			const style = user_styles[key];

			if (Object.keys(style).length === 0) {
				return;
			}

			const styles = {};

			if (style.font_size !== undefined) {
				styles['fontSize'] = style.font_size;
			}

			if (style.font_unit !== undefined) {
				styles['fontSize'] = styles['fontSize'] + style.font_unit;
			}

			if (style.font_family !== undefined) {
				styles['fontFamily'] = style.font_family.replace(/\s/g, '');
			}

			if (style.font_style !== undefined) {
				switch(style.font_style) {
					case 'Italic':
						styles['font-style'] = style.font_style;
						break;
					case 'Bold':
						styles['font-weight'] = style.font_style;
						break;
					case 'Underline':
						styles['text-decoration'] = style.font_style;
						break;
					default:
						styles['text-decoration'] = style.font_style;
						break;
				}
			}

			if (style.line_height !== undefined) {
				styles['line-height'] = style.line_height;
			}

			if (style.padding_left !== undefined) {
				const padding_left_em = style.padding_left * 6.0225;
				styles['display'] = 'inline-block';
				styles['padding-left'] = padding_left_em + 'em';
			}

			if (style.padding_right !== undefined) {
				const padding_right_em = style.padding_right * 6.0225;
				styles['display'] = 'inline-block';
				styles['padding-right'] = padding_right_em + 'em';
			}

			if (style.padding_top !== undefined) {
				styles['padding-top'] = style.padding_top + 'pt';
			}

			if (style.padding_bottom !== undefined) {
				styles['padding-bottom'] = style.padding_bottom + 'pt';
			}

			if (style.hanging_indent !== undefined) {
				const hanging_indent_em = style.hanging_indent * 6.0225;
				styles['text-indent'] = '-' + hanging_indent_em + 'em';
			}

			if (style.font_color !== undefined) {
				styles['color'] = style.font_color;
			}

			const format = {
				inline: 'span',
				styles
			}

			let title = '';
			if (style.style_name !== undefined) {
				title = style.style_name;
			}

			const style_format = { title: title, format: title.trim() };

			all_formats[title.trim()] = format;
			all_style_formats.push(style_format);

			this.setState({ formats: all_formats });
			this.setState({ style_formats : all_style_formats });

			this.perform_update(this.id);
		}
	}

	render() {
		let expression_builder_dialog;
		if (this.state.expression_builder_dialog !== undefined && this.state.dialog_open) {
			expression_builder_dialog = this.state.expression_builder_dialog;
		}

		return(
			<div>
				{expression_builder_dialog}
				<textarea {...this.props} id={this.id} className={editorClassName} style={{ visibility: 'hidden', border: 'none' }}>
				</textarea>
			</div>
		);
	}
}

export default RichTextEditor;
