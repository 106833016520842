import React from 'react';
import './ConfirmationMessage.css';
import { Button } from '../../lib/ui';

class ConfirmationMessage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			message: ''
		}

		this.timeout = undefined;
	}

	timeoutHandler(timeout) {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = undefined;
		}

		if (timeout > 0) {
			this.timeout = setTimeout(() => {
				this.timeout = undefined;
				this.closeConfirmationMessage();
			}, timeout);
		}
	}

	notifyUserSuccess(action, type, user_message) {
		let message = `[^] ${action} ${type} Succeeded`;

		if (user_message) {
			message = `${message}: ${user_message}`;
		}

		this.notifyUserMessage(message);
	}

	notifyUserFailure(action, type, user_message) {
		let message = '[X]';
		if (action && type) {
			message = `${message} ${action} ${type} Failed`;
		}
		if (user_message) {
			message = `${message}: ${user_message}`;
		}
		this.notifyUserMessage(message, -1);
	}

	notifyUserMessage(message, timeout = 5000) {
		this.setState({message: message});
		this.timeoutHandler(timeout);
	}

	closeConfirmationMessage() {
		this.setState({message: ''});

		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = undefined;
		}
	}

	render() {
		return(
			<div>
				{this.state.message !== '' &&
					<div className='confirmation-message'>
						<p>{this.state.message}</p>
						<Button color="primary" onClick={() => {
							this.closeConfirmationMessage()
						}}>Close</Button>
					</div>
				}
			</div>
		)
	}
}

export default ConfirmationMessage;
