import React from 'react';
import {AccessTime, CheckCircleOutline, ChatBubbleOutline, StarBorderRounded, StarRounded, MoreHoriz, LinkOffOutlined} from '@material-ui/icons';
import {List, ListItem, Grid, Typography, CircularProgress, Box} from '@material-ui/core';
import {Link} from 'react-router-dom';

import './RecentViews.css';

import buffer_utils from '../../lib/utils/buffer_utils';

const styles = {
	templateTextStyle: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	},
	individualContainerWidth: {
		width: '420px'
	},
	minWidthContaier: {
		width: '340px'
	},
	individualContainer: {
		backgroundColor: 'darkgrey',
		marginTop: '16px',
		height: '1px'
	},
	templateStatusEstimatedTimeView: {
		marginBottom: '15px',
		fontSize: '0.7rem',
		float: 'right',
		opacity: '0.8'
	},
	templateStatusView: {
		fontSize: '0.6rem',
		borderRadius: '5px',
		padding: '5px',
		width: 'fit-content',
		float: 'right'
	},
	statusIcon: {
		fontSize: '1.9rem',
		padding: '10px'
	}
};

const colorStateMap = {
	'APPROVED': '#a3fff6',
	'IN REVIEW': '#a6d5ff',
	'COMPLETED': '#bbfc86',
	'HIGHLIGHTED ICON': '#ffb96e',
	'TITLE': '#0278c7'
};

const defaults = {
	backgroundColor: '#dbdbdb',
	progressColor: '#f1f3fa'
};

class RecentViews extends React.Component {
	/* XXX:TODO: What is this for ? */
	getTemplateReviewsView(type, metadata) {
		if (type !== 'documents' && type !== 'templates') {
			return;
		}

		if (!metadata || ! metadata.reviews || !Array.isArray(metadata.reviews)) {
			return;
		}

		return(
			<div className="templates-reviews-container">
				<ChatBubbleOutline style={{fontSize:'1rem'}}/>
				<div className="templates-reviews-count-view">
					<span>{metadata.reviews.length > 1 ? metadata.reviews.length : ' '}</span>
				</div>
			</div>
		);
	}

	handleHighlightClick(id) {
		if (this.props.highlightCallBack) {
			this.props.highlightCallBack(id);
		}
	}

	getHighlightedIcon(data, id) {
		const args = {
			key: `higlight_${id}`,
			onClick: () => {
				this.handleHighlightClick(id)
			}
		}

		if (data && data.isHighlighted) {
			return <StarRounded style={{
				color: colorStateMap['HIGHLIGHTED ICON']
			}} {...args}/>
		} else {
			return <StarBorderRounded {...args}/>
		}
	}

	forBuffer() {
		if (this.props.type.includes('Buffer') /* XXX:TODO: This is incredibly daft */) {
			return(true);
		}

		return(false);
	}

	getTargetTypeIcon(type, template) {
		const status = template.status;

		if (type === 'templates') {
			let backgroundColor = colorStateMap[status];
			if (backgroundColor === undefined) {
				backgroundColor = defaults.backgroundColor;
			}
			return(
				<div className="completed-icon-view" style={{backgroundColor}}>
					{template && template.isCompleted ? <CheckCircleOutline style={styles.statusIcon}/> : <AccessTime style={styles.statusIcon}/>}
				</div>
			);
		} else if (type === 'documents') {
			const value = template && template.percentageCompleted ? template.percentageCompleted : 0;
			return(
				<div className="document-status-icon-view" style={{width:'60px', marginTop:'5px'}}>
					<Box position="relative" display="inline-flex">
						<CircularProgress variant="determinate"
							value={100}
							thickness={4}
							size={50}
							style={{
								position: "absolute",
								color: defaults.progressColor
							}}
						/>
						<CircularProgress variant="static" thickness={4} value={value} size={50}/>
						<Box
							top={0}
							left={0}
							bottom={0}
							right={0}
							position="absolute"
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Typography variant="subtitle1" component="div" color="textSecondary">{value}</Typography>
						</Box>
					</Box>
				</div>
			);
		}
	}

	getTemplateSubTitle(type, template){
		if (type === 'projects'){
			return template ? `${template.studies ? template.studies : 0} Studies,${template.documents ? template.documents : 0} Documents` : '';
		}else{
			return template && template.tagline ? template.tagline : ' ';
		}
	}

	getStatus(type, template) {
		const statusBgColor = template.status && colorStateMap[template.status] ? colorStateMap[template.status] : defaults.backgroundColor;

		if ((type === 'templates' || type === 'documents') && template) {
			return <div className="template-status" style={type === 'documents' && template.estimatedTime ? {marginTop:'5px'} : {marginTop:'35px'}}>
				<Typography style={styles.templateStatusEstimatedTimeView}>
					{type === 'documents' && template.estimatedTime ? (`Est. ${template.estimatedTime}`) : ' '}
				</Typography>
				<Typography style={{...styles.templateStatusView, backgroundColor: statusBgColor}}>
					{template && template.status ? template.status : 'NO STATUS'}
				</Typography>
			</div>
		} else if (this.forBuffer() && template) {
			return <div className="template-status" style={type === 'documents' && template.estimatedTime ? {marginTop:'5px'} : {marginTop:'35px'}}>
				<Typography style={{...styles.templateStatusView, backgroundColor: statusBgColor}}>
						DRAFT
				</Typography>
			</div>
		} else {
			return null;
		}
	}

	getIndividualTemplateView(template) {
		if (!(template.id && template.name)) {
			return;
		}

		let width;
		if (this.props.containerWidth === 'large') {
			width = styles.individualContainerWidth;
		} else {
			width = styles.minWidthContaier;
		}

		let widthOfTitle = 40;

		if (!template.status) {
			widthOfTitle += 15;
		}

		let id, type;

		if (this.forBuffer()) {
			id = buffer_utils.get_item_id_from_buffer_id(template.id);
			if (this.props.type === 'Templates Buffer') {
				type = 'templates';
			} else {
				type = 'documents';
			}
		} else {
			id = template.id;
			type = this.props.type;
		}

		const routeLink = this.props.routePath ? this.props.routePath(id, type) : ' ';

		return(
			<ListItem key={template.id} style={{marginBottom:'16px'}}>
				<div key={`list_item_${template.id}`}>
					<Grid container>
						{template.status ?
							<Grid item le={{width:'15%'}}>
								{this.getTargetTypeIcon(this.props.type, template)}
							</Grid> : ''
						}
						<Grid item container style={{width: `${widthOfTitle}%`, cursor:'pointer'}}>
							<Typography variant="subtitle1" style={styles.templateTextStyle}>
								{!this.forBuffer() && <Link style={{textDecoration:'none', color:colorStateMap.TITLE}} to={routeLink}>{template.name}</Link>}
								{this.forBuffer() && <Link style={{textDecoration:'none', color:colorStateMap.TITLE}} to={{ pathname: routeLink, state: {buffer: template}}}>{template.name}</Link>}
							</Typography>
							<Typography variant="subtitle2" style={styles.templateTextStyle}>
								{this.getTemplateSubTitle(this.props.type, template)}
							</Typography>
						</Grid>
						<Grid item style={{width:'10%'}}>
							{this.getTemplateReviewsView(this.props.type, template)}
						</Grid>
						<Grid item style={{width:'25%'}} >
							{this.getStatus(this.props.type, template)}
						</Grid>
						<Grid item style={{width:'10%'}}>
							{this.getHighlightedIcon(template, template.id)}
							<MoreHoriz className="template-more-options"/>
						</Grid>
					</Grid>
					<div style={{...styles.individualContainer, ...width}}/>
				</div>
			</ListItem>
		);
	}

	sortOnHighlight(item){
		if (item && item){
			return item.isHighlighted ? -1 : 1;
		}else{
			return 0;
		}
	}

	getListEmptyView() {
		let empty_message;

		if (this.props.loading === true) {
			empty_message = <span>Loading...</span>
		} else {
			empty_message = <span>No Recents to Show</span>
		}

		return(
			<div className="list-empty-view">
				<LinkOffOutlined/>
				<div className="list-empty-text">{empty_message}</div>
			</div>
		);
	}

	render() {
		let listItems;
		if (this.props.templates && Object.keys(this.props.templates).length >= 1) {
			listItems = Object.entries(this.props.templates).sort(this.sortOnHighlight).map((item) => {
				return(this.getIndividualTemplateView(item[1]));
			});
		} else {
			listItems = this.getListEmptyView();
		}

		/*
		 * Compute the title (XXX:TODO: This is a terrible interface)
		 */
		let title;
		if (this.forBuffer()) {
			title = this.props.type;
		} else {
			title = `Recent ${this.props.type}`;
		}

		return(
			<div>
				<p className='recent-templates-view-title'>{title}</p>
				<div className='recent-templates-view-container'>
					<List>
						{listItems}
					</List>
				</div>
			</div>
		);
	}
}

export default RecentViews;
