/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/data_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import recursive_object_utils from './recursive_object_utils';
const _testing = undefined;

const csv_parse_lib = require('csv-parse/lib/sync');

async function parse_csv(data, options = {}) {
	const csv_parse_options = {
		trim: true,
		skip_empty_lines: true,
		relax_column_count: true
	};

	let final_data = {};
	let type;
	let headers = {};
	let ordered_headers = [];

	/*
	 * The "auto" type will try various things -- even things that cannot
	 * be specified manually.  These are the "exploded" views.
	 *
	 * Exploded views are views which have already had row*columns
	 * evaluated and the data is just being stored with Row, Column, and
	 * Value columns.  We can then construct the right kind of data from
	 * this.
	 *
	 * Additionally, "auto" will try to figure out if it's something else
	 * that it recognizes and re-invoke the parser with those options.
	 */
	if (options.type === 'auto') {
		/*
		 * Determine if this is some kind of exploded view
		 *
		 * This exploded view will have the headers Row,
		 * Column, and Value and assign each value based
		 * on the intersection of the specified keys
		 */
		const parsed_data = csv_parse_lib(data, {
			...csv_parse_options,
			columns: false
		});

		/*
		 * Look for hints that this is an "exploded" view, which has
		 * column names like "Column", "Row", and "Value"
		 */
		const parsed_headers = parsed_data[0];

		/**
		 ** Append unique value to header if header is a duplicate
		 **/
		const header_values = deduplicate_headers(parsed_headers, options.type);
		headers = header_values.deduplicated_headers;
		ordered_headers = header_values.ordered_headers;
		const header_row = ordered_headers;

		let row_header_name, column_header_name, value_header_name;
		for (const header_item of header_row) {
			const header_item_lc = header_item.toLowerCase();

			if (['row'].includes(header_item_lc)) {
				row_header_name = header_item;
			}
			if (['col', 'column'].includes(header_item_lc)) {
				column_header_name = header_item;
			}
			if (['data', 'value'].includes(header_item_lc)) {
				value_header_name = header_item;
			}
		}

		/*
		 * If a value field was found, but no column or row field,
		 * look for a special field called "Key" and assume it is a
		 * column field name
		 */
		if (value_header_name !== undefined && column_header_name === undefined && row_header_name === undefined) {
			column_header_name = header_row.filter(function(header_item) {
				const header_item_lc = header_item.toLowerCase();
				if (header_item_lc === 'key') {
					return(true);
				}

				return(false);
			})[0];
		}

		/*
		 * Handle the exploded cases (columns-row, rows, columns)
		 */
		if (row_header_name !== undefined && column_header_name !== undefined && value_header_name !== undefined) {
			type = 'columns-rows';
			for (const row of parsed_data) {
				recursive_object_utils.set(final_data, [row[column_header_name]], row[row_header_name], row[value_header_name]);
			}
		} else if (row_header_name !== undefined && value_header_name !== undefined) {
			/*
			 * Exploded view with just rows
			 */
			/* XXX:TODO */
			throw(new kaialpha.UserError('Incomplete: Row-based data is not complete'));
		} else if (column_header_name !== undefined && value_header_name !== undefined) {
			/*
			 * Exploded view with just columns
			 */
			type = 'columns';
			const column_data = {};
			for (const row of parsed_data) {
				const column_name = row[column_header_name];
				const value = row[value_header_name];

				if (column_data[column_name] === undefined) {
					column_data[column_name] = [];
				}

				column_data[column_name].push(value);
			}

			const column_names = Object.keys(column_data);
			final_data = [];
			for (const values_index in column_data[column_names[0]]) {
				const row_data = {};
				for (const column_name of column_names) {
					row_data[column_name] = column_data[column_name][values_index];
				}
				final_data.push(row_data);
			}
		} else if (header_row[0] === '') {
			/*
			 * Unexploded view with Columns * Rows
			 *
			 * Re-invoke the parser with the correct type
			 */
			const forward_options = Object.assign(options, {
				type: 'columns-rows'
			});
			return(await parse_csv(data, forward_options));
		} else {
			/*
			 * Unexploded view with just columns, we already have the
			 * parsed data so don't re-parse it and just return
			 * what we have.
			 */
			type = 'columns';
			const header_values = deduplicate_headers(parsed_headers, options.type);
			headers = header_values.deduplicated_headers;
			ordered_headers = header_values.ordered_headers;
			const header_row = ordered_headers

			final_data = get_column_data(parsed_data, header_row);
		}
	} else if (options.type === 'columns-rows') {
		/*
		 * Columns * Rows, something like:
		 *
		 *             ,"Col1" ,"Col2"
		 *       "Row1","C1*R1","C2*R1"
		 *       "Row2","C1*R2","C2*R2"
		 *       "Row3","C1*R3","C2*R3"
		 *
		 * Since each individual cell has a name, this returns an
		 * object of objects:
		 *       "Col1": {
		 *           "Row1": "C1*R1",
		 *           "Row2": "C1*R2",
		 *           "Row3": "C1*R3"
		 *       },
		 *       "Col2": {
		 *           "Row1": "C2*R1",
		 *           "Row2": "C2*R2",
		 *           "Row3": "C2*R3"
		 *       }
		 */
		const parsed_data = csv_parse_lib(data, {
			...csv_parse_options,
			columns: false
		});

		const parsed_headers = parsed_data[0];

		/**
		 ** Append unique value to header if header is a duplicate
		 **/
		const header_values = deduplicate_headers(parsed_headers, options.type);
		headers = header_values.deduplicated_headers;
		ordered_headers = header_values.ordered_headers;
		const header_row = ordered_headers

		header_row.unshift('');
		for (const row of parsed_data.slice(1)) {
			const row_name = row[0];
			for (const column_name_index_str in header_row) {
				const column_name_index = Number(column_name_index_str);
				if (column_name_index === 0) {
					continue;
				}

				const column_name = header_row[column_name_index];

				recursive_object_utils.set(final_data, [column_name], row_name, row[column_name_index]);
			}
		}
		header_row.splice(0, 1);

		type = 'columns-rows';
	} else if (options.type === 'columns') {
		/*
		 * Columns, something like:
		 *
		 *       "Col1","Col2"
		 *       "C1V1","C2V1"
		 *       "C1V2","C2V2"
		 *       "C1V3","C2V3"
		 *
		 * Since only the columns have names, each row is given a
		 * numeric index (starting with 0) and the result is an
		 * array (one entry per row) with each value being a cell
		 * with the named columns:
		 *       [
		 *           {"Col1": "C1V1", "Col2": "C2V1"},
		 *           {"Col1": "C1V2", "Col2": "C2V2"},
		 *           {"Col1": "C1V3", "Col2": "C2V3"}
		 *       ]
		 */
		type = 'columns';
		const parsed_data = csv_parse_lib(data, {
			...csv_parse_options,
			columns: false
		});

		const parsed_headers = parsed_data[0];
		const header_values = deduplicate_headers(parsed_headers, options.type);
		headers = header_values.deduplicated_headers;
		ordered_headers = header_values.ordered_headers;
		const header_row = ordered_headers

		final_data = get_column_data(parsed_data, header_row);

	} else if (options.type === 'rows') {
		/* XXX:TODO */
		throw(new kaialpha.UserError('Incomplete: Row-based data is not complete'));
	}

	const retval = {
		data: final_data,
		type: type,
		'@metadata': {
			headers: headers,
			ordered_headers: ordered_headers
		}
	}

	return(retval);
}

if (_testing) {
	_testing.parse_csv = async function() {
		const checks = [
			{
				csv: ['A,B','1,2'],
				type: 'auto',
				result: {
					type: 'columns',
					data: [{ A: '1', B: '2' }],
					'@metadata': {
						headers: { A: 'A', B: 'B' },
						ordered_headers: ['A', 'B']
					}
				}
			},
			{
				csv: ['A,B','1,2','3,4'],
				type: 'auto',
				result: {
					type: 'columns',
					data: [{ A: '1', B: '2' }, { A: '3', B: '4' }],
					'@metadata': {
						headers: { A: 'A', B: 'B' },
						ordered_headers: ['A', 'B']
					}
				}
			},
			{
				csv: ['A,B','1,2','3,4'],
				type: 'columns',
				result: {
					type: 'columns',
					data: [{ A: '1', B: '2' }, { A: '3', B: '4' }],
					'@metadata': {
						headers: { A: 'A', B: 'B' },
						ordered_headers: ['A', 'B']
					}
				}
			},
			{
				csv: [',A,B','X,1,2','Y,3,4'],
				type: 'auto',
				result: {
					type: 'columns-rows',
					data: {
						A: { X: '1', Y: '3' },
						B: { X: '2', Y: '4' }
					},
					'@metadata': {
						headers: { A: 'A', B: 'B' },
						ordered_headers: ['A', 'B']
					}
				}
			},
			{
				csv: [',A,B','X,1,2','Y,3,4'],
				type: 'columns-rows',
				result: {
					type: 'columns-rows',
					data: {
						A: { X: '1', Y: '3' },
						B: { X: '2', Y: '4' }
					},
					'@metadata': {
						headers: { A: 'A', B: 'B' },
						ordered_headers: ['A', 'B']
					}
				}
			},
			{
				/* Test for duplicate headers */
				csv: ['A,A','1,2','3,4'],
				type: 'auto',
				result: {
					type: 'columns',
					data: [{ A: '1', 'A_1': '2' }, { A: '3', 'A_1': '4' }],
					'@metadata': {
						headers: {
							'A': 'A',
							'A_1': 'A'
						},
						ordered_headers: ['A', 'A_1']
					}
				}
			},
			{
				csv: ['row, A','1,2','3,4'],
				type: 'auto',
				result: {
					type: 'columns',
					data: [{ 'row': '1', A: '2' }, { 'row': '3', 'A': '4' }],
					'@metadata': {
						headers: { row: 'row', A: 'A' },
						ordered_headers: ['row', 'A']
					}
				}
			},
			{
				csv: ['col, A','1,2','3,4'],
				type: 'auto',
				result: {
					type: 'columns',
					data: [{ 'col': '1', A: '2' }, { 'col': '3', 'A': '4' }],
					'@metadata': {
						headers: { col: 'col', A: 'A' },
						ordered_headers: ['col', 'A']
					}
				}
			},
			{
				csv: ['data, A','1,2','3,4'],
				type: 'auto',
				result: {
					type: 'columns',
					data: [{ 'data': '1', A: '2' }, { 'data': '3', 'A': '4' }],
					'@metadata': {
						headers: { data: 'data', A: 'A' },
						ordered_headers: ['data', 'A']
					}
				}
			}
		];

		for (const check of checks) {
			const data = check.csv.join('\n');
			const result = await parse_csv(data, { type: check.type });

			const result_normalized = {
				type: result.type,
				data: result.data,
				'@metadata': result['@metadata']
			};

			const check_result_normalized = {
				type: check.result.type,
				data: check.result.data,
				'@metadata': check.result['@metadata']
			};

			/* istanbul ignore if */
			if (JSON.stringify(result_normalized) !== JSON.stringify(check_result_normalized)) {
				throw(new Error(`Failed while processing ${JSON.stringify(check)} -- got: ${JSON.stringify(result_normalized)}`));
			}
		}

		return(true);
	};

	_testing.parse_csv_exceptions = async function () {
		const data = ['row, value', '1,2'];
		try {
			await parse_csv(data.join('\n'), { type: 'auto' });
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("Failed parse csv expcetion case, expected a exception but passed");
	}
}

function get_column_data(parsed_data, header_row) {
	const final_data = [];

	for (const row of parsed_data.slice(1)) {
		const row_data = {};
		let values_index = 0;
		for (const column_name of header_row) {
			row_data[column_name] = row[values_index];
			values_index++;
		}
		final_data.push(row_data);
	}

	return(final_data);
}

function deduplicate_headers(parsed_headers, type) {
	const header_count = {};
	const headers = {
		deduplicated_headers: {},
		ordered_headers: []
	};

	if (type === 'columns-rows') {
		parsed_headers.splice(0, 1);
	}

	for(const header of parsed_headers) {
		/*
		 * If header value is a duplicate, increment count in
		 * header_count object and append _<count> to the
		 * header value to make it unique
		 */
		if (header_count[header] === undefined) {
			header_count[header] = 0;
			headers.deduplicated_headers[header] = header;
			headers.ordered_headers.push(header);
		} else {
			header_count[header]++;
			const deduplicated_header = header + `_${header_count[header]}`;
			headers.deduplicated_headers[deduplicated_header] = header;
			headers.ordered_headers.push(deduplicated_header);
		}
	}

	return(headers);
}

async function fetch_data(url, options = {}) {
	options = Object.assign({
		type: 'auto'
	}, options);

	/*
	 * Convert "ka://" URLs into relevant ones
	 */
	url = url.replace(/^ka:\/\//, "/api/v1/data/");

	/*
	 * Fetch the resource
	 */
	const response = await fetch(url);

	if (!response.ok) {
		throw(new Error(`Failed to fetch ${url}`));
	}

	/*
	 * Get the type and encoding from the response
	 */
	const info = await response.blob();
	const type_raw = info.type;
	const type = type_raw.split(';')[0];

	switch (type) {
		case "text/csv":
			{
				const data = await (new Response(info)).text();

				return(await parse_csv(data, options));
			}
		case 'image/png':
		case 'image/jpeg':
		case 'image/svg+xml':
			{
				return await fetch_metadata_for_image(info, url, options);
			}
		default:
			return info;
	}
}

async function fetch_metadata_for_image(data, source, options) {
	const image_object = {};
	image_object.name = options.short_name;
	image_object.image = await blob_image_to_base64(data);
	image_object.type = 'image';

	const image_metadata = await fetch_image_metadata_csv(source, options);
	if (image_metadata && image_metadata.data) {
		image_object.metadata = image_metadata;
	}
	return image_object;
}

function blob_image_to_base64(blob = null) {
	if (blob) {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		return new Promise(function (resolve, reject) {
			reader.onloadend = function () {
				resolve(reader.result);
			};

			reader.onerror = function (error) {
				reject(error);
			}
		});
	}
}

async function fetch_image_metadata_csv(source, options) {
	const image_metadata_source = `${source}.csv`;
	try {
		const data = await fetch_data(image_metadata_source, options);
		if (data && data.data) {
			return data;
		}
		return undefined;
	} catch (err) {
		kaialpha.log.debug(`Image ${source} with the metadata source value ${image_metadata_source} is not found`);
		return undefined;
	}
}

function get_datasource_name(datasource) {
	/*
	 * Tables may be backed by data sources, or be backed by...?
	 */
	if (datasource && datasource.match) {
		/*
		 * Check if datasource is a single resource from a variable
		 * with multiple resources. Match object key (i.e. EfficacyResults["Primary"]
		 * or EfficacyResults.Primary) with multiple resources. Use regex
		 * to get datasource name and key (i.e. EfficacyResults["Primary"])
		 */
		let key;
		const matches = [/[^.]*$/, /\["(.*)"]/, /\['(.*)']/];

		for (const index in matches) {
			const regex = matches[index];
			const matched_datasource = datasource.match(regex);

			if (matched_datasource !== null && matched_datasource.index > 0) {
				if (matched_datasource[1] === undefined) {
					key = matched_datasource[0];
				} else {
					key = matched_datasource[1];
				}

				/* Get datasource name without key (i.e. string preceeding '.' or '[') */
				datasource = (datasource.split('.')[0].length < datasource.split('[')[0].length) ?
					datasource.split('.')[0] : datasource.split('[')[0];
			}
		}
		return[datasource, key];
	}

	console.error(new Error('get_datasource_name() called with something other than a string'), "datasource=", datasource);
}

function sanitize_datasource_values(datasource_value, key) {
	let table_data_info;
	const datasources = [];
	if (datasource_value !== undefined) {
		if (key === undefined) {
			table_data_info = datasource_value;
		} else {
			table_data_info = datasource_value[key];
		}
	}

	/*
	 * Add data source data to an array so all sources can be mapped
	 * and rendered in the case that there are multiple resources
	 * for one variable.
	 */
	for (const key in table_data_info) {
		if (key !== 'last_updated' && key !== '@metadata'&& key !== 'metadata'
			&& key !== 'type' && key !== 'footnotes') {
			let data = table_data_info[key];
			let type = table_data_info['type'];
			const metadata = table_data_info['@metadata'];

			if (data['data'] !== undefined) {
				data = data['data'];
			}

			if (type === undefined) {
				type = table_data_info[key]['type'];
			}

			datasources.push({name: key, data: data, type: type, '@metadata': metadata})
		}
	}

	return[table_data_info, datasources];

}

const _to_export_auto = {
	fetch_data,
	get_datasource_name,
	sanitize_datasource_values,
	get_column_data,
	deduplicate_headers,
	_testing
};
export default _to_export_auto;
