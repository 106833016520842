import React from 'react';
import MUIButton from '@material-ui/core/Button';

import { CircularProgress } from '../../lib/ui';

class Button extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.state = {};
	}

	render() {
		const new_props = {
			variant: "contained",
			color: "secondary",
			...this.props
		};

		let async_onClick_function;
		if (new_props !== undefined && new_props.onClick !== undefined) {
			if (new_props.onClick.constructor !== undefined && new_props.onClick.constructor.name === 'AsyncFunction') {
				async_onClick_function = new_props.onClick;
			}
		}

		if (async_onClick_function !== undefined) {
			new_props.onClick = async (...args) => {
				let retval;
				let forward_error;

				this.setState({
					asyncActionState: 'IN PROGRESS'
				});

				let failed = false;
				try {
					retval = await async_onClick_function(...args);
				} catch (_forward_error) {
					failed = true;
					forward_error = _forward_error;
				}

				if (failed === true) {
					this.setState({
						asyncActionState: 'ERROR'
					});

					throw(forward_error);
				}

				this.setState({
					asyncActionState: 'COMPLETE'
				});

				return(retval);
			}
		}

		let add;
		switch (this.state.asyncActionState) {
			case 'IN PROGRESS':
				add = <><CircularProgress/><span>&nbsp;</span></>

				/* Also disable the button while it is in progress */
				new_props.disabled = true;
				break;
			case 'ERROR':
				add = <span key='button_error'>[ERROR]&nbsp;</span>
				/* Add an error icon (XXX:TODO) */
				break;
			default:
				/* By default, do nothing */
				break;
		}

		let children = React.Children.toArray(new_props.children);
		delete new_props['children'];

		if (add !== undefined) {
			children = [add, ...children];
		}

		return(<MUIButton {...new_props}>{children}</MUIButton>);
	}
}

export default Button;
