import React from 'react';
import {
	Build,
	ChevronLeft,
	ChevronRight,
	Dashboard,
	Description,
	ExitToApp,
	Folder,
	FolderSpecial,
	InsertDriveFile,
	NotInterested,
	Person,
	PlaylistAddCheck,
} from '@material-ui/icons';

class Icon extends React.Component {
	static notFound() {
		return(NotInterested);
	}

	static forType(type, variant) {
		type = type.toLowerCase();

		switch (type) {
			case 'template':
				return(Build);
			case 'document':
				return(Description);
			case 'folder':
				if (variant === 'special') {
					return(FolderSpecial);
				} else {
					return(Folder);
				}
			case 'project':
				return(InsertDriveFile);
			case 'task':
				return(PlaylistAddCheck);
			default:
				console.error('No known icon for type', type);
				return(Icon.notFound());
		}
	}

	static forAction(action) {
		action = action.toLowerCase();

		/*
		 * These actions share the icons for their respective types
		 */
		if (['templates', 'documents', 'folders', 'projects', 'tasks'].includes(action)) {
			const type = action.slice(0, -1);
			return(this.forType(type));
		}

		switch (action) {
			case 'dashboard':
				return(Dashboard);
			case 'logout':
				return(ExitToApp);
			case 'profile':
				return(Person);
			default:
				console.error('No known icon for action', action);
				return(Icon.notFound());
		}
	}

	static forUI(element) {
		element = element.toLowerCase();
		switch (element) {
			case 'expand-right':
			case 'collapse-left':
				return(ChevronLeft);
			case 'expand-left':
			case 'collapse-right':
				return(ChevronRight);
			default:
				console.error('No known icon for UI element', element);
				return(Icon.notFound());
		}
	}

	render() {
		const new_props = {...this.props};
		let IconToUse = Icon.notFound();

		for (const method of ['forType', 'forAction', 'forUI']) {
			if (this.props[method] === undefined) {
				continue;
			}

			delete new_props[method];

			IconToUse = Icon[method](this.props[method], this.props.variant);
		}

		return(<IconToUse {...new_props}/>);
	}
}

export default Icon;
