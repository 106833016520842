import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import { Button } from '../../../lib/ui';
import LazyTreeView from '../../LazyTreeView';
import ListsUpload from '../Upload';

import list from '../../../api/list.js';
import list_utils from '../../../lib/utils/list_utils.js';

class ListsSelector extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		let type = props.type;
		if (type === undefined) {
			if (props.match && props.match.params && props.match.params.type) {
				type = props.match.params.type;
			}
		}

		if (type) {
			this.state = {
				type: type,
			};
		}

		this.tree = React.createRef();

		this.mounted = false;
		this.load_lists();
	}

	componentDidMount() {
		this.mounted = true;
	}

	async load_lists() {
		const lists_info = await list.get_user_lists(null, this.state.type, {
			fields: ['name', 'permissions', 'default']
		});

		const lists = lists_info[this.state.type];

		if (this.mounted) {
			this.setState({
				lists: lists
			});
		} else {
			this.state.lists = lists;
		}
	}

	async refresh_lists() {
		await this.load_lists();

		if (this.tree.current) {
			this.tree.current.load_toplevel_items();
		}
	}

	async delete_list(id) {
		try {
			this.set_status('ok');
			await list.delete_user_list(null, this.state.type, id)
		} catch (deletion_error) {
			this.set_status('error', deletion_error);
		}

		await this.refresh_lists();
		this.setState({
			selected_id: undefined
		});
	}

	async make_list_default(id) {
		try {
			const current_default_info = await list_utils.get_user_default_list(null, this.state.type, {
				fields: ['id', 'version']
			});

			if (current_default_info !== undefined) {
				/*
				 * If the current default is already this one, do nothing
				 */
				if (current_default_info.id === id) {
					return;
				}

				const current_default = await list.get_user_list(null, this.state.type, current_default_info.id, current_default_info.version);

				delete current_default['default'];

				await list.update_user_list(null, this.state.type, current_default.id, current_default, 'Removing the default');
			}
		} catch (ignored_error) {
			/* We ignore this error, we will catch the setting error */
			console.error('[IGNORED ERROR]', ignored_error);
		}

		const new_default = await list.get_user_list(null, this.state.type, id);
		new_default.default = true;
		try {
			this.set_status('ok');
			await list.update_user_list(null, this.state.type, new_default.id, new_default, 'Setting as default');
		} catch (update_error) {
			this.set_status('error', update_error);
		}
		await this.refresh_lists();
	}

	async make_list_not_default(id) {
		const old_default = await list.get_user_list(null, this.state.type, id);
		delete old_default['default'];

		try {
			this.set_status('ok');
			await list.update_user_list(null, this.state.type, old_default.id, old_default, 'Removing default');
		} catch (update_error) {
			this.set_status('error', update_error);
		}
		await this.refresh_lists();
	}

	set_status(type, message) {
		if (message !== undefined) {
			message = String(message);
		}

		this.setState({
			status: {
				type,
				message
			}
		});
	}

	render_status() {
		if (this.state === undefined || this.state.status === undefined) {
			return;
		}

		switch (this.state.status.type) {
			case 'error':
				return(
					<div>
						[ERROR] {this.state.status.message}
					</div>
				);
			case 'ok':
				break;
			default:
				console.error('Unknown status type:', this.state.status.type);
				break;
		}
	}

	render() {
		let tree;
		if (this.state.lists !== undefined) {
			tree = <LazyTreeView
				ref={this.tree}
				computeItem={async function(item) {
					const new_item = await LazyTreeView.define_item(item);
					new_item.default = item.default;
					return(new_item);
				}}
				renderItemLabel={function(item) {
					let suffix = '';
					if (item.default === true) {
						suffix = ' [Default]';
					}
					const label = `${item.name} [Owner: ${item.owner}]${suffix}`;

					return(label);
				}}
				getChildren={async (id, version) => {
					/* Currently no children are supported */
					if (id !== undefined) {
						return([]);
					}

					return(this.state.lists);
				}}
				onClick={(id) => {
					this.setState({
						selected_id: id
					});
				}}
			/>;
		}

		let selected_pathname;
		if (this.state.selected_id) {
			selected_pathname = `/activity/listseditor/${this.state.type}/${this.state.selected_id}`;
		}

		const status_section = this.render_status();

		return(
			<Grid container>
				<Grid item xs={12}>
					{status_section}
				</Grid>
				<Grid item xs={1}/>
				<Grid item xs={10}>
					<Grid container>
						<Grid item xs={12} align="center"><h1>Select List</h1></Grid>
						<Grid item xs={12}>{tree}</Grid>
						<Grid item xs={6}>
							<Button
								color="primary"
								disabled={!this.state.selected_id}
								component={Link}
								to={{ pathname: selected_pathname }}
							>Edit</Button>
							<Button
								color="secondary"
								disabled={!this.state.selected_id}
								onClick={() => {
									this.delete_list(this.state.selected_id);
								}}
							>Delete</Button>
							<Button
								color="secondary"
								disabled={!this.state.selected_id}
								onClick={() => {
									this.make_list_default(this.state.selected_id);
								}}
							>Make Default</Button>
							<Button
								color="secondary"
								disabled={!this.state.selected_id}
								onClick={() => {
									this.make_list_not_default(this.state.selected_id);
								}}
							>Make Not Default</Button>
							{/* XXX:TODO
							<Button
								color="secondary"
								onClick={() => {
									this.create_new_list();
								}}
							>Create New List</Button>
*/}
							<ListsUpload color="secondary" type={this.state.type} onComplete={() => {
								this.refresh_lists();
							}}/>
						</Grid>
						<Grid item xs={6}></Grid>
					</Grid>
				</Grid>
				<Grid item xs={1}/>
			</Grid>
		);
	}
}

export default ListsSelector;
