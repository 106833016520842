/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/error_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
class UserError extends Error { }

class ResourceNotFoundError extends Error { }

class NunjucksError extends Error {
	constructor(last_element_info, render_error) {
		/*
		 * Handle instantiation from a string
		 */
		if (last_element_info instanceof String && render_error === undefined) {
			const serialized_error = JSON.parse(last_element_info);

			if (serialized_error.nunjucks_error === true) {
				last_element_info = serialized_error.error_info;
				render_error = serialized_error.render_error
			}
		}

		let render_error_string = '';
		if (render_error) {
			render_error_string = render_error.toString();
		}

		const message = {
			error_info: last_element_info,
			render_error: render_error_string,
			nunjucks_error: true
		};

		super(JSON.stringify(message));

		this.name = 'NunjucksError';
	}
}

const _to_export_auto = {
	UserError,
	NunjucksError,
	ResourceNotFoundError
}
export default _to_export_auto;
