export const color_map = {
	navigationSideBarTextColor: 'white',
	navigationSideBarBackgroundColor: '#0059b8',
	navigationSideBarToggleIconColor: '#a6a6a6',
	navigationSideBarHoverColor: '#00378a'
}

export const measurement_map = {
	sideBarOpenedWidth: '240px',
	sideBardClosedWidth: '65px',
}

export const logo_small = require('../assets/icons/logo-small.png').default;

export const logo_large = require('../assets/icons/logo-large.png').default;
