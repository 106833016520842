import React from 'react';
import './WorkflowEditor.css';

import UserListSelect from '../UserListSelect';
import ExpressionBuilder from '../ExpressionBuilder';

import { Button } from '../../lib/ui';
import Sticky from 'react-sticky-el';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import nunjucks_utils from '../../lib/utils/nunjucks_utils';

import ReactFlow, {
	Controls,
	Background,
	ReactFlowProvider
} from 'react-flow-renderer';

const uuid = require('uuid');
class WorkflowEditor extends React.Component {
	actions = ['set_owner', 'set_ui_action_review', 'if'];
	blocks = ['named_code_block', 'instantiate_template']
	states = ['In Progress', 'Reviewed Needs Changes', 'Reviewed Accepted']

	constructor(props) {
		super(props);

		this.state = {
			dialog_open: false,
			open_code_block: false,
			blocks: [],
			current_element: '',
			current_block: '',
			elements: {},
			named_code_block: '',
			edges: []
		}

		this.construct_node = this.construct_node.bind(this);
		this.delete_node = this.delete_node.bind(this);
		this.save_workflow = this.save_workflow.bind(this);
		this.handle_close = this.handle_close.bind(this);
		this.set_block = this.set_block.bind(this);
		this.set_code_block_name = this.set_code_block_name.bind(this);
		this.add_block = this.add_block.bind(this);
		this.select_block = this.select_block.bind(this);
	}

	start_position = {x: 250, y: 80}

	set_parameters(id, type, block, event) {
		let parameters;

		if (event === undefined) {
			return;
		}

		if (event.target !== undefined) {
			parameters = event.target.value;
		} else {
			parameters = event;
		}

		/* Update parameters for element in array */
		const elements = this.state.elements;
		const nodes = elements[block]['nodes'];
		const index = nodes.findIndex(x => x.id === id);

		if (index !== undefined && index !== null) {
			if (typeof parameters === 'object') {
				parameters = parameters.toString();
			}
			nodes[index]['parameters'][type] = parameters;
			this.setState({ elements: elements });
		}
	}

	handle_close() {
		this.setState({ dialog_open: false });
		this.setState({ open_code_block: false });
	}

	select_element(element) {
		this.setState({ dialog_open: true });
		this.setState({ current_element: element })
	}

	select_block(element) {
		if (element === 'named_code_block') {
			this.setState({ open_code_block: true });
			return;
		}

		this.add_block(element);
	}

	add_block(element) {
		const elements = this.state.elements;
		let block = element;

		if (this.state.open_code_block === true) {
			this.setState({ open_code_block: false });
			block = this.state.named_code_block;
		}

		if (this.state.blocks.includes(block)) {
			return;
		}

		/* Add block to workflow editor only if it has not yet been added */
		elements[block] = {nodes: [], edges: []};
		this.setState({elements: elements});
		this.setState({blocks: this.state.blocks.concat(block)});
	}

	set_block(event) {
		if (event.target !== undefined) {
			this.setState({ current_block: event.target.value });
		}
	}

	set_code_block_name(event) {
		this.setState({named_code_block: event.target.value});
	}

	WorkflowEditor_item(items) {
		const nodes = [];

		for(const index in items) {
			const item = items[index];
			switch (item.type) {
				case 'label':
					nodes.push(
						<div>
							<label>{item.value}</label>
							<br></br>
							<br></br>
						</div>
					);
					break;
				case 'text':
					{
						nodes.push(
							<div>
								<label>{item.title}</label>
								<br></br>
								<input onChange={(event) => this.set_parameters(item.id, item.title, item.block, event)} />
							</div>
						);
						break;
					}
				case 'select':
					{
						nodes.push(
							<div>
								<label>{item.title}</label>
								<select onChange={(event) => this.set_parameters(item.id, item.title, item.block, event)}>
									{item.values.map((value) =>
										<option>{value}</option>
									)}
								</select>
							</div>
						);
						break;
					}
				case 'autocomplete':
					{
						nodes.push(
							<div>
								<label>{item.title}</label>
								<br></br>
								<UserListSelect
									multiple={true}
									onChange={(event) => this.set_parameters(item.id, item.title, item.block, event)}
								/>
							</div>
						);
						break;
					}
				case 'expressionbuilder':
					{
						nodes.push(
							<div>
								<label>{item.title}</label>
								<br></br>
								<ExpressionBuilder
									autoCompleteConfig={item.autoCompleteConfig}
									onChange={(event) => this.set_parameters(item.id, item.title, item.block, event)}
								/>
							</div>
						);
						break;
					}
				default:
					throw(new Error(`Unable to handle item with type "${item.type}" in workflow editor item`));
			}
		}

		return(<div>{nodes}</div>)
	}

	construct_node() {
		this.handle_close();

		const current_element = this.state.current_element;
		const current_block = this.state.current_block;
		let data;
		const id = uuid.v4();

		/* Generate input fields for node based on action type */
		switch(current_element) {
			case 'set_owner':
				data = this.WorkflowEditor_item([
					{
						type: 'label',
						value: current_element
					},
					{
						type: 'autocomplete',
						id: id,
						operation: current_element,
						title: 'owner',
						block: current_block,
					}
				]);
				break;
			case 'set_ui_action_review':
				data = this.WorkflowEditor_item([
					{
						type: 'label',
						value: current_element
					},
					{
						type: 'text',
						id: id,
						operation: current_element,
						title: 'uuid/gid',
						block: current_block,
					},
					{
						type: 'autocomplete',
						id: id,
						operation: current_element,
						title: 'owner',
						block: current_block,
					},
					{
						type: 'select',
						id: id,
						operation: current_element,
						title: 'state',
						block: current_block,
						values: this.states
					},
				]);
				break;
			case 'if':
				data = this.WorkflowEditor_item([
					{
						type: 'label',
						value: current_element
					},
					{
						type: 'expressionbuilder',
						id: id,
						operation: current_element,
						title: 'statement',
						block: current_block,
						autoCompleteConfig: [
							{
								trigger: '|',
								options: nunjucks_utils.constants.filters
							},
							{
								trigger: ' ',
								options: nunjucks_utils.constants.operators,
								excludePredecessors: [...nunjucks_utils.constants.operators]
							}
						],
					}
				])
				break;
			default:
				break;
		}

		this.add_node(id, current_block, current_element, data);
	}

	add_node(id, block, element, data) {
		const new_node = {
			id: id,
			data: {label: data},
			sourcePosition: 'right',
			targetPosition: 'left',
			position: this.start_position,
			operation: element,
			parameters: [],
			style: {width: '13%'}
		}

		/* TODO: Check if current block has has node with operations
		 * that can't be reused
		 */
		const elements = this.state.elements;
		const updated_elements = elements[block]['nodes'].push(new_node);
		this.setState({...this.state.elements, ...updated_elements});
	}

	connect_nodes(event, block) {
		const source_id = event.source;
		const target_id = event.target;

		const new_edge = {
			id: uuid.v4(),
			source: source_id,
			target: target_id,
			animated: false
		}

		/* Add edge to state */
		const elements = this.state.elements;
		const elements_at_block = elements[block];
		const updated_elements = elements_at_block;
		updated_elements['edges'].push(new_edge);

		/*
		 * Set in_if_statement to true to target node if it is
		 * part of an if statement
		 */
		const source_element_index = elements_at_block['nodes'].findIndex(x => x.id === source_id);
		const source_element = elements_at_block['nodes'][source_element_index];

		if (source_element.operation === 'if') {
			const target_element_index = elements_at_block['nodes'].findIndex(x => x.id === target_id);
			updated_elements['nodes'][target_element_index]['in_if_statement'] = true;
		}

		this.setState({...this.state.elements, ...updated_elements});
	}

	delete_node(all_elements, block) {
		const element = all_elements[0];
		const elements = this.state.elements;
		let type;

		/* Delete edge */
		if (element.source !== undefined && element.target !== undefined) {
			type = 'edges';
		}

		/* Delete node */
		if (element.source === undefined && element.target === undefined) {
			type = 'nodes';
		}

		const updated_elements = elements[block][type].map(a => ({...a}));

		for(const index in updated_elements) {
			const node = updated_elements[index];
			if (node.id === element.id) {
				updated_elements.splice(index, 1);
				elements[block][type] = updated_elements;
				this.setState({elements : elements})
			}
		}
	}

	save_workflow() {
		const actions = {}
		const if_statement_actions = [];
		let operation;
		let parameters;

		/* Construct actions object with blocks */
		const elements = this.state.elements;
		for(const block in elements) {

			actions[block] = [];
			const block_nodes = elements[block]['nodes'];
			const block_edges = elements[block]['edges'];

			/* Sort nodes in correct order */
			block_nodes.sort((a, b) => (a.position.x >= b.position.x) ? 1 : -1)

			for(const item in block_nodes) {
				const element_info = block_nodes[item];

				if (element_info.operation === undefined) {
					break;
				}

				operation = element_info['operation'];
				parameters = Object.values(element_info['parameters']);

				/* Get all statements following an if statement */
				if (element_info.operation ==='if') {
					/* Get all target nodes for source node */
					const target_element = block_edges.map((x, index) => x.source === element_info.id ? index : '').filter(String);
					target_element.forEach((element) => {
						const target_id = block_edges[element].target;
						const target_element_index = block_nodes.findIndex(x => x.id === target_id);
						const target_element_info = block_nodes[target_element_index];

						if_statement_actions.push({
							operation: target_element_info['operation'],
							parameters: Object.values(target_element_info['parameters'])
						})
					})

					parameters = [Object.values(element_info['parameters']), if_statement_actions]
				}

				/* Don't add actions that are a part of the if statement */
				if (element_info.in_if_statement === undefined) {
					actions[block].push({
						operation: operation,
						parameters: parameters
					})
				}
			}
		}

		const output = {
			workflow: {
				actions: actions
			}
		}

		console.log("Output: ", output)
	}

	flatten_elements(elements) {
		const nodes = elements.nodes;
		const edges = elements.edges;

		const formatted_elements = nodes.concat(edges);

		return(formatted_elements);
	}

	on_drag_stop(event, node, block) {
		const x_position = node.position.x;
		const y_position = node.position.y;
		const node_id = node.id;

		/* Get node in block and update its position */
		const elements = this.state.elements;
		const updated_nodes = elements[block]['nodes'];

		const index = updated_nodes.findIndex(x => x.id === node_id);
		updated_nodes[index]['position'] = {x: x_position, y: y_position};
		this.setState({elements: elements});
	}

	render() {
		const select_block_dialog =
			<Dialog open={this.state.dialog_open} onClose={this.handle_close} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Add Action</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Select a block to add this action to.
					</DialogContentText>
					<Select onChange={this.set_block}>
						{this.state.blocks.map(function(block) {
							return(<MenuItem key={block} value={block}>{block}</MenuItem>);
						})}
					</Select>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handle_close} color="primary">
						Cancel
					</Button>
					<Button onClick={this.construct_node} color="primary">
						Add Action
					</Button>
				</DialogActions>
			</Dialog>

		const named_code_block_dialog =
			<Dialog open={this.state.open_code_block} onClose={this.handle_close} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Enter a name for the code block.</DialogTitle>
				<DialogContent>
					<input onChange={this.set_code_block_name}></input>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handle_close} color="primary">
						Cancel
					</Button>
					<Button onClick={this.add_block} color="primary">
						Submit
					</Button>
				</DialogActions>
			</Dialog>

		const react_flow =
			<div>
				{this.state.blocks.map((block) => {
					return(
						<div className='react-flow-container'>
							<p>{block}</p>
							<ReactFlowProvider>
								<ReactFlow
									key={block}
									elements={this.flatten_elements(this.state.elements[block])}
									style={{width: '100%', height: '500px'}}
									snapToGrid={true}
									snapGrid={[15, 15]}
									onElementsRemove={(element) => this.delete_node(element, block)}
									onConnect={(event) => this.connect_nodes(event, block)}
									onNodeDragStop={(event, node) => this.on_drag_stop(event, node, block)}
									deleteKeyCode={8}
								>
									<Controls />
									<Background variant="lines" gap={12} size={4}/>
								</ReactFlow>
							</ReactFlowProvider>
						</div>
					);
				})}
			</div>

		return(
			<div className='workflow-editor'>
				<Sticky>
					<Grid container className="user-elements" align="center">
						<Grid item xs={2}>
							<Button className="save-workflow-btn" variant="contained" onClick={this.save_workflow}>Save</Button>
						</Grid>
						<Grid item xs={12}>

							{/* Display actions */}
							{this.actions.map((element) =>
								<span key={'user_elements_' + element} className="element">
									<Button variant="contained" onClick={() => {this.select_element(element)}}>{element.replace(/_/g, " ")}</Button>
								</span>
							)}

							{/* Display blocks */}
							{this.blocks.map((element) =>
								<span key={'user_elements_' + element} className="element">
									<Button variant="contained" onClick={() => {this.select_block(element)}}>{element.replace(/_/g, " ")}</Button>
								</span>
							)}
						</Grid>
					</Grid>
				</Sticky>
				{react_flow}
				{select_block_dialog}
				{named_code_block_dialog}
			</div>
		)
	}
}

export default WorkflowEditor;
