import React from 'react';
import './App.css';
import {
	Switch,
	Route,
} from "react-router-dom";
import LandingPage from '../LandingPage';
import Profile from '../Profile';
import DocumentCreator from '../DocumentCreator';
import DocumentSelector from '../DocumentSelector';
import DocumentEditor from '../DocumentEditor';
import TemplateEditor from '../TemplateEditor';
import TemplateSelector from '../TemplateSelector';
import NavigationSideBar from '../NavigationSideBar';
import WorkflowEditor from '../WorkflowEditor';
import ListsSelector from '../Lists/Selector';
import ListsEditor from '../Lists/Editor';
import FoldersSelector from '../Folders/Selector';
import FoldersEditor from '../Folders/Editor';
import StatusBar from '../StatusBar';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { measurement_map } from '../../assets/app_assets.js'
import user from '../../api/user';
import {
	CircularProgress
} from '../../lib/ui';
import general_utils from '../../lib/utils/general_utils';

const uuid = require('uuid');

function ErrorPage() {
	return(<div>This page does not exist.</div>);
}

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: true,
			user_id_known: false
		};
		this._mounted = false;

		this.status_bar = React.createRef();

		/*
		 * Get the current user ID, this is to prevent the application
		 * from being displayed before authentication, which can cause
		 * unsophisticated users to think there is a security issue.
		 */
		(async () => {
			for (let retry = 0; retry < 100; retry++) {
				let valid_user_id;
				try {
					const user_id = await user.get_user_id();
					if (user_id && user_id.length > 0) {
						valid_user_id = true;
					}
				} catch (user_id_fetch_error) {
					console.error('Failed to get User ID:', user_id_fetch_error);
				}

				if (valid_user_id) {
					if (this._mounted) {
						this.setState({
							user_id_known: true
						});
					} else {
						this.state.user_id_known = true;
					}
					break;
				}

				general_utils.async_sleep(100);
			}
		})();
	}

	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	componentDidCatch(error, info) {
		console.error("Error while rendering application:", this.props, "; Error:", error);
	}

	static getDerivedStateFromError(error) {
		return({
			failure: true,
			error: error
		});
	}

	toggleSideBar() {
		this.setState(function(previousState) {
			return({ open: !previousState.open });
		});
	}

	componentDidMount() {
		this._mounted = true;
	}

	render() {
		if (this.state && this.state.failure) {
			return(<div>
				<h1>An error has occured.</h1>
				<div>While processing the page, an error has occured and been logged.</div>
				<div>Error information:<pre>{this.state.error.stack}</pre></div>
			</div>);
		}

		if (this.state.user_id_known !== true) {
			return(<CircularProgress />);
		}

		let margin = measurement_map.sideBardClosedWidth;
		if (this.state.open) {
			margin = measurement_map.sideBarOpenedWidth;
		}

		const send_props = {
			statusBar: {
				ref: this.status_bar,
				push_status: function(...args) {
					if (!this.ref) {
						return;
					}
					if (!this.ref.current) {
						return;
					}
					return(this.ref.current.push_status(...args));
				}
			}
		};

		return(
			<div className="App" style={{ marginLeft: margin }}>
				<NavigationSideBar
					open={this.state.open}
					toggleCallBack={() => { this.toggleSideBar(); }}
				></NavigationSideBar>
				<StatusBar ref={this.status_bar}/>
				<Switch>
					<Route path="/activity/profile" component={Profile} />
					<Route path="/activity/doccreator" component={DocumentCreator} />
					<Route path="/activity/docselector" component={DocumentSelector} />
					<Route exact path="/activity/doceditor/:document_id/version/:version_id" component={DocumentEditor} />
					<Route path="/activity/doceditor/:document_id" component={DocumentEditor} />
					<Route path="/activity/doceditor" component={DocumentEditor} />
					<Route path="/activity/templateeditor/:template_id" render={(props) => {
						return(<TemplateEditor key={'template_' + props.match.params.template_id} {...props}/>)
					}}/>
					<Route path="/activity/templateeditor" render={(props) => {
						return(<TemplateEditor key={'new_' + uuid.v4()} {...props}/>)
					}}/>
					<Route path="/activity/templateselector" component={TemplateSelector}/>
					<Route path="/activity/workfloweditor" component={WorkflowEditor}/>
					<Route path="/activity/listsselector/:type" component={ListsSelector}/>
					<Route path="/activity/listseditor/:type/:id" component={ListsEditor}/>
					<Route exact path="/activity/folders" render={(props) => {
						return(<FoldersSelector {...send_props} {...props}/>);
					}}/>
					<Route exact path="/activity/folders/:id" render={(props) => {
						return(<FoldersEditor {...send_props} {...props}/>);
					}}/>
					<Route exact path="/" component={LandingPage} />
					<Route path="/" component={ErrorPage} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
