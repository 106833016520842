import React from 'react';
import ReactDOM from 'react-dom';
import './DocumentEditor.css';
import './generator.css';
import TemplateDocEditorArea from '../TemplateDocEditorArea';
import DialogBox from '../DialogBox';
import ACLEditor from '../ACLEditor';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ConfirmationMessage from '../ConfirmationMessage';
import CommentView from '../Comment/CommentView';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
	getDocumentById,
	getDocumentDiff,
	mergeDocumentObjects,
	createDocument,
	updateDocumentVersion,
	updateDocumentTemplateVersion,
	renderDocumentHTML,
	createDocumentBuffer,
	getDocumentBuffer,
	updateDocumentBuffer,
	renderDocumentBufferHTML
} from '../../api/document.js';

import {getTemplateById, getTemplateDiff} from '../../api/template.js';
import {get_image_s3_get, get_user_document} from '../../api/document.js';
import document_utils from '../../lib/utils/document_utils';
import object_utils from '../../lib/utils/object_utils';
import clientmapping_utils from './clientmapping_utils';
import buffer_utils from '../../lib/utils/buffer_utils';
import nunjucks_utils from '../../lib/utils/nunjucks_utils';
import user from '../../api/user';
import { TextField, Typography, CircularProgress } from '@material-ui/core';
import Variables from '../Variables';

import CachedIcon from '@material-ui/icons/Cached';
import diff_utils from '../../lib/utils/diff_utils';
import AuditLog from '../../components/AuditLog';

import parse from 'html-react-parser';

class DocumentEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			download_dialog_open: false,
			acl_editor_open: false,
			previewContent: '',
			previewDocContent: [],
			template: {},
			errorMsg: '',
			document: {},
			action: '',
			doc_name: 'Unnamed Document',
			new_document: '',
			button_template_upgrade: '',
			template_updated_flash: false,
			document_updated_flash: false,
			bufferIntervalTime: user.get_user_pref(undefined, 'user/buffer_save_freq'),
			buffer: {},
			renderBuffer: false,
			showSavingBufferText : false,
			hideBufferText: true,
			is_read_only_view: false,
			upgrade_status: false,
			checking_for_update: false,
			right_pane_tab_value: 0,
			audit_log_open: false,
			file: ''
		};
		this.openDownloadDialog = this.openDownloadDialog.bind(this);
		this.saveDocument = this.saveDocument.bind(this);
		this.updateDocumentToLatestTemplate = this.updateDocumentToLatestTemplate.bind(this);
		this.displayPreview = this.displayPreview.bind(this);
		this.closeDownloadDialog = this.closeDownloadDialog.bind(this);
		this.variableValues = this.variableValues.bind(this);
		this.updateBuffer = this.updateBuffer.bind(this);
		this.handle_right_pane_tab_change = this.handle_right_pane_tab_change.bind(this);

		this.editor_area = React.createRef();

		/* Create a reference to ConfirmationMessage component */
		this.confirmation_message = React.createRef();
	}

	/* Interval that will be set to periodically check for template updates */
	checkForTemplateUpdatesInterval = null;
	checkForDocumentUpdatesInterval = null;
	checkForBufferUpdatesInterval = null;

	setLocation(props, state) {
		if (!state.document_id) {
			return;
		}

		if (this.url_has_id) {
			return;
		}
		this.url_has_id = true;

		this.props.history.replace(`${this.props.match.path}/${state.document_id}`);
	}

	componentDidUpdate(prevProps) {
		this.setLocation(this.props, this.state);

		const previewDocContent = this.state.previewDocContent;
		const contentWithComments = [];
		for (const element of previewDocContent) {
			if (element.props) {
				const id = element.props.id;
				this.comment_ref_map[id] = React.createRef();

				const content =
					<div>
						{element}
						{this.get_comment_view(id)}
					</div>
				contentWithComments.push(content);
			}
		}

		const docPreview = <div id='5b4599b7-4e95-4baa-9854-7fa4d050021f_docPreview' />;
		const docPreviewDOMElement = window.document.getElementById('5b4599b7-4e95-4baa-9854-7fa4d050021f_docPreview');

		if (docPreviewDOMElement) {
			ReactDOM.hydrate(contentWithComments, docPreviewDOMElement);
		}

		if (this.state.docPreview === undefined) {
			this.setState({docPreview: docPreview})
		}
	}

	async componentDidMount() {
		let type, content_id, action, version_id;

		if (this.props.location && this.props.location.state && this.props.location.state.buffer) {
			this.setState({ buffer : this.props.location.state.buffer})
		}

		let read_only_view = false;
		if (this.props.match && this.props.match.params && this.props.match.params.document_id) {
			/*
			 * A document ID may be passed in as a parameter
			 * as part of the URL, parameterization is done by
			 * the global App router.
			 */
			content_id = this.props.match.params.document_id;
			version_id = this.props.match.params.version_id;

			if (version_id) {
				read_only_view = true;
			}
			type = 'documents';
			action = 'Edit';
			this.url_has_id = true;
		} else if (this.props && this.props.location && this.props.location.state && this.props.location.state.id) {
			/*
			 * You can also link directly using state -- this may be needed for
			 * more advanced cases (like creating a document from a template)
			 */
			type = this.props.location.state.type;
			content_id = this.props.location.state.id;
			action = this.props.location.state.action;
		}

		if (action !== undefined) {
			this.setState({ action: action, is_read_only_view: read_only_view });
		}

		/*
		* The Document Editor can be initiated in one of two ways:
		*       1. When creating a new Document, we are given only a
		*               Template ID from which to build a Document off of.
		*               This is the "templates" type.
		*       2. When editing an existing Document we are given a
		*               Document ID.
		*/
		switch (type) {
			case 'templates':
				{
					const template = await getTemplateById(content_id, version_id);
					this.setState({
						document_id: null,
						document: {
							template: {
								id: template.id,
								version: template.version
							}
						},
						template: template
					});
					await this.saveDocument();
				}
				break;
			case 'documents':
				{
					const document = await getDocumentById(content_id, version_id);
					let template = null;
					if (document.template) {
						template = await getTemplateById(document.template.id, document.template.version);
					}

					this.setState({
						document_id: content_id,
						document: document,
						template: template,
						doc_name: document.name
					});

					this.current_version = document.version;

					this.displayDocumentPreview();
				}
				break;
			default:
				throw(new Error('Internal error: Only templates and documents are supported'));
		}

		/* Periodically check if new version of document exists */
		this.checkForUpdates();

		this.checkExistingBuffer();
	}

	async checkExistingBuffer() {
		if (this.checkForBufferUpdatesInterval) {
			clearInterval(this.checkForBufferUpdatesInterval);
		}
		if (this.state.bufferIntervalTime < 0) {
			return;
		}
		const current_user = await user.get_user_id();
		const buffer_exists = Object.keys(this.state.buffer);

		if (buffer_exists.length === 0) {
			try {
				const buffer = await getDocumentBuffer(buffer_utils.get_buffer_id(current_user, this.state.document_id, this.current_version));
				this.setState({ buffer: buffer, renderBuffer: true }, () => {
					if (window.confirm('This Document has buffer. Do you want to resume from Buffer ?')) {
						const document = object_utils.copy_object(buffer);
						document.id = buffer_utils.get_item_id_from_buffer_id(buffer.id);
						document.version = buffer_utils.get_item_version_from_buffer_id(buffer.id);
						this.setState({ document: document, doc_name: buffer.name });
						this.editor_area.current.reset(document, null);
						this.checkForBufferUpdates();
					} else {
						this.checkForBufferUpdates();
					}
				});
			} catch(err) {
				console.error(err);
				this.checkForBufferUpdates();
			}
		} else {
			try {
				const buffer = await getDocumentBuffer(this.state.buffer.id);
				const document = object_utils.copy_object(buffer);
				document.id = buffer_utils.get_item_id_from_buffer_id(buffer.id);
				document.version = buffer_utils.get_item_version_from_buffer_id(buffer.id);
				this.setState({ document: document, doc_name: buffer.name, renderBuffer: true }, () => {
					this.editor_area.current.reset(document, null);
				});
			} catch (err) {
				console.error(err);
			}
			this.checkForBufferUpdates();
		}
	}

	checkForBufferUpdates() {
		if (this.state.bufferIntervalTime <= 0) {
			return;
		}
		this.checkForBufferUpdatesInterval = setInterval(async () => {
			await this.updateBufferDocumentState();
		}, this.state.bufferIntervalTime * 1000);
	}

	async updateBufferDocumentState() {
		const retval = await this.updateBuffer();
		if (retval) {
			this.setState({
				buffer: retval,
				renderBuffer: true
			});
		}
	}

	assignDocumentValuesToBuffer(current_buffer) {
		const existing_document = object_utils.copy_object(this.state.document);
		existing_document.id = current_buffer.id;
		existing_document.version = current_buffer.version;
		existing_document.previous_version = current_buffer.previous_version;
		existing_document.child_resources = current_buffer.child_resources;
		return(existing_document);
	}

	async updateBuffer() {
		let retval = {}
		this.setState({ showSavingBufferText: true });
		const variables = this.variableValues();
		const buffer_exists = Object.keys(this.state.buffer);
		if (buffer_exists.length !== 0) {
			const current_buffer = await getDocumentBuffer(this.state.buffer.id);
			const existing_buffer = this.assignDocumentValuesToBuffer(current_buffer);
			const diff = diff_utils.diff_objects(existing_buffer, current_buffer);
			if (Object.keys(diff).length === 0) {
				this.setState({ showSavingBufferText: false, hideBufferText: false});
				setTimeout(() => {
					this.setState({hideBufferText : true })
				}, 3000)
				return;
			} else {
				const buffer = await updateDocumentBuffer(this.state.buffer.id, current_buffer.version, this.state.doc_name, variables, this.state.document.permissions);
				this.setState({ buffer: buffer, showSavingBufferText: false, hideBufferText: false});
				retval = buffer;
				setTimeout(() => {
					this.setState({hideBufferText : true })
				}, 3000)
			}
		} else {
			const buffer = await createDocumentBuffer(this.state.document, this.current_version);
			this.setState({ buffer: buffer, showSavingBufferText: false, hideBufferText: false});
			retval = buffer
			setTimeout(() => {
				this.setState({hideBufferText : true })
			}, 3000)
		}

		return(retval);
	}

	componentWillUnmount() {
		if (this.checkForTemplateUpdatesInterval) {
			clearInterval(this.checkForTemplateUpdatesInterval);
		}

		if (this.checkForDocumentUpdatesInterval) {
			clearInterval(this.checkForDocumentUpdatesInterval);
		}

		if (this.checkForBufferUpdatesInterval) {
			clearInterval(this.checkForBufferUpdatesInterval);
		}
	}

	setDocumentName(event) {
		this.setState({ doc_name: event.target.value })
	}

	async checkForDocumentUpdates() {
		const current_user = await user.get_user_id();
		const document = this.state.document;

		if (!document || !document.permissions || !document.permissions.owners) {
			return;
		}
		const owners = document.permissions.owners;

		if (!owners.includes(current_user)) {
			return;
		}

		/* Check if document has a new version every minute */
		this.checkForDocumentUpdatesInterval = setInterval(async () => {
			this.setState({ checking_for_update: true })
			const current_document_version = this.current_version;
			const new_document = await getDocumentById(this.state.document.id);
			if (new_document.version !== current_document_version && this.state.button_template_upgrade !== 'disabled') {

				const old_document = this.state.document;
				old_document.version = current_document_version;

				const diff = await getDocumentDiff(old_document, new_document);
				const isChanged = Object.keys(diff.changed).length === 0;

				this.setState({
					document_updated_flash: true,
					checking_for_update: false,
					new_document: new_document
				}, () => {
					if (this.state.button_template_upgrade !== 'disabled') {
						this.showUpgradeAlert('Document', isChanged);
					}
				});
			}
		}, 60000);
		return() => clearInterval(this.checkForDocumentUpdatesInterval);
	}

	/*
	 * Refresh the Editor area with an updated Document.  This will
	 * check to see if the template has changed and reset the editor
	 * area if so.
	 */
	async refreshDocumentEditor(document_info, options = {}) {
		this.setState({ upgrade_status : true});
		options = Object.assign({
			force: false
		}, options);
		const new_document = await getDocumentById(document_info.id, document_info.version);
		const new_state = {
			document_id: new_document.id,
			document: new_document,
			doc_name: new_document.name
		}
		this.current_version = new_document.version;

		/*
		 * Detect if the current document's template has changed, and if
		 * so push this change up to the template editor area
		 */
		let template_upgraded = false;
		const old_template_id = this.state.document.template.id;
		const old_template_version = this.state.document.template.version;
		if (new_document.template.id !== old_template_id || new_document.template.version !== old_template_version) {
			template_upgraded = true;
		}

		if (template_upgraded) {
			const new_template = await getTemplateById(new_document.template.id, new_document.template.version);

			new_state['template'] = new_template;
		}

		this.setState(new_state, () => {
			if (template_upgraded || options.force) {
				this.editor_area.current.reset(new_document, this.state.template);
			}
			this.setState({ upgrade_status : false});
		});

		/* Update clientmapping_utils */
		const current_user = await user.get_user_id();
		await this.renderUserDocumentHTML(current_user, new_document.id, new_document.version);

		this.setState({
			refresh: false
		});
	}

	/*
	 * Get the current variable values from the Editor area
	 */
	variableValues() {
		return(this.editor_area.current.get_document()['variables']);
	}

	/*
	 * Handle preview
	 */
	async displayPreview() {
		document.getElementById('preview-doc-content').style.display = 'inline';
		const id = this.state.document_id;
		const version = this.current_version;
		const buffer_exists = Object.keys(this.state.buffer);
		try {
			let res;
			if (buffer_exists.length !== 0) {
				res = await renderDocumentBufferHTML(this.state.buffer.id);
			} else {
				res = await renderDocumentHTML(id, version);
			}
			this.setPreviewContent(res);
			this.notifyUserSuccess('Preview', 'Document');
		} catch (preview_error) {
			this.notifyUserError('while generating preview:', preview_error);
		}
	}

	setPreviewContent(res) {
		/*
		 * If we are asked to render something that isn't a string,
		 * convert it appropriately.
		 */
		if (typeof(res) !== 'string') {
			res = JSON.stringify(res);
		}

		this.setState({ previewContent: res })
	}

	async displayDocumentPreview() {
		this.setState({
			refresh: true
		});
		const current_user = await user.get_user_id();
		const id = this.state.document.id;
		let version = this.current_version;
		if (this.current_version && (version !== this.current_version)){
			version = this.current_version;
		}
		this.renderUserDocumentHTML(current_user, id, version);
	}

	async renderUserDocumentHTML(user_id, document_id, version_id) {
		this.setState({variable_changes: {}});
		const template = this.state.template;
		const document = await get_user_document(user_id, document_id, version_id);

		if (this.current_version && (document["version"] !== this.current_version)) {
			document["version"] = this.current_version;
		}

		const document_info = {
			document_id: document.id,
			version_id: document.version
		};

		const variables = await document_utils.process_document_variables(user_id, document_info, {
			add_missing_variables: function(variable_name) {
				return(variable_name);
			},
			document_variable_callback: async function(variable, variable_info, variable_name) {
				if (!variable_info) {
					console.error(`While processing document ${document_id}/${version_id} we came upon a variable ${variable} which is not defined in the template!`);
				}

				if (variable_info && variable_info.type === 'image') {
					const image_info = JSON.parse(variable);
					const image_data = await get_image_s3_get(image_info.key);
					return(`<img style="max-width: 50%" src="${'data:' + image_info.type + ';base64,' + image_data.data}">`);
				}

				if (variable_info && variable_info.type === 'reference') {
					const replacement_values = {
						value: variable.value,
						type: variable.type,
						parent_value: variable.parent
					};

					const reference_value = document_utils.get_reference_value(variable, variable.element_id, variable.value, replacement_values);
					return(reference_value);
				}

				if (variable_info && variable_info.type === 'datasource') {
					/*
					 * Handler for datasources
					 */
					variable.toString = function() {
						return(JSON.stringify(this));
					}
				}

				if (variable_info && variable_info.type === 'expression') {
					return(variable.options.expression);
				}

				if (variable_info && variable_info.type === 'multi_input') {
					let footnotes_data = '';
					for (const footnotes of variable) {
						delete footnotes['tableData']
						footnotes_data += `${JSON.stringify(footnotes)}, `
					}
					return(footnotes_data);
				}

				return(variable);
			}
		});

		const env = nunjucks_utils.createEnvironment(variables);
		const file = await clientmapping_utils.generateJSXElements(document, template, variables, env);

		if (Object.keys(file[1]).length > 0) {
			this.setState({variable_changes: file[1]});
		}

		this.setState({file: file});

		this.setState({previewDocContent: file, variable_changes: {}, refresh: false});
		return(file);
	}

	/*
	 * Save the document, this will create a new Document if it has not
	 * already been saved.
	 */
	async mergeDocumentVersionsAndSave() {
		/*
		 * Get the latest version of the document, as well as the shared ancestor (base)
		 */
		const document_id = this.state.document_id;
		const document_base = await getDocumentById(document_id, this.current_version);
		const document_1 = this.state.document;
		const document_2 = await getDocumentById(document_id);

		/*
		 * If the new document is what we already expected, something else must be wrong,
		 * so don't try to merge (it would be pointless).  Instead, re-save without
		 * the merge function being called.
		 */
		if (document_2.version === this.current_version) {
			return(await this.saveDocument({ mergeVersionsOnError: false }));
		}

		/*
		 * If the documents don't actually differ, just update our version (no save required)
		 */
		const diff = getDocumentDiff(document_1, document_2);
		if (Object.keys(diff.changed).length === 0 && Object.keys(diff.added).length === 0 && Object.keys(diff.deleted).length === 0) {
			this.current_version = document_2.version;
			return(true);
		}

		/*
		 * Construct the new document by merging
		 */
		let new_document;
		try {
			new_document = mergeDocumentObjects(document_1, document_2, document_base);
		} catch (merge_error) {
			console.error('Merge error, will retry in non-strict mode:', merge_error);
			console.debug('Old =', document_1, '; New =', document_2, '; Base =', document_base);
			console.debug('Diff =', diff);
			try {
				new_document = mergeDocumentObjects(document_1, document_2, document_base, { strict: false });
			} catch (worse_merge_error) {
				this.notifyUserError('while saving: Document has been changed and we are unable to merge due to an error:', worse_merge_error, '.  Click Publish again to forcefully overwrite.');
				this.setState({
					forceSave: true
				});
				setTimeout(() => {
					this.setState({
						forceSave: false
					});
				}, 60000);
				return(false);
			}
		}

		/*
		 * Save the merged changes and capture the results
		 */
		const new_version_info = await updateDocumentVersion(document_id, document_2.version, new_document.name, new_document.variables);
		this.current_version = new_version_info.version;

		/*
		 * Reload the editor with the newly merged changes
		 */
		await this.refreshDocumentEditor(new_version_info, {
			force: true
		});

		return(true);
	}

	async saveDocument(options = {}) {
		/*
		 * Set default options
		 */
		options = {
			mergeVersionsOnError: true,
			forceSave: false,
			...options
		};

		/*
		 * Determine if we need to save the document
		 */
		const variables = this.variableValues();

		if (!options.forceSave) {
			const shouldSave = await (async () => {
				/**
				 ** Get the existing document
				 **/
				const document_id = this.state.document_id;
				if (document_id === null) {
					return(true);
				}

				const document_base = await getDocumentById(this.state.document_id, this.current_version);
				if (document_base.name !== this.state.doc_name) {
					return(true);
				}

				if (variables === undefined || document_base.variables === undefined) {
					if (variables !== undefined || document_base.variables !== undefined) {
						return(true);
					}
				}

				const diff_variables = diff_utils.diff_objects(variables, document_base.variables);
				if (Object.keys(diff_variables).length !== 0) {
					return(true);
				}

				return(false);
			})();

			if (!shouldSave) {
				return(true);
			}
		}

		this.state.refresh = true;

		/*
		 * Clear user error
		 */
		this.notifyUserError();
		this.notifyUserMessage('Saving Document');

		try {
			let id = this.state.document_id;
			let current_version = this.current_version;
			const name = this.state.doc_name;

			if (id === null) {
				/*
				 * Perform an initial save if the document has not been created
				 * already
				 */
				const document_info = await createDocument(this.state.template, name);
				await this.refreshDocumentEditor(document_info, {
					force: true
				});

				id = document_info.id;
				current_version = document_info.version;

				this.notifyUserSuccess('Create', 'Document');
			}

			let save_over_version = current_version;
			if (options.forceSave) {
				save_over_version = 'HEAD';
			}

			const new_version_info = await updateDocumentVersion(id, save_over_version, name, variables);
			this.current_version = new_version_info.version;

			this.setState({buffer: {}, renderBuffer: false });
			this.notifyUserSuccess('Update', 'Document');
		} catch (error) {
			/*
			 * If the save failed, maybe it was due to conflicting versions so try to de-conflict
			 * then save again, and if that fails, return the error.
			 */
			if (options.mergeVersionsOnError) {
				return(await this.mergeDocumentVersionsAndSave());
			} else {
				this.notifyUserError('Error while saving:', error);
				return(false);
			}
		}

		this.setState({ action: 'Edit', refresh: false}, () => {
			this.displayDocumentPreview();
		});

		return(true);
	}

	/*
	 * Save the document, then open a dialog for downloading a version
	 * of the rendered content.
	 */
	async openDownloadDialog() {
		const saved = await this.saveDocument();
		if (!saved) {
			return;
		}
		this.notifyUserSuccess('View', 'Document');
		this.setState({ download_dialog_open: true })
	}

	closeDownloadDialog() {
		this.setState({ download_dialog_open: false })
	}

	async checkForTemplateUpdates() {
		if (!this.state.template) {
			return;
		}
		/* Check if current user is document owner */
		const current_user = await user.get_user_id();
		const document = this.state.document;

		if (document.permissions === undefined) {
			return;
		}

		const owners = document.permissions.owners;

		/** XXX:TODO: This should use the existing functionality to determine if the user has the "owner" capability" **/
		if (!owners.includes(current_user)) {
			return;
		}

		/* Check if document's template has been updated every 30 seconds */
		this.checkForTemplateUpdatesInterval = setInterval(async () => {
			this.setState({ checking_for_update: true })
			const current_template = this.state.template;
			const updated_template = await getTemplateById(current_template.id);

			if (updated_template.version === current_template.version) {
				this.setState({ checking_for_update: false });
				return;
			}

			const diff = await getTemplateDiff(current_template, updated_template);

			/*
			 * If template has changed, notify user and guide them to
			 * click the "template upgrade button"
			 */
			if (Object.keys(diff.added).length === 0 && Object.keys(diff.changed).length === 0 && Object.keys(diff.deleted).length === 0) {
				this.setState({ checking_for_update: false });
				return;
			}
			const isChanged = Object.keys(diff.changed).length === 0;
			this.setState({ template_updated_flash: true, checking_for_update: false }, () => {
				if (this.state.button_template_upgrade !== 'disabled') {
					this.showUpgradeAlert('Template', isChanged)
				}
			});
		}, 30000);
	}

	/*
	 * Save the current document, then upgrade to the latest version of
	 * the template
	 */
	async updateDocumentToLatestTemplate(options = {}) {
		options = Object.assign({
			onStart: undefined,
			onComplete: undefined
		}, options);

		const saved = await this.saveDocument();
		if (!saved) {
			return;
		}

		if (options.onStart) {
			options.onStart();
		}

		this.notifyUserError();
		try {
			const document_id = this.state.document_id;
			const template_id = this.state.template.id;

			const new_document = await updateDocumentTemplateVersion(document_id, 'HEAD', template_id, {
				start_with_top: true,
				recursive: true
			});

			this.setState({file: ''});

			await this.refreshDocumentEditor({
				id: document_id,
				version: new_document.version
			});

			const buffer_exists = Object.keys(this.state.buffer);
			if (buffer_exists.length !== 0) {
				this.setState({
					buffer: {},
					renderBuffer: false
				});
			}
		} catch (template_upgrade_error) {
			this.notifyUserError('Failed to upgrade to latest version:', template_upgrade_error);
		}

		if (options.onComplete) {
			options.onComplete();
		}
	}

	notifyUserError(...args) {
		if (args.length === 1 && args[0] === undefined) {
			args = [];
		}

		if (args.length === 0) {
			this.confirmation_message.current.closeConfirmationMessage();
			return;
		}

		const error_string = args.map(function(arg) {
			if (arg instanceof Object) {
				return(arg.toString());
			}
			return(String(arg));
		}).join(' ');

		this.confirmation_message.current.notifyUserFailure(undefined, undefined, error_string);

		console.error('User Error:', ...args);
	}

	notifyUserMessage(message) {
		if (!this.confirmation_message || !this.confirmation_message.current) {
			return;
		}

		this.confirmation_message.current.notifyUserMessage(message);
	}

	notifyUserSuccess(action, type, message) {
		if (!this.confirmation_message || !this.confirmation_message.current) {
			return;
		}
		this.confirmation_message.current.notifyUserSuccess(action, type, message);
	}

	stopCheckingForUpgrades() {
		if (this.checkForDocumentUpdatesInterval) {
			clearInterval(this.checkForDocumentUpdatesInterval);
		}
		if (this.checkForTemplateUpdatesInterval) {
			clearInterval(this.checkForTemplateUpdatesInterval);
		}
	}

	checkForUpdates() {
		this.checkForTemplateUpdates();
		this.checkForDocumentUpdates();
	}

	onUpgrade(type) {
		if (type === 'Document') {
			this.refreshDocumentEditor(this.state.new_document, {
				force: true
			});
		} else {
			this.updateDocumentToLatestTemplate({
				onStart: () => {
					this.setState({
						button_template_upgrade: 'disabled'
					});
				},
				onComplete: () => {
					this.setState({
						button_template_upgrade: ''
					});
				}
			});
		}
	}

	onAlertDismiss() {
		if (window.confirm('New necessary information may not be included if you do not upgrade to newest version. Are you sure you want to cancel?')) {
			/*
			 * Do not check for upgrades if the alert is dismissed
			 */
			if (this.state.document_updated_flash === false && this.state.template_updated_flash === true) {
				this.checkForDocumentUpdates();
			} else if (this.state.document_updated_flash === true && this.state.template_updated_flash === false) {
				this.checkForTemplateUpdates();
			}
		}
	}

	onAlertConfirm(type, isChanged) {
		if (window.confirm(`You could lose information if you do upgrade and the new ${type} may not contain elements you have previously worked on. Are you sure you want to upgrade?`) && this.state.button_template_upgrade !== 'disabled') {
			this.onUpgrade(type);
			this.checkForUpdates();
		} else {
			this.showUpgradeAlert(type, isChanged);
		}
	}

	showUpgradeAlert(type, isChanged) {
		this.stopCheckingForUpgrades();

		if (window.confirm(`${type} has been updated. Do you want to update the ${type}?`)) {
			if (!isChanged) {
				this.onAlertConfirm(type, isChanged);
			} else {
				this.onUpgrade(type);
				this.checkForUpdates();
			}
		} else {
			this.onAlertDismiss()
		}
	}

	update_tempDoc_version(id) {
		const document = this.state.document;
		document.version = id;
		this.current_version = id;
		this.setState({ document: document });
	}

	routeTemplatePath(path) {
		this.props.history.push(path);
		this.props.history.go();
	}

	getActionButtons() {
		return <div>
			<Button style={{marginLeft:'10px', color: '#FFFFFF', backgroundColor: '#0052CC', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px'}}variant='contained' onClick={() => this.saveDocument()} disableElevation disabled={this.state.previewDocContent.length <= 0}>Publish</Button>
			<Button style={{marginLeft:'10px', color: '#FFFFFF', backgroundColor: '#0052CC', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px'}} variant='contained' disableElevation onClick={() => this.saveDocument()}>Save</Button>
			<Button style={{marginLeft:'10px', color: '#969798', backgroundColor: '#F1F3FA', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px'}} variant='contained' disableElevation component={Link} to={{pathname: "/activity/docselector"}}>Close</Button>
		</div>
	}

	comment_ref_map = {};

	construct_comment_ref(id) {
		if (id === undefined || id === '') {
			return;
		}

		if (!this.comment_ref_map[id]) {
			this.comment_ref_map[id] = React.createRef();
		}
	}

	get_comment_view(id) {
		this.construct_comment_ref(id);
		return(<CommentView
			ref={this.comment_ref_map[id]}
			id={id}
			document={this.state.document}
			template={this.state.template}
			type='document'
			read_only={this.state.is_read_only_view}
			updateDocumentVersion={(id) => this.update_tempDoc_version(id)}
			routeVersion={(path) => { this.routeTemplatePath(path) }}
		/>)
	}

	getAction(action) {
		action.persist();
		const element = action.target.id.split('_');
		const element_action = element[1];

		if (element_action === undefined || element_action === '' || element_action === null) {
			return;
		}

		const element_id = element[[0]];
		if (element_id === undefined || element_id === '' || element_id === null) {
			return;
		}

		switch(element_action) {
			case 'comment':
				{
					if (this.state.is_read_only_view) {
						break;
					}

					if (!this.comment_ref_map[element_id] || this.comment_ref_map[element_id] === null) {
						this.comment_ref_map[element_id] = React.createRef();
					}

					if (this.comment_ref_map[element_id].current !== null) {
						this.comment_ref_map[element_id].current.add_or_remove_comments_view(element_id);
					}

					break;
				}
			case 'history':
				break;
			case 'table':
				{
					const variable_name = element[0];
					const table_element_id = element[2];
					const action = element[3];
					switch (action) {
						case 'history':
							this.fetch_data(variable_name);
							break;
						case 'comment':
							if (table_element_id === undefined) {
								break;
							}

							if (this.comment_ref_map[table_element_id] === undefined) {
								break;
							}

							this.comment_ref_map[table_element_id].current.add_or_remove_comments_view(element_id);
							break;
						default:
							throw(new Error(`Unable to handle unknown action ${action} on a table element`));
					}
				}
				break;
			case 'display':
				this.displayToggle(element[2], element);
				break;
			case 'review':
				break;
			default:
				throw(new Error(`Unable to handle element ${JSON.stringify(element)} in action ${action}`));
		}
	}

	displayToggle(id, element){
		if (!id || document.getElementById(id) === undefined || document.getElementById(id) === null){
			return;
		}

		const element_display = document.getElementById(id).style.display;
		if (element_display && element_display === 'block'){
			document.getElementById(id).style.display = 'none';
			if (document.getElementById(element.join('_')).classList.contains('action-button-6')) {
				document.getElementById(element.join('_')).classList.remove('action-button-6');
			}
			document.getElementById(element.join('_')).classList.add('action-button-5');
		} else {
			document.getElementById(id).style.display = 'block';
			if (document.getElementById(element.join('_')).classList.contains('action-button-5')) {
				document.getElementById(element.join('_')).classList.remove('action-button-5');
			}
			document.getElementById(element.join('_')).classList.add('action-button-6');
		}
	}

	async set_document_variables(name, value) {
		let variables;
		if (this.state.document.variables !== undefined) {
			variables = object_utils.copy_object(this.state.document.variables);
		} else {
			variables = {};
		}

		variables[name] = value;
		const document_state = this.state.document;
		document_state.variables = variables
		this.setState({ document : document_state}, () => this.saveDocument());

	}

	async fetch_data(variable_info) {
		const current_user = await user.get_user_id();
		const data = await document_utils.fetch_document_data_source_value(current_user, this.state.document.id, 'HEAD', variable_info);
		data['last_updated'] = Date.now();
		this.set_document_variables(variable_info, data);
	}

	handle_right_pane_tab_change(event, new_value) {
		this.setState({right_pane_tab_value: new_value});

		if (new_value === 1) {
			this.comment_ref_map[this.state.document.id].current.add_or_remove_comments_view(this.state.document.id, 'show')
		} else {
			this.comment_ref_map[this.state.document.id].current.add_or_remove_comments_view(this.state.document.id, 'hide')
		}
	}

	render() {
		const document = this.state.document;
		const template = this.state.template;

		// Get preview content
		const previewContent = this.state.previewContent;
		const preview = parse(previewContent);

		/*
		 * Since variables are not created in previewDocContent,
		 * we have to add their IDs to the comment_ref_map separately
		 */
		let variable_comments;
		if (this.state.template && this.state.template.variables) {
			const template_body = this.state.template.body;
			for (const element of template_body) {
				const element_id = Object.keys(element)[0];
				const element_info = element[element_id];

				if (element_info.type === 'variable') {
					variable_comments = this.get_comment_view(element_id);
				}
			}
		}

		// Get metadata
		let metadata = null
		if (template) {
			metadata = template["metadata"];
		}

		let template_metadata;
		if (metadata) {
			template_metadata =
			<div className="metadata">
				<p className="key-hdr">Key:</p>
				<p className="value-hdr">Value:</p>
				{Object.entries(metadata).map(([key, value]) => {
					return(<div key={'metadata_' + key}>
						<p className="key-txt">{key}</p>
						<p className="value-txt">{value}</p>
					</div>);
				}
				)}
			</div>
		}

		let all_variables = {};
		let all_variables_flat = {};
		if (this.state.template && this.state.template.variables) {
			all_variables = this.state.template.variables;
			if (Object.keys(all_variables).length > 0) {

				all_variables_flat = Object.keys(all_variables).map((variable_name) => {
					const variable_info = all_variables[variable_name];

					variable_info['name'] = variable_name;

					return(variable_info);
				});
			}
		}

		let variables_pane;
		if (Object.keys(all_variables_flat).length > 0 && this.state.right_pane_tab_value === 0) {
			variables_pane =
			<Variables
				type='document'
				variables={all_variables_flat}
				save_variables_csv = {this.save_variables_csv}
			/>
		}

		const editor_area = <TemplateDocEditorArea
			ref={this.editor_area}
			type="document"
			action={this.state.action}
			template={this.state.template}
			document={this.state.document}
			onError={(error_message) => {
				this.notifyUserError(error_message);
			}}
			onRequestSave={async () => {
				const success = await this.saveDocument();
				return(success);
			}}
			version={this.current_version}
			updateDocumentVersion={(id) => this.update_tempDoc_version(id)}
			routeVersion={(path) => { this.routeTemplatePath(path) }}
			readOnly={this.state.is_read_only_view}
			addComment={e => this.getAction(e)}
		/>

		let audit_log = undefined;
		if (this.state.audit_log_open) {
			audit_log = <AuditLog
				type="document"
				id={this.state.document.id}
				visible={this.state.audit_log_open}
				onCancel={() => this.setState({ audit_log_open: false })}
				history={this.props.history}
			/>
		}

		return(
			<div>
				<ConfirmationMessage ref={this.confirmation_message}/>
				<Grid container style={{height:'100%'}}>
					<Grid item style={{width:'16%', padding:'1%', backgroundColor:'rgba(241, 243, 250, 0.3)'}}>
						<Typography>Document Structure</Typography>
						<Grid container style={{width:'100%'}}>
						</Grid>
					</Grid>
					<Grid item style={{width:'60%', padding:'50px', height:'100vh', overflow: 'scroll'}}>
						<Grid item style={{height:'60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '32px'}}>
							<Grid style={{display:'flex', alignItems: 'center'}}><Typography style={{float:'left', marginRight: '12px'}}>Document Editor</Typography>
								{/* XXX:TODO: This needs to be updated to the new UI */}
								{this.state.refresh && this.state.previewDocContent.length <= 0 &&  <Grid style={{display: 'flex'}}><CircularProgress  thickness='6' style={{height: '16px', width:'16px', marginRight: '8px', color:'#969798'}}/><Typography style={{fontSize: '12px', color: '#969798', fontStyle: 'italic'}}>Refreshing</Typography></Grid>}
								{(this.state.showSavingBufferText) && (Object.keys(this.state.buffer).length !== 0) && <div className='document-buffer-save-text'><div><CachedIcon style={{fontSize: 24}}/></div><div className='saving-text'>Saving Buffer</div></div>}
								{!this.state.hideBufferText && (!this.state.showSavingBufferText) && (Object.keys(this.state.buffer).length !== 0) && <div className='document-buffer-save-text'><div className='saving-text'>Saved in Buffer</div></div>}
							</Grid>
							<Grid item direction='row' alignItems='right' style={{float:'right'}}>
								{this.getActionButtons()}
							</Grid>
						</Grid>
						{this.state.previewDocContent.length <= 0 && <Grid item style={{textAlign: 'center', marginTop:'6em'}}><CircularProgress/></Grid>}
						{this.state.previewDocContent.length > 0 && <Grid onClick={e => this.getAction(e)} item className="document-preview" id="preview-doc-content" style={{width: '100%', overflow: 'scroll'}}>
							{this.state.docPreview}
						</Grid>}
					</Grid>
					<Grid item style={{width:'24%', padding:'1%', backgroundColor:'rgba(241, 243, 250, 0.3)'}}>
						<Typography>Document Structure</Typography>
						<Grid item justify="center" alignItems="center">
							<Grid item xs={12}>
								<div className='document-details'>
									{/* Template / Document information */}
									<TextField id={document.name} value={this.state.doc_name} onChange={(event) => {
										this.setDocumentName(event);
									}}></TextField>
									<Typography variant="subtitle1">Document State :</Typography>
									<Typography variant='body1'>{document.state}</Typography>
									<Typography variant="subtitle1">Template name: </Typography>
									<Typography variant="body1">{template?.name}</Typography>
									<span className="document-header">Document included by: LinkToDocumentHere </span><br></br><br></br>
								</div>
							</Grid>
							<Grid container style={{width:'100%', marginTop: '16px'}}>
								<Tabs
									value={this.state.right_pane_tab_value}
									variant="fullWidth"
									onChange={this.handle_right_pane_tab_change}
								>
									<Tab label="Variables" />
									<Tab id={document.id} label="Comments" />
									<Tab label="Contributors" />
									<Tab label="Tasks" />
								</Tabs>
								{variables_pane}
								{this.get_comment_view(this.state.document.id)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<div className="full-document">

					{/* Template body */}
					{editor_area}
					{/* Metadata */}
					<Grid justify="center" alignItems="center">
						{template_metadata}
					</Grid>

					{variable_comments}
					{audit_log}

					{/* Document action buttons */}
					<div className="document-btns-container">
						<Grid align='center'>
							<Grid item xs={10}>
								<div className="document-btns">
									<Button className="action-btns" color="primary" id="preview-doc-btn" onClick={this.displayPreview} disabled={this.state.action === 'Create'}>Preview</Button>
									<Button className="action-btns" color="primary" id="download-doc-btn" onClick={this.openDownloadDialog} disabled={this.state.action === 'Create'}>View</Button>
									<Button className="action-btns" color="primary" id="save-doc-btn" onClick={this.saveDocument} disabled={this.state.is_read_only_view}>Publish Document</Button>
									<Button className="action-btns" color="primary" id="save-doc-btn" onClick={this.updateBuffer}>Save Document</Button>
									<Button className="action-btns" color="primary" id="update-template-btn" onClick={async () => {
										await this.updateDocumentToLatestTemplate({
											onStart: () => {
												this.setState({button_template_upgrade: 'disabled'});
											},
											onComplete: () => {
												this.notifyUserSuccess('Upgrade', 'Document');
												this.setState({button_template_upgrade: ''});
											}
										});
									}} disabled={this.state.action === 'Create' || this.state.button_template_upgrade === 'disabled' || this.state.is_read_only_view}>Template Upgrade</Button>
									<Button className="action-btns" color="primary" id="acl-editor-doc-btn" onClick={() => { this.setState({ acl_editor_open: true }); }} disabled={this.state.action === 'Create'}>ACL Editor</Button>
									<Button className="action-btns" color="primary" id="acl-editor-doc-btn" onClick={() => { this.setState({ audit_log_open: true });}} disabled={this.state.action === 'Create'}>Audit Logs</Button>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>

				{/* Document preview */}
				<div className="document-preview" id="preview-doc-content">
					{preview}
				</div>

				{(this.state.download_dialog_open === true) && <DialogBox id={this.state.document_id} type='document' onClick={this.closeDownloadDialog} buffer={this.state.buffer} renderBuffer={this.state.renderBuffer}/>}

				<ACLEditor
					visible={this.state.acl_editor_open}
					permissions={object_utils.copy_object(this.state.document.permissions)}
					onSave={async (new_permissions) => {
						const new_document = object_utils.copy_object(this.state.document);
						new_document.permissions = new_permissions;

						this.setState({
							document: new_document,
							acl_editor_open: false
						});

						/** XXX:TODO: Save the ACL right now or wait ? **/
						if (this.state.document_id && this.state.document_id !== '') {
							this.notifyUserMessage('Saving ACL');
							try {
								const new_document_info = await updateDocumentVersion(this.state.document_id, 'HEAD', undefined, undefined, new_permissions);
								this.notifyUserMessage('Updated ACL');

								this.current_version = new_document_info.version;
							} catch (save_error) {
								this.notifyUserError('Save', 'ACL', save_error.message);
							}
						}
					}}
					onCancel={() => {
						this.setState({ acl_editor_open: false });
					}}
				/>

			</div>
		)
	}
}

export default DocumentEditor;
