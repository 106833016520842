import React from 'react';
import './Variables.css';
import SearchBar from "material-ui-search-bar";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
class Variables extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			variables: []
		}
	}

	componentDidMount() {
		this.setState({ variables: this.props.variables });
	}

	componentDidUpdate(nextProps, nextState) {
		if (nextProps !== this.props) {
			this.setState({ variables: this.props.variables });
		}
	}

	search_variables(search_value) {
		const variables = this.state.variables;
		const final_results = [];

		if (search_value === '' || search_value === undefined || search_value === null) {
			this.setState({ variables: this.props.variables });
		} else {
			if (search_value) {
				for(const variable of variables) {
					if (variable.name.toLowerCase().includes(search_value.toLowerCase().trim())) {
						final_results.push(variable);
					}
				}
			}
			this.setState({ variables: final_results });
		}
	}

	save_variables_csv(event) {
		this.props.save_variables_csv(event);
	}

	download_csv_file_format() {
		const csv_data = [
			'name',
			'type',
			'description',
			'options',
			'source',
			'datasource_type',
			'row_headers',
			'column_headers'
		];
		const href = 'data:text/csv;charset=utf-8,' + encodeURI(csv_data.join(','));
		return(<a href={href} download='bulk_create.csv'><GetAppRoundedIcon style={{color:'#0052CC', height: '20px', cursor: 'pointer'}}/></a>);
	}


	render() {
		const variables = this.state.variables;

		let download_csv_file_format = <div></div>;
		let bulk_upload = <div></div>;
		if (this.props.type === 'template') {
			download_csv_file_format = this.download_csv_file_format();

			bulk_upload =
			<div>
				<input accept='.csv' className='upload-input' id="bulk-upload-variables" type="file" onChange={this.props.save_variables_csv}/>
				{!this.props.disable_add && <label htmlFor="bulk-upload-variables">
					<Tooltip title="Bulk upload">
						<IconButton component='span'>
							<AddRoundedIcon style={{color:'#0052CC', height: '20px', cursor: 'pointer', marginRight: '4px', marginBottom: '8px'}}/>
						</IconButton>
					</Tooltip>
				</label>}
			</div>
		}

		return(
			<div className='variables-pane'>
				<div className='variables-pane-header'>
					<div className='variable-pane-header-search'>
						<SearchBar
							className='search-variables'
							placeholder='Search Variables'
							// value={this.state.value}
							onChange={(search_value) => this.search_variables(search_value)}
						/>
					</div>
					<div className='variable-pane-header-action-buttons'>
						{bulk_upload}
						<Tooltip title="Download CSV Format">
							{download_csv_file_format}
						</Tooltip>
					</div>
				</div>

				{variables.map((variable) =>
					<div>
						<div className='variable-container'>
							<p className='variable-name'>{variable.name}</p>
							<p className='variable-type'>{variable.type}</p>
							<p className='variable-description'>{variable.description}</p>
						</div>
						<div className='variable-from-container'>
							<p className='variable-from-name'>{variable.from_name}</p>
						</div>
					</div>
				)}
			</div>
		)
	}

}

export default Variables;