import React from 'react';
import MaterialTableStyled from '../MaterialTableStyled';
import object_utils from '../../lib/utils/object_utils';
import './Table.css';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

const uuid = require('uuid');
class Table extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			base_column: '__row_header_7894fac5-cbd4-44e6-845d-ad19e4a5022f',
			reference: 'cell-reference',
		}
	}

	copyToClipboard(str) {
		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	}

	toolTipMessage(typeOfReference) {
		let reference_type;
		switch (typeOfReference) {
			case 'absolute-reference':
				reference_type = 'Absolute Reference';
				break;
			case 'row-search-reference':
				reference_type = "Row Search for this Column's value";
				break;
			case 'column-search-reference':
				reference_type = "Column Search for this Row's value";
				break;
			case 'cell-reference':
				reference_type = 'Cell Reference';
				break;
			default:
				reference_type = `Unknown reference (${typeOfReference})`;
				break;
		}
		return `Copy ${reference_type}`;
	}

	renderCell(value, column, row, table_key, options) {
		const base_column = this.state.base_column;
		const title = this.props.datasource_name;
		const type = this.props.table_data_info.type;
		const row_relative_position = options.row;
		const column_relative_position = options.column;

		return(
			<div
				className='table-cell'

				onMouseLeave={() => {
					const element = document.getElementById(`${table_key}[${row}][${column}]`);
					element.style.visibility = 'hidden';
				}}

				onMouseEnter={() => {
					if (column === base_column) {
						let tooltip_msg;
						if (this.state.reference === 'absolute-reference' && type === 'columns-rows') {
							tooltip_msg = 'Copy cell absolute reference';
						} else {
							tooltip_msg = 'Copy row reference';
						}
						this.setState({
							tooltip_msg: tooltip_msg
						});
					} else {
						this.setState({
							tooltip_msg: this.toolTipMessage(this.state.reference)
						});
					}
					const element = document.getElementById(`${table_key}[${row}][${column}]`);
					element.style.visibility = 'unset';
				}}>{value}

				<div id={`${table_key}[${row}][${column}]`}
					onClick={() => {
						let copy_str;
						if (type === 'columns-rows' && column === base_column) {
							if (this.state.reference === 'absolute-reference') {
								copy_str = `{{${title} | absolute('${row_relative_position}', '${column_relative_position}')}}`;
							} else {
								copy_str = `{{${title}.data | row('${row}')}}`;
							}
						} else if (type === 'columns' && column === base_column) {
							copy_str = `{{${title}.data | row('${row}')}}`;
						} else if (type === 'columns-rows' && column !== base_column) {
							copy_str = this.switch_type_and_get_copy_string(this.state.reference, {
								'title': title,
								...this.get_copy_string_options_object(column, row, value, row_relative_position, column_relative_position)
							}, type);
						} else if (type === 'columns' && column !== base_column) {
							copy_str = this.switch_type_and_get_copy_string(this.state.reference, {
								'title': title,
								...this.get_copy_string_options_object(column, row, value, row + 1, column_relative_position)
							}, type);
						}
						this.copyToClipboard(copy_str);
						this.setState({
							tooltip_msg: 'Copied'
						});
					}}
					className='table-icon'>
					<Tooltip placement='top' title={this.state.tooltip_msg}>
						<FileCopyIcon style={{ height: '1em' }} color="disabled" />
					</Tooltip>
				</div>
			</div>
		);
	}

	switch_type_and_get_copy_string(copyType, options, type) {

		switch (copyType) {

			case 'absolute-reference':
				return `{{${options.title} | absolute('${options.row_relative_position}','${options.column_relative_position}')}}`;

			case 'cell-reference':
				if (type === 'columns') {
					return `{{${options.title}.data['${options.column}']['${options.row}']}}`;
				}
				return `{{${options.title}.data['${options.row}']['${options.column}']}}`;
			case 'row-search-reference':
				return `{{${options.title} | find_rows('${options.row}', '${options.value}')}}`;

			case 'column-search-reference':
				return `{{${options.title} | find_columns('${options.column}', '${options.value}')}}`;

			default:
				return '';
		}
	}

	get_copy_string_options_object(row, column, value, row_relative_position, column_relative_position) {
		return{
			'row': row,
			'column': column,
			'value': value,
			'row_relative_position': row_relative_position,
			'column_relative_position': column_relative_position
		}
	}

	render() {
		let table_data;
		const table_title = this.props.table_title;
		const table_data_info = this.props.table_data_info;
		const column_headers = this.props.column_headers;
		const row_headers = this.props.row_headers;
		const base_column = this.state.base_column;
		const type = table_data_info.type;
		const table_key = `${table_title}_${uuid.v4()}`;

		let absolute_column_header_index = 0;

		const table_columns = [{
			field: base_column,
			title: '',
			row: 0,
			column: absolute_column_header_index,
			render: (rowData) => {
				let row;
				if (type === 'columns') {
					row = rowData.tableData.id;
				} else if (type === 'columns-rows') {
					row = rowData[base_column];
				}
				const options = {
					row: rowData.tableData.id + 1,
					column: 0
				}
				return(this.renderCell(rowData[base_column], base_column, row, table_key, options));
			}
		}]


		if (type === 'columns-rows') {
			const current_data = table_data_info.data;

			/*
			 * Determine Row and Column headers
			 */
			/** If the user specified some value then use
			 ** it, otherwise use all the Row/Columns.
			 **/
			let column_keys;
			if (table_data_info['@metadata'] === undefined) {
				column_keys = Object.keys(current_data);
			} else {
				column_keys =  table_data_info['@metadata']['ordered_headers'];
			}

			let row_keys = Object.keys(current_data[column_keys[0]]);

			if (column_headers && column_headers.length > 0) {
				column_keys = column_headers;
			}

			if (row_headers && row_headers.length > 0) {
				row_keys = row_headers;
			}

			/**
			 ** Compute the displayed values
			 **/
			column_keys.forEach((column_key, index) => {
				column_key = column_key.trim();
				absolute_column_header_index++;
				table_columns.push({
					field: column_key,
					title: column_key,
					row: 0,
					column: absolute_column_header_index,
					render: (rowData) => {
						const options = {
							row: rowData.tableData.id + 1,
							column: index + 1
						}
						return(this.renderCell(rowData[column_key], column_key, rowData[base_column], table_key, options));
					}
				})
			});

			/*
			 * Convert existing string-based headers into arrays
			 */
			if (!Array.isArray(column_keys)) {
				/* XXX:TODO: Use better CSV parse for this and EditableElementPart_attribute_input_map_func_csv */
				column_keys = column_keys.split(',');
			}

			if (!Array.isArray(row_keys)) {
				/* XXX:TODO: Use better CSV parse for this and EditableElementPart_attribute_input_map_func_csv */
				column_keys = column_keys.split(',');
			}

			table_data = [];
			for (const row_key of row_keys) {
				const current_row = {};
				current_row[base_column] = row_key;
				for (const column_key of column_keys) {
					let current_value;
					if (current_data && current_data[column_key]) {
						current_value = current_data[column_key][row_key];
					}
					current_row[column_key] = current_value;
				}
				table_data.push(current_row);
			}

		} else if (type === 'columns') {
			absolute_column_header_index = 0;
			table_data = object_utils.copy_object(table_data_info.data);

			let column_keys;
			if (!table_data_info['@metadata']) {
				column_keys = Object.keys(table_data[0]);
			} else if (table_data_info['@metadata']) {
				column_keys = table_data_info['@metadata']['ordered_headers'];
			} else {
				column_keys = column_headers;
			}

			if (column_headers && column_headers.length > 0) {
				column_keys = column_headers;
			}

			/*
			 * Convert existing string-based headers into arrays
			 */
			if (!Array.isArray(column_keys)) {
				/* XXX:TODO: Use better CSV parse for this and EditableElementPart_attribute_input_map_func_csv */
				column_keys = column_keys.split(',');
			}

			column_keys.map((column_key, index) => {
				column_key = column_key.trim();
				if (column_key === 'tableData') {
					return(undefined);
				}
				return({
					field: column_key,
					title: column_key,
					row: 0,
					column: absolute_column_header_index++,
					render: (rowData) => {

						const options = {
							row: rowData.tableData.id + 1,
							column: index
						}

						return(this.renderCell(rowData[column_key], column_key, rowData.tableData.id, table_key, options));
					}
				});
			}).filter(function(value) {
				if (value === undefined) {
					return(false);
				}

				if (value.field !== base_column) {
					table_columns.push(value);
				}
				return true;
			});

			if (table_data.map) {
				table_data.map((row, index) => {
					row[base_column] = `${index + 1})`;
					return row;
				});
			} else {
				console.error('table_data =', table_data, 'has no map!');
				table_data = [];
			}
		} else {
			throw(new Error(`Unsupported data source format: ${table_data_info.type}`));
		}

		return(
			<div>
				<Grid
					component="label"
					container
					alignItems="center"
					spacing={1}
					style={{ backgroundColor: 'white', borderRadius: 5, padding: '10px', marginBottom: '10px' }}>
					<FormControl component="fieldset">
						<p style={{ fontSize: '12px', color: '#8600b3', fontWeight: '500' }}>Type of Cell Reference</p>
						<RadioGroup
							aria-label="reference"
							value={this.state.reference}
							onChange={(event) => { this.setState({ reference: event.target.value }) }}>
							<FormControlLabel
								value="absolute-reference"
								control={<Radio />}
								label="Absolute Reference (Y, X coordinate)" />
							<FormControlLabel
								value="cell-reference"
								control={<Radio />}
								label="Cell Reference (Named cell)" />
							<FormControlLabel
								value="row-search-reference"
								control={<Radio />}
								label="Find Matching Rows" />
							<FormControlLabel
								value="column-search-reference"
								control={<Radio />}
								label="Find Matching Columns" />
						</RadioGroup>
					</FormControl>
				</Grid>

				<MaterialTableStyled
					title={table_title}
					reference={this.state.reference}
					type={type}
					selection={true}
					data={table_data}
					columns={table_columns}
				/>
			</div>);
	}
}

export default Table;
