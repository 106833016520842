/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/document_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import object_utils from './object_utils';
import data_utils from './data_utils';
import buffer_utils from './buffer_utils';
import testing_utils from './testing_utils';
const glob_to_regexp = require('glob-to-regexp');
const uuid = require('uuid');
const fs = require('fs');

const _testing = undefined;

/*
 * Function to take a Document and Template and return the serialized body
 */
function body_serialize(document, template) {
	const retval = [];

	let body = [];
	let from;

	if (template && Array.isArray(template.body) && document && Array.isArray(document.body_extend)) {
		throw new Error("Ambiguity on Template and Document body elements, as both has body parts and we are not sure to choose which");
	}

	if (template && Array.isArray(template.body)) {
		body = template.body;
		from = 'template';
	} else if (document && Array.isArray(document.body_extend)) {
		body = document.body_extend;
		from = 'document;'
	} else {
		kaialpha.log.debug('Body element is missing on both template and document:', { document, template });
	}

	/*
	 * Indicate the source of this element
	 */
	for (const element_info of body) {
		const element_id = Object.keys(element_info)[0];
		const element = Object.assign({
			'$from': from
		}, element_info[element_id]);

		if (element.type === 'variable') {
			const variable_name = element.name;
			if (template && template.variables && template.variables[variable_name]) {
				element['$variable_descriptor'] = template.variables[variable_name];
			}
		}

		retval.push({
			[element_id]: element
		});
	}

	/*
	 * XXX:TODO: Parse through the document.body_extend and update the returned value
	 */

	return(retval);
}

/*
 * Function to convert the "style" of a body from one where each element
 * is identified by a single key whose value is the element to a style
 * where the ID information is stored as an attribute of the element
 */
function body_unpack(body) {
	const body_elements = [];

	if (body === null) {
		return(body_elements);
	}

	for (const body_element_info of body) {
		const body_element_id = Object.keys(body_element_info)[0];
		const body_element = body_element_info[body_element_id];

		body_elements.push(Object.assign({
			'$element_id': body_element_id
		}, body_element));
	}

	return(body_elements);
}

/*
 * Function to return an array of all body element IDs (recursively)
 */
function body_element_ids(body) {
	const retval = [];

	for (const element of body) {
		const element_id = Object.keys(element)[0];
		const element_body = element[element_id];

		retval.push(element_id);

		if (element_body.body) {
			retval.push(...body_element_ids(element_body.body));
		}
	}

	return(retval);
}

function body_element_map(body) {
	const body_map = {};
	const body_elements = body_by_element_tag(null, body);

	for (const body_element of body_elements) {
		body_map[body_element.id] = body_element.contents;
	}

	return(body_map);
}

/*
 * Function to take a serialized document body and produce a list of elements
 * with a given tag (such as "template" or "section") and their IDs.
 *
 * This will take into account nested body structures.
 */
function body_by_element_tag(element_tag, body, container = undefined, options = {}) {
	options = {
		evaluate_expressions: false,
		variables: {},
		...options
	};

	if (container === undefined) {
		container = ['Document'];
	}

	const retval = [];

	if (!(body instanceof Array)) {
		return(retval);
	}

	for (const element of body) {
		const element_id = Object.keys(element)[0];
		const element_body = element[element_id];
		const element_type = element_body.type;
		const element_container = [...container];
		element_container.push(`${element_type} ${element_body.name}`);

		if (element_type === element_tag || element_tag === null) {
			if (options.evaluate_expressions) {
				switch (element_type) {
					case 'switch':
					case 'loop':
						if (element_body.expression) {
							element_body.expression_evaluated = kaialpha.lib.nunjucks_utils.compute_expression(element_body.expression, options.variables)
						}
						break;
					default:
						/* Nothing to do for most elements */
						break;
				}
			}

			retval.push({
				type: element_type,
				id: element_id,
				contents: element_body,
				container: container
			});
		}

		switch (element_type) {
			case 'section':
				if (element_body.body) {
					retval.push(...body_by_element_tag(element_tag, element_body.body, element_container, options));
				}
				break;
			case 'switch':
				{
					const expression_result = {
						valid: false
					};

					if (options.evaluate_expressions === true && element_body.expression) {
						expression_result.value = element_body.expression;
						expression_result.valid = true;
					}

					if (element_body.values instanceof Object) {
						for (const expression_value in element_body.values) {
							if (expression_result.valid === true) {
								/*
								 * If we already matched a value, skip all other values
								 */
								if (expression_result.matched === true) {
									continue;
								}

								if (kaialpha.lib.nunjucks_utils.compare_expressions(expression_result.value, expression_value, options.variables)) {
									expression_result.matched = true;
								} else {
									/*
									 * If this case does not match, skip it
									 */
									continue;
								}
							}

							const value_body = element_body.values[expression_value];
							if (value_body.body) {
								if (element_tag === null) {
									retval.push({
										type: '@meta:value',
										id: `${element_id}-value-${expression_value}`,
										contents: {
											type: '@meta:value',
											value: expression_value
										},
										container: element_container
									});
								}
								retval.push(...body_by_element_tag(element_tag, value_body.body, [...element_container, `Value ${expression_value}`], options));
							}
						}
					}

					/*
					 * If either there is no valid expression or we have not matched any expression
					 * evaluate the default case
					 */
					if (expression_result.valid !== true || expression_result.matched !== true) {
						if (element_body.default && element_body.default.body) {
							if (element_tag === null) {
								retval.push({
									type: '@meta:default',
									id: `${element_id}-default`,
									contents: {
										type: '@meta:default'
									},
									container: element_container
								});
							}
							retval.push(...body_by_element_tag(element_tag, element_body.default.body, [...element_container, 'Default'], options));
						}
					}
				}
				break;
			case 'loop':
				/*
				 * TODO: Pre-processor expression evaluation might be needed in the future
				 */
				if (element_body.body) {
					retval.push(...body_by_element_tag(element_tag, element_body.body, element_container, options));
				}

				if (element_body.else && element_body.else.body) {
					if (element_tag === null) {
						retval.push({
							type: '@meta:else',
							id: `${element_id}-else`,
							contents: {
								type: '@meta:else'
							},
							container: element_container
						});
					}
					retval.push(...body_by_element_tag(element_tag, element_body.else.body, [...element_container, 'Else'], options));
				}
				break;
			default:
				break;
		}
	}

	return(retval);
}

async function body_templates(body, options = {}) {
	const template_tags = body_by_element_tag('template', body, options.container);
	/*
	 * XXX: TODO: Template Editor currently corrupts saved
	 *            templates, and fills it in with invalid
	 *            template IDs;  Filter this out now until
	 *            validation can be implemented
	 */
	const retval = [];
	for (const item of template_tags) {
		if (!item.contents.expression && (!item.contents.id || item.contents.id === '')) {
			kaialpha.log.debug('[DEBUG WARNING] Dropping misformatted item:', item);

			continue;
		}

		/**
		 * If there's an element with an expression without callback or variables,
		 * skip it, otherwise loop thro it.
		 */
		if (item.contents.expression && (options.get_templates_callback === undefined || options.variables === undefined)) {
			continue;
		}

		if (item.contents.expression && options.get_templates_callback && options.variables) {
			const metadata_expresssion = kaialpha.lib.nunjucks_utils.renderString(item.contents.expression, options.variables);
			const template_options = { fields: ['version'], filter: `metadata.${metadata_expresssion}` };
			const template_info = await options.get_templates_callback(template_options);
			const templates = template_info.templates;
			/**
			 * sort templates by their id(uuid),
			 * so their order remains consistent
			 */
			templates.sort(function (a, b) {
				if (a.id < b.id) {
					return(-1);
				} else if (a.id > b.id) {
					return(1);
				} else {
					return(0);
				}
			});

			templates.forEach(function (template, index) {
				const template_item = object_utils.copy_object(item);
				template_item.contents.id = template.id;
				template_item.contents.version = template.version;
				const replacement_values = {
					id: template.id.replace(/-/g, ''),
					ordinal: index + 1
				};
				template_item.contents.name = kaialpha.lib.nunjucks_utils.renderString(template_item.contents.name, replacement_values);
				delete template_item.contents.expression;
				retval.push(template_item);
			});
		} else {
			retval.push(item);
		}
	}
	return(retval);
}

/*
 * Sortly<->Body
 */
function get_body_sortly_items(body) {
	const retval = [];

	const flattened = body_by_element_tag(null, body);
	for (const item_master of flattened) {
		const item = object_utils.copy_object(item_master);

		switch (item.type) {
			case 'section':
				delete item.contents['body'];
				break;
			case 'switch':
				delete item.contents['values'];
				delete item.contents['default'];
				break;
			case 'loop':
				delete item.contents['body'];
				delete item.contents['else'];
				break;
			default:
				break;
		}

		retval.push({
			id: item.id,
			type: item.type,
			contents: item.contents,
			depth: item.container.length - 1
		});
	}

	return(retval);
}

function assemble_body_sortly_items(items) {
	items = object_utils.copy_object(items);

	let retval = null;

	let last_item_depth = 0;
	const current_items = [];
	let current_item = {};

	for (const item of items) {
		if (item.depth > last_item_depth) {
			if ((item.depth - last_item_depth) !== 1) {
				throw(new /*kaialpha.User*/Error(`Skip insert of item detected, last_item_depth was ${last_item_depth}, but this item is ${item.depth}; item = ${JSON.stringify(current_item)}`));
			}

			current_items.push(current_item);

			const current_item_wrapper = current_item['body'].slice(-1)[0];
			current_item = current_item_wrapper[Object.keys(current_item_wrapper)[0]];
		} else if (item.depth < last_item_depth) {
			for (let count = (last_item_depth - item.depth); count > 0; count--) {
				current_item = current_items.pop();
			}
		}

		if (item.type === '@meta:value') {
			if (current_item.type !== 'switch') {
				throw(new /*kaialpha.User*/Error(`Error "value" item may only be a child of a "switch" item; item = ${JSON.stringify(current_item)}`));
			}

			if (current_item['values'] === undefined) {
				current_item['values'] = {};
			}
			current_item['values'][item.contents.value] = {
				body: []
			}

			current_items.push(current_item);
			current_item = current_item['values'][item.contents.value]

			last_item_depth = item.depth + 1;
			continue;
		} else if (item.type === '@meta:default') {
			if (current_item.type !== 'switch') {
				throw(new /*kaialpha.User*/Error(`Error "default" item may only be a child of a "switch" item; item = ${JSON.stringify(current_item)}`));
			}
			current_item['default'] = {
				body: []
			}

			current_items.push(current_item);
			current_item = current_item['default']

			last_item_depth = item.depth + 1;
			continue;
		} else if (item.type === '@meta:else') {
			if (current_item.type !== 'loop') {
				throw(new /*kaialpha.User*/Error(`Error "else" item may only be a child of a "loop" item; item = ${JSON.stringify(current_item)}`));
			}
			current_item['else'] = {
				body: []
			}

			current_items.push(current_item);
			current_item = current_item['else']

			last_item_depth = item.depth + 1;
			continue;
		}

		if (!['switch', 'loop', 'section', undefined].includes(current_item.type)) {
			throw(new Error(`Attempted to create a child of an element with type ${current_item.type}`));
		}
		if (current_item.type === 'switch') {
			if (item.type.substr(0, 6) !== '@meta:') {
				throw(new Error(`Attempted to create a child of an element with type "${current_item.type}", new type is not "@meta:*" but instead "${item.type}"`));
			}
		}

		/*
		 * XXX:TODO: Should this be done in a separate process ?
		 */
		delete item.contents['$from'];
		delete item.contents['$variable_descriptor'];

		const body_item = {
			[item.id]: item.contents
		}

		if (current_item['body'] === undefined) {
			current_item['body'] = [];
		}

		current_item['body'].push(body_item);

		if (retval === null) {
			retval = current_item['body'];
		}

		last_item_depth = item.depth;
	}

	if (retval === null) {
		retval = [];
	}

	return(retval);
}

function process_body_expressions(body, variables) {
	const retval = {};

	const body_list = body_by_element_tag('switch', body, undefined, {
		evaluate_expressions: true,
		variables: variables
	});

	for (const element of body_list) {
		if (element.type !== 'switch') {
			continue;
		}

		if (element.contents === undefined) {
			continue;
		}

		const variable_name = element.contents.name;

		if (!variable_name) {
			continue;
		}

		/*
		 * As a consequence of passing "evaluate_expression = true" to
		 * body_by_element_tag, expressions are evaluated
		 */
		const variable_value = element.contents.expression_evaluated;

		retval[variable_name.toLowerCase()] = variable_value;
	}

	return(retval);
}

if (_testing) {
	_testing.process_body_expressions = function() {
		const body = [
			{
				"f1e38294-2a9f-493a-94f6-560b1611d80c": {
					"type": "switch",
					"name": "Var1",
					"expression": "111",
					"values": {
						"111": {
							"body": [
								{
									"7eb7fa69-69a4-4b64-8ff8-3053a139684d": {
										"type": "switch",
										"name": "Var2",
										"expression": "332 + 1",
										"values": {
											"333": {
												"body": [
													{
														"6c378607-e4f8-4c28-98c8-882e191f71ec": {
															"type": "switch",
															"name": "Var3",
															"expression": "\"works\""
														}
													}
												]
											},
											"444": {
												"body": [
													{
														"e20d1151-9938-4024-9660-3b3f0b5eb49d": {
															"type": "switch",
															"name": "Var3",
															"expression": "666"
														}
													}
												]
											}
										},
										"default": {
											"body": [
												{
													"882e7672-6ccf-4889-b32e-9905b6c4c289": {
														"type": "switch",
														"name": "Var3",
														"expression": "777"
													}
												}
											]
										}
									}
								}
							]
						},
						"222": {
							"body": [
								{
									"e2aec282-f005-4d31-a88d-9d563d8493ea": {
										"type": "switch",
										"name": "Var3",
										"expression": "444"
									}
								}
							]
						}
					},
					"default": {
						"body": [
							{
								"a7f272b7-92c0-4191-8cac-a3d1d921d2eb": {
									"type": "switch",
									"name": "Var3",
									"expression": "555"
								}
							}
						]
					}
				}
			}
		];

		const check = process_body_expressions(body, {});
		testing_utils.assert.object_equals(check, {
			"var1": "111",
			"var2": "333",
			"var3": "works"
		});

		return(true);
	};
}

/*
 * TODOC
 */
async function process_document_variables(user_id, document_info, options = {}) {
	/*
	 * Default options
	 */
	options = Object.assign({
		add_missing_variables: undefined,
		document_variable_callback: undefined,
		allow_empty_values: true,
		_state: {
			include_global: true,
			parent: undefined
		},
		get_document_obj: undefined
	}, options);

	/*
	 * If we want to include the global structure, first get the top-level
	 * document and everything under it, then make '__current' point to
	 * the specified document ID
	 */
	const variables = {};

	if (options._state.include_global) {
		let top_document_info;
		if (options.get_document_obj) {
			top_document_info = options.get_document_obj(document_info.document_id, document_info.version_id);
		}

		if (top_document_info === undefined){
			top_document_info = await get_toplevel_document(user_id, document_info.document_id, document_info.version_id);
		}

		variables['__current'] = {}
		variables['global'] = {};
		const tree = await process_document_variables(user_id, {
			document_id: top_document_info.id,
			version_id: top_document_info.version,
			buffer_id: document_info.buffer_id
		}, {
			...options,
			_state: {
				...options._state,
				include_global: false,
				global: variables['global'],
				set_current: {
					object: variables,
					document_id: document_info.document_id,
					version_id: document_info.version_id
				}
			}
		});
		Object.assign(variables.global, tree);

		variables.global['__add_missing_variables'] = options.add_missing_variables;

		return(variables);
	}

	variables['__current'] = variables;
	variables['global'] = options._state.global;

	if (options._state.set_current && options._state.set_current.document_id === document_info.document_id) {
		options._state.set_current.object['__current'] = variables;
	}

	try {
		let document;
		if (options.get_document_obj) {
			document = options.get_document_obj(document_info.document_id, document_info.version_id);
		}

		if (document === undefined) {
			document = await kaialpha.lib.document.get_user_document(user_id, document_info.document_id, document_info.version_id);
		}

		if (document_info.buffer_id !== undefined &&
					buffer_utils.get_item_id_from_buffer_id(document_info.buffer_id) === document.id) {
			const buffer = await kaialpha.lib.document_buffer.get_user_document_buffer(user_id, document_info.buffer_id);
			document.variables = buffer.variables;
		}
		const template = await kaialpha.lib.document.get_user_template_from_document(user_id, document);
		const body = body_serialize(document, template);
		const body_template_options = {
			get_templates_callback: async function(filter) {
				const templates = await kaialpha.lib.template.get_user_templates(user_id, filter);
				return templates;
			}
		};
		const subtemplates_info = await body_templates(body, body_template_options);
		const element_subdocument_mapping = document.subdocuments;
		const template_subdocument_mapping = {};

		/**
		 * Get a map of subdocument and it's template id
		 */
		const run_promises_mappings = [];
		for (const element_id of Object.keys(element_subdocument_mapping)) {
			let subdocument_ids = [];
			if (element_subdocument_mapping[element_id].document_id !== undefined) {
				subdocument_ids = element_subdocument_mapping[element_id].document_id;
			}

			if (typeof subdocument_ids === 'string') {
				subdocument_ids = [subdocument_ids];
			}

			/***
			 * Existing documents could have document_id which are mapped to a string,
			 * so in that case, just converting that to an array before parsing,
			 */
			subdocument_ids.forEach(function(subdocument_id) {
				const run_promise = async function() {
					const document = await kaialpha.lib.document.get_user_document(user_id, subdocument_id, 'HEAD');
					template_subdocument_mapping[document.template.id] = subdocument_id;
				}();
				run_promises_mappings.push(run_promise);
			});
		}
		await Promise.all(run_promises_mappings);

		const run_promises = [];
		subtemplates_info.forEach(function(subtemplate_info) {
			if (element_subdocument_mapping === undefined) {
				kaialpha.log.error(`When trying to get Subdocument Information on Document ID ${document_info.document_id}/${document_info.version_id} we found that "document.subdocuments" was undefined.  Subtemplate Info =`, subtemplate_info);
				return;
			}

			const run_promise = async function() {
				const subdocument_name = subtemplate_info.contents.name;
				const subdocument_global_name = subtemplate_info.contents.global_name;
				const template_id = subtemplate_info.contents.id;
				const subdocument_id = template_subdocument_mapping[template_id];
				const subdocument_version = 'HEAD';

				if (!subdocument_name) {
					return;
				}

				document_info.document_id = subdocument_id;
				document_info.version_id = subdocument_version;
				variables[subdocument_name.toLowerCase()] = await process_document_variables(user_id, document_info, options);

				if (subdocument_global_name) {
					options._state.global[subdocument_global_name.toLowerCase()] = variables[subdocument_name.toLowerCase()];
				}

				variables[subdocument_name.toLowerCase()]['parent'] = variables;
			}();
			run_promises.push(run_promise);
		});
		await Promise.all(run_promises);

		if (template.metadata) {
			Object.entries(template.metadata).forEach(function(template_metadata_info) {
				const template_metadata_name = template_metadata_info[0];
				const template_metadata_value = template_metadata_info[1];

				if (template_metadata_value !== '' || options.allow_empty_values) {
					variables[template_metadata_name.toLowerCase()] = template_metadata_value;
				}
			});
		}

		if (body) {
			const current_template_id = template.id;

			if (current_template_id !== undefined) {
				/* XXX:TODO: Why do these specifically want a template ? */
				const reference_variables = await kaialpha.lib.template_utils.process_template_references(user_id, body, current_template_id, null, options);
				Object.assign(variables, reference_variables);

				const nunjucks_styling = await kaialpha.lib.template_utils.process_template_style(user_id, body, current_template_id, null, options);
				Object.assign(variables, nunjucks_styling);
			}
		}

		if (document.variables) {
			const variable_run_promises = [];
			Object.entries(document.variables).forEach(function(document_variable_info) {
				const run_promise = async function() {
					const document_variable_name = document_variable_info[0];
					let document_variable_value = document_variable_info[1];

					if (document_variable_value !== '' || options.allow_empty_values) {
						if (options.document_variable_callback) {
							document_variable_value = await options.document_variable_callback(document_variable_value, template.variables[document_variable_name]);
						}
						variables[document_variable_name.toLowerCase()] = document_variable_value;
					}
				}();
				variable_run_promises.push(run_promise);
			});
			await Promise.all(variable_run_promises);
		}

		if (body) {
			const evaluated_expressions = process_body_expressions(body, variables);
			Object.assign(variables, evaluated_expressions);
		}
	} catch(err) {
		kaialpha.log.debug('Error in process_document_variables: ', err)
	}

	return(variables);
}

function get_value_from_element(element) {
	const element_type = element.type;

	let value;
	switch (element_type) {
		case 'section':
		case 'variable':
		case 'template':
			value = element.name;
			break;
		case 'header':
		case 'footer':
			value = element.value;
			break;
		case 'title':
		case 'table':
			value = element.title;
			break;
		case 'html':
			value = element.text;
			break;
		case 'table_of_contents':
			value = 'Table of Contents'
			break;
		default:
			value = element.name;
			break;
	}

	if (value === undefined) {
		value = `[UNKNOWN ${JSON.stringify(element.type)}]`;
	}

	return value;
}

async function get_data_source_matches(user_id, source) {
	const datasource_items = await kaialpha.lib.data.list_data(user_id, '/');

	/*
	 * Compute the portion of the URL that is being matched
	 * against wildcards, to compute a name for this element.
	 */
	const star_position = source.indexOf('*');
	const trim_left = source.lastIndexOf('/', star_position) + 1;
	const trim_right = (source.length - star_position - 1) * -1;

	const source_regex = glob_to_regexp(source, {
		extended: true,
		globstar: true
	});

	/* Loop through datasource items and check if url matches source */
	const matches = [];
	for (const item of datasource_items) {
		const item_url = item.url;
		const item_wildcard_match = item_url.match(source_regex);
		if (item_wildcard_match === null) {
			continue;
		}

		const name = item_url.slice(trim_left, trim_right);
		matches.push({
			url: item_url,
			short_name: name
		});
	}

	return(matches);
}

async function fetch_data_source_value(user_id, source, variable_info = {}) {
	/*
	 * If there's a wildcard, iterate over all the matches and create a
	 * structured object.
	 */
	let data = {};
	if (source.includes('*')) {
		/* Get all data sources that match source url */
		const matches = await get_data_source_matches(user_id, source);

		const num_variables = matches.length;
		for (const item of matches) {
			/* Store data source data and metadata in data object */
			data[item.short_name] = await data_utils.fetch_data(item.url, variable_info);
			data.type = 'multi_data_source';
		}

		if (!data['@metadata']) {
			data['@metadata'] = {};
		}

		data['@metadata']['size'] = num_variables;

	} else {
		data = await data_utils.fetch_data(source, variable_info);
	}

	return(data);
}

async function fetch_document_data_source_value(user_id, document_id, version_id, variable_name) {
	/*
	 * Get the document and template
	 */
	const document = await kaialpha.lib.document.get_user_document(user_id, document_id, version_id);
	const template = await kaialpha.lib.document.get_user_template_from_document(user_id, document);

	/*
	 * Get the variable
	 */
	const variable_info = template.variables[variable_name].options;
	if (!variable_info) {
		return;
	}

	const source_template = variable_info.source;

	/*
	 * Compute the source -- if there are no variable references
	 * use the value as-is
	 */
	let source;
	if (source_template.includes('{{') || source_template.includes('{%')) {
		const missing_value_sentinel = 'missing_6a4d8535-0eac-426c-b190-484e47c752b0';
		const document_info = {
			document_id: document_id,
			version_id: version_id
		}
		const variables = await process_document_variables(null, document_info, {
			add_missing_variables: function() { return(missing_value_sentinel); },
			allow_empty_values: false
		});

		source = kaialpha.lib.nunjucks_utils.noKaiAlpha.renderString(source_template, variables);

		/*
		 * Verify that the source does not rely on any missing values
		 */
		if (source.includes(missing_value_sentinel)) {
			throw(new Error(`Unable to fetch data source for ${variable_name} -- its source relies on values that have not been supplied`));
		}
	} else {
		source = source_template
	}

	const data = await fetch_data_source_value(user_id, source, variable_info);

	data['last_updated'] = Date.now(); /* XXX:TODO: Should we use the user's clock for this ? */

	return(data);
}

/*
 * TODOC
 */
async function get_toplevel_document(user_id, document_id, version_id) {
	const document = await kaialpha.lib.document.get_user_document(user_id, document_id, version_id);

	if (!document) {
		throw(new Error(`Failed to fetch document with id ${document_id} and version ${version_id}`));
	}

	if (!document.superdocument) {
		return({
			id: document_id,
			version: version_id
		});
	}

	return(await get_toplevel_document(user_id, document.superdocument.document_id, 'HEAD'));
}

function get_reference_value(element, element_id, value, replacement_values) {
	let format = element.format;
	if (format === undefined) {
		format = '{{value}}';
	}

	let formatted_value;
	if (format === '{{link}}') {
		formatted_value = `<a href='#${element_id}'>${value}</a>`;
	} else {
		formatted_value = kaialpha.lib.nunjucks_utils.renderString(format, replacement_values);
	}

	return(formatted_value);
}

async function _get_image_data(image_info, isParsed = true) {
	const s3 = new kaialpha.aws.S3();
	const bucket_path = 'images/';

	let key = image_info.key;
	if (!key.includes(bucket_path)) {
		key = `${bucket_path}${image_info.key}`;
	}

	const file_data = await s3.getObject({
		Bucket: kaialpha.configuration.images_s3_bucket,
		Key: key
	}).promise();

	const file_data_b64 = file_data.Body.toString('base64');

	if (isParsed) {
		return({
			data: 'data:' + image_info.type + ';base64,' + file_data_b64
		});
	}

	return({
		data: file_data_b64
	});
}

function get_image_tag_after_setting_source(source) {
	return `<img src="${source}" alt="Unammed Image">`;
}

function check_image_type_and_process_image_object(object) {
	if (object.type && object.type === 'image') {
		const image = get_image_tag_after_setting_source(object.image);
		return image;
	}
	return(JSON.stringify(object));
}

function add_toString_to_all_sub_objects(multi_source_object) {
	for (const image_object in multi_source_object) {
		if (multi_source_object[image_object].type === 'image') {
			multi_source_object[image_object].toString = function () {
				return check_image_type_and_process_image_object(this);
			};
		}
	}
	return multi_source_object;
}

async function render_document_processing_variables(user_id, document_info, document) {
	const variables = await process_document_variables(user_id, document_info, {
		add_missing_variables: function(variable_name) {
			return(`<span class="missingvalue"><mark>{{${variable_name}}}</mark></span>`);
		},
		document_variable_callback: async function (variable, variable_info) {
			if (!variable_info) {
				kaialpha.log.error(`While processing document ${document_info.document_id}/${document_info.version_id} we came upon a variable ${variable} which is not defined in the template!`);
			}

			if (variable_info && variable_info.type === 'image') {
				const image_info = JSON.parse(variable);
				const image_obj = {'key': image_info.key};
				const image_data = await _get_image_data(image_obj, false);
				return(`<img style="max-width: 50%" src="${'data:' + image_info.type + ';base64,' + image_data.data}">`);
			}

			if (variable_info && variable_info.type === 'reference') {
				const replacement_values = {
					value: variable.value,
					type: variable.type,
					parent_value: variable.parent
				};

				const reference_value = get_reference_value(variable, variable.element_id, variable.value, replacement_values);

				return(reference_value);
			}

			if (!(variable instanceof Object)) {
				return(variable);
			}

			if (typeof(variable) === 'string') {
				return(variable);
			}

			if (variable_info && variable_info.type === 'datasource') {
				if (variable.type === 'multi_data_source') {
					variable = add_toString_to_all_sub_objects(variable);
				}
				variable.toString = function () {
					return check_image_type_and_process_image_object(this);
				}
			}

			if (variable_info && variable_info.type === 'expression') {
				return(variable.options.expression)
			}

			return(variable);
		}
	});

	const nunjucks_file = await kaialpha.lib.generator.generateNunjucksFromDocument(document, {
		user_id: user_id,
		type: 'document'
	});

	const html_file = await kaialpha.lib.generator.generateHTML(nunjucks_file, variables, {
		get_user_list_entries: async function(list_type, list_id, list_version) {
			return(await kaialpha.lib.list_utils.get_user_list_entries(user_id, list_type, list_id, list_version));
		}
	});

	fs.unlinkSync(nunjucks_file);

	return(html_file);
}

async function fetch_all_datasource_values(document, error) {
	/*
	 * Start with top level document and work
	 * through subdocuments
	 */
	const template = await kaialpha.lib.document.get_user_template_from_document(null, document);
	const template_variables = template.variables;

	/*
	 * Loop through variables and fetch their
	 * values for all datasources.
	 */
	const document_variables = document.variables;
	for (const key in template_variables) {
		const variable_info = template_variables[key];
		const type = variable_info.type;

		if (type === 'datasource') {
			try {
				const data = await fetch_document_data_source_value(null, document.id, 'HEAD', key);
				document_variables[key] = data;
			} catch(fetch_error) {
				error += `${fetch_error} \n `;
			}
		}
	}

	/*
	 * Patch document to change variables
	 */
	const updated_document = await kaialpha.lib.document.updateDocumentVersion(document.id, 'HEAD', document.name, document_variables, document.permissions);

	/*
	 * If document has subdocuments, fetch its datasources.
	 */
	const subdocument_map = document.subdocuments;
	if (subdocument_map instanceof Object) {
		for (const subdocument_element_id in subdocument_map) {
			const subdocument_info = subdocument_map[subdocument_element_id];
			const subdocument_id = subdocument_info.document_id;

			const subdocument = await kaialpha.lib.document.get_user_document(null, subdocument_id, 'HEAD');

			return(fetch_all_datasource_values(subdocument, error));
		}
	}

	return({
		version_id: updated_document.version,
		error: error,
		success: 'Successfully fetched all datasources'
	})
}

/*
 * Compare if a given function when converse to another return the original value
 *
 * Return value: boolean
 */
if (_testing) {
	const body = [
		{
			[uuid.v4()]: {
				type: 'title',
				title: 'A Report for Customer {{CustomerID}}'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerID'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerType'
			}
		},
		{
			[uuid.v4()]: {
				type: 'html',
				text: 'This is a report for Customer {{CustomerID}} ({{CustomerType}})'
			}
		}
	];

	_testing._verify_condition_return_original_input = function () {

		const sortly = get_body_sortly_items(body);
		const body_check = assemble_body_sortly_items(sortly);

		/* istanbul ignore next */
		/* This either yeilds true or throws error which is not needed in coverage report */
		if (JSON.stringify(body) === JSON.stringify(body_check)){
			return(true);
		} else {
			throw(new Error(`When comparing get_body_sortly_items converse to assemble_body_sortly_items got different from origin body  but was expected to be equal`));
		}
	}

	_testing.body_serialize = function () {

		let result = body_serialize({ 'body_extend': body });

		/* istanbul ignore if */
		if (!Array.isArray(result)) {
			throw new Error("body_serialize should yeild an array of body_extend from document but found different");
		}

		result = body_serialize(undefined, { 'body': body });

		/* istanbul ignore if */
		if (!Array.isArray(result)) {
			throw new Error("body_serialize should yeild an array of body from template but found different");
		}

		return true;
	}

	_testing.body_serialize_with_both_body = function () {
		try {
			body_serialize({ 'body_extend': body }, { 'body': body });
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("Both template and document has body hence this should have thrown error");
	}

	_testing.body_serialize_with_no_body = function () {
		const result = body_serialize({ 'body_extend': 'something' }, { 'body': 'something' });

		if (Object.keys(result).length === 0) {
			return(true);
		}

		/* istanbul ignore next */
		throw new Error("Both template and document has no body hence this should returned an empty set");
	}

	_testing.body_element_ids = function () {
		const result = body_element_ids(body);

		/* istanbul ignore if */
		if (result.length !== body.length) {
			throw new Error("Body element ids shoulb match with body elements, but missing here");
		}

		return true;
	}

	_testing.body_element_ids_with_extra_nested_element = function () {
		const newArray = new Array(body);

		newArray.push({
			[uuid.v4()]: {
				'type': 'section',
				'name': 'Section',
				'body': [body[0]]
			}
		});

		const result = body_element_ids(newArray);

		/* istanbul ignore if */
		if ((result.length !== newArray.length + 1)) {
			throw new Error("Should have the subsection template id, but not found here");
		}

		return true;
	}

	_testing.body_by_element_tag_fallback_test_1 = function () {
		const result = body_by_element_tag(undefined, null);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error("There is no body element here ");
		}

		return true;
	}

	_testing.body_by_element_tag_fallback_test_2 = function () {
		const result = body_by_element_tag('template', { 'test': 'text' });

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error(`There should not be any result but got this ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.body_by_element_tag_fallback_test_3 = function () {
		const result = body_by_element_tag('template', { 'test': 'text' }, undefined, { evaluate_expressions: true });

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error(`There should not be any result but got this ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.body_by_element_tag_fallback_test = function () {

		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'section',
					text: 'Section',
					body: [
						{
							[uuid.v4()]: {
								type: 'html',
								text: 'Something'
							}
						}
					]
				}
			}
		]

		const result = body_by_element_tag('template', custom_body);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error("There is no body element here ");
		}

		return true;
	}

	_testing.body_by_element_tag_fallback_test_with_not_identified_type = function () {

		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'some',
					text: 'OtherSection'
				}
			}
		]

		const expected_result = {
			"contents": { type: "some", text: "OtherSection" }
		};

		const result = body_by_element_tag(null, custom_body, undefined, { evaluate_expressions: true });

		/* istanbul ignore if */
		if (!testing_utils.object_equals(result[0].contents, expected_result.contents)) {
			throw new Error(`While doing body_by_element_tag we got ${JSON.strigify(result)} but expected ${JSON.stringify(expected_result)}`);
		}

		return true;
	}

	_testing.body_by_element_tag_switch_type_test_2 = function () {

		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'switch',
					expression: '1 * 1',
					values: {
						'1': {
							body: {
								[uuid.v4()]: {
									type: 'html',
									text: '<p></p>'
								}
							}
						}
					}
				}
			}
		]

		const result = body_by_element_tag(null, custom_body);

		/* istanbul ignore if */
		if (result[0].type !== "switch") {
			throw new Error(`While testing body_by_element_tag expected type as switch but found ${result[0].type}`);
		}

		return true;
	}

	_testing.body_by_element_tag_switch_type_test_3 = function () {

		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'loop',
					expression: 2,
					else: {
						type: 'section',
						body: {
							[uuid.v4()]: {
								type: 'html',
								text: '</p>'
							}
						}
					}
				}
			}
		]

		const result = body_by_element_tag(null, custom_body);

		/* istanbul ignore if */
		if (result[0].type !== 'loop' && result[1].type !== '@meta:else') {
			throw new Error(`While testing body_by_element_tag_switch_type_test_3 with custom body we found ${result[0].type} and ${result[1].type} instead of loop and meta:else`)
		}
		return true;
	}

	_testing.body_templates_fallback = async function () {
		const result = await body_templates(body);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error(`There is no template here but the result yeilds one, which is wrong: ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.body_templates_test = async function () {
		const newArray = new Array(body);

		newArray.push({
			[uuid.v4()]: {
				'type': 'template',
				'id': 'template-id',
			}
		});

		const result = await body_templates(newArray);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Templates should be present in this function: ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.body_templates_test_without_id = async function () {
		const newArray = new Array(body);

		newArray.push({
			[uuid.v4()]: {
				'type': 'template',
				'id': '',
			}
		});

		const result = await body_templates(newArray);

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error("body_templates_test_without_id failed ");
		}

		return true;
	}

	_testing.body_unpack_test = function () {
		const result = body_unpack(null);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error("There is not body elements hence it should return empty error but found elements");
		}
		return true;
	}

	_testing.body_unpack_test_with_body_elements = function () {
		const result = body_unpack(body);

		/* istanbul ignore if */
		if (result.length !== body.length) {
			throw new Error("Body elements should match with body unpack elements but found different");
		}
		return true;
	}

	_testing.body_element_map_test = function () {
		const result = body_element_map(body);

		/* istanbul ignore if */
		if (Object.keys(result).length !== body.length) {
			throw new Error("Body elements should match with body unpack elements but found different");
		}
		return true;
	}

	_testing.get_body_sortly_items_test1 = function () {
		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'section',
					text: 'Section',
					body: [
						{
							[uuid.v4()]: {
								type: 'html',
								text: 'Something'
							}
						},
						{
							[uuid.v4()]: {
								type: 'loop',
								body:[]
							}
						},
						{
							[uuid.v4()]: {
								type: 'switch',
								values: '1, 2, 3',
								contents: []
							}
						}
					]
				}
			}
		]

		const result = get_body_sortly_items(custom_body);

		/* istanbul ignore if */
		if (result[0].type !== 'section') {
			throw new Error(`As per body items section should have came first`);
		}

		return true;
	}

	_testing.get_assemble_body_sorty_items_loop = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'section',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			},
			{
				id: '6daba29b-5331-4593-b9de-66ca69671388',
				type: 'html',
				contents: { type: 'html', text: 'Something' },
				depth: 1
			}
		];

		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.get_assemble_body_sorty_items_exceptions1 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'section',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			},
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'html',
				contents: { type: 'html', text: 'Section' },
				depth: 2
			}
		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	}

	_testing.get_assemble_body_sorty_items_exceptions2 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:value',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			}

		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	}

	_testing.get_assemble_body_sorty_items_exceptions3 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:default',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			}

		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	}

	_testing.get_assemble_body_sorty_items_exceptions4 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'switch',
				contents: { type: 'switch', values: [1, 2, 3] },
				depth: 0
			},
			{
				id: 'd1cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:value',
				contents: {value: 1},
				depth: 1
			},
			{
				id: 'd3cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:default',
				contents: {value: 1},
				depth: 1
			}

		];
		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.get_assemble_body_sorty_items_exceptions5 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'switch',
				contents: { type: 'switch'},
				depth: 0
			},
			{
				id: 'd1cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:value',
				contents: {value: 1},
				depth: 1
			},
		];
		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.get_assemble_body_sorty_items_loop_exceptions1 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'loop',
				contents: {
					type: 'loop', body: []
				},
				depth: 0
			},
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:else',
				contents: {
					type: 'loop', body: []
				},
				depth: 1
			}
		];
		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.get_assemble_body_sorty_loop_exceptions2 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:else',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			}

		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	}

	_testing.get_assemble_body_without_correct_type = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'switch',
				contents: { type: 'switch'},
				depth: 0
			},
			{
				id: 'd1cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'html',
				contents: {},
				depth: 1
			},
		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	}

	_testing.assemble_body_sortly_items_without_items = function () {
		const result = assemble_body_sortly_items([]);
		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error(`Expected an empty element but found ${JSON.stringify(result)}`);
		}
		return true;
	}

	_testing.get_value_from_element_test1 = function () {
		const elements = [
			{ type: 'section', name: 'Section1', result: 'Section1' },
			{ type: 'header', value: 'Header1', result: 'Header1' },
			{ type: 'title', title: 'Title1', result: 'Title1' },
			{ type: 'html', text: 'HTML', result: 'HTML' },
			{ type: 'default', name: 'Default', result: 'Default' },
			{ type: 'default', result:  `[UNKNOWN ${JSON.stringify('default')}]`}
		]

		for (const element of elements) {
			const result = get_value_from_element(element);

			/* istanbul ignore if */
			if (result !== element.result) {
				throw new Error(`Should have ${element.result} but got ${result}`);
			}
		}

		return true;
	}

}

const _to_export_auto = {
	body_element_ids,
	body_element_map,
	body_serialize,
	body_unpack,
	body_templates,
	body_by_element_tag,
	get_body_sortly_items,
	assemble_body_sortly_items,
	process_document_variables,
	get_data_source_matches,
	fetch_data_source_value,
	fetch_document_data_source_value,
	get_toplevel_document,
	get_value_from_element,
	get_reference_value,
	_testing,
	render_document_processing_variables,
	_get_image_data,
	fetch_all_datasource_values
};
export default _to_export_auto;
