import React from 'react';
import TemplateDocSelector from '../TemplateDocSelector';
import lib_template from '../../api/template';
import template_utils from '../../lib/utils/template_utils';

class TemplateSelector extends React.Component {
	async getTemplateChildren(parent = null, version = undefined) {
		const getTemplateChildren = await lib_template.getTemplateChildren(parent, version);
		return getTemplateChildren;
	}

	async deleteTemplate(template_id) {
		const supertemplates = await template_utils.get_template_supers(null, template_id, {
			include_self: false
		});
		if (supertemplates.length > 1) {
			throw(new Error('Unable to delete the selected template since it is included by more than one template.'));
		}

		const deleted_template = await lib_template.deleteTemplate(template_id);
		return(deleted_template);
	}

	render() {
		return(
			<div className="edit-document">
				<TemplateDocSelector
					action = 'Edit'
					display_type = 'Template'
					content_type = 'templates'
					header_type = 'Template'
					getChildren = {this.getTemplateChildren}
					deleteItem = {async (id) => {
						const deleted_template = await this.deleteTemplate(id);
						return(deleted_template);
					}}
				/>
			</div>
		)
	}
}

export default TemplateSelector;
