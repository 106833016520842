import Table from '../Table';
import ReactDOMServer from 'react-dom/server';

import kaialpha from '../../lib/kaialpha';
import document_utils from'../../lib/utils/document_utils';
import object_utils from '../../lib/utils/object_utils';
import generator_utils from '../../lib/utils/generator_utils';
import data_utils from '../../lib/utils/data_utils';
const uuid = require('uuid');

let variable_change = {};
const mem_cache = {};

async function default_mapping_function(element_type, element_id, element, generate_options) {
	if (generate_options.document_id !== undefined) {
		element_id = `${element_id}+${generate_options.document_id}`
	}
	switch (element_type) {
		case 'DOCUMENT_BEGIN':
			{
				const header_element_id = getHeaderAndFooter(generate_options, 'page_header_id');
				return([
					<div className="html-text" id={header_element_id}>
						{getHeaderAndFooter(generate_options, 'page_header')}
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
					</div>
				]);
			}
		case 'DOCUMENT_END':
			{
				const footer_element_id = getHeaderAndFooter(generate_options, 'page_footer_id');
				return([
					<div className="html-text" id={footer_element_id}>
						{getHeaderAndFooter(generate_options, 'page_footer')}
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
					</div>
				]);
			}
		case 'FILE_BEGIN':
			return([]);
		case 'FILE_END':
			return([]);
		case 'html':
			{
				const env = generate_options.env;
				const variables = generate_options.variables;

				let html_text;
				if (env) {
					html_text =  env.renderString(element.text, variables);
				} else {
					html_text = element.text;
				}

				const html_markup = {
					__html: html_text
				}
				return([
					<div className="html-text" id={element_id}>
						<div dangerouslySetInnerHTML={html_markup}></div>
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
						<br></br>
					</div>
				]);
			}
		case 'title':
			return([
				<span>
					<div className="title-component" id={element_id}>
						{element.title}
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
					</div>
					<br></br>
				</span>
			]);
		case 'template':
			{
				const template_view = await getTemplateView(element, element_id, generate_options);
				const concatenated_templates = Array.prototype.concat.apply([], template_view);
				return(concatenated_templates);
			}
		case 'section':
			{
				const section = await getSection(element, element_id, generate_options);
				const concatenated_sections = Array.prototype.concat.apply([], section);
				return(concatenated_sections);
			}
		case 'switch':
			{
				const retval = [];
				retval.push(<span>{renderSwitch('Switch', element.name, element_id)}</span>);
				if (element.values instanceof Object) {
					for (const element_value in element.values) {
						const id = uuid.v4();
						const element_value_body = element.values[element_value];
						retval.push(
							<div>
								{renderSwitch(element.expression, element_value, element_id, true, id)}
								<div className='indent-accordian' id={id}>
									<div className='indent'>
										{await generator_utils.generateNunjucksValueFromBody(element_value_body.body, generate_options, default_mapping_function, false)}
									</div>
								</div>
							</div>
						)
					}
				}


				if (element.default && element.default.body) {
					const id = uuid.v4();
					retval.push(
						<div>
							{renderSwitch('Default', 'default', element_id, true, id)}
							<div className='indent-accordian' id={id}>
								<div className='indent'>
									{await generator_utils.generateNunjucksValueFromBody(element.default.body, generate_options, default_mapping_function, false)}
								</div>
							</div>
						</div>
					)
				}
				return(retval);
			}
		case 'table_of_contents':
			{
				let all_sections;
				if (generate_options.base_details) {
					all_sections = await getAllSections(generate_options.base_details.template.body);
				} else {
					kaialpha.log.warn('Generating TOC we found no "generate_options.base_details"');
				}

				return([
					<div className="table-of-contents" id={element_id}>
						<div className="toc-title">Table of Contents</div>
						{all_sections}
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
					</div>
				])
			}
		case 'header':
			return([]);
		case 'footer':
			return([]);
		case 'table':
			{
				const table_data_info = await getTableData(element, generate_options);
				const datasource_name = element.datasource;
				const column_headers = element.column_headers;
				const row_headers = element.row_headers;

				let table_title = 'Table';
				if (element.title) {
					table_title = element.title;
				}

				const datasources = [];
				for (const table_data of table_data_info) {
					datasources.push(
						<Table
							table_title={`${table_title} (from ${datasource_name}, file: ${table_data.name})`}
							datasource_name={datasource_name}
							table_data_info={table_data}
							column_headers={column_headers}
							row_headers={row_headers}
							type={table_data.type}
						/>
					)
				}

				let display_table;
				if (datasources.length === 0) {
					display_table = <div>Unable to fetch table data for table {table_title}</div>
				} else {
					display_table = datasources
				}

				return([
					<div className="table-container" id={element_id}>
						{display_table}
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element.datasource + "_table_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element.datasource + "_table_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element.datasource + "_table_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
					</div>
				]);
			}
		case 'variable':
			return([]);
		case 'reference':
			{
				return([
					<div className="reference-container">
						{getReference(element, element_id)}
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
					</div>
				]);
			}
		case 'comment':
			return([
				<div className="comment-text" id={element_id}>
					{element.text}
					<div id="action-button-container">
						<div id="action-buttons">
							<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
							<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
							<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
						</div>
					</div>
					<br></br>
				</div>
			]);
		case 'style':
			{
				const style_values = get_style_values(element);

				return([
					<div className="style-container" id={element_id}>
						<p className="style-element-action">Action: {element.option}</p>
						{style_values}
						<div id="action-button-container">
							<div id="action-buttons">
								<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
								<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
								<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
							</div>
						</div>
						<br></br>
					</div>
				]);
			}
		case 'loop':
			{
				const retval = [];
				const id = uuid.v4();
				retval.push(<div>{renderLoop('Loop', element.name, element_id, false, id)}</div>);
				if (element.body instanceof Object) {
					const element_value_body = element.body;
					retval.push(
						<div className='indent-accordian' id={id}>
							<div className="indent">
								{await generator_utils.generateNunjucksValueFromBody(element_value_body, generate_options, default_mapping_function, false)}
							</div>
						</div>
					)
				}

				if (element.else && element.else.body) {
					const id = uuid.v4();
					retval.push(
						<div>
							{renderLoop('Else', 'else', element_id, true, id)}
							<div className='indent-accordian' id={id}>
								<div className='indent'>
									{await generator_utils.generateNunjucksValueFromBody(element.else.body, generate_options, default_mapping_function, false)}
								</div>
							</div>
						</div>
					)
				}

				return(retval);
			}
		case 'citations_list':
			return([
				<div className="citations-container" id={element_id}>
					<p className="citations-title">List of Citations</p>
					<div id="action-button-container">
						<div id="action-buttons">
							<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
							<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
							<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
						</div>
					</div>
				</div>
			]);
		case 'abbreviations_list':
			return([
				<div className="abbreviations-container" id={element_id}>
					<p className="abbreviations-title">List of Abbreviations</p>
					<div id="action-button-container">
						<div id="action-buttons">
							<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
							<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
							<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
						</div>
					</div>
				</div>
			]);
		case 'VALIDATE_BEGIN':
		case 'VALIDATE_END':
			console.error('Found VALIDATE_BEGIN/END elements rendering document -- this indicates that something enabled validation on the client side');
			return([]);
		default:
			throw(new console.error(`Unhandled element type ${element_type} in ${JSON.stringify(element)}`));
	}
}

function get_style_values(element) {
	const element_values = element['value'];
	const values = [];

	if (element.option === 'Style element') {
		values.push(<p>Element type: {element_values['element_type']}</p>);
	}

	if (element.option === 'Style element' || element.option === 'Add style') {
		values.push(
			<div>
				<p>Font color: {element_values['font_color']}</p>
				<p>Font family: {element_values['font_family']}</p>
				<p>Font size: {element_values['font_size']}</p>
				<p>Font style: {element_values['font_style']}</p>
				<p>Font units: {element_values['font_units']}</p>
				<p>Hanging indent: {element_values['hanging_indent']}</p>
				<p>Line height: {element_values['line_height']}</p>
				<p>Spacing bottom: {element_values['padding_bottom']}</p>
				<p>Spacing left: {element_values['padding_left']}</p>
				<p>Spacing right: {element_values['padding_right']}</p>
				<p>Spacing top: {element_values['padding_top']}</p>
			</div>
		);
	}

	/* Add additional styles for table elements */
	if (element.option === 'Style element' && element_values['element_type'] === 'table') {
		values.push(
			<div>
				<p>Cell width: {element_values['cell_width']}</p>
				<p>Cell height: {element_values['cell_height']}</p>
				<p>Header width: {element_values['header_width']}</p>
				<p>Header height: {element_values['header_height']}</p>
				<p>Cell text alignment: {element_values['cell_text_align']}</p>
				<p>Header text alignment: {element_values['header_text_align']}</p>
				<p>Border width: {element_values['border_width']}</p>
				<p>Border color: {element_values['border_color']}</p>
				<p>Border style: {element_values['border_style']}</p>
				<p>Collapse borders: {element_values['border_collapse']}</p>
			</div>
		);
	}

	if (element.option === 'Add section numbering') {
		values.push(<p>Numbering scheme: {element_values['numbering_scheme']}</p>);
	}

	if (element.option === 'Add symbol') {
		values.push(
			<div>
				<p>Glyph: {element_values['glyph']}</p>
				<p>Symbol name: {element_values['symbol_name']}</p>
			</div>
		);
	}

	return(values);
}

async function getTableData(variable_info, generate_options) {
	if (!generate_options.base_details) {
		kaialpha.log.warn('Generating Table Data we found no "generate_options.base_details"');
		return([]);
	}

	const variable_details = {};
	variable_details.data = object_utils.copy_object(variable_info);
	let datasources = [];
	if (variable_details.data.datasource) {
		const datasource_details = data_utils.get_datasource_name(variable_details.data.datasource);
		const datasource = datasource_details[0];
		const key = datasource_details[1];

		let datasource_value = generate_options.base_details.template.variables ? generate_options.base_details.template.variables[datasource] : undefined;
		try {
			datasource_value = await fetch_data(datasource, generate_options);
			const table_values = data_utils.sanitize_datasource_values(datasource_value, key);
			datasources = table_values[1];
			return(datasources);
		} catch (err) {
			return([]);
		}
	}
}

async function set_document_variable(name, value, generate_options) {
	let variables_change;
	if (variable_change !== undefined) {
		variables_change = object_utils.copy_object(variable_change);
	} else {
		variables_change = {};
	}

	variables_change[name] = value;
	variable_change = variables_change;
	generate_options.base_details.document.variables = variables_change;
}


async function fetch_data(variable_info, generate_options) {
	if (!(generate_options.base_details.document.variables[variable_info.datasource])) {
		const data = await document_utils.fetch_document_data_source_value(generate_options.user_id, generate_options.base_details.document.id, 'HEAD', variable_info);
		data['last_updated'] = Date.now();
		set_document_variable(variable_info.datasource, data, generate_options);
		return data;
	} else {
		return generate_options.base_details.document.variables[variable_info.datasource];
	}
}

async function getSection(element, element_id, generateOptions) {
	const returnArray = [];
	returnArray.push(
		<div className="title-component" id={element_id}>
			{element.name}
			<div id="action-button-container">
				<div id="action-buttons">
					<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
					<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
					<div id={element_id + "_comment"} className="action-button toolbar-button document-action-button-3"></div>
				</div>
			</div>
		</div>
	);
	if (element.body) {
		const subElements = await generator_utils.generateNunjucksValueFromBody(element.body, generateOptions, default_mapping_function, false);
		returnArray.push(subElements);
	}
	return(returnArray);
}

async function getTemplateView(element, element_id, generate_options) {

	/* Build a template value */
	let template_retval = [];

	if (generate_options.type === 'document') {
		const document_mapping = generate_options._template_element_id_to_document_id_map[element_id];
		if (!document_mapping || !document_mapping.document_id) {
			throw(new Error(`Unable to render template element ${element_id}, not found in mapping: ${JSON.stringify(generate_options._template_element_id_to_document_id_map)}`));
		}

		/*
		* XXX:TODO: Should we record specific version numbers ?
		*/
		const subdocument_id = document_mapping.document_id;

		const template_element_id = `${element_id}-${subdocument_id}`;
		template_retval.push(<div className="template-view" id={template_element_id}></div>);

		const options = {
			document_id: subdocument_id
		};

		/* If user doesn't have permission to subdocument, don't render it */
		try {
			const subdocument = await generate_options.get_user_document(subdocument_id, 'HEAD');

			/*
			* Get the document's template
			*/
			let subTemplate = null;

			if (mem_cache[subdocument.template.id]) {
				subTemplate = mem_cache[subdocument.template.id]
			} else {
				subTemplate = await generate_options.get_user_template(subdocument.template.id, subdocument.template.version);
				mem_cache[subdocument.template.id] = subTemplate;
			}

			const subdocument_contents = await generator_utils.generateNunjucksValueFromBody(subTemplate.body, options, default_mapping_function, false);

			template_retval = template_retval.concat(subdocument_contents);

		} catch (err) {
			template_retval.push(<p style={{color: "red", fontWeight: "bold"}}>Subdocument has been omitted, error accessing it: {err}</p>);
		}
	} else {
		let subTemplate = null;
		if (mem_cache[element.id]) {
			subTemplate = mem_cache[element.id];
		} else {
			subTemplate = await generate_options.get_user_template(element.id, element.version);
		}

		const template_options = {
			template_id: subTemplate.id
		};

		const subTemplateContents = await generator_utils.generateNunjucksValueFromBody(subTemplate.body, template_options, default_mapping_function, false);

		template_retval = template_retval.concat(subTemplateContents);
	}

	return(template_retval);
}

function getReference(element, element_id) {
	if (!element || !element.value) {
		return([]);
	}

	return([
		<p className="reference-text" id={element_id}>Reference: <u><a className="reference-text" href={element.value.element_id}>{element.name}</a></u></p>
	])
}

function getHeaderAndFooter(options, type) {
	if (options.internal_variables && options.internal_variables[type]) {
		return options.internal_variables[type];
	}
	return '';
}

async function getAllSections(body, count = 0) {
	let returnArray = [];

	/* Recursive-call to all the sub templates and sub documents. */
	for (const element of body) {
		const currentObjId = Object.entries(element)[0][0];
		const currentObj = Object.entries(element)[0][1];

		if (currentObj.type && currentObj.type === 'section') {
			const href = "#" + currentObjId;
			returnArray.push(
				<li className='list-element' key={currentObjId}>
					<a className='list-element' href={href}>{currentObj.name}</a>
				</li>)
			if (currentObj.body && Array.isArray(currentObj.body) && currentObj.body.length > 0) {
				const getSubSections = await getAllSections(currentObj.body, count, 0);
				returnArray.push(getSubSections);
			}
		}

		if (currentObj.type && currentObj.type === "template") {

			let template = null;

			if (mem_cache[currentObj.id]) {
				template = mem_cache[currentObj.id];
			} else {
				template = await kaialpha.lib.template.getTemplateById(currentObj.id, currentObj.version);
				mem_cache[currentObj.id] = template;
			}

			/* Make a recursive call for subtemplate. */
			if (template && template.body && template.body.length > 0) {
				const value = await getAllSections(template.body, count, true);
				if (Array.isArray(value) && value.length > 0) {
					returnArray = returnArray.concat(value);
				}
			}
		}
	}
	return returnArray;
}


/*
*  Render Switch Block
*/

function renderSwitch(expression, value, element_id, indent = false, id) {
	let type;

	const switch_container_class_name = "switch-container " + (indent ? 'indent' : '');
	const action_btn_container_class_name = indent ? 'width-4' : '';
	if (expression === 'Default') {
		type = 'default';
		return [
			<div className={switch_container_class_name}>
				<div className="switch-expression-container">
					<div className="switch-inner-container">
						<div className="switch-image-not-selected-container">
							<div className="switch-image-not-selected"></div>
						</div>
						<div className="switch-expression-detail">
							<div className="switch-name">
								{expression}
							</div>
						</div>
					</div>
					<div id="action-button-container" className={action_btn_container_class_name}>
						<div id="action-buttons">
							<div id={element_id + "-default_history"} className="toolbar-button document-action-button-1"></div>
							<div id={element_id + "-default_review"} className="action-button toolbar-button document-action-button-2"></div>
							<div id={element_id + "-default_comment_" + type} className="action-button toolbar-button document-action-button-3"></div>
							{indent ? <div id={"case_display_" + id} className="action-button toolbar-button document-action-button-5"></div> : ' '}
						</div>
					</div>
				</div>
			</div>
		]
	} else {
		let updated_element_id;
		if (expression === 'Switch') {
			type = 'switch';
			updated_element_id = `${element_id}_${type}`
		} else {
			type = 'case';
			updated_element_id = `${element_id}_${type}_${value}`
		}
		return [
			<div className={switch_container_class_name}>
				<div className="switch-expression-container">
					<div className="switch-inner-container">
						<div className="switch-image-not-selected-container">
							<div className="switch-image-not-selected"></div>
						</div>
						<div className="switch-expression-detail">
							<div className="switch-name">{expression} =
							</div>
							<div className="switch-expression">
								{value}
							</div>
						</div>
					</div>
				</div>
				<div id="action-button-container" className={action_btn_container_class_name}>
					<div id="action-buttons">
						<div id={updated_element_id + "-default_history"} className="toolbar-button document-action-button-1"></div>
						<div id={updated_element_id + "-default_review"} className="action-button toolbar-button document-action-button-2"></div>
						<div id={updated_element_id + "-default_comment_" + type} className="action-button toolbar-button document-action-button-3"></div>
						{indent ? <div id={"case_display_" + id} className="action-button toolbar-button document-action-button-5"></div> : ' '}
					</div>
				</div>
			</div>
		]
	}

}

/*
* Render loop element
*/
function renderLoop(expression, value, element_id, indent = false, id) {
	let type;

	const loop_container_class_name = "switch-container " + (indent ? 'indent' : '');
	const action_btn_container_class_name = indent ? 'width-4' : '';
	if (expression === 'Loop') {
		type = 'loop';
		return [
			<div className={loop_container_class_name}>
				<div className="switch-expression-container">
					<div className="switch-inner-container">
						<div className="switch-image-not-selected-container">
							<div className="switch-image-not-selected"></div>
						</div>
						<div className="switch-expression-detail">
							<div className="switch-name">{expression} =
							</div>
							<div className="switch-expression">
								{value}
							</div>
						</div>
					</div>
					<div id="action-button-container" className={action_btn_container_class_name}>
						<div id="action-buttons">
							<div id={element_id + "_history"} className="toolbar-button document-action-button-1"></div>
							<div id={element_id + "_review"} className="action-button toolbar-button document-action-button-2"></div>
							<div id={element_id + "_comment_" + type} className="action-button toolbar-button document-action-button-3"></div>
							<div id={"case_display_" + id} className="action-button toolbar-button document-action-button-5"></div>
						</div>
					</div>
				</div>
			</div>
		]
	}

	if (expression === 'Else') {
		type = 'else';
		return [
			<div>
				<div className={loop_container_class_name}>
					<div className="switch-expression-container">
						<div className="switch-inner-container">
							<div className = "switch-image-not-selected-container">
								<div className = "switch-image-not-selected"></div>
							</div>
							<div className="switch-expression-detail">
								<div className="switch-name">
									{expression}
								</div>
							</div>
						</div>
					</div>
					<div id="action-button-container" className={indent ? 'width-4' : ''}>
						<div id="action-buttons">
							<div id={element_id + "-else_history"} className="toolbar-button document-action-button-1"></div>
							<div id={element_id + "-else_review"} className="action-button toolbar-button document-action-button-2"></div>
							<div id={element_id + "-else_comment_" + type} className="action-button toolbar-button document-action-button-3"></div>
							{indent ? <div id={"case_display_" + id} className="action-button toolbar-button document-action-button-5"></div> : ' '}
						</div>
					</div>
				</div>
			</div>
		]
	}
}


/*
* Generate a Nunjucks template from a Document
*/
async function _generateNunjucksFromDocumentWithFunction(document = {}, template, generate_function, options = {}) {
	const body = document_utils.body_serialize(null, template);
	if (!options._template_element_id_to_document_id_map) {
		options._template_element_id_to_document_id_map = {};
	}
	if (!options.base_details) {
		options.base_details = {};
	}

	options.base_details = Object.assign({
		document: document,
		template: template
	});

	options.variables_change = {};

	const variables = {
		internal_variables: {
			page_header: '',
			page_footer: ''
		}
	};

	if (options.base_details.template) {
		options.base_details.template.body.forEach(async (element) => {
			if (element[Object.keys(element)[0]].type === 'header') {
				variables.internal_variables.page_header = element[Object.keys(element)[0]].value;
				variables.internal_variables.page_header_id = Object.keys(element)[0];
			} else if (element[Object.keys(element)[0]].type === 'footer') {
				variables.internal_variables.page_footer = element[Object.keys(element)[0]].value;
				variables.internal_variables.page_footer_id = Object.keys(element)[0];
			}
		})
	}

	Object.assign(options, variables);

	options._template_element_id_to_document_id_map = Object.assign(options._template_element_id_to_document_id_map, document.subdocuments);
	return(await generate_function(body, options));
}

async function generateNunjucksFromDocument(document, template, options = {}) {
	variable_change = {};
	return(await _generateNunjucksFromDocumentWithFunction(document, template, generateNunjucksFromBody, options));
}

/*
* Generate a nunjucks file from an array of elements.  This array may come from,
* for example, the Template "body" and the Document "body_extend" elements merged
* together.
*
* An optional mapping function can be supplied to override the default one, which
* generates the Nunjucks output.  This will change the behavior to render something
* other than Nunjucks.  The "BEGIN" and "END" synthetic elements will be requested
* to begin and end the document, respectively.  The mapping function should accept
* 2 parameters: element type name, and an object containing any parameters of that
* element.
*
* It will return the filename for this Nunjucks template
*/

async function generateJSXElements(document, template, variables, env) {
	const body = document_utils.body_serialize(document, template);

	const options = {
		validate_elements: false,
		variables: variables,
		env: env,
		base_details: {
			document: document,
			template: template,
		}
	}

	const output = await generator_utils.generateNunjucksValueFromBody(body, options, default_mapping_function);
	return(output);
}

async function generateNunjucksFromBody(body, options = {}) {
	let output = await generator_utils.generateNunjucksValueFromBody(body, options, async (...args) => {
		const mapping_str = await default_mapping_function(...args);
		if (!mapping_str) {
			return(mapping_str);
		}
		return([ReactDOMServer.renderToString(mapping_str)]);
	}, false);
	output = [].concat.apply([], output);
	const data = variable_change;
	return [output.join('\n') + '\n', data];
}

/*
* Render a Nunjucks document with variable values supplied as parameters.
* This will return the filename of the rendered HTML document
*
* Format of "variables" parameter ?
*/
function generateHTMLString(nunjucks_file, variables, variables_length) {
	try {
		const rendered = kaialpha.lib.nunjucks_utils.renderString(nunjucks_file, variables);
		return(rendered);
	} catch (err) {
		const error_message = err.message.split(':');
		const variable_name = new RegExp('{{(.*?)}}', 'g');
		const variables_data = nunjucks_file.match(variable_name);
		const error = error_message[1];

		let length;
		if (variables_length === undefined) {
			length = variables_data.length;
		} else {
			length = variables_length;
		}

		if (length !== 0) {
			const copy_nunjucks = nunjucks_file.slice();

			if (error) {
				const modified_nunjucks = modifyVariable(copy_nunjucks, error, variables_data, length);
				const file = modified_nunjucks.file;
				const updated_variables_length = modified_nunjucks.variables_length;
				return(generateHTMLString(file, variables, updated_variables_length));
			}
		} else {
			throw(err);
		}
	}
}

function modifyVariable(nunjucks_file, error, variables, variables_length) {
	let file = nunjucks_file;
	const operators = ['|', '|', '/', '//', '%', '*', '**', '==', '===', '!=', '!==', '>', '>=', '<', '<='];

	for (const item of variables) {
		/* XX:TODO: Improve the way we are checking for errors and replacing variables */
		if ((error.includes('cite') && item.includes('cite')) || operators.find(chr => item.includes(chr))) {
			const starting_index = file.indexOf(item);
			const ending_index = starting_index + item.length;
			const extracted_variable = file.substring(starting_index, ending_index);
			const modified_variable = "<span><mark style='background: #FFBDAD; color: #BF2600; padding-left:4px; padding-right:4px; padding-top:2px; padding-bottom:2px; cursor:pointer; border-radius:2px'>";
			const variable = modified_variable.concat('{% raw %}', extracted_variable, '{% endraw %}','</mark></span>');
			file = [file.slice(0,starting_index), variable, file.slice(ending_index)].join('');
			variables_length--;
		}
	}

	return({
		file: file,
		variables_length: variables_length
	});
}

const _to_export = {
	generateNunjucksFromDocument,
	generateNunjucksFromBody,
	generateHTMLString,
	mapping_function: {
		default: default_mapping_function
	},
	generateJSXElements
};
export default _to_export;
