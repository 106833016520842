/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/client/document.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
import user from './user';
import lib_template from './template';
import lib_document_utils from '../lib/utils/document_utils';
import cache_utils from '../lib/utils/cache_utils';
import object_utils from '../lib/utils/object_utils';
import diff_utils from '../lib/utils/diff_utils';
const cached_document_subdocument = {};
const cached_document = {};
const cached_document_short = {};
const cached_document_info = {};

/*
 * Get all templates (or a subset)
 */
export async function getDocuments(filter = undefined) {
	let request = 'document?fields=version,name,permissions,subdocuments';
	if (filter !== undefined) {
		request += `&filter=${filter}`;
	}

	const response = await api.call('GET', request);

	const document_info = response.documents;

	return document_info;
}

export async function deleteDocument(document_id) {
	return(await api.call('DELETE', `document/${document_id}`));
}

export async function delete_document(user_id, document_id) {
	return(await deleteDocument(document_id));
}

async function getDocumentSubdocumentsOrdered(id, version) {
	const subdocument_id_ordering = {};

	const document = await getDocumentById(id, version);

	const subdocument_map = document.subdocuments;

	if (!subdocument_map) {
		return(subdocument_id_ordering);
	}

	let template;

	if (document.template) {
		template = await lib_template.getTemplateById(document.template.id, document.template.version);
	}

	/*
	 * XXX:TODO: This is not needed, it only uses it to pass to
	 * body_templates, which doesn't need it because the information
	 * is already known, inside the Document's subdocument mapping !!!
	 */
	const variables = await lib_document_utils.process_document_variables(undefined, {
		document_id: document.id,
		version_id: document.version,
	}, {});

	const body = lib_document_utils.body_serialize(document, template);
	const body_template_options = {
		get_templates_callback: async function(filter) {
			const templates = await lib_template.get_user_templates(undefined, filter);
			return templates;
		},
		variables: variables
	};
	const body_templates = await lib_document_utils.body_templates(body, body_template_options);

	let index = -1;
	for (const template_item of body_templates) {

		const template_element_id = template_item.id;
		if (subdocument_map[template_element_id]) {
			let subdocument_ids = [];

			if (subdocument_map[template_element_id].document_id) {
				subdocument_ids = subdocument_map[template_element_id].document_id;
			}

			for (const subdocument_id of subdocument_ids) {
				subdocument_id_ordering[subdocument_id] = index++;
			}
		} else {
			kaialpha.log.error(`[DEBUG WARNING] Failed to find template element with ID ${template_element_id} from template ${JSON.stringify(template)} in subdocument map:`, subdocument_map);
		}
	}

	return(subdocument_id_ordering);
}

/*
 * Get all templates within a heirarchy
 */
export async function getDocumentSubdocuments(parent = null, version = undefined) {
	let cache_key = parent;

	if (parent !== null && version) {
		cache_key = [parent, version].join('|');
	}

	/* XXX:TODO: Handle "version" of "date:*" */
	if (!version || version === 'HEAD') {
		kaialpha.log.debug(new Error('Inefficient call to getDocumentSubdocuments, lacks version'));

		cache_key = null;
	}

	const retval = await cache_utils.cache_promise(cached_document_subdocument, cache_key, async function() {
		let filter;
		if (parent === null) {
			filter = '!superdocument.document_id'
		} else {
			filter = `superdocument.document_id=${parent}`
		}

		const subdocuments = await getDocuments(filter);

		if (parent === null || version === undefined) {
			return(subdocuments);
		}

		/*
		 * Sort the results based on the Document order.  To do that, we need
		 * to get the document ID.
		 */
		const sort_info = await getDocumentSubdocumentsOrdered(parent, version);

		const subdocuments_sorted = subdocuments.sort(function(id_a, id_b) {
			const id_a_val = sort_info[id_a.id];
			const id_b_val = sort_info[id_b.id];

			if (id_a_val < id_b_val) {
				return(-1);
			} else if (id_a_val > id_b_val) {
				return(1);
			} else {
				return(0);
			}
		});

		return(subdocuments_sorted);
	}, {
		name: 'cached_document_subdocument'
	});

	return(retval);
}

//Create a document
export async function createDocument(template, document_name) {
	if (document_name === null || document_name === undefined || document_name === '') {
		document_name = "Unnamed Document"
	}

	const payload = {
		name: document_name,
		state: '',
		permissions: {
			owners: [await user.get_user_id()]
		},
		template: {
			id: template['id'],
			version: template['version']
		},
		subdocuments: {},
		variables: {}
	};

	const info = await api.call('POST', 'document', payload);

	return info;
}

//Get document by ID
export async function getDocumentById(id, version = undefined) {
	if (!id) {
		throw(new Error('id must be provided'));
	}
	if (version === 'HEAD') {
		version = undefined;
	}

	if (version) {
		/* XXX:TODO: Handle "version" of "date:*" */
		const cache_key = [id, version].join('|');

		const retval = await cache_utils.cache_promise(cached_document, cache_key, async function() {
			return(await api.call('GET', `document/${id}/versions/${version}`));
		}, {
			name: 'cached_document'
		});

		return(retval);
	} else {
		const retval = await cache_utils.cache_promise(cached_document_short, id, async function() {
			return(await api.call('GET', `document/${id}`));
		}, {
			cache_expires: 5000,
			name: 'cached_document_short'
		});

		return(retval)
	}
}

//Update document
export async function updateDocumentVersion(document_id, document_version, document_name, variables, permissions) {
	if (!document_version) {
		throw(new Error('[DEBUG] updateDocumentVersion now requires document_version'));
	}

	const payload = {
		id: document_id,
		version: document_version,
		change: {
			name: document_name,
			variables,
			permissions
		},
		delete: {
			variables: null
		}
	};

	const retval = await api.call('PATCH', `document/${document_id}`, payload);

	cache_utils.clear(cached_document_short);

	return(retval);
}

export async function apply_diff_user_document(user_id, document_id, version_id_head, summary, diff) {
	if (diff === undefined) {
		diff = {};
	}

	const payload = {
		id: document_id,
		version: version_id_head,
		summary: summary,
		change: diff.change,
		delete: diff.delete
	};

	const retval = await api.call('PATCH', `document/${document_id}`, payload);

	cache_utils.clear(cached_document_short);

	return(retval);
}

export async function updateDocumentComments(document_id, document_version, comments) {
	if (!document_version) {
		throw(new Error('[DEBUG] updateDocumentVersion now requires document_version'));
	}

	const payload = {
		id: document_id,
		version: document_version,
		change: {
			comments
		},
		delete: {
			comments: null
		}
	};

	cache_utils.clear(cached_document_short);

	const retval = await api.call('PATCH', `document/${document_id}`, payload);

	return(retval);
}

export function getDocumentDiff(old_version, new_version) {
	const diff = diff_utils.diff_objects(old_version, new_version, false);

	if (!diff.changed) {
		diff.changed = {};
	}
	if (!diff.added) {
		diff.added = {};
	}
	if (!diff.deleted) {
		diff.deleted = {};
	}

	/*
	 * Delete spurious differences
	 */
	delete diff.changed['version'];
	delete diff.changed['previous_version'];

	return(diff);
}

export async function getVersionsOfTheDocument(document_id) {
	if (!document_id) {
		throw(new Error('[DEBUG] id is mandatory to get versions of a document'));
	}

	const retval = await api.call('GET', `document/${document_id}/versions`);

	return(retval);
}

export async function getDocumentDiffByVersionIds(document_id, old_version, new_version) {
	if (!(old_version || new_version || document_id)) {
		throw(new Error('[DEBUG] documentId, old version id and new version id is mandatory to getting difference'));
	}

	const retval = await api.call('GET', `document/${document_id}/diff/${old_version}/${new_version}`);

	return(retval);
}

export function mergeDocumentObjects(version_1, version_2, version_base) {
	version_1 = object_utils.copy_object(version_1);
	version_2 = object_utils.copy_object(version_2);
	version_base = object_utils.copy_object(version_base);
	['version', 'previous_version', 'child_resources'].forEach(function(element) {
		delete version_1[element];
		delete version_2[element];
		delete version_base[element];
	});

	const merged = diff_utils.merge_objects(version_1, version_2, version_base);

	return(merged);
}

export async function updateDocumentTemplateVersion(document_id, document_version, template_id, options = {}) {
	options = Object.assign({
		recursive: false,
		start_with_top: false
	}, options);

	/*
	 * Ensure that a reasonable configuration has been requested
	 */
	if (options.start_with_top && !options.recursive) {
		throw(new Error(`Invalid combination of recursive+start_with_top (recursive=${options.recrusive}, start_with_top=${options.start_with_top})`));
	}

	/*
	 * If we are asked to start with the "top-level" document, set the current document to that.
	 */
	if (options.start_with_top) {
		options.original_request_document_id = document_id;
		options.start_with_top = false;

		const top_document_info = await lib_document_utils.get_toplevel_document(null, document_id, 'HEAD');
		if (!top_document_info) {
			throw(new Error(`Failed to get top document info for document_id=${document_id}`));
		}

		document_id = top_document_info.id;
		document_version = top_document_info.version;
		template_id = undefined;
	}

	/*
	 * If a template ID was not specified, fetch the document and get the template ID
	 */
	if (template_id === undefined) {
		const top_document = await getDocumentById(document_id, document_version);

		template_id = top_document.template.id;
	}

	/*
	 * Fetch the latest version of the document's template, and put that version as
	 * this document's template version
	 */
	const latest_template = await lib_template.getTemplateById(template_id);
	const latest_version = latest_template.version;

	cache_utils.clear(cached_document_short);

	const new_document_info = await api.call('PATCH', `document/${document_id}`, {
		id: document_id,
		version: 'HEAD',
		change: {
			template: {
				version: latest_version
			}
		}
	});

	/*
	 * Ensure that we return, ultimately, the new information for the
	 * requested document.  If we are asked to be recursive, we only
	 * want to keep the information once we get to the original document
	 * ID, even if we were also asked to start from the top.
	 *
	 * If we weren't asked to be recursive then the current document must
	 * be the document we were asked about.
	 */
	let retval = undefined;
	if (document_id === options.original_request_document_id || options.original_request_document_id === undefined) {
		retval = new_document_info;
	}

	/*
	 * If we are recursing, follow all sub-documents
	 */
	if (options.recursive) {
		const document = await getDocumentById(document_id, document_version);
		const subdocument_map = document.subdocuments;

		const check_retval_promises = [];
		if (subdocument_map instanceof Object) {
			for (const subdocument_element_id in subdocument_map) {
				const subdocument_info = subdocument_map[subdocument_element_id];
				const subdocument_ids = subdocument_info.document_id;

				for (const subdocument_id of subdocument_ids) {
					const check_retval_promise = updateDocumentTemplateVersion(subdocument_id, 'HEAD', undefined, options);
					check_retval_promises.push(check_retval_promise);
				}
			}
		}

		/*
		 * Wait for all subdocument promises to settle, and if any of
		 * them resolve to the new document version for the originally
		 * requested document, pull it out.
		 */
		const check_retvals = await Promise.all(check_retval_promises);
		for (const check_retval of check_retvals) {
			if (check_retval !== undefined) {
				retval = check_retval;
				break;
			}
		}
	}

	return(retval);
}

export async function renderDocumentHTML(id, version) {
	const body = await api.call('GET', `document/${id}/versions/${version}/render/html`);

	return(body);
}

export async function renderDocumentPDF(id, version) {
	const pdf_info = await api.call('GET', `document/${id}/versions/${version}/render/pdf`);
	return(pdf_info);
}

/*
 * Helpers for compatibility
 */
export async function get_user_document(user_id, document_id, document_version) {
	return(await getDocumentById(document_id, document_version));
}

export async function get_user_documents(user_id, options = {}) {
	const query = kaialpha.lib.versions_utils.list_generate_query_from_options(options);
	const request = `document${query}`;

	if (options.auto_paginate === true) {
		/* XXX:TODO */
		throw(new Error('Documents currently do not support pagination'));
	}

	const response = await cache_utils.cache_promise(cached_document_info, request, async function() {
		const retval = await api.call('GET', request);

		delete retval['child_resources'];

		return(retval);
	}, {
		cache_expires: 1000,
		name: 'cached_document_info'
	});

	return(response);
}

export async function get_user_template_from_document(user_id, document) {
	const template_id = document.template.id;
	const template_version = document.template.version;

	const retval = await lib_template.getTemplateById(template_id, template_version);

	return(retval);
}

export async function update_user_document(user_id, document_id, new_document, summary = undefined, cms_options = undefined) {

	const payload = {
		name: new_document.name,
		id: document_id,
		permissions: new_document.permissions,
		state: new_document.state,
		subdocuments: new_document.subdocuments,
		template: new_document.template,
		variables: new_document.variables,
		version: new_document.version
	}

	const retval = await api.call('PUT', `document/${document_id}`, payload);
	return(retval);
}

/*
 * Get S3 bucket pre-signed url
 */
export async function create_presigned_post(type) {
	const info = await api.call('POST', `document/s3_url/${type}`);
	return info;
}
/*
 * Get All Document Buffers
 */
export async function getAllDocumentBuffers() {
	const response = await api.call('GET', 'document/buffer?fields=version,name');

	if (response.documents) {
		return(response.documents);
	}

	return([]);
}
/*
 * Get Single Document Buffer
 */
export async function getDocumentBuffer(id) {
	const retval = await api.call('GET', `document/buffer/${id}`);
	return retval;
}
/*
 * Create Document Buffer
 */
export async function createDocumentBuffer(document, version) {
	const payload = {
		name : document.name,
		state: document.state,
		permissions: document.permissions,
		template: document.template,
		subdocuments: document.subdocuments,
		variables: document.variables,
		id: document.id,
		version: version
	}
	const retval = await api.call('POST', 'document/buffer', payload);
	return retval;
}

export async function updateDocumentBuffer(id, version, document_name, variables, permissions) {
	const payload = {
		id: id,
		version: version,
		change: {
			name: document_name,
			variables,
			permissions
		},
		delete: {
			variables: null
		}
	};

	const retval = await api.call('PATCH', `document/buffer/${id}`, payload);

	return(retval);
}

export async function renderDocumentBufferHTML(id) {
	const body = await api.call('GET', `document/buffer/${id}/render/html`, undefined);

	return(body);
}

export async function renderDocumentBufferPDF(id) {
	const pdf_info = await api.call('GET', `document/buffer/${id}/render/pdf`);
	return(pdf_info);
}

export async function convertUploadedDocToDocument(key){
	let document = null;
	if (key) {
		document = await api.call('POST', `document/generate/data/${key}`);
	}
	return document;
}

export async function get_image_s3_get(image_id) {
	const info = await api.call('GET', `document/variable/${image_id}`);
	return info;
}

export async function get_user_document_canonical_permissions(user_id, document_id) {
	return(await api.call('GET', `document/${document_id}/permissions`));
}

export async function diff_user_document(user_id, document_id, version_old, version_new) {
	return(await getDocumentDiffByVersionIds(document_id, version_old, version_new));
}

const _to_export = {
	getDocuments,
	deleteDocument,
	delete_document,
	getDocumentDiff,
	getDocumentSubdocuments,
	mergeDocumentObjects,
	createDocument,
	getDocumentById,
	updateDocumentVersion,
	apply_diff_user_document,
	diff_user_document,
	updateDocumentComments,
	renderDocumentHTML,
	renderDocumentPDF,
	get_user_document,
	get_user_documents,
	get_user_template_from_document,
	update_user_document,
	create_presigned_post,
	get_image_s3_get,
	convertUploadedDocToDocument,
	getAllDocumentBuffers,
	getDocumentBuffer,
	createDocumentBuffer,
	updateDocumentBuffer,
	renderDocumentBufferHTML,
	renderDocumentBufferPDF,
	getVersionsOfTheDocument,
	getDocumentDiffByVersionIds,
	get_user_document_canonical_permissions
}
export default _to_export;
