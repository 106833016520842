/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/cache_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import object_utils from './object_utils';
const promise_object_id = 'promise_73059e2a-7427-4525-9ade-ec7f4d07ffb8';
const clean_token_object_id = 'clean_b3a7b754-cc4c-44ba-a34b-0784a7acb783'
const _testing = undefined;

const debug_known_caches = [];
const debug_known_caches_names = [];
let debug_printout;

function _debug_cache_index_to_name(cache_index) {
	let debug_cache_object_name = debug_known_caches_names[cache_index];

	/* istanbul ignore if */
	if (debug_cache_object_name === undefined) {
		debug_cache_object_name = `#${cache_index}`;
	}

	return(debug_cache_object_name)
}

if (_testing) {
	_testing._debug_cache_index_to_name = function () {
		const result = _debug_cache_index_to_name('index');

		/* istanbul ignore if */
		if (result !== '#index') {
			throw new Error("Expected #index but found" + result);
		}
		return true;
	}
}

/*
 * Return an object from cache, failing that resolve it asynchronously
 * once and then store and return that value.
 */
async function cache_promise(cache_object, cache_id, promise_lambda, options = {}) {
	/*
	 * In debug mode, periodically print out all known caches
	 */
	/* istanbul ignore if */
	if (kaialpha.debug) {
		if (!debug_known_caches.includes(cache_object)) {
			debug_known_caches.push(cache_object);
			debug_known_caches_names.push(options.name);
		}

		if (!debug_printout) {
			debug_printout = setTimeout(function() {
				debug_printout = undefined;

				kaialpha.log.debug('[cache_utils] Known caches:');
				for (const cache_index in debug_known_caches) {
					const debug_cache_object = debug_known_caches[cache_index];
					const debug_cache_object_name = _debug_cache_index_to_name(cache_index);
					let debug_cache_pending_promises = 0;
					if (debug_cache_object[promise_object_id] instanceof Object) {
						debug_cache_pending_promises = Object.keys(debug_cache_object[promise_object_id]).length;
					}

					kaialpha.log.debug(`[cache_utils]     ${debug_cache_object_name}:`, String(JSON.stringify(debug_cache_object)).length, `bytes, ${debug_cache_pending_promises} pending promises`)
				}
			}, 30000);
		}
	}

	/*
	 * Default options
	 */
	options = Object.assign({
		cache_expires: -1
	}, options);

	/*
	 * If the "cache_id" is null, do not cache, just return the value
	 */
	if (cache_id === null || cache_id === undefined) {
		const retval = await promise_lambda();
		return(retval);
	}

	/*
	 * Clear the cache if it has expired
	 */
	if (options.cache_expires >= 0) {
		const expires = cache_object[clean_token_object_id];
		if (expires !== undefined) {
			const now = Date.now();
			if (now > expires) {
				clear(cache_object);
			}
		}
	}

	/*
	 * If the value is already cached, return a copy
	 */
	if (cache_object[cache_id]) {
		return(object_utils.copy_object(cache_object[cache_id]));
	}

	if (!cache_object[promise_object_id]) {
		cache_object[promise_object_id] = {};
	}

	const promises = cache_object[promise_object_id];

	/*
	 * Check for a cached promise, if one is not found, create
	 * one.
	 */
	if (!promises[cache_id]) {
		promises[cache_id] = promise_lambda();
	}

	/*
	 * Wait for the cached promise to resolve
	 */
	cache_object[cache_id] = await promises[cache_id];
	delete promises[cache_id];

	/*
	 * If we should clean the cache periodically, schedule that now
	 */
	if (options.cache_expires >= 0) {
		/*
		 * Schedule a cleaning past some epoch
		 */
		cache_object[clean_token_object_id] = Date.now() + options.cache_expires;
	}

	/*
	 * Return a copy of the object
	 */
	return(object_utils.copy_object(cache_object[cache_id]));
}

function clear(cache_object) {
	/* istanbul ignore if */
	if (kaialpha.debug) {
		if (debug_known_caches.includes(cache_object)) {
			const cache_index = debug_known_caches.indexOf(cache_object);
			const debug_cache_object_name = _debug_cache_index_to_name(cache_index);
			kaialpha.log.debug(`[cache_utils] Clearing ${debug_cache_object_name}`);
		}
	}

	for (const clean_key in cache_object) {
		delete cache_object[clean_key];
	}
}

if (_testing) {
	_testing.cache_promise_and_clear = async function() {
		const local_cache = {};
		const from_cache_1 = await cache_promise(local_cache, 'test', async function() {
			return(42);
		});

		const from_cache_2 = await cache_promise(local_cache, 'test', async function () {
			/* istanbul ignore next */
			/* As cache id is same for both the promise functions previous function is been used */
			return(43);
		});

		/* istanbul ignore if */
		if (from_cache_1 !== from_cache_2) {
			throw(new Error(`Cached lookup failed !  Value 1 = ${from_cache_1}, Value 2 = ${from_cache_2}`));
		}

		clear(local_cache);

		const from_cache_3 = await cache_promise(local_cache, 'test', async function() {
			return(43);
		});

		/* istanbul ignore if */
		if (from_cache_3 !== 43) {
			throw(new Error(`Cache invalidation lookup failed !`));
		}

		return(true);
	}

	_testing.cache_promise_and_expire = async function() {
		const local_cache = {};
		const from_cache_1 = await cache_promise(local_cache, 'test', async function() {
			return(42);
		}, {
			cache_expires: 1
		});

		await new Promise(function(resolve) {
			setTimeout(function() {
				resolve();
			}, 10);
		});

		const from_cache_2 = await cache_promise(local_cache, 'test', async function() {
			return(43);
		}, {
			cache_expires: 1
		});

		/* istanbul ignore if */
		if (from_cache_1 === from_cache_2) {
			throw(new Error(`Cache expiration lookup failed !  Value 1 = ${from_cache_1}, Value 2 = ${from_cache_2}`));
		}

		return(true);
	};
	_testing.cache_promise_with_cache_id_null = async function () {
		const from_cache_1 = await cache_promise({}, null, async function () {
			return(42);
		});

		/* istanbul ignore if */
		if (from_cache_1 !== 42) {
			throw(new Error(`Cache without cache id failed ! As output expected is 42 but found Value 2 = ${from_cache_1}`));
		}
		return true;
	}
}

const _to_export_auto = {
	cache_promise,
	clear,
	_testing
}
export default _to_export_auto;
