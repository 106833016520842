import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';

import {
	Button,
	Prompt,
	ButtonDialog
} from '../../../lib/ui';
import FoldersTreeView from '../TreeView';
import ACLEditor from '../../ACLEditor';

import folder_lib from '../../../api/folder';
import user_lib from '../../../api/user';
import general_utils from '../../../lib/utils/general_utils';
import object_utils from '../../../lib/utils/object_utils';

const uuid = require('uuid');

class FoldersSelector extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.state = {
			selected_item: {}
		};

		this.mounted = false;
		this.lazy_tree_view_ref = React.createRef();
	}

	componentDidMount() {
		this.mounted = true;
	}

	push_status(type, message, id = undefined) {
		this.props.statusBar.push_status(type, message, id);
	}

	async _make_change_with_status(progress_msg, error_msg, lambda) {
		const activity_id = uuid.v4();

		this.push_status('progress', progress_msg, activity_id);

		let failed = false;
		try {
			await lambda();
		} catch (activity_error) {
			failed = true;
			console.error('Failed to rename:', activity_error);
			this.push_status('error', error_msg, activity_id);
		}

		await this.refresh_treeview_folder();

		if (!failed) {
			this.push_status(undefined, undefined, activity_id)
		}
	}

	async assign_to_folder(item, folder_id) {
		await this._make_change_with_status('Assigning...', `failed to assign ${item.id} to ${folder_id}`, async () => {
			await folder_lib.new_user_folder_child(null, folder_id, item);
			try {
				await general_utils.update_permissions_any_type_add_inherit_parent(null, item.type, item.id, {
					type: 'folder',
					id: folder_id
				});
			} catch (perms_error) {
				await folder_lib.delete_user_folder_child(null, folder_id, item.id);

				throw(perms_error);
			}
		});
	}

	async unassign_from_folder(item, folder_id) {
		await this._make_change_with_status('Unassigning...', `failed to unassign ${item.id} from ${folder_id}`, async () => {
			await folder_lib.delete_user_folder_child(null, folder_id, item.id);

			try {
				await general_utils.update_permissions_any_type_delete_inherit_parent(null, item.type, item.id, {
					type: 'folder',
					id: folder_id
				});
			} catch (delete_error) {
				await folder_lib.new_user_folder_child(null, folder_id, item);

				throw(delete_error);
			}
		});
	}

	async rename_item_any_type(item, new_name) {
		const type = item.type;
		const item_id = item.id;
		const item_version = item.version;

		await this._make_change_with_status('Renaming...', `failed to rename ${type} ${item_id}`, async () => {
			await general_utils.rename_item_any_type(null, type, item_id, item_version, new_name);
		});
	}

	async update_permissions_any_type(item, new_permissions) {
		const type = item.type;
		const item_id = item.id;
		const item_version = item.version;

		await this._make_change_with_status('Changing permissions...', `failed to change permissions on ${type} ${item_id}`, async () => {
			await general_utils.update_permissions_any_type(null, type, item_id, item_version, new_permissions);
		});
	}

	async delete_item_any_type(item) {
		const type = item.type;
		const item_id = item.id;

		await this._make_change_with_status('Deleting...', `failed to delete ${type} ${item_id}`, async () => {
			await general_utils.delete_item_any_type(null, type, item_id);
		});
	}

	async new_folder(parent, name) {
		await this._make_change_with_status('Creating...', `failed to create new folder named ${name} within ${parent}`, async () => {
			const inherit_from = [];
			if (parent !== undefined) {
				inherit_from.push({
					type: 'folder',
					id: parent
				});
			}

			const folder = {
				name: name,
				permissions: {
					owners: [await user_lib.get_user_id()],
					inherit_from
				}
			};

			await folder_lib.new_user_folder(null, parent, folder);
		});
	}

	async refresh_treeview_folder() {
		await this.lazy_tree_view_ref.current.reload();
	}

	render() {
		const tree = <FoldersTreeView ref={this.lazy_tree_view_ref} onClick={(id, type, item) => {
			this.setState({
				selected_item: item
			});
		}}/>;

		/*
		 * Compute which buttons should be accessible based on the type of item selected
		 * Use a lambda here to avoid cluttering the variable namespace, but we don't
		 * want to put this logic too far away, either.
		 */
		const buttons = [];
		(() => {
			let button_list = {};
			const selected_item = this.state.selected_item;
			if (selected_item === undefined) {
				return;
			}

			const id = selected_item.id;
			const type = selected_item.type;
			const parent = selected_item.parent;

			let parent_id, parent_type
			if (parent !== undefined) {
				parent_id = selected_item.parent.id;
				parent_type = selected_item.parent.type;
			}

			const button_order = [
				{label: 'New Sub Folder', id: 'new_child_folder'},
				{label: 'Assign to Folder', id: 'assign'},
				{label: 'Unassign from Folder', id: 'unassign'},
				{label: 'Edit', id: 'edit'},
				{label: 'Rename', id: 'rename'},
				{label: 'Delete', id: 'delete'},
				{label: 'ACL Editor', id: 'acl_editor'},
				{label: 'New Top-level Folder', id: 'new_top_folder'}
			];

			if (type !== undefined) {
				button_list['assign'] = true;
				button_list['unassign'] = true;
				button_list['rename'] = true;
				button_list['delete'] = true;
				button_list['acl_editor'] = true;
			}

			switch (type) {
				case 'folder':
					button_list['new_child_folder'] = true;
					break;
				case 'template':
				case 'document':
					button_list['edit'] = true;
					break;
				case undefined:
					break;
				default:
					throw(new Error(`Unsupported kind of item selected: ${type}`));
			}

			if (parent_type !== 'folder') {
				delete button_list['unassign'];
			}

			if (parent_id !== undefined && parent_id[0] === '@') {
				delete button_list['unassign'];
			}

			if (id !== undefined && id[0] === '@') {
				button_list = {};
			}

			if (type !== undefined) {
				button_list['new_top_folder'] = true;
			}

			let button_index = -1;
			buttons.push(...button_order.map((button_info) => {
				const button_id = button_info.id;
				const label = button_info.label;

				if (button_list[button_id] !== true) {
					return(undefined);
				}

				button_index++;
				let color;
				if (button_index === 0) {
					color = 'primary';
				} else {
					color = 'secondary';
				}

				const button_props = {
					key: `folder_button_${button_id}`
				};

				switch (button_id) {
					case 'new_top_folder':
					case 'new_child_folder':
						{
							let parent_item;
							if (label === 'New Sub Folder') {
								parent_item = selected_item;
							}
							if (parent_item === undefined) {
								parent_item = {};
							}
							return(
								<ButtonDialog {...button_props} button_color={color} title={label} onClose={(save) => {
									const new_folder_name = this._selected_new_folder_name;
									this._selected_new_folder_name = undefined;
									if (!save) {
										return;
									}

									this.new_folder(parent_item.id, new_folder_name);
								}}>
									<Prompt.Group>
										<Prompt.Item title='Name:'><TextField onChange={(event) => {
											this._selected_new_folder_name = event.target.value;
										}}/></Prompt.Item>
									</Prompt.Group>
								</ButtonDialog>
							);
						}
					case 'assign':
						return(
							<ButtonDialog {...button_props} button_color={color} title={label} onClose={(save) => {
								const selected_target_folder = this._selected_target_folder;
								this._selected_target_folder = undefined;
								if (!save) {
									return;
								}

								this.assign_to_folder(selected_item, selected_target_folder);
							}}>
								<Prompt.Group>
									<Prompt.Item title='Folder:'><FoldersTreeView types={['folder']} onClick={(id, type) => {
										this._selected_target_folder = id;
									}}/></Prompt.Item>
								</Prompt.Group>
							</ButtonDialog>
						);
					case 'unassign':
						button_props.disabled = false;
						button_props.onClick = async (event) => {
							if (parent_type !== 'folder') {
								throw(new Error(`Tried to unassign from something other than folder: ${parent_type}`));
							}
							await this.unassign_from_folder(selected_item, parent_id);
						}
						break;
					case 'edit':
						{
							let pathname;

							/* XXX:TODO: Move this to a central library */
							switch (type) {
								case 'template':
									pathname = `/activity/templateeditor/${id}`;
									break;
								case 'document':
									pathname = `/activity/doceditor/${id}`;
									break;
								default:
									/* We do nothing in the default case */
									break;
							}

							button_props.disabled = false;
							button_props.component = Link;
							button_props.to = {
								pathname
							};
						}
						break;
					case 'rename':
						return(
							<ButtonDialog {...button_props} button_color={color} title={label} onClose={(save) => {
								const new_name = this._selected_folder_new_name;
								this._selected_folder_new_name = undefined;
								if (!save) {
									return;
								}

								this.rename_item_any_type(selected_item, new_name);
							}}>
								<Prompt.Group>
									<Prompt.Item title='Existing Name:'>{selected_item.name}</Prompt.Item>
									<Prompt.Item title='New Name:'><TextField defaultValue={selected_item.name} onChange={(event) => {
										this._selected_folder_new_name = event.target.value;
									}}/></Prompt.Item>
								</Prompt.Group>
							</ButtonDialog>
						);
					case 'acl_editor':
						{
							let acl_editor_open = false;
							if (this.state.acl_editor && this.state.acl_editor[id] === true) {
								acl_editor_open = true;
							}

							button_props.onClick = () => {
								this.setState({
									acl_editor: {
										[id]: true
									}
								});
							}

							const save_lambda = (new_permissions) => {
								this.setState({
									acl_editor: {
										[id]: false
									}
								});

								if (new_permissions === undefined) {
									return;
								}

								this.update_permissions_any_type(selected_item, new_permissions);
							}

							return(
								<React.Fragment key={`acl_editor`}>
									<ACLEditor
										id={id}
										type={type}
										visible={acl_editor_open}
										permissions={object_utils.copy_object(selected_item.permissions)}
										onSave={save_lambda}
										onCancel={save_lambda}
									/>
									<Button
										color={color}
										{...button_props}
									>{label}</Button>
								</React.Fragment>
							);
						}
					case 'delete':
						button_props.onClick = async () => {
							await this.delete_item_any_type(selected_item);
						}
						break;
					default:
						/* Disable any buttons that are not currently implemented */
						button_props.disabled = true;
						break;
				}
				return(
					<Button
						color={color}
						{...button_props}
					>{label}</Button>
				);
			}));
		})();

		return(
			<Grid container>
				<Grid item xs={1}/>
				<Grid item xs={10}>
					<Grid container>
						<Grid item xs={12} align="center"><h1>Select Folder</h1></Grid>
						<Grid item xs={12}>{tree}</Grid>
						<Grid item xs={12}>{buttons}</Grid>
					</Grid>
				</Grid>
				<Grid item xs={1}/>
			</Grid>
		);
	}
}

export default FoldersSelector;
