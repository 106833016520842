import { color_map, measurement_map } from '../../assets/app_assets.js';
export const styles = theme => {
	const color = color_map.navigationSideBarBackgroundColor;

	const drawerPaperCommon = {
		backgroundColor: color,
		overflowY: 'unset',
		transition: 'width 0.2s'
	}

	return({
		drawerPaperOpen: {
			width: measurement_map.sideBarOpenedWidth,
			...drawerPaperCommon
		},

		drawerPaperClose: {
			width: measurement_map.sideBardClosedWidth,
			alignItems: 'center',
			...drawerPaperCommon
		},

		drawerContainer: {
			height: '32px',
			width: '32px',
			backgroundColor: 'white',
			color: 'black',
			borderRadius: '50%',
			zIndex: '12001',
			position: 'absolute',
			right: -15,
			top: 80,
			boxShadow: '0 0 1px 0.1px #969696'
		},

		logo: {
			height: '40px'
		},

		logoContainer: {
			height: '10%',
			margin: '0px auto',
			marginTop: '80px'
		},

		routeContainer: {
			marginTop: '40px',
			width: '100%'
		},


		avatarContainer: {
			zIndex: 1200,
			marginTop: '45px',
			height: '8rem'
		},

		toggleIcon: {
			color: color_map.navigationSideBarToggleIconColor,
			padding: '0.4rem',
			fontSize: '1.2rem'
		},

		customLink: {
			display: 'flex',
			alignItems: 'center',
			margin: '8px 0px',
			justifyContent: 'flex-start',
			textDecoration: 'none',
			color: 'white',
			width: '100%',
			fontWeight: 400
		},

		avatarLarge: {
			margin: '0px auto',
			height: '80px',
			width: '80px'
		},

		avatarSmall: {
			margin: '0px auto',
			height: '40px',
			width: '40px'
		},

		avatarText: {
			textAlign: 'center',
			fontSize: '1rem',
			fontWeight: 'bold',
			color: 'white',
			transition: 'width 0.2s'
		},

		linkText: {
			width: '1rem',
			height: '1rem',
			display: 'inline-block',
			fontSize: '1rem'
		},

		listItem: {
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: color_map.navigationSideBarHoverColor
			}
		}
	})
};
