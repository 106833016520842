/*
 * KaiAlpha object, similar to the server-side, so code can be used in
 * both environments.
 *
 * Use getters for this to lazily evaluate to avoid problems with
 * circular dependencies
 */
const kaialpha = {
	lib: {
		get document() { return(require('../api/document').default); },
		get template() { return(require('../api/template').default); },
		get data() { return(require('../api/data').default); },
		get list() { return(require('../api/list').default); },
		get folder() { return(require('../api/folder').default); },
		get document_utils() { return(require('./utils/document_utils').default); },
		get template_utils() { return(require('./utils/template_utils').default); },
		get nunjucks_utils() { return(require('./utils/nunjucks_utils').default); },
		get object_utils() { return(require('./utils/object_utils').default); },
		get versions_utils() { return(require('./utils/versions_utils').default); },
		get list_utils() { return(require('./utils/list_utils').default); },
		get user() { return(require('../api/user').default); },
		get promise() { return(require('../api/promise').default); },
		get folder_utils() { return(require('./utils/folder_utils').default); },
		get general_utils() { return(require('./utils/general_utils').default); },
		get error_utils() { return(require('./utils/error_utils').default); },
		get date_utils() { return(require('./utils/date_utils').default); },
		get diff_utils() { return(require('./utils/diff_utils').default); },
		get recursive_object_utils() { return(require('./utils/recursive_object_utils').default); },
		debug: {
			set_components: function() {},
			includes_component: function() { return(false); },
			log: function(component, ...args) { return(console.debug(...args)); }
		}
	},
	version: require('./version.js'),
	log: console,
	debug: false
};

kaialpha.UserError = kaialpha.lib.error_utils.UserError;
kaialpha.ResourceNotFoundError = kaialpha.lib.error_utils.ResourceNotFoundError;
kaialpha.NunjucksError = kaialpha.lib.error_utils.NunjucksError;

/*
 * XXX:TODO: Is there a better way to detect that we are in debug mode ?
 */
if (window.__REACT_DEVTOOLS_APPEND_COMPONENT_STACK__ !== undefined) {
	kaialpha.debug = true;
}

export default kaialpha;
