import React from 'react';
import './ElementsDialogBox.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '../../lib/ui';
import Grid from '@material-ui/core/Grid';
import validation_utils from '../../lib/utils/validation_utils';

const images = require.context('../../images', true);

class ElementsDialogBox extends React.Component {
	elements = validation_utils.structure.element_info;

	constructor(props) {
		super(props);
		this.state = {
			dialog_open: true,
		}
	}

	handle_close = () => {
		this.props.close_dialog();
		this.setState({ dialog_open: false })
	};

	componentDidMount() {
		this.setState({ dialog_open: true });
	}

	componentWillReceiveProps() {
		this.setState({ dialog_open: true });
	}

	add_component() {
		let element_name = this.state.current_element;
		if (element_name === 'instructional text') {
			element_name = 'comment';
		}

		if (element_name === 'table of contents' || element_name === 'citations list' || element_name === 'abbreviations list') {
			element_name = element_name.replace(/ /g, '_');
		}

		this.props.add_element(element_name, null);
		this.setState({ dialog_open: false });
		this.props.close_dialog();
	}

	render() {
		return(
			<div>
				<Dialog
					open={this.state.dialog_open}
					onClose={this.handle_close}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle className='component-library-hdr'>Component Library</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							{this.elements.map((element) =>
								<Grid item xs={3}>
									<div id={element.name} className={'dialog-element-container' + (this.state.current_element === element.name.toLowerCase() ? ' highlight' : '')} onClick={() => this.setState({ current_element: element.name.toLowerCase() })}>
										<div className='dialog-element-image-container'>
											<div className='dialog-element-image'>
												{element.icon !== undefined && <img alt="dialog-icon" src={images(`./${element.icon}`).default}/>}
											</div>
										</div>
										<div className='dialog-element-detail-container'>
											<div className='dialog-element-title'>{element.name}</div>
											<div className='dialog-element-sub-title'>{element.description}</div>
										</div>
									</div>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => this.handle_close()}>
							Close
						</Button>
						<Button
							color='primary'
							disabled={this.state.current_element === undefined}
							onClick={() => this.add_component()}>
							Add
						</Button>
					</DialogActions>
				</Dialog>

			</div>
		)
	}
}

export default ElementsDialogBox;
