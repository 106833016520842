import React from 'react';
import './PreviewDataSource.css';
import Table from '../Table';
import data_utils from '../../lib/utils/data_utils';
import { Button } from '../../lib/ui';
import data_lib from '../../api/data';
import document_utils from '../../lib/utils/document_utils';

/* Used for Debug Console */
import Grid from '@material-ui/core/Grid';
import nunjucks_utils from '../../lib/utils/nunjucks_utils';

class PreviewDataSource extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			datasource_info: '',
			table_data_info: [],
			error: '',
			variables: [],
			href: [],
			show_preview: false,
			data: []
		}
		this.replace_variable = this.replace_variable.bind(this);
	}

	source = '';

	async populate_datasource_items() {
		const result = await data_lib.list_data();
		return result;
	}

	componentDidMount() {
		this.componentDidUpdate({});
	}

	componentDidUpdate(prevProps) {
		let new_value;
		let old_value;

		if (this.props && this.props.datasource_info) {
			new_value = this.props.datasource_info.data;
		}

		if (prevProps && prevProps.datasource_info) {
			old_value = prevProps.datasource_info.data;
		}

		if (new_value === undefined || new_value === null) {
			new_value = '';
		}

		if (new_value !== old_value) {
			this.setState({ datasource_info: new_value });
		}
	}

	update_preview() {
		this.setState({ show_preview: true });
		const datasource_info = this.state.datasource_info;

		if (datasource_info.options === undefined || datasource_info.options.source === '' || datasource_info.options.source === undefined) {
			this.setState({ error: 'Please select a source'});
			return;
		}

		this.source = datasource_info.options.source;

		if (typeof this.source === 'object') {
			this.source = this.source.url;
		}

		/* Check if source is dependent on variables */
		this.check_for_variables(datasource_info);

		/* Fetch data */
		this.fetch_data();
	}

	replace_variable(event) {
		const variable_name = event.target.name;
		const variable_value = event.target.value;
		sessionStorage.setItem(variable_name, variable_value);
	}

	async check_for_variables(datasource_info) {
		/*
		* If source is dependent on variables, replace variable names
		* with values provided by user
		*/
		if (this.source === undefined || this.source === '') {
			this.setState({ error: 'Please select a source'});
			return;
		}

		if ((this.source.includes('{{'))) {
			const variable_name = new RegExp('{{(.*?)}}', 'g');
			const variables = this.source.match(variable_name);
			const variable_values = [];
			this.setState({ variables: variables });

			for (const variable of variables) {
				const variable_value = sessionStorage.getItem(variable);
				this.source = this.source.replace(variable, variable_value);

				if (variable_value !== null) {
					variable_values.push(variable_value);
				}
			}
		}
	}

	async fetch_data() {
		/* Clear error message */
		this.setState({ error: '' });
		const datasource_info = this.state.datasource_info;

		try {
			/* If source has a wildcard, get matching data sources */
			const new_table_data_info = [];
			if (this.source.includes('*')) {
				const matches = await document_utils.get_data_source_matches(null, this.source);

				for (const match of matches) {
					/* Fetch data for all matching data sources*/
					datasource_info.options['short_name'] = match.short_name;
					const data = await data_utils.fetch_data(match.url, datasource_info.options);

					/*
					* Data that is not of type text/csv is returned as a Blob that
					* can be converted to a URL so the raw file can be downloaded.
					*/
					if (data && data.type === 'image') {
						const image_data = data;
						image_data.variable_name = this.state.datasource_info.name;
						new_table_data_info.push(image_data);
					} else if (data instanceof Blob) {
						const url = window.URL.createObjectURL(data);
						if (this.state.href.length < matches.length) {
							this.setState({ href: this.state.href.concat({name: match.short_name, data: url})});
						}
					} else {
						new_table_data_info.push({
							variable_name: this.state.datasource_info.name,
							name: match.short_name,
							data: data
						});
					}
				}
			} else {
				datasource_info.options['short_name'] = this.state.datasource_info.name;
				const data = await data_utils.fetch_data(this.source, datasource_info.options);

				if (data && data.type === 'image') {
					new_table_data_info.push(data);
				} else {
					new_table_data_info.push({
						name: this.state.datasource_info.name,
						data: data
					});
				}
			}
			this.setState({ table_data_info: new_table_data_info });
		} catch (err) {
			let err_msg = err.message;

			/* Specify error message if datasource contains no data */
			if (err.message === 'Cannot convert undefined or null to object') {
				err_msg = 'Datasource contains no data';
			}

			this.setState({ table_data_info: [] });
			this.setState({ error: err_msg});
		}
	}

	compute_debug_console_output() {
		const input = this.state.debug_console_input;
		const table_data_info = this.state.table_data_info;
		if (this.state.debug_console_input === undefined) {
			return('');
		}
		/*
		 * This must match the logic in the part of document that sets the
		 * variable value
		 */
		const variable_value = {};
		if (!this.source.includes('*')) {
			if (table_data_info[0].type === 'image') {
				Object.assign(variable_value, table_data_info[0]);
			} else {
				Object.assign(variable_value, table_data_info[0].data);
			}
		} else {
			for (const subset of table_data_info) {
				if (subset.type === 'image') {
					variable_value[subset.name] = { metadata: subset.metadata };
				} else {
					variable_value[subset.name] = subset.data;
				}
			}
		}

		/*
		 * We only set one variable, the datasource variable name as we are
		 * identified by our calling component
		 */
		const variables = {
			[this.props.own_variable_name.toLowerCase()]: variable_value
		}

		let output;
		let error = false;
		try {
			output = nunjucks_utils.renderString(input, variables);
		} catch (render_error) {
			output = <pre>ERROR: {String(render_error)}</pre>;
			error = true;
		}

		return({
			output,
			error
		});
	}

	render_debug_console() {
		const debug_console_output_info = this.compute_debug_console_output();
		const debug_console_output = debug_console_output_info.output;

		let debug_border_color = 'rgba(0, 0, 0, 0)';
		if (debug_console_output_info.error) {
			debug_border_color = 'red';
		}

		return(<Grid container spacing={3}>
			<Grid item xs={3}>DEBUG OUTPUT:</Grid><Grid item xs={9} style={{border: `1px solid ${debug_border_color}`}}>{debug_console_output}</Grid>
			<Grid item xs={3}>DEBUG INPUT:</Grid><Grid item xs={9}><textarea cols={60} rows={10} name='debug_code' onChange={(event) => {
				this.debug_console_input = event.target.value;
				if (this.debug_console_input_update !== undefined) {
					return;
				}

				this.debug_console_input_update = setTimeout(() => {
					this.debug_console_input_update = undefined;
					this.setState({
						debug_console_input: this.debug_console_input
					});
				}, 10);
			}}/></Grid>
		</Grid>);
	}

	render() {
		const datasource_info = this.state.datasource_info;
		const table_data_info = this.state.table_data_info;
		const variables = this.state.variables;
		const href = this.state.href;

		let datasource_table;
		let column_headers;
		let row_headers;

		if (datasource_info !== '' && table_data_info.length !== 0) {
			column_headers = datasource_info.options.column_headers;
			row_headers = datasource_info.options.row_headers;

			datasource_table =
			<div>
				{table_data_info.map((table_data) => {

					let image = undefined;
					if (table_data.image) {
						image = (<div style={{ padding: '10px' }}>
							<p>{table_data.name}</p>
							<img src={table_data.image} alt="Unnamed Source" />
						</div>);
					}

					let table = undefined;
					if (table_data.data && Object.keys(table_data.data).length > 0) {
						let table_data_info = table_data.data;
						let table_title = table_data.name;

						if (table_data.variable_name) {
							table_title = `${table_data.variable_name}.${table_title}`
						}

						if (table_data.metadata) {
							table_data_info = {};
							table_data_info = table_data.metadata;
							table_title = `${table_title}.metadata`;
						}

						table = <Table
							table_title={table_title}
							datasource_name={table_title}
							table_data_info={table_data_info}
							column_headers={column_headers}
							row_headers={row_headers}
						/>
					}

					return(
						<div>
							{image}
							{table}
						</div>
					);
				})}
			</div>
		}

		let get_variable_values;
		if (variables.length !== 0) {
			get_variable_values =
			<div>
				<p>Enter values for the following variables: </p>
				{variables.map(variable =>
					<div>
						{variable}:
						<input name={variable} defaultValue={sessionStorage.getItem(variable)} onBlur={this.replace_variable}></input>
					</div>)}
				<Button className='submit-variables-btn' onClick={() => this.update_preview()}>Submit Values</Button>
			</div>
		}

		let download_data;
		if (href.length !== 0 && table_data_info.length === 0) {
			download_data =
			<div>
				<p>Datasource cannot be previewed.</p>
				{href.map((href_data) =>
					<a className='download-data' href={href_data.data} download>Download Data</a>
				)}
			</div>
		}

		let preview;
		let preview_button;
		let update_preview_button;
		if (this.state.show_preview === true) {
			preview =
				<div>
					<div className='preview-variables-prompt'>
						{get_variable_values}
					</div>
					<div className='datasource-table-preview'>
						{datasource_table}
					</div>
					<div className='download-data-container'>
						{download_data}
					</div>
					<p className='fetch-datsource-preview-err'>{this.state.error}</p>
				</div>
			preview_button = <Button className='preview-data-btn' onClick={() => this.setState({show_preview: false})}>Hide Preview</Button>;
			update_preview_button = <Button className='update-preview-btn' onClick={() => this.update_preview()}>Update Preview</Button>
		} else {
			preview_button = <Button className='preview-data-btn' onClick={() => this.update_preview()}>Preview Data</Button>;
		}

		let debug_console;
		if (preview) {
			debug_console = this.render_debug_console();
		}

		return(
			<div className='preview-datasource-container'>
				{preview_button}
				{preview}
				{update_preview_button}
				{debug_console}
			</div>
		)
	}
}

export default PreviewDataSource;
