/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/recursive_object_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;

/*
 * Deeply set/get a key within an object
 */
function recursive_object_set(object, path, key, value) {
	const return_object = object;

	path.forEach(function(element) {
		if (!(element in object)) {
			object[element] = {}
		}
		object = object[element]
	});

	if (value === undefined) {
		delete object[key];
	} else {
		object[key] = value;
	}

	return(return_object);
}

function recursive_object_get(object, path, key) {

	path.forEach(function(element) {
		if (object === undefined) {
			return;
		}

		if (!(element in object)) {
			object = undefined;
			return;
		}
		object = object[element]
	});

	if (object === undefined) {
		return(undefined);
	}

	const return_value = object[key];

	return(return_value);
}

/*
 * Run code every leaf of an object
 */
function recursive_object_exec(object, lambda, path = []) {
	let return_promise = false;
	if (lambda.constructor.name === 'AsyncFunction') {
		return_promise = true;
	}

	if (object === undefined || object === null || typeof(object) !== 'object') {
		if (return_promise) {
			return(Promise.all([]));
		} else {
			return;
		}
	}

	const promises = [];
	for (const key in object) {
		if (typeof(object[key]) === 'object' && !(object[key] instanceof Array) && object[key] !== null) {
			const sub_key_path = [...path];
			sub_key_path.push(key);

			if (lambda.constructor.name === 'AsyncFunction') {
				const promise = recursive_object_exec(object[key], lambda, sub_key_path)
				promises.push(promise);
			} else {
				recursive_object_exec(object[key], lambda, sub_key_path)
			}
			continue;
		}

		if (lambda.constructor.name === 'AsyncFunction') {
			const promise = lambda(object, path, key);
			promises.push(promise);
		} else {
			lambda(object, path, key);
		}
	}

	if (return_promise) {
		return(Promise.all(promises));
	}
}

if (_testing) {

	const asynchronousFunction = async () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({ 'response': 'done'});
			}, 100);
		})
	}

	_testing.recursive_object_set = function () {
		let result = recursive_object_set({}, ['one'], 'key', 'value');

		/* istanbul ignore if */
		if (result.one['key'] !== 'value') {
			throw new Error("Recursive object set differes from actual result");
		}

		result = recursive_object_set(result, ["one"], 'key');

		/* istanbul ignore if */
		if (result.one['key']) {
			throw new Error("Recursive object set with undefined should be deleted from main object");
		}

		return true;
	};

	_testing.recursive_object_get = function () {
		const result = recursive_object_get(
			{
				one: { 'key': 'value' }
			}, ['one'], 'key');

		/* istanbul ignore if */
		if (result !== 'value') {
			throw new Error("Recursive object get should return a value");
		}

		return true;
	}

	_testing.recursive_object_get_test2 = function () {
		const result = recursive_object_get(
			{
				one: { 'key': 'value' }
			}, [undefined], 'key');

		/* istanbul ignore if */
		if (result) {
			throw new Error("Recursive object get should return a value");
		}

		return true;
	}

	_testing.recursive_object_get_test3 = function () {
		const result = recursive_object_get(undefined, ['something']);

		/* istanbul ignore if */
		if (result) {
			throw new Error("Recursive object get should return a value");
		}

		return true;
	}

	_testing.recursive_object_exec = async function () {
		const result = await recursive_object_exec(
			{
				'function' : asynchronousFunction
			}, asynchronousFunction, ['function']);

		const response = result[0];

		/* istanbul ignore if */
		if (response.response !== 'done') {
			throw new Error("Recursive exec should return a value");
		}

		return true;
	}

	_testing.recursive_object_exec_test2 = async function () {
		const result = await recursive_object_exec(undefined, asynchronousFunction, ['function']);

		const response = result[0];

		/* istanbul ignore if */
		if (response) {
			throw new Error("Recursive exec should return a value");
		}

		return true;
	}
}

const _to_export_auto = {
	set: recursive_object_set,
	get: recursive_object_get,
	exec: recursive_object_exec,
	_testing
}
export default _to_export_auto;
