/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/general_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
async function _apply_diff_any_type(user_id, type, item_id, item_version, summary, diff) {
	let retval;

	switch (type) {
		case 'template':
			retval = await kaialpha.lib.template.apply_diff_user_template(user_id, item_id, item_version, summary, diff);
			break;
		case 'document':
			retval = await kaialpha.lib.document.apply_diff_user_document(user_id, item_id, item_version, summary, diff);
			break;
		case 'folder':
			retval = await kaialpha.lib.folder.apply_diff_user_folder(user_id, item_id, item_version, summary, diff);
			break;
		default:
			throw(new Error(`Unable to apply diff on item with type ${type}, unsupported type`));
	}

	return(retval);
}

async function rename_item_any_type(user_id, type, item_id, item_version, new_name, options = {}) {
	options = {
		summary: 'Renaming',
		...options
	};

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			name: new_name
		}
	}));
}

async function update_permissions_any_type(user_id, type, item_id, item_version, new_permissions, options = {}) {
	options = {
		summary: 'Changing permissions',
		...options
	};

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			permissions: new_permissions
		},
		delete: {
			permissions: null
		}
	}));
}

async function update_permissions_any_type_add_inherit_parent(user_id, type, item_id, new_parent, options = {}) {
	options = {
		summary: 'Changing permissions (adding parent)',
		...options
	};

	const item = await get_item_any_type(user_id, type, item_id, 'HEAD', {
		fields: ['permissions']
	});

	const item_version = item.version;

	let new_permissions = item.permissions;
	if (new_permissions === null || new_permissions === undefined) {
		new_permissions = {};
	}

	/*
	 * If the item is explicitly not inheriting permissions,
	 * do not add any
	 */
	let inherit_from = new_permissions.inherit_from;
	if (inherit_from === null) {
		return({
			id: item_id,
			version: item_version
		});
	}

	if (inherit_from === undefined) {
		inherit_from = [];
	}

	inherit_from.push({
		type: new_parent.type,
		id: new_parent.id
	});

	new_permissions['inherit_from'] = inherit_from;

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			permissions: new_permissions
		},
		delete: {
			permissions: null
		}
	}));
}

async function update_permissions_any_type_delete_inherit_parent(user_id, type, item_id, old_parent, options = {}) {
	options = {
		summary: 'Changing permissions (deleting parent)',
		...options
	};

	const item = await get_item_any_type(user_id, type, item_id, 'HEAD', {
		fields: ['permissions']
	});

	const item_version = item.version;

	const new_permissions = item.permissions;
	if (new_permissions === null || new_permissions === undefined) {
		return({
			id: item_id,
			version: item_version
		});
	}

	let inherit_from = new_permissions.inherit_from;
	if (inherit_from === null || inherit_from === undefined) {
		return({
			id: item.id,
			version: item.version
		});
	}

	inherit_from = inherit_from.filter(function(item) {
		if (item.type !== old_parent.type) {
			return(true);
		}

		if (item.id !== old_parent.id) {
			return(true);
		}

		return(false);
	});

	new_permissions['inherit_from'] = inherit_from;

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			permissions: new_permissions
		},
		delete: {
			permissions: null
		}
	}));
}

async function delete_item_any_type(user_id, type, item_id) {
	let retval;

	switch (type) {
		case 'template':
			retval = await kaialpha.lib.template.delete_template(user_id, item_id);
			break;
		case 'document':
			retval = await kaialpha.lib.document.delete_document(user_id, item_id);
			break;
		case 'folder':
			/* XXX:TODO: rename delete_user_folder -> delete_folder */
			retval = await kaialpha.lib.folder.delete_user_folder(user_id, item_id);
			break;
		default:
			throw(new Error(`Unable to delete item with type ${type}, unsupported type`));
	}

	return(retval);
}

async function get_item_any_type(user_id, type, id, version, options = {}) {
	options = {
		fields: undefined,
		...options
	};

	let item;
	switch (type) {
		case 'template':
			item = await kaialpha.lib.template.get_user_template(user_id, id, version);
			break;
		case 'document':
			item = await kaialpha.lib.document.get_user_document(user_id, id, version);
			break;
		case 'folder':
			if (version !== undefined && version !== 'HEAD') {
				throw(new Error(`May only get the latest version of folders, requested: ${version}`));
			}

			item = await kaialpha.lib.folder.get_user_folder(user_id, id);
			break;
		default:
			throw(new Error(`Unable to get item with type ${type}, unsupported type`));
	}

	let retval = item;

	/* XXX:TODO: It would be better if we could just request certain fields */
	if (options.fields !== undefined) {
		retval = {
			id: item.id,
			version: item.version,
		}

		for (const field of options.fields) {
			retval[field] = item[field];
		}
	}

	return(retval);
}

async function get_canonical_permissions_any_type(user_id, type, id, options = {}) {
	options = {
		list_type: undefined,
		...options
	};

	switch (type) {
		case 'template':
			return(await kaialpha.lib.template.get_user_template_canonical_permissions(user_id, id));
		case 'document':
			return(await kaialpha.lib.document.get_user_document_canonical_permissions(user_id, id));
		case 'folder':
			return(await kaialpha.lib.folder.get_user_folder_canonical_permissions(user_id, id));
		case 'list':
			/* XXX:TODO */
			throw(new Error(`Unable to get item with type ${type}, unsupported type (options = ${JSON.stringify(options)}`));
		default:
			throw(new Error(`Unable to get item with type ${type}, unsupported type`));
	}
}

/**
 * Sleep Function
 * @param {integer} time_ms - How much time to sleep
 */
async function async_sleep(time_ms) {
	const resolve_promise = new Promise(function(resolve) {
		setTimeout(() => {
			resolve();
		}, time_ms);
	});
	await resolve_promise;
}

const _to_export_auto = {
	rename_item_any_type,
	update_permissions_any_type,
	update_permissions_any_type_add_inherit_parent,
	update_permissions_any_type_delete_inherit_parent,
	delete_item_any_type,
	get_item_any_type,
	get_canonical_permissions_any_type,
	async_sleep
};

export default _to_export_auto;
