/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/generator_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const path = require('path');

/*
 * XXX:TODO
 *
 * "mapping_callback" and "toAddPath" should be options, not positional parameters
 * This was a poor design, but fixing it will require work
 *
 * XXX:TODO
 */
async function generateNunjucksValueFromBody(body, options, mapping_callback, toAddPath = true) {
	if (options === undefined) {
		options = {}
	}

	options = {
		validate_elements: true,
		mapping_function: mapping_callback,
		element_callback: undefined,
		element_blacklist: [],
		generator_cache_function: async function(ignored_key, lambda) {
			/*
			 * The default caching function doesn't cache
			 */
			return(await lambda());
		},
		get_user_document: async function(document_id, document_version) {
			const key = ['document', this.user_id, document_id, document_version];
			return(await this.generator_cache_function(key, async () => {
				return(await kaialpha.lib.document.get_user_document(this.user_id, document_id, document_version));
			}));
		},
		get_user_template: async function(template_id, template_version) {
			const key = ['template', this.user_id, template_id, template_version];
			return(await this.generator_cache_function(key, async () => {
				return(await kaialpha.lib.template.get_user_template(this.user_id, template_id, template_version));
			}));
		},
		get_user_templates: async function(filter) {
			return(kaialpha.lib.template.get_user_templates(this.user_id, filter));
		},
		process_document_variables: async function(document_id) {
			return(kaialpha.lib.document_utils.process_document_variables(this.user_id, {
				document_id: document_id,
				version_id: 'HEAD'
			}));
		},
		get_user_list_entries: async function(list_type, list_id, list_version) {
			const key = ['list', this.user_id, list_type, list_id, list_version];
			return(await this.generator_cache_function(key, async () => {
				return(await kaialpha.lib.list_utils.get_user_list_entries(this.user_id, list_type, list_id, list_version));
			}));
		},
		recurse_into_templates: true,
		include_document_begin_end: true,
		templatedir: kaialpha.workdir + '/njk_templates',
		...options
	};

	const output = [];

	/*
	 * Add header from the BEGIN synthetic element
	 */
	if (options.include_document_begin_end) {
		/*
		 * Create the document header
		 */
		if (toAddPath) {
			if (kaialpha.server_options) {
				const kalib_path = path.join(kaialpha.server_options.root_directory, '/resources/nunjucks/lib/kalib.njk');
				options.kalib_path = kalib_path;
			}
		}

		output.push(...(await options.mapping_function('DOCUMENT_BEGIN', null, {}, options)));
	}

	output.push(...(await options.mapping_function('FILE_BEGIN', null, {}, options)));

	let validate_header, validate_footer;
	if (options.validate_elements === true) {
		validate_header = await options.mapping_function('VALIDATE_BEGIN', null, {}, options);
		validate_footer = await options.mapping_function('VALIDATE_END', null, {}, options);
	}

	/*
	 * loop through each object in body and generate nunjucks output based on type
	 */
	const element_options = {
		...options,
		include_document_begin_end: false
	};

	for (const element of body) {
		const element_id = Object.keys(element)[0];
		const element_body = element[element_id];
		const element_type = element_body.type;

		/*
		 * If this element is blacklisted, skip it
		 */
		if (options.element_blacklist !== undefined) {
			if (options.element_blacklist.includes(element_id)) {
				continue;
			}
		}

		if (options.element_callback) {
			options.element_callback(element_type, element_id, element_body);
		}

		let output_elements = [];
		try {
			output_elements = await options.mapping_function(element_type, element_id, element_body, element_options);
		} catch (map_function_error) {
			output_elements = [];
			output_elements.push(`Failed to map/render element ${element_type}/${element_id} (full element: ${JSON.stringify(element_body)}: ${map_function_error}`);
		}

		if (options.validate_elements === true) {
			const output_elements_str = [...validate_header, ...output_elements, ...validate_footer].join('\n');
			if (!(await kaialpha.lib.nunjucks_utils.parseString(output_elements_str))) {
				output_elements = [];
				output_elements.push(`Failed to render element ${element_type}/${element_id}`);
			}
		}

		output.push(...output_elements);
	}

	/*
	 * Add footer from the END synthetic element
	 */
	output.push(...(await options.mapping_function('FILE_END', null, {}, options)));
	if (options.include_document_begin_end) {
		output.push(...(await options.mapping_function('DOCUMENT_END', null, {}, options)));
	}

	return(output);
}

const _to_export_auto = {
	generateNunjucksValueFromBody
}
export default _to_export_auto;
