/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/client/data.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
import cache_utils from '../lib/utils/cache_utils';
const cache_data = {};

export async function list_data(user_id, data_path = '/') {
	const results = await cache_utils.cache_promise(cache_data, data_path, async function() {
		const results = await api.call('GET', 'data');
		return(results);
	}, {
		cache_expires: 60000,
		name: 'cache_data'
	});

	return(results.items);
}

const _to_export = {
	list_data
};
export default _to_export;
