/* Style attributes */
const font_styles = [ {name: 'Bold'}, {name: 'Italic'}, {name: 'Underline'}] ;
const line_heights = [ {name: 'normal'} ];
const font_units = [ {name: 'px'}, {name: 'em'}, {name: 'pt'} ];
const text_align_values = [ {name: 'left'}, {name: 'center'}, {name: 'right'} ];
const font_sizes = [
	{size: '5'}, {size: '5.5'}, {size: '6.5'},
	{size: '7.5'}, {size: '8'}, {size: '9'},
	{size: '10'}, {size: '10.5'}, {size: '11'},
	{size: '12'}, {size: '14'}, {size: '16'},
	{size: '18'}, {size: '20'}, {size: '22'},
	{size: '24'}, {size: '26'}, {size: '28'},
	{size: '36'}, {size: '48'}, {size: '72'}
];
const cell_sizes = [
	{size: '10'}, {size: '20'}, {size: '30'},
	{size: '40'}, {size: '50'}, {size: '60'},
	{size: '70'}, {size: '80'}, {size: '90'},
	{size: '100'}, {size: '110'}, {size: '120'}
];
const fixed_in_and_pt_sizes = [
	{size: '.5'}, {size: '1'}, {size: '1.5'},
	{size: '2'}, {size: '2.5'}, {size: '3'},
	{size: '3.5'}, {size: '4'}, {size: '4.5'},
	{size: '5'}, {size: '5.5'}, {size: '6'},
	{size: '6.5'}, {size: '7'}, {size: '7.5'},
	{size: '8'}, {size: '8.5'}, {size: '9'},
	{size: '9.5'}, {size: '10'}, {size: '10.5'}
];
const font_families = [
	{name: 'Arial'}, {name: 'Helvetica'}, {name: 'Avenir'}, {name: 'Times'},
	{name: 'Calibri'}, {name: 'Courier'}, {name: 'Tahoma'}, {name: 'Verdana'}
];
const colors = [
	{name: 'Black'}, {name: 'White'}, {name: 'Blue'}, {name: 'Red'}, {name: 'Green'},
	{name: 'Yellow'}, {name: 'Pink'}, {name: 'Orange'}, {name: 'Brown'}
];
const border_styles = [
	{name: 'solid'}, {name: 'none'}, {name: 'dotted'},
	{name: 'dashed'}
];

const style_parameters = [
	{
		title: 'Font Size',
		attribute: 'font_size',
		param: 'size',
		values: font_sizes
	},
	{
		title: 'Font Units',
		attribute: 'font_units',
		param: 'name',
		values: font_units
	},
	{
		title: 'Font Family',
		attribute: 'font_family',
		param: 'name',
		values: font_families
	},
	{
		title: 'Font Style',
		attribute: 'font_style',
		param: 'name',
		values: font_styles
	},
	{
		title: 'Line Height',
		attribute: 'line_height',
		param: 'name',
		values: line_heights
	},
	{
		title: 'Left Indent (inches)',
		attribute: 'padding_left',
		param: 'size',
		values: fixed_in_and_pt_sizes
	},
	{
		title: 'Right Indent (inches)',
		attribute: 'padding_right',
		param: 'size',
		values: fixed_in_and_pt_sizes
	},
	{
		title: 'Space Before (pt)',
		attribute: 'padding_top',
		param: 'size',
		values: fixed_in_and_pt_sizes
	},
	{
		title: 'Space After (pt)',
		attribute: 'padding_bottom',
		param: 'size',
		values: fixed_in_and_pt_sizes
	},
	{
		title: 'Hanging Indent (inches)',
		attribute: 'hanging_indent',
		param: 'size',
		values: fixed_in_and_pt_sizes
	},
	{
		title: 'Font Color',
		attribute: 'font_color',
		param: 'name',
		values: colors
	}
];

const table_style_parameters = [
	{
		title: 'Cell Width (px)',
		attribute: 'cell_width',
		param: 'size',
		values: cell_sizes
	},
	{
		title: 'Cell Height (px)',
		attribute: 'cell_height',
		param: 'size',
		values: cell_sizes
	},
	{
		title: 'Header Width (px)',
		attribute: 'header_width',
		param: 'size',
		values: cell_sizes
	},
	{
		title: 'Header Height (px)',
		attribute: 'header_height',
		param: 'size',
		values: cell_sizes
	},
	{
		title: 'Cell Text Alignment',
		attribute: 'cell_text_align',
		param: 'name',
		values: text_align_values
	},
	{
		title: 'Header Text Alignment',
		attribute: 'header_text_align',
		param: 'name',
		values: text_align_values
	},
	{
		title: 'Border Width (pt)',
		attribute: 'border_width',
		param: 'size',
		values: fixed_in_and_pt_sizes
	},
	{
		title: 'Border Color',
		attribute: 'border_color',
		param: 'name',
		values: colors
	},
	{
		title: 'Border Style',
		attribute: 'border_style',
		param: 'name',
		values: border_styles
	},
	{
		title: 'Collaprse Borders',
		attribute: 'border_collapse',
		param: 'value',
		values: [{value: 'collapse'}]
	}
];

function get_style_properties(props, overrides_function) {
	const styles = [];

	for (const style of style_parameters) {
		styles.push({
			label: style.title,
			attribute: ['value', style.attribute],
			type: 'autocomplete',
			param: style.param,
			values: style.values,
			field_overrides: overrides_function(props, ['value', style.attribute], { options: { managed: true } } )
		})
	}

	return(styles);
}

function get_table_style_properties(props, overrides_function) {
	const table_styles = [];

	for (const style of table_style_parameters) {
		table_styles.push({
			label: style.title,
			attribute: ['value', style.attribute],
			type: 'autocomplete',
			param: style.param,
			values: style.values,
			field_overrides: overrides_function(props, ['value', style.attribute], { options: { managed: true } } )
		})
	}

	return(table_styles);
}

const _to_export = {
	get_style_properties,
	get_table_style_properties
};
export default _to_export;
