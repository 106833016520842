/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/client/template.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
import user from './user';
import cache_utils from '../lib/utils/cache_utils';
import document_utils from '../lib/utils/document_utils';
import object_utils from '../lib/utils/object_utils';
import diff_utils from '../lib/utils/diff_utils';
const cached_template = {};
const cached_template_short = {};
const cached_template_children = {};
const cached_template_parents = {};
const cached_template_diff = {};
const cached_templates_info = {};

/*
 * Get all templates (or a subset)
 */
export async function getTemplates(filter = undefined, fields = ['name', 'permissions'], archived = false) {
	const options = {
		filter,
		fields,
		auto_paginate: true
	};

	const template_info = await get_user_templates(null, options);

	if (template_info.templates === undefined) {
		return([]);
	}

	return(template_info.templates);
}

async function getTemplateToplevels(options = {}) {
	options = {
		include_body: true,
		...options
	};

	const fields = ['version', 'name', 'permissions', 'metadata'];
	if (options.include_body === true) {
		fields.push('body');
	}

	const top_level_templates = await getTemplates('metadata.toplevel=true', fields);

	for (const template_index in top_level_templates) {
		const template = top_level_templates[template_index];
		if (!template.body) {
			continue;
		}

		const child_templates = await document_utils.body_templates(template.body);
		template['children'] = child_templates;
	}

	/*
	 * Construct a virtual template to represent orphaned templates,
	 * that is templates which are not a top-level template and not
	 * included in any other template.
	 */
	top_level_templates.push({
		id: '@orphans',
		name: 'Orphans',
		children: ['@children'],
		permissions: {
			owners: ['@system']
		}
	});

	return(top_level_templates);
}

/*
 * Get the list of templates which are not included by any other template
 */
async function getTemplateOrphans() {
	const orphan_templates = {};

	/*
	 * XXX: We could really just request "body" here, and get the name+permissions
	 *      of only the orphaned templates;  It is likely, though, that the body
	 *      contents will be the vast majority of all templates and so the
	 *      additional bits would be small.  Further, we don't have a way to
	 *      request the name and permissions of a set of template IDs, so we would
	 *      need to make individual requests which would likely be slower in most
	 *      cases
	 */
	const all_templates = await getTemplates(undefined, ['version', 'name', 'permissions', 'body', 'metadata']);

	for (const template of all_templates) {
		orphan_templates[template.id] = template;
	}

	for (const template of all_templates) {
		const child_templates = await document_utils.body_templates(template.body);

		for (const child_template_info of child_templates) {
			const child_template_id = child_template_info.contents.id;

			delete orphan_templates[child_template_id];
		}

		if (template.metadata && template.metadata.toplevel === 'true') {
			delete orphan_templates[template.id];
		}
	}

	const retval = [];
	for (const template_index in orphan_templates) {
		const template = orphan_templates[template_index];
		const child_templates = await document_utils.body_templates(template.body);

		retval.push({
			id: template.id,
			name: template.name,
			permissions: template.permissions,
			children: child_templates
		});
	}

	return(retval);
}

export async function getTemplateTreeItem(template) {
	const template_children = await document_utils.body_templates(template.body);

	return({
		id: template.id,
		version: template.version,
		name: template.name,
		permissions: template.permissions,
		children: template_children,
		archived: template._archived
	});
}

export async function getTemplateTreeItemById(id, version) {
	let template;

	try {
		template = await getTemplateTreeItem(await getTemplateById(id, version));
	} catch(fetch_error) {
		kaialpha.log.error(`Failed to fetch template ${id}/${version}:`, fetch_error);
	}

	return(template);
}

/*
 * Get the parent templates for a given template (or an empty array if it has
 * no parents)
 */
export async function getTemplateParents(child, version = undefined) {
	let cache_key;

	/* XXX:TODO: Handle "version" of "date:*" */
	if (version === 'HEAD') {
		version = undefined;
	}

	if (version) {
		cache_key = [child, version].join('|');
	}

	if (cache_key === undefined) {
		console.debug('getTemplateParents called with no version, this results in reduced caching');
	}

	const retval = await cache_utils.cache_promise(cached_template_parents, cache_key, async function() {
		/* XXX:TODO: This does not handle the version currently, even though it is cached based on it ! */
		const super_templates_info = await kaialpha.lib.template_utils.get_template_supers(null, child, {
			include_self: false
		});

		const parent_template_promises = [];
		for (const super_template_info of super_templates_info) {
			const parent_template_promise = getTemplateTreeItemById(super_template_info.id, super_template_info.version);
			parent_template_promises.push(parent_template_promise);
		}

		const parent_templates = (await Promise.all(parent_template_promises)).filter(function(item) {
			/*
			 * Filter out any "undefined" values, which could be the result of
			 * an error while fetching.
			 */
			if (item === undefined) {
				return(false);
			}
			return(true);
		});

		return(parent_templates);
	}, {
		name: 'cached_template_parents'
	});

	return(retval);
}

/*
 * Get the child templates for a given template, or all top-level templates
 */
export async function getTemplateChildren(parent = null, version = undefined) {
	let cache_key = parent;

	/* XXX:TODO: Handle "version" of "date:*" */
	if (version === 'HEAD') {
		version = undefined;
	}

	if (parent !== null && version) {
		cache_key = [parent, version].join('|');
	}

	/*
	 * If we are looking for orphans, do not cache the result
	 */
	if (parent === '@orphans') {
		cache_key = null;
	}

	const retval = await cache_utils.cache_promise(cached_template_children, cache_key, async function() {
		if (parent === '@orphans') {
			return(await getTemplateOrphans());
		}

		if (parent === null) {
			return(await getTemplateToplevels({
				include_body: true
			}));
		}

		const template = await getTemplateById(parent, version);
		if (!template.body) {
			return([]);
		}

		const child_templates_info = await document_utils.body_templates(template.body);

		const child_template_promises = [];
		for (const child_template_info of child_templates_info) {
			const child_template_id = child_template_info.contents.id;
			const child_template_promise = getTemplateTreeItemById(child_template_id);
			child_template_promises.push(child_template_promise);
		}

		const child_templates = (await Promise.all(child_template_promises)).filter(function(item) {
			/*
			 * Filter out any "undefined" values, which could be the result of
			 * an error while fetching.
			 */
			if (item === undefined) {
				return(false);
			}
			return(true);
		});

		return(child_templates);
	}, {
		name: 'cached_template_children'
	});

	return(retval);
}

export async function deleteTemplate(template_id) {
	return(await api.call('DELETE', `template/${template_id}`));
}

export async function delete_template(user_id, template_id) {
	return(await deleteTemplate(template_id));
}

//Update template
export async function updateTemplateVersion(template_id, template_version, name, body, variables, metadata, permissions) {
	if (!template_version) {
		throw(new Error('[DEBUG] updateTemplateVersion now requires template_version'));
	}

	const payload = {
		"id": template_id,
		"version": template_version,
		"change": {},
		"delete": {}
	}

	if (name !== undefined) {
		payload.change.name = name;
	}

	if (body !== undefined) {
		payload.change.body = body;
		payload.delete.body = null;
	}

	if (variables !== undefined) {
		payload.change.variables = variables;
		payload.delete.variables = null;
	}

	if (metadata !== undefined) {
		payload.change.metadata = metadata;
		payload.delete.metadata = null;
	}

	if (permissions !== undefined) {
		payload.change.permissions = permissions;
		payload.delete.permissions = null;
	}

	const retval = await api.call('PATCH', `template/${template_id}`, payload);

	cache_utils.clear(cached_template_short);
	cache_utils.clear(cached_templates_info);

	return(retval);
}

export async function apply_diff_user_template(user_id, template_id, version_id_head, summary, diff) {
	if (diff === undefined) {
		diff = {};
	}

	const payload = {
		id: template_id,
		version: version_id_head,
		summary: summary,
		change: diff.change,
		delete: diff.delete
	};

	const retval = await api.call('PATCH', `template/${template_id}`, payload);

	cache_utils.clear(cached_template_short);
	cache_utils.clear(cached_templates_info);

	return(retval);
}

/*
 * XXX:TODO: Move to utils function (validation_utils?)
 */
function is_valid_template_id(id) {
	if (typeof(id) !== 'string') {
		return(false);
	}

	if (id.match(/^[0-9a-fA-F-]*$/) === null) {
		return(false);
	}

	if (id.length !== 36) {
		return(false);
	}

	return(true);
}

/*
 * Get template by ID
 */
export async function getTemplateById(id, version = undefined) {
	if (!is_valid_template_id(id)) {
		throw(new Error(`Invalid template ID specified: ${JSON.stringify(id)}`));
	}

	if (version === 'HEAD') {
		version = undefined;
	}

	if (version) {
		/* XXX:TODO: Handle "version" of "date:*" */
		const cache_key = [id, version].join('|');

		const retval = await cache_utils.cache_promise(cached_template, cache_key, async function() {
			return(await api.call('GET', `template/${id}/versions/${version}`));
		}, {
			name: 'cached_template'
		});

		return(retval);
	} else {
		const retval = await cache_utils.cache_promise(cached_template_short, id, async function() {
			return(await api.call('GET', `template/${id}`));
		}, {
			cache_expires: 5000,
			name: 'cached_template_short'
		});
		return(retval);
	}
}

//Create a template
export async function createTemplate(name, body, variables, metadata, permissions) {
	if (name === "null") {
		name = "Undefined";
	}

	if (permissions === undefined || permissions === null) {
		permissions = {
			owners: [await user.get_user_id()]
		}
	}

	const payload = {
		name,
		metadata,
		permissions,
		variables,
		body
	}

	const retval = await api.call('POST', 'template', payload);

	cache_utils.clear(cached_templates_info);

	return(retval);
}

/*
 * XXX:TODO: This is identical to "getDocumentDiff" -- we should make a more abstract function for both
 */
export function getTemplateDiff(old_version, new_version) {
	/* kaialpha.log.debug(`Diffing ${JSON.stringify(old_version)} and ${JSON.stringify(new_version)}`); */
	const diff = diff_utils.diff_objects(old_version, new_version, false);

	if (!diff.changed) {
		diff.changed = {};
	}
	if (!diff.added) {
		diff.added = {};
	}
	if (!diff.deleted) {
		diff.deleted = {};
	}

	/*
	 * Delete spurious differences
	 */
	delete diff.changed['version'];
	delete diff.changed['previous_version'];

	return(diff);
}

export async function getVersionsOfTheTemplate(template_id) {
	if (!template_id) {
		throw(new Error('[DEBUG] id is mandatory to get versions of a template'));
	}

	const retval = await api.call('GET', `template/${template_id}/versions`);

	return(retval);
}

export async function getTemplateDiffByVersionIds(template_id, old_version, new_version) {
	if (!(old_version || new_version || template_id)) {
		throw(new Error('[DEBUG] documentId, old version id and new version id is mandatory to getting difference'));
	}

	const cache_key = [template_id, old_version, new_version].join('|');
	const retval = await cache_utils.cache_promise(cached_template_diff, cache_key, async function() {
		return(await api.call('GET', `template/${template_id}/diff/${old_version}/${new_version}`));
	}, {
		cache_expires: (30 * 60 * 1000),
		name: 'cached_template_diff'
	});

	return(retval);
}

export async function generatePDFFromHTML(html) {
	const payload = {
		html: html
	}

	const retval = await api.call('POST', `template/generate/pdf`, payload);

	return(retval);
}

/*
 * XXX:TODO: This is identical to "mergeDocumentObjects" -- we should make a more abstract function for both
 */
export function mergeTemplateObjects(version_1, version_2, version_base) {
	version_1 = object_utils.copy_object(version_1);
	version_2 = object_utils.copy_object(version_2);
	version_base = object_utils.copy_object(version_base);
	['version', 'previous_version', 'child_resources'].forEach(function(element) {
		delete version_1[element];
		delete version_2[element];
		delete version_base[element];
	});

	const merged = diff_utils.merge_objects(version_1, version_2, version_base);

	return(merged);
}

/*
 * Helpers for compatibility
 */
export async function get_user_template(user_id, template_id, template_version) {
	return(await getTemplateById(template_id, template_version));
}

export async function get_user_templates(user_id, options = {}) {
	options = {
		auto_paginate_count: 2000,
		...options
	};

	if (options.auto_paginate === true && options.count !== undefined) {
		throw(new Error('Unsupported combination of auto_paginate and count'));
	}

	const query = kaialpha.lib.versions_utils.list_generate_query_from_options(options);

	let cache_key;
	if (options.auto_paginate === true) {
		cache_key = `template/auto_paginate/${query}`;
	} else {
		cache_key = `template/no_auto_paginate/${query}`;
	}

	const response = await cache_utils.cache_promise(cached_templates_info, cache_key, async function() {
		if (options.auto_paginate === true) {
			const page_options = {
				...options,
				count: options.auto_paginate_count
			};

			const retval = {
				templates: []
			}

			let continue_processing = true;
			while (continue_processing) {
				const page_query = kaialpha.lib.versions_utils.list_generate_query_from_options(page_options);
				const page_request = `template${page_query}`;
				const results = await api.call('GET', page_request);

				retval.templates.push(...results.templates);

				if (results.next === undefined) {
					continue_processing = false;
				}

				page_options.after = results.next;
			}

			return(retval);
		} else {
			const request = `template${query}`;

			const retval = await api.call('GET', request);

			delete retval['child_resources'];

			return(retval);
		}
	}, {
		cache_expires: 60000,
		name: 'cached_templates_info'
	});

	return(response);
}

export async function renderTemplateHTML(id, version) {
	const body = await api.call('GET', `template/${id}/versions/${version}/render/html`);

	return(body);
}

export async function renderTemplatePDF(id, version) {
	const pdf_info = await api.call('GET', `template/${id}/versions/${version}/render/pdf`);
	return(pdf_info);
}

/*
 * Get All Template Buffers
 */
export async function getAllTemplateBuffers() {
	const response = await api.call('GET', 'template/buffer?fields=version,name');
	if (response.templates) {
		return(response.templates);
	}

	return([]);
}

/*
 * Get Single Template Buffer
 */
export async function getTemplateBuffer(id) {
	const retval = await api.call('GET', `template/buffer/${id}`);
	return retval;
}
/*
 * Create Template Buffer
 */
export async function createTemplateBuffer(current_params, version) {
	const template = {
		id: current_params.template_id,
		version: version,
		name: current_params.name,
		metadata: current_params.metadata,
		permissions: current_params.permissions,
		variables: current_params.template.variables,
		body: current_params.template.body
	}
	const retval = await api.call('POST', 'template/buffer', template);
	return retval;
}

export async function updateTemplateBuffer(id, version, current_params) {
	const payload = {
		id: id,
		version: version,
		change: {},
		delete: {}
	}

	if (current_params.name !== undefined) {
		payload.change.name = current_params.name;
	}

	if (current_params.template.body !== undefined) {
		payload.change.body = current_params.template.body;
		payload.delete.body = null;
	}

	if (current_params.template.variables !== undefined) {
		payload.change.variables = current_params.template.variables;
		payload.delete.variables = null;
	}

	if (current_params.metadata !== undefined) {
		payload.change.metadata = current_params.metadata;
		payload.delete.metadata = null;
	}

	if (current_params.permissions !== undefined) {
		payload.change.permissions = current_params.permissions;
		payload.delete.permissions = null;
	}

	const retval = await api.call('PATCH', `template/buffer/${id}`, payload);
	return retval;
}

export async function renderTemplateBufferHTML(id) {
	const body = await api.call('GET', `template/buffer/${id}/render/html`);

	return(body);
}

export async function renderTemplateBufferPDF(id) {
	const pdf_info = await api.call('GET', `template/buffer/${id}/render/pdf`);
	return(pdf_info);
}

export function computeElementDisplayName(element_name) {
	let retval;

	switch (element_name) {
		case "comment":
			retval = 'Instructional Text';
			break;
		case "citations_list":
			retval = 'List of Citations';
			break;
		case "abbreviations_list":
			retval = 'List of Abbreviations';
			break;
		default:
			retval = element_name.split('_').map(function(word) {
				const firstLetter = word[0].toUpperCase();
				const rest = word.slice(1);

				return(`${firstLetter}${rest}`);
			}).join(' ');
			break;
	}

	return(retval);
}

export async function convertUploadedDocToTemplate(key) {
	let template = null;
	if (key) {
		template = await api.call('POST', `template/generate/data/${key}`);
	}
	return template;
}

export async function get_user_template_canonical_permissions(user_id, template_id) {
	return(await api.call('GET', `template/${template_id}/permissions`));
}

export async function diff_user_template(user_id, template_id, version_id_old, version_id_new) {
	return(await getTemplateDiffByVersionIds(template_id, version_id_old, version_id_new));
}

const _to_export = {
	createTemplate,
	getTemplateParents,
	getTemplateChildren,
	deleteTemplate,
	delete_template,
	getTemplateTreeItemById,
	getTemplateTreeItem,
	getTemplateById,
	getTemplateDiff,
	mergeTemplateObjects,
	updateTemplateVersion,
	apply_diff_user_template,
	diff_user_template,
	getTemplates,
	computeElementDisplayName,
	get_user_template,
	get_user_templates,
	renderTemplateHTML,
	renderTemplatePDF,
	getAllTemplateBuffers,
	getTemplateBuffer,
	createTemplateBuffer,
	updateTemplateBuffer,
	renderTemplateBufferHTML,
	renderTemplateBufferPDF,
	convertUploadedDocToTemplate,
	getVersionsOfTheTemplate: getVersionsOfTheTemplate,
	getTemplateDiffByVersionIds,
	generatePDFFromHTML,
	get_user_template_canonical_permissions
};
export default _to_export;
