import React from 'react';
import './TemplateDocSelector.css';
import { Button } from '../../lib/ui';
import { Link } from 'react-router-dom';
import LazyTreeView from '../LazyTreeView';
import ConfirmationMessage from '../ConfirmationMessage';

class TemplateDocSelector extends React.Component {
	static defaultProps = {
		display_type: '',
		content_type: '', /* documents or templates */
		action: '',       /* create or edit */
		header_type: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			name_search_val: '',
			owner_search_val: '',
			content_id: '',
		};

		/* Create a reference to ConfirmationMessage component */
		this.confirmation_message = React.createRef();
	}


	setSearch(type, value) {
		this.setState({
			[`${type}_search_val`]: value
		});
	}

	setContentId(id) {
		this.setState({ content_id: id });
	}

	filterItem(item) {
		if (!this.state) {
			return true;
		}

		if (!(this.state.name_search_val || this.state.owner_search_val)) {
			return true;
		}

		if (this.state.name_search_val === "" && this.state.owner_search_val === "") {
			return(true);
		}

		if (
			item &&
			item.name && item.owner &&
			item.name.toLowerCase().includes(this.state.name_search_val.toLowerCase().trim()) &&
			item.owner.toLowerCase().includes(this.state.owner_search_val.toLowerCase().trim())
		) {
			return(true);
		}

		if (
			item &&
			item.name &&
			this.state.name_search_val &&
			item.name.toLowerCase().includes(this.state.name_search_val.toLowerCase().trim())
		) {
			return(true);
		}

		if (
			item &&
			item.owner &&
			this.state.owner_search_val &&
			item.owner.toLowerCase().includes(this.state.owner_search_val.toLowerCase().trim())
		) {
			return(true);
		}

		return(false);
	}

	render() {
		const content_id = this.state.content_id;
		const display_type = this.props.display_type;
		const header_type = this.props.header_type;
		const content_type = this.props.content_type;
		const action = this.props.action;

		let link_to_path;
		if (header_type === 'Template' && content_type === 'templates') {
			link_to_path = '/activity/templateeditor';
		} else {
			link_to_path = '/activity/doceditor';
		}

		const button_to = {
			pathname: link_to_path,
			state: {
				id: content_id,
				type: content_type,
				action: action
			}
		};

		/*
		 * When editing (vs. creating), use a direct link to the route
		 * which will instantiate the editor object
		 */
		if (action === 'Edit') {
			button_to.pathname = `${link_to_path}/${content_id}`;
			delete button_to['state'];
		}

		const button =
			<Button
				disabled={content_id === ''}
				component={Link}
				to={button_to}
				color="primary"
				className="doc-action-btn"
			>{action} {header_type}</Button>;

		let deletebutton;
		if (this.props.deleteItem) {
			deletebutton = <Button
				disabled={content_id === ''}
				onClick={async () => {
					/** XXX:TODO: This should use inline UI components instead of window.confirm **/
					if (window.confirm('Are you sure you would like to delete this template?')) {
						let delete_error;
						let deleted_template;
						try {
							deleted_template = await this.props.deleteItem(this.state.content_id);
						} catch (_delete_error) {
							delete_error = _delete_error;
						}

						const action = 'Deleting';
						const type = content_type;

						if (!deleted_template) {
							this.confirmation_message.current.notifyUserFailure(action, type, delete_error);
						} else {
							this.confirmation_message.current.notifyUserSuccess(action, type, null);
						}
					}
				}}
				color="secondary"
				className="doc-action-btn"
			>DELETE {header_type}</Button>;
		}

		let tree_view;
		if (content_type) {
			tree_view =
				<LazyTreeView
					onClick = {(id) => {
						this.setContentId(id)
					}}
					getChildren = {this.props.getChildren}
					filterItem = {(item) => {
						return(this.filterItem(item))
					}}
				/>;
		}

		return(
			<div className="document-layout">
				<ConfirmationMessage ref={this.confirmation_message}/>
				<p className="main-hdr">{action} {header_type}</p>
				<div className="content-name">
					<p className="content-type-hdr">{display_type} Name:</p>
					{tree_view}
				</div>
				<div className="filters">
					<p className="filters-hdr">Filters: </p>
					<div className="name">
						<label>{display_type} Name: </label>
						<input className="name-input" id="name-input-value" onChange={(event) => {
							this.setSearch("name", event.target.value);
						}}></input>
					</div>
					<div className="owner">
						<label>Owner: </label>
						<input className="owner-input" id="owner-input-value" onChange={(event) => {
							this.setSearch("owner", event.target.value);
						}}></input>
					</div>
				</div>
				{button}
				{deletebutton}
			</div>
		);
	}
}

export default TemplateDocSelector;
