import React from 'react';
import user from '../../api/user'
import object_utils from '../../lib/utils/object_utils'
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

class LazyTreeView extends React.Component{
	/*
	 * Set default properties
	 */
	static defaultProps = {
		renderItemLabel: this.render_item_label,
		computeItem: this.define_item,
		getItemId: this.get_item_id,
		getItemVersion: this.get_item_version,
		getItemChildCount: this.get_item_child_count,
		getChildren: undefined,
		getParents: undefined,
		filterItem: undefined,
		onClick: undefined,
		selected: undefined
	};

	constructor(props) {
		super(props);

		this._mounted = false;
		this.state = {
			item_children: {
				root: ['@loading']
			},
			expanded_items: {}
		};

		this.item_children_promises = {};
		this.seen_items = {};
	}

	async reload(from_id = undefined, from_version = undefined, options = {}) {
		options = {
			_initial_call: true,
			...options
		};

		if (options._initial_call === true) {
			/*
			 * Clear cache of seen items
			 */
			this.seen_items = {};
		}

		/*
		 * Update the requested item
		 */
		const parent_items = await this.load_children(from_id, from_version);

		/*
		 * Update all expanded items
		 */
		const promises = [];
		for (const child of parent_items) {
			const node_id = this._item_to_node_id(child);
			if (this.state.expanded_items[node_id] === undefined) {
				continue;
			}

			const child_id = this.props.getItemId(child);
			const child_version = this.props.getItemVersion(child);

			promises.push(this.reload(child_id, child_version, {
				...options,
				_initial_call: false
			}));
		}

		await Promise.all(promises);

		/*
		 * If an item is selected, _initial_call it since it may have changed
		 */
		if (options._initial_call === true) {
			if (this.state.selected) {
				const selected_item_id = this.props.getItemId(this.state.selected);

				const selected_item = this.seen_items[selected_item_id];

				if (selected_item) {
					let parent_item_id, parent_item;
					if (this.state.selected.parent) {
						parent_item_id = this.props.getItemId(this.state.selected.parent)
						parent_item = this.seen_items[parent_item_id];
					}

					this.select_item(selected_item, parent_item);
				}
			}
		}
	}

	componentDidMount() {
		this._mounted = true;

		if (this.props.selected === undefined) {
			this.load_toplevel_items();
		} else {
			this.select_item(this.props.selected, undefined, {
				enable_callbacks: false
			});
			this.load_selected_item(this.props.selected);
		}
	}

	/* XXX:TODO: Rewrite this as componentDidUpdate */
	UNSAFE_componentWillReceiveProps(nextProps) {
		/*
		 * If an item is selected, and the version changes, reload the item
		 */
		if (!nextProps.selected) {
			return;
		}

		if (!this.props.selected) {
			return;
		}

		const current_id = this.props.getItemId(this.props.selected);
		const current_version = this.props.getItemVersion(this.props.selected);

		const new_id = this.props.getItemId(nextProps.selected);
		const new_version = this.props.getItemVersion(nextProps.selected);

		if (current_id === new_id) {
			if (current_version === new_version) {
				return;
			}
		}

		this.load_selected_item(nextProps.selected);
	}

	/*
	 * Default methods for user replacable functions
	 */
	static get_item_id(item) {
		return(item.id);
	}

	static get_item_version(item) {
		return(item.version);
	}

	static get_item_child_count(item) {
		if (item.children) {
			return(item.children.length);
		}

		return(0);
	}

	static render_item_label(item) {
		return(`${item.name} [Owner: ${item.owner}]`);
	}

	static async define_item(item) {
		if (!item.permissions || !item.permissions.owners) {
			console.debug('Excluding item', item, 'in LazyTreeView.define_item because it lacks a valid owners statement (missing)');

			return;
		}

		if (!(item.permissions.owners instanceof Array)) {
			console.debug('Excluding item', item, 'in LazyTreeView.define_item because it lacks a valid owners statement (not an array)');

			return;
		}

		let owner;
		try {
			owner = await user.get_user_username(item.permissions.owners[0]);
		} catch (owner_lookup_error) {
			/* Ignored */
		}

		if (owner === undefined) {
			owner = 'Unknown';
		}

		return({
			...item,
			owner: owner
		});
	}

	/*
	 * Compute a cache key from an item ID and Version
	 */
	static _get_id_cache_key(id = undefined, version = undefined) {
		let id_name = id;
		if (id_name === undefined) {
			id_name = 'root';
		}

		let version_name = version;
		if (version_name === undefined) {
			version_name = 'HEAD';
		}

		const cache_items = [id_name];

		/*
		 * XXX:TODO: Currently caching is based on the ID,
		 * with the version excluded so that it can be updated
		 */
		// eslint-disable-next-line
		if (false) {
			if (version !== undefined) {
				cache_items.push(version);
			}
		}

		const cache_key = cache_items.join('_');

		return(cache_key);
	}

	/*
	 * Set the children of an item
	 */
	async set_children(parent_id, parent_version, items) {
		await new Promise((resolve, reject) => {
			this.setState(function(prevState) {
				const cache_key = LazyTreeView._get_id_cache_key(parent_id, parent_version);
				return({
					item_children: {
						...prevState.item_children,
						[cache_key]: items
					}
				});
			}, resolve);
		});
	}

	async fetch_children(parent_id = undefined, parent_version = undefined) {
		if (this.props.getChildren === undefined) {
			return([]);
		}

		const items = await this.props.getChildren(parent_id, parent_version);
		const internal_items = [];
		for (const item of items) {
			const new_item = await this.props.computeItem(item);

			if (new_item === undefined) {
				continue;
			}

			const new_item_id = this.props.getItemId(new_item);
			this.seen_items[new_item_id] = new_item;

			internal_items.push(new_item);
		}

		return(object_utils.copy_object(internal_items));
	}

	/*
	 * fetch+set
	 */
	async load_children(parent_id = undefined, parent_version = undefined) {
		const cache_key = LazyTreeView._get_id_cache_key(parent_id, parent_version);

		if (this.item_children_promises[cache_key] !== undefined) {
			return(await this.item_children_promises[cache_key]);
		}

		if (parent_id !== undefined) {
			if (parent_version === undefined) {
				console.warn(`When loading children of parent_id=${parent_id}, requested children unversioned, this does not use caching optimally`);
			}
		}

		const internal_items_promise = this.fetch_children(parent_id, parent_version);
		this.item_children_promises[cache_key] = internal_items_promise;

		const internal_items = await internal_items_promise;

		delete this.item_children_promises[cache_key];

		await this.set_children(parent_id, parent_version, internal_items);

		return(internal_items);
	}

	/*
	 * Load all items, this should be called if no item is initially selected
	 */
	async load_toplevel_items() {
		/*
		 * getChildren with no arguments will give us all the top-level
		 * items
		 */
		return(await this.load_children());
	}

	/*
	 * Load a selected item, use the selected item to recurse up the tree to find
	 * the appropriate roots/top-levels.
	 */
	async load_selected_item(item, options = {}) {
		options = {
			_state: {
				seen_ids: [],
				top_levels: [],
				top_level_promises: [],
				apply_top_levels: true
			},
			...options
		};

		const apply_top_levels = options._state.apply_top_levels;
		options._state.apply_top_levels = false;

		if (!this.props.getParents) {
			return;
		}

		const item_id = this.props.getItemId(item);
		const item_version = this.props.getItemVersion(item);

		if (item_id === undefined) {
			return;
		}

		options._state.seen_ids.push(item_id);

		const parents = (await this.props.getParents(item_id, item_version)).filter((parent_item) => {
			const parent_id = this.props.getItemId(parent_item);
			if (options._state.seen_ids.includes(parent_id)) {
				return(false);
			}
			return(true);
		});

		/*
		 * This is a top-level item, indicate that it's a child of "root"
		 */
		if (parents.length === 0) {
			const new_item = await this.props.computeItem(item);
			if (new_item !== undefined) {
				options._state.top_levels.push(new_item);
			}
		}

		/*
		 * Keep recursing up to find more top-levels
		 */
		for (const parent_item of parents) {
			this.expand_item(parent_item);
			await this.load_selected_item(parent_item, options);
		}

		this.load_children(item_id, item_version);

		if (apply_top_levels) {
			this.set_children(undefined, undefined, options._state.top_levels);
		}
	}

	/*
	 * These functions manage the expanded items
	 */
	expand_item(item_node_id) {
		this.setState(function(prevState) {
			return({
				expanded_items: {
					...prevState.expanded_items,
					[item_node_id]: true
				}
			});
		});
	}

	collapse_item(item_node_id) {
		this.setState(function(prevState) {
			delete prevState.expanded_items[item_node_id];
			return({
				expanded_items: {
					...prevState.expanded_items,
				}
			});
		});
	}

	toggle_expand_collapse_item(item_node_id) {
		if (this.state.expanded_items[item_node_id]) {
			this.collapse_item(item_node_id);
		} else {
			this.expand_item(item_node_id);
		}
	}

	/*
	 * Select an item
	 */
	select_item(item, parent_item, options = {}) {
		options = {
			enable_callbacks: true,
			...options
		};

		this.setState({
			selected: {
				...item,
				id: this.props.getItemId(item),
				version: this.props.getItemVersion(item),
				parent: parent_item
			}
		});

		if (options.enable_callbacks === true && this.props.onClick) {
			const item_id = this.props.getItemId(item);
			const item_version = this.props.getItemVersion(item);

			this.props.onClick(item_id, item_version, {
				...item,
				parent: parent_item
			});
		}
	}

	/*
	 * Render an item -- if it has children add a "Loading"
	 * element as a child element, as a place holder until
	 * the children can be resolved
	 */
	_get_loading_item(parent_id) {
		return <TreeItem key={'loading_' + parent_id} nodeId={'loading_' + parent_id} label='Loading...'/>;
	}

	_item_to_node_id(item, parent_item) {
		if (item['@node_id'] !== undefined) {
			return(item['@node_id']);
		}

		let parent_item_id = '';
		if (parent_item !== undefined) {
			if (parent_item['@node_id'] === undefined) {
				throw(new Error(`Looking up ID but found something useless: ${JSON.stringify(parent_item)}`));
			}

			parent_item_id = parent_item['@node_id'];
		}

		const item_node_id = ['item', this.props.getItemId(item), parent_item_id].join('_');
		//console.debug('Computing node ID for', item, '=', item_node_id);

		item['@node_id'] = item_node_id;

		return(item_node_id);
	}

	render_treeview_item(item, parent_item = undefined) {
		if (item === '@loading') {
			return(this._get_loading_item(item));
		}

		const item_id = this.props.getItemId(item);
		const item_version = this.props.getItemVersion(item);
		const label = this.props.renderItemLabel(item);

		const item_node_id = this._item_to_node_id(item, parent_item);

		const child_count = this.props.getItemChildCount(item);
		let item_has_children = false;
		if (child_count > 0) {
			item_has_children = true;
		}

		let children_elements = [];
		if (item_has_children) {
			const cache_key = LazyTreeView._get_id_cache_key(item_id, item_version);
			const computed_children = this.state.item_children[cache_key];

			if (computed_children === undefined) {
				children_elements = [this._get_loading_item(item_id)];
			} else {
				children_elements = computed_children.map((child_item) => {
					return(this.render_treeview_item(child_item, item));
				});
			}
		}


		return(
			<TreeItem
				key={item_node_id}
				nodeId={item_node_id}
				label={label}
				onLabelClick={() => {
					this.select_item(item, parent_item);
				}}
				onIconClick={() => {
					if (item_has_children) {
						this.load_children(item_id, item_version);

						this.toggle_expand_collapse_item(item_node_id);
					}
				}}
			>{children_elements}</TreeItem>
		);
	}

	render() {
		let selected_node_id;

		const tree_items = this.state.item_children.root.filter((item) => {
			if (this.props.filterItem === undefined) {
				return(true);
			}
			return(this.props.filterItem(item));
		}).map((item) => {
			return(this.render_treeview_item(item));
		});

		if (this.state.selected) {
			selected_node_id = this._item_to_node_id(this.state.selected);
		} else {
			selected_node_id = '';
		}

		return(
			<TreeView
				defaultCollapseIcon = {<ExpandMoreIcon />}
				defaultExpandIcon = {<ChevronRightIcon />}
				expanded = {Object.keys(this.state.expanded_items)}
				selected = {selected_node_id}
			>{tree_items}</TreeView>
		);
	}
}

export default LazyTreeView;
