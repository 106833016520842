import React from 'react';
import './DocumentCreator.css';
import TemplateDocSelector from '../TemplateDocSelector';
import {getTemplates} from '../../api/template.js';


class DocumentCreator extends React.Component {
	async getDocumentTemplates(){
		return await getTemplates('metadata.toplevel=true');
	}

	render() {
		return(
			<div className="edit-document">
				<TemplateDocSelector
					action = 'Create'
					display_type = 'Template'
					content_type = 'templates'
					header_type = 'Document'
					getChildren = {this.getDocumentTemplates}
				></TemplateDocSelector>
			</div>
		)
	}
}

export default DocumentCreator;
