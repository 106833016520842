//import Button from '@material-ui/core/Button';
import Button from '../../components/Button';
import ButtonDialog from '../../components/ButtonDialog';
import Prompt from '../../components/Prompt';
//import CircularProgress from '@material-ui/core/CircularProgress';
import CircularProgress from '../../components/CircularProgress';
import Icon from '../../components/Icon';
import TextField from '@material-ui/core/TextField';

export {
	Button,
	ButtonDialog,
	Prompt,
	CircularProgress,
	Icon,
	TextField
};

const _to_export = {
	Button,
	ButtonDialog,
	Prompt,
	CircularProgress,
	Icon,
	TextField
};
export default _to_export;
