import React from 'react';

class PromptItem extends React.Component {
	render() {
		return(
			<tr>
				<td>{this.props.title}</td>
				<td>{this.props.children}</td>
			</tr>
		);
	}
}

export default PromptItem;
