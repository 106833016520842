import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '../../lib/ui';
import document_lib from '../../api/document.js';
import template_lib from '../../api/template.js';
const download = require('downloadjs');

class DialogBox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dialog_open: true,
			id: '',
			htmlChecked: false,
			pdfChecked: false
		}
		this.submitDownload = this.submitDownload.bind(this);
	}

	handleClose = () => {
		this.props.onClick();
		this.setState({ dialog_open: false })
	};

	componentDidMount() {
		this.setState({ dialog_open: true });
		this.setState({ id: this.props.id });
	}

	componentWillReceiveProps() {
		this.setState({ dialog_open: true });
	}

	handleCheckbox = (event) => {
		switch(event.target.value) {
			case 'html':
				this.setState({htmlChecked: event.target.checked})
				break;
			case 'pdf':
				this.setState({pdfChecked: event.target.checked})
				break;
			default:
				break;
		}
	}

	async submitDownload(event) {
		this.props.onClick();
		this.setState({ dialog_open: false });
		if (this.state.htmlChecked === true) {
			await this.downloadHTML();
		}
		if (this.state.pdfChecked === true) {
			await this.downloadPDF();
		}
	}

	async downloadHTML() {
		const id = this.state.id;
		const version = 'HEAD';
		switch(this.props.type) {
			case 'document':
				if (Object.keys(this.props.buffer).length !== 0 && this.props.renderBuffer) {
					const document_html = await document_lib.renderDocumentBufferHTML(this.props.buffer.id);
					download(document_html, 'doc.html', 'text/html');
				} else {
					const document_html = await document_lib.renderDocumentHTML(id, version);
					download(document_html, 'doc.html', 'text/html');
				}
				break;
			case 'template':
				if (Object.keys(this.props.buffer).length !== 0 && this.props.renderBuffer) {
					const template_html = await template_lib.renderTemplateBufferHTML(this.props.buffer.id);
					download(template_html, 'doc.html', 'text/html');
				} else {
					const template_html = await template_lib.renderTemplateHTML(id, version);
					download(template_html, 'doc.html', 'text/html');
				}
				break;
			default:
				throw(new Error(`Tried to fetch HTML for unsupported type ${this.props.type}`));
		}
	}

	async downloadPDF() {
		const id = this.state.id;
		const version = 'HEAD';
		switch(this.props.type) {
			case 'document':
				if (Object.keys(this.props.buffer).length !== 0 && this.props.renderBuffer) {
					const document_pdf = await document_lib.renderDocumentBufferPDF(this.props.buffer.id);
					this.saveFile(document_pdf);
				} else {
					const document_pdf = await document_lib.renderDocumentPDF(id, version);
					this.saveFile(document_pdf);
				}
				break;
			case 'template':
				if (Object.keys(this.props.buffer).length !== 0 && this.props.renderBuffer) {
					const template_pdf = await template_lib.renderTemplateBufferPDF(this.props.buffer.id);
					this.saveFile(template_pdf);
				} else {
					const template_pdf = await template_lib.renderTemplatePDF(id, version);
					this.saveFile(template_pdf);
				}
				break;
			default:
				break;
		}
	}

	async saveFile(res) {
		const link = document.createElement('a');
		link.href = res.link;
		link.download = 'doc.pdf';
		link.click();
	}

	render() {
		return(
			<div>
				<Dialog
					open={this.state.dialog_open}
					onClose={this.handleClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Select the format you would like to download:</DialogTitle>
					<DialogContent>
						<label>HTML</label>
						<Checkbox
							color="default"
							checked={this.state.htmlChecked}
							value="html"
							onChange={this.handleCheckbox}
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
						<label>PDF</label>
						<Checkbox
							color="default"
							checked={this.state.pdfChecked}
							value="pdf"
							onChange={this.handleCheckbox}
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="secondary">
						Cancel
						</Button>
						<Button onClick={this.submitDownload} color="primary">
						Download
						</Button>
					</DialogActions>
				</Dialog>

			</div>
		)
	}
}

export default DialogBox;
