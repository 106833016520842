/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/versions_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;

/*
 * Well known UIDs
 */
const system_user_id = '@system';
const system_everyone_id = '@all';

function list_generate_options_from_query(query) {
	const options = {};

	if (!query) {
		return(options);
	}

	if (query.fields) {
		options.fields = query.fields.split(',');
	}

	if (query.count) {
		options.count = Number(query.count);
	}

	if (query.filter) {
		/*
		 * Parse the filter into our internal representation
		 */
		options.filter = query.filter;
	}

	if (query.order) {
		options.order = query.order;
	}

	if (query.orderby) {
		options.orderby = query.orderby;
	}

	if (query.after) {
		options.after = query.after;
	}

	return(options);
}

if (_testing) {
	_testing.list_generate_options_from_query = function () {
		let result;

		const query = {
			fields: "A, B, C",
			count: "10",
			filter: "metadata = true"
		}

		result = list_generate_options_from_query();

		/* istanbul ignore if */
		if (result.fields) {
			throw new Error("Expected an empty field by found some");
		}

		result = list_generate_options_from_query(query);

		/* istanbul ignore if */
		if (!(Array.isArray(result.fields) && result.fields.length === 3 && result.count === 10 && result.filter === 'metadata = true')) {
			throw new Error(`Expected exact values for the query but found some mismatching elements: ${JSON.stringify(result)}`);
		}

		return true;
	}
}

function list_generate_query_from_options(options = {}) {
	const query_parts = [];

	if (options.fields !== undefined && options.fields.length > 0) {
		const encoded_fields = options.fields.map(function(field) {
			return(encodeURIComponent(field));

		});
		query_parts.push(`fields=${encoded_fields.join(',')}`);
	}

	if (options.count !== undefined) {
		query_parts.push(`count=${encodeURIComponent(options.count)}`);
	}

	if (options.filter !== undefined && options.filter !== '') {
		query_parts.push(`filter=${encodeURIComponent(options.filter)}`);
	}

	if (options.order) {
		query_parts.push(`order=${encodeURIComponent(options.order)}`);
	}

	if (options.orderby) {
		query_parts.push(`orderby=${encodeURIComponent(options.orderby)}`);
	}

	if (options.after !== undefined && options.after !== '') {
		query_parts.push(`after=${encodeURIComponent(options.after)}`);
	}

	if (query_parts.length === 0) {
		return('');
	}

	return(`?${query_parts.join('&')}`);
}

if (_testing) {
	_testing.list_generate_query_from_options = function() {
		const checks = [
			{
				options: {
					fields: ['a', 'b']
				},
				output: '?fields=a,b'
			},
			{
				options: {
					count: 10
				},
				output: '?count=10'
			},
			{
				options: {
					filter: 'metadata.toplevel=true'
				},
				output: '?filter=metadata.toplevel%3Dtrue'
			},
			{
				options: {
					filter: undefined,
					count: undefined,
					fields: []
				},
				output: ''
			},
			{
				options: {
					fields: ['a', 'b'],
					count: 10,
					filter: 'metadata.toplevel=true'
				}
			},
			{
				options: {
					order: 'asc',
					orderby: 'date',
					filter: 'metadata.toplevel=true'
				}
			}
		];

		for (const check of checks) {
			if (check.output !== undefined) {
				const check_output = list_generate_query_from_options(check.options);

				/* istanbul ignore if */
				if (check_output !== check.output) {
					throw(new Error(`Expected to get ${check.output} but got ${check_output} for options ${JSON.stringify(check.options)}`));
				}
			} else {
				const check_query_string = list_generate_query_from_options({
					...check.options,
					_url_encode: false
				});

				/*
				 * Convery the query string into what we would normally get from
				 * the API as the query context.
				 */
				const check_query = (function() {
					const clean = check_query_string.slice(1);
					const parts = clean.split('&');
					const retval = {};
					for (const part of parts) {
						const part_info = part.split('=');
						const key = part_info[0];
						const value = decodeURIComponent(part_info[1]);

						retval[key] = value;
					}

					return(retval);
				})();

				const check_options = list_generate_options_from_query(check_query);

				kaialpha.lib.testing_utils.assert.object_equals(check.options, check_options);
			}
		}

		return(true);
	}
}

/*
 * Return value: list
 */
function _verify_permissions_expand_user_id_list(user_id_list, item_permissions) {
	const user_id_list_expanded = [];

	if (!user_id_list) {
		return(user_id_list_expanded);
	}

	for (const user_id of user_id_list) {
		if (user_id === system_user_id || user_id === system_everyone_id) {
			user_id_list_expanded.push(user_id);

			continue;
		}

		if (user_id === undefined || user_id === null || user_id.slice === undefined) {
			continue;
		}

		if (user_id.slice(0, 1) === '@') {
			const type = user_id.split(':')[0].slice(1);

			switch (type) {
				case 'role':
					if (!item_permissions.roles) {
						break;
					}

					{
						const role_name = user_id.split(':').slice(1).join(':');
						const role_users = item_permissions.roles[role_name];
						const sub_user_id_list = _verify_permissions_expand_user_id_list(role_users, item_permissions);
						user_id_list_expanded.push(...sub_user_id_list);
					}
					break;
				case 'group':
					/** XXX:TODO **/
					{
						// eslint-disable-next-line
						const group_name = user_id.split(':').slice(1).join(':');
						kaialpha.log.debug(`[WARNING] Groups are not yet supported when processing ${user_id}`)
					}
					break;
				default:
					throw(new Error(`Unknown ACL Type: ${type} in ${user_id}`));
			}

			continue;
		}

		user_id_list_expanded.push(user_id);
	}

	return(user_id_list_expanded);
}

/*
 * Compare two given levels to see if the former is included in the latter
 *
 * Return value: boolean
 */
function _verify_permissions_compare_level(level, want) {
	if (level === want) {
		return(true);
	}

	if (level === 'write') {
		return(true);
	}

	return(false);
}

if (_testing) {
	_testing._verify_permissions_compare_level = function() {
		const table = [
			{l: 'write', w: 'write', r: true},
			{l: 'write', w: 'read', r: true},
			{l: 'read', w: 'write', r: false},
			{l: 'write:comment', w: 'write', r: false},
			{l: 'write', w: 'write:comment', r: true},
			{l: 'write:comment', w: 'write:comment', r: true},
			{l: 'write:body', w: 'write:comment', r: false},
		]

		for (const item of table) {
			const r = _verify_permissions_compare_level(item.l, item.w);

			/* istanbul ignore if */
			if (r !== item.r) {
				throw(new Error(`When comparing level="${item.l}" and want="${item.w}" got ${r} instead of ${item.r}`));
			}
		}

		return(true);
	}

	_testing._verify_permissions_expand_user_id_list_without_users = function () {
		const result = _verify_permissions_expand_user_id_list();

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error("Result without user list should not have any data");
		}

		return true;
	}

	_testing._verify_permissions_expand_user_id_list_system_user = function () {

		const result = _verify_permissions_expand_user_id_list(['@system']);

		/* istanbul ignore if */
		if (result[0] !== '@system') {
			throw new Error("Shoud have @system but found" + result[0]);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_all_user = function () {

		const result = _verify_permissions_expand_user_id_list(['@all']);

		/* istanbul ignore if */
		if (result[0] !== '@all') {
			throw new Error("Shoud have @all but found" + result[0]);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_without_type = function () {

		try {
			_verify_permissions_expand_user_id_list(["@something"]);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("Should have thrown not found error, but passed the funciton");
	}

	_testing._verify_permissions_expand_user_id_list_without_role = function () {
		const result = _verify_permissions_expand_user_id_list(["@role"], { 'roles': undefined });
		/* istanbul ignore if*/
		if (result.length > 0) {
			throw new Error(`Should not have any items in result as roles are undefined but found ${result}`);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_without_user_id_list = function () {
		const result = _verify_permissions_expand_user_id_list([undefined], { 'roles': undefined });
		/* istanbul ignore if*/
		if (result.length > 0) {
			throw new Error(`Should not have any items in result as roles are undefined but found ${result}`);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_group_type = function () {
		const result = _verify_permissions_expand_user_id_list(['@group'], { 'group': 'csk' });
		/* istanbul ignore if*/
		if (result.length > 0) {
			throw new Error(`Should not have any items in result as roles are undefined but found ${result}`);
		}
		return true;
	}
}

async function _canonicalize_single_permissions(permissions) {
	if (permissions === undefined || permissions === null) {
		return(permissions);
	}

	permissions = kaialpha.lib.object_utils.copy_object(permissions);
	const orig_permissions = kaialpha.lib.object_utils.copy_object(permissions);

	if (permissions['owners'] !== undefined) {
		permissions['owners'] = _verify_permissions_expand_user_id_list(permissions['owners'], orig_permissions);
	}

	for (const part_type of ['acl']) {
		let part = permissions[part_type];

		if (part === undefined) {
			part = {};
		}

		const keys = Object.keys(part);

		for (const key of keys) {
			let subpart = _verify_permissions_expand_user_id_list(part[key], orig_permissions);

			if (subpart === undefined) {
				subpart = [];
			}

			const subpart_merged = [...(new Set([...subpart]))];

			if (permissions[part_type] === undefined) {
				permissions[part_type] = {};
			}

			permissions[part_type][key] = subpart_merged;
		}
	}

	delete permissions['roles'];

	return(permissions);
}

async function canonicalize_permissions(permissions, options = {}) {
	options = {
		get_item_any_type: kaialpha.lib.general_utils.get_item_any_type,
		_state: {
			seen: {}
		},
		...options
	};

	if (permissions === undefined || permissions === null) {
		return(permissions);
	}

	if (permissions.inherit_from === undefined || permissions.inherit_from === null) {
		return(await _canonicalize_single_permissions(permissions));
	}

	if (Array.isArray(permissions.inherit_from) && permissions.inherit_from.length === 0) {
		return(await _canonicalize_single_permissions(permissions));
	}

	permissions = kaialpha.lib.object_utils.copy_object(permissions);

	let base_permissions;
	for (const item of permissions.inherit_from) {
		base_permissions = permissions;

		const type = item.type;
		const id = item.id;

		if (type === undefined || id === undefined) {
			throw(new Error(`Error while expanding permissions, invalid inherit_from entry: ${JSON.stringify(item)}`));
		}

		/*
		 * Avoid loops by avoiding reprocessing the same item
		 */
		const item_key = ['type', type, 'id', id].join('|');
		if (options._state.seen[item_key] === true) {
			continue;
		}
		options._state.seen[item_key] = true;

		const inherit_item = await options.get_item_any_type(system_user_id, type, id, 'HEAD', {
			fields: ['permissions']
		});

		if (inherit_item === undefined) {
			throw(new Error(`Error while expanding permissions, inherit_from entry: ${JSON.stringify(item)} points to something that does not exist`));
		}

		const inherit_permissions = await canonicalize_permissions(inherit_item.permissions, options);
		if (inherit_permissions === null || inherit_permissions === undefined) {
			continue;
		}

		permissions = {
			owners: [...(new Set([...base_permissions.owners, ...inherit_permissions.owners]))]
		};

		const item_a = base_permissions;
		const item_b = inherit_permissions;
		for (const part_type of ['acl']) {
			let part_a = item_a[part_type];
			let part_b = item_b[part_type];

			if (part_a === undefined && part_b === undefined) {
				continue;
			}

			if (part_a === undefined) {
				part_a = {};
			}

			if (part_b === undefined) {
				part_b = {};
			}

			const keys = new Set([...Object.keys(part_a), ...Object.keys(part_b)]);

			for (const key of keys) {
				let subpart_a = _verify_permissions_expand_user_id_list(part_a[key], item_a);
				let subpart_b = _verify_permissions_expand_user_id_list(part_b[key], item_b);

				if (subpart_a === undefined) {
					subpart_a = [];
				}

				if (subpart_b === undefined) {
					subpart_b = [];
				}

				const subpart_merged = [...(new Set([...subpart_a, ...subpart_b]))];

				if (permissions[part_type] === undefined) {
					permissions[part_type] = {};
				}

				permissions[part_type][key] = subpart_merged;
			}
		}

	}

	delete permissions['roles'];
	delete permissions['inherit_from'];

	return(permissions);
}

/*
 * Determine if a given user ID has the requested level of access
 * based on the permission set of a document
 *
 * There are three levels of access: read, write, owner
 *
 * Owner has all the permissions of "write", plus the ability to change
 * owner and change permissions.
 *
 * Write has all the permissions of "read", plus the ability to create
 * new versions of the document (or, if scoped, that sub-document).
 *
 * Read can read the document, and ALL past versions (or, if scoped
 * a specific sub-tree).
 *
 * Scoped rules look like <scope>:<level>, where unscoped rules look like
 * <level>.
 *
 * Additionally, symbolic roles, which are document-specified aliases for
 * many user or group IDs, can be specified as "roles", and referenced by
 * "@role:<role>" within the "acl" portion of the permissions document.
 *
 * Group IDs may be specified as @group:<group_id>
 *
 * Example permissions document:
 *       {
 *           "owners": ["uid1", "gid1"],
 *           "roles": {
 *               "friends": ["uid3"],
 *               "reviewer": ["uid2"],
 *               "author": ["@group:gid"]
 *           },
 *           "acl" {
 *               "read": ["@role:friends"],
 *               "write": ["@role:author"],
 *               "comments:write": ["@role:reviewer"]
 *           }
 *       }
 *
 * Return value: boolean
 */
async function verify_permissions(user_id, requested_permissions, item_permissions, options = {}) {
	/*
	 * By default, deny access -- but if ACL enforcement is disabled, then
	 * change this default to allow.
	 */
	let default_disposition = false;
	if (kaialpha.configuration.disable_acl_enforcement === true) {
		default_disposition = true;
	}
	options = Object.assign({
		default_disposition
	}, options);

	kaialpha.lib.debug.log('acl', 'Checking to see if User ID', user_id, 'has permission', requested_permissions, 'against permissions list:', item_permissions);

	/*
	 * The system user always has access to everything.
	 */
	if (user_id === system_user_id) {
		return(true);
	}

	/*
	 * Expand item_permissions by expansion from inherited ACLs
	 */
	item_permissions = await canonicalize_permissions(item_permissions, options);

	/*
	 * Validate that the requested permissions are valid.
	 *
	 * They must be one of read, write, owner, or scoped read/write.
	 */
	/** XXX:TODO **/

	/*
	 * If the user belongs to a group of Administrators, override the ACL
	 * and allow full control
	 */
	/** XXX:TODO **/

	/*
	 * If the permissions are missing, nobody (except system) has any
	 * access.
	 */
	if (!item_permissions) {
		if (options.default_disposition) {
			kaialpha.lib.debug.log('acl', `[WARNING] ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access (no permissions document)`);
		}

		return(options.default_disposition);
	}

	/*
	 * The owner of a document may make any changes.
	 */
	if (item_permissions.owners instanceof Array) {
		const owners = item_permissions.owners;
		for (const owner of owners) {
			if (owner === user_id) {
				return(true);
			}
		}
	}

	/*
	 * No other users may make ACL changes, only read/write or scoped read/write
	 */
	if (requested_permissions === 'owner') {
		if (options.default_disposition) {
			kaialpha.lib.debug.log('acl', `[WARNING] ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access based on ${JSON.stringify(item_permissions)} (not owner)`);
		}

		return(options.default_disposition);
	}

	/*
	 * Process the ACL
	 */
	/**
	 ** If the ACL is empty, return the default disposition immediately
	 **/
	if (!item_permissions.acl) {
		if (options.default_disposition) {
			kaialpha.lib.debug.log('acl', `[WARNING] ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access based on ${JSON.stringify(item_permissions)} (no ACL)`);
		}

		return(options.default_disposition);
	}

	/**
	 ** Process all the relevant ACL entries
	 **/
	for (const level in item_permissions.acl) {
		/***
		 *** If this ACL level won't grant the requested
		 *** permissions then we do not need to process it.
		 ***/
		if (!_verify_permissions_compare_level(level, requested_permissions)) {
			continue;
		}

		/***
		 *** Now that we know this rule will grant the requested
		 *** access, determine if the user is eligible for it.
		 ***
		 *** Get the list of users and expand it.
		 ***/
		const user_id_list = item_permissions.acl[level];

		if (user_id_list.includes(user_id)) {
			return(true);
		}

		/***
		 *** If the set of users includes "Everyone", then any logged in
		 *** user will match.
		 ***/
		if (user_id_list.includes(system_everyone_id)) {
			return(true);
		}
	}

	if (options.default_disposition) {
		kaialpha.lib.debug.log('acl', `[WARNING] ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access based on ${JSON.stringify(item_permissions)} (not allowed)`);
	}

	return(options.default_disposition);
}

if (_testing) {
	const uuid = require('uuid');

	_testing.verify_permissions = async function() {
		const table = [
			{u: 'user1', w: 'write', d: {
				owners: ['user1']
			}, r: true},
			{u: 'user1', w: 'read', d: {
				owners: ['user1']
			}, r: true},
			{u: 'user2', w: 'write', d: {
				owners: ['user1']
			}, r: false},
			{u: 'user2', w: 'write', d: {
				owners: ['user1'],
				acl: {
					"write": ['user2']
				}
			}, r: true},
			{u: 'user2', w: 'write', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2']
				},
				acl: {
					"write": ['@role:authors']
				}
			}, r: true},
			{u: 'user2', w: 'read', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2']
				},
				acl: {
					"write": ['@role:authors']
				}
			}, r: true},
			{u: 'user3', w: 'read', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2']
				},
				acl: {
					"write": ['@role:authors']
				}
			}, r: false},
			{u: 'user3', w: 'read', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2'],
					reviewers: ['user3']
				},
				acl: {
					"write": ['@role:authors'],
					"write:comments": ['@role:reviewers'],
					"read": ['@role:reviewers']
				}
			}, r: true},
			{u: 'user4', w: 'read', d: {
				owners: ['demo-user-id-1'],
				acl: {
					read: ['@all']
				}
			}, r: true},
			{u: '@system', w: 'null', d: {
				owners: ['demo-user-id-1'],
				acl: {
					read: ['@all']
				}
			}, r: true},
			{ u: 'user', w: 'null', d: undefined, r: false },
			{u: 'user-5', w: 'owner', d: {
				owners: ['demo-user-id-1'],
				acl: {
					read: ['@all']
				}
			}, r: false},
		]

		for (const item of table) {
			const r = await verify_permissions(item.u, item.w, item.d, { default_disposition: false });

			/* istanbul ignore if */
			if (r !== item.r) {
				throw(new Error(`When validating that user "${item.u}" can get "${item.w}" access to document with ACL ${JSON.stringify(item.d)}, got ${r} instead of ${item.r}`));
			}
		}

		return(true);
	}

	_testing.verify_permissions_with_default_disposition = async function () {
		const permissions = {
			owners: ['user-6'], acl: {
				read: ['@all']
			}
		};
		const result = await verify_permissions('user-7', 'owner', permissions, { 'default_disposition': true });

		/* istanbul ignore if */
		if (!result) {
			throw new Error(`While validating with default_disposition true it verification returned ${result} but needed true`);
		}
		return true;
	};

	_testing.verify_permissions_with_default_disposition_2 = async function () {
		const result = await verify_permissions('user-6', 'owner', undefined, { 'default_disposition': true });

		/* istanbul ignore if */
		if (!result) {
			throw new Error(`While validating with default_disposition true it verification returned ${result} but needed true`);
		}
		return true;
	};

	_testing.verify_permissions_with_default_disposition_3 = async function () {
		const permissions = {
			owners: ['user-6']
		};

		const result = await verify_permissions('user-7', 'read', permissions, { 'default_disposition': true });

		/* istanbul ignore if */
		if (!result) {
			throw new Error(`While validating with default_disposition true it verification returned ${result} but needed true`);
		}
		return true;
	};

	_testing.verify_permissions_expand = async function () {
		const permissions = {
			'type_document_id_test1': {
				owners: ['user6'],
				inherit_from: [
					{
						type: 'document',
						id: 'test2'
					}
				]
			},
			'type_document_id_test2': {
				owners: ['user7']
			},
			'type_document_id_test3': {
				owners: ['user1'],
				roles: {
					authors: ['user2'],
					reviewers: ['user10', 'user11'],
					testing: ['user12']
				},
				acl: {
					"write": ['@role:authors'],
					"read": ['@role:testing']
				}
			},
			'type_document_id_test4': {
				owners: ['user5'],
				roles: {
					authors: ['user3'],
					reviewers: ['user10']
				},
				acl: {
					"write": ['@role:authors'],
					"read": ['@role:reviewers']
				},
				inherit_from: [
					{id: 'test3', type: 'document'},
					{id: 'test2', type: 'document'}
				]
			},
			'type_document_id_test4b': {
				owners: ['user5'],
				roles: {
					authors: ['user3', '@role:mergetest'],
					mergetest: ['user11']
				},
				acl: {
					"write": ['@role:authors'],
				}
			},
			'type_document_id_test5': {
				owners: ['user20'],
				roles: {
					authors: ['user3', '@role:testing'],
					reviewers: ['user10'],
					testing: ['user21']
				},
				acl: {
					"write": ['@role:authors'],
					"read": ['@role:reviewers']
				},
				inherit_from: [
					{id: 'test4', type: 'document'},
					{id: 'test2', type: 'document'},
				]
			},
			'type_document_id_test6': {
				owners: ['user60'],
				roles: {
					mergetest: ['user61']
				},
				acl: {
					"read": ['@role:mergetest']
				},
				inherit_from: [
					{id: 'test4b', type: 'document'}
				]
			}
		};

		const checks = [
			{ id: 'type_document_id_test1', w: 'owner', u: 'user7', r: true },
			{ id: 'type_document_id_test2', w: 'owner', u: 'user7', r: true },
			{ id: 'type_document_id_test1', w: 'owner', u: 'user6', r: true },
			{ id: 'type_document_id_test2', w: 'owner', u: 'user6', r: false },
			{ id: 'type_document_id_test3', w: 'owner', u: 'user1', r: true },
			{ id: 'type_document_id_test3', w: 'owner', u: 'user2', r: false },
			{ id: 'type_document_id_test3', w: 'write', u: 'user2', r: true },
			{ id: 'type_document_id_test3', w: 'write', u: 'user3', r: false },
			{ id: 'type_document_id_test3', w: 'read', u: 'user12', r: true },
			{ id: 'type_document_id_test3', w: 'read', u: 'user10', r: false },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user1', r: true },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user5', r: true },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user2', r: false },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user7', r: true },
			{ id: 'type_document_id_test4', w: 'write', u: 'user2', r: true },
			{ id: 'type_document_id_test4', w: 'write', u: 'user3', r: true },
			{ id: 'type_document_id_test4', w: 'write', u: 'user4', r: false },
			{ id: 'type_document_id_test4', w: 'write', u: 'user4', r: false },
			{ id: 'type_document_id_test4', w: 'read', u: 'user12', r: true },
			{ id: 'type_document_id_test4', w: 'read', u: 'user10', r: true },
			{ id: 'type_document_id_test5', w: 'owner', u: 'user20', r: true },
			{ id: 'type_document_id_test5', w: 'owner', u: 'user1', r: true },
			{ id: 'type_document_id_test5', w: 'write', u: 'user21', r: true },
			{ id: 'type_document_id_test5', w: 'write', u: 'user12', r: false },
			{ id: 'type_document_id_test5', w: 'read', u: 'user12', r: true },
			{ id: 'type_document_id_test6', w: 'owner', u: 'user60', r: true },
			{ id: 'type_document_id_test6', w: 'owner', u: 'user5', r: true },
			{ id: 'type_document_id_test6', w: 'write', u: 'user61', r: false },
			{ id: 'type_document_id_test6', w: 'read', u: 'user61', r: true },
			{ id: 'type_document_id_test6', w: 'write', u: 'user3', r: true },
			{ id: 'type_document_id_test6', w: 'write', u: 'user11', r: true },
		];

		for (const check of checks) {
			const permissions_document = permissions[check.id];
			const result = await verify_permissions(check.u, check.w, permissions_document, {
				get_item_any_type: async function(user_id, type, id, version) {
					if (version !== 'HEAD' && version !== undefined) {
						throw(new Error('We only support latest version'));
					}

					const index = ['type', type, 'id', id].join('_');
					const object = {
						name: 'dummy',
						id: id,
						version: uuid.v4(),
						permissions: permissions[index]
					};
					return(object);
				},
				default_disposition: false
			});

			/* istanbul ignore if */
			if (result !== check.r) {
				throw new Error(`While validating with expansion verification of ${JSON.stringify(check)} returned ${result} but needed ${check.r}`);
			}
		}

		return true;
	};
}

const _to_export_auto = {
	list_generate_options_from_query,
	list_generate_query_from_options,
	canonicalize_permissions,
	verify_permissions,
	special_uids: {
		system_user_id,
		system_everyone_id
	},
	_testing
}
export default _to_export_auto;
