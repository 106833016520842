import React from 'react';
import './Profile.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import user from '../../api/user';

import {
	ButtonDialog,
	Prompt,
	TextField
} from '../../lib/ui';

class Profile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			user_id: '',
			display_name: '',
			user_name: '',
			groups: [],
			roles: []
		};
		this.handleClick = this.handleClick.bind(this);
	}

	async componentDidMount() {
		document.getElementById("role-info").style.display = "block";
		document.getElementById("group-info").style.display = "none";
		try {
			const res = await this.fetchProfile();
			this.storeUserInfo(res);
		} catch (err) {
			console.error(err);
		}
	}

	fetchProfile = user.get_user_info;

	storeUserInfo(res) {
		this.setState({ user_id: res["id"]} );
		this.setState({ display_name: res["display_name"]} );
		this.setState({ user_name: res["name"]} );
		// this.setState({ roles: res["system_roles"]} );
		// this.setState({ groups: res["groups"]} );
	}

	handleClick(event) {
		if (event === "roles") {
			document.getElementById("role-info").style.display = "block";
			document.getElementById("group-info").style.display = "none";
		}
		if (event === "groups") {
			document.getElementById("role-info").style.display = "none";
			document.getElementById("group-info").style.display = "block";
		}
	}

	get buffer_save_freq() {
		const current_freq = user.get_user_pref(undefined, 'user/buffer_save_freq');

		return(current_freq);
	}

	set buffer_save_freq(new_value) {
		const new_value_sanitized = Number(new_value);
		user.set_user_prefs(undefined, {
			'user/buffer_save_freq': new_value_sanitized
		});

		/* XXX:TODO: I guess really the buffer frequency could be part of this components state... */
		this.forceUpdate();
	}

	render() {
		let no_groups_message;
		let no_roles_message;
		let display_groups;
		let display_roles;
		if (this.state.groups.length === 0) {
			no_groups_message = <p>You are not a member of any groups.</p>;
		} else {
			display_groups = <div>
				{this.state.groups.map((group) =>
					<p>{group}</p>
				)}
			</div>;
		}

		if (this.state.roles.length === 0) {
			no_roles_message = <p>You do not have any roles.</p>
		} else {
			display_roles = <div>
				{this.state.roles.map((role) =>
					<p>{role}</p>
				)}
			</div>;
		}

		return(
			<>
				<div className="profile">
					<div className="user-info">
						<p className="user-name">{this.state.display_name}</p>
						<div className="user-img"></div>
						<p className="user-email">{this.state.user_name}</p>
					</div>
					<div className="user-system-info">
						<AppBar position="static">
							<Tabs variant="fullWidth" aria-label="nav tabs example">
								<Tab label="Roles" onClick={() => this.handleClick("roles")} />
								<Tab label="Groups" onClick={() => this.handleClick("groups")} />
							</Tabs>
						</AppBar>
						<div className="group-info" id="group-info">
							{no_groups_message}
							{display_groups}
						</div>
						<div className="role-info" id="role-info">
							{no_roles_message}
							{display_roles}
						</div>
					</div>
				</div>
				<ButtonDialog
					title="Set Buffer Save Frequency"
					onClose={(save) => {
						if (save !== true) {
							return;
						}

						this.buffer_save_freq = this.__new_buffer_save_freq;
						delete this['__new_buffer_save_freq'];
					}}
				>
					<Prompt.Group>
						<Prompt.Item title="New Frequency:"><TextField defaultValue={this.buffer_save_freq} onBlur={(event) => {
							this.__new_buffer_save_freq = event.target.value;
						}}/></Prompt.Item>
					</Prompt.Group>
				</ButtonDialog>
			</>
		);
	}
}

export default Profile;
