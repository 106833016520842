import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '../../lib/ui';

class ButtonDialog extends React.Component {
	constructor(...args) {
		super(...args);

		this.state = {
			dialog_open: false
		};
	}

	render() {
		let main_button_title = this.props.button_title;
		if (main_button_title === undefined) {
			main_button_title = this.props.title;
		}

		let button_info = this.props.buttons;
		if (button_info === undefined) {
			button_info = {
				"Cancel": () => {
					if (this.props.onClose) {
						this.props.onClose(false);
					}
				},
				"Okay": () => {
					if (this.props.onClose) {
						this.props.onClose(true);
					}
				}
			};
		}

		const buttons = [];
		const final_title = Object.keys(button_info).slice(-1)[0];
		for (const button_title of Object.keys(button_info)) {
			const button_action = button_info[button_title];
			let sub_button_color = "secondary";

			if (button_title === final_title) {
				sub_button_color = "primary";
			}

			buttons.push(<Button key={`button_${button_title}`} onClick={() => {
				this.setState({
					dialog_open: false
				});

				button_action();
			}} color={sub_button_color}>{button_title}</Button>);
		}

		let button_color = "secondary";
		if (this.props.button_color !== undefined) {
			button_color = this.props.button_color;
		}

		return(
			<>
				<Dialog
					open={this.state.dialog_open}
					onClose={this.onClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
					<DialogContent>{this.props.children}</DialogContent>
					<DialogActions>{buttons}</DialogActions>
				</Dialog>
				<Button
					color={button_color}
					disabled={this.props.disabled}
					onClick={() => {
						this.setState({
							dialog_open: true
						});
					}}
				>{main_button_title}</Button>
			</>
		);
	}
}

export default ButtonDialog;
