/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/object_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;

/*
 * Duplicate an object, this prevents modifications from affecting the source
 */
function copy_object(object) {
	/*
	 * If the value passed in isn't derived from an Object, return it
	 * without trying to serialize/deserialize it.
	 */
	if (!(object instanceof Object)) {
		return(object);
	}

	return(JSON.parse(JSON.stringify(object)));
}

if (_testing) {
	_testing.copy_object = function () {
		const obj = { testing: 'something' };

		let result;

		result = copy_object(obj);

		/* istanbul ignore if */
		if (obj === result) {
			throw new Error("Both objects should not be same but found a same reference ");
		}

		result = copy_object(["Something"]);

		/* istanbul ignore if */
		if (!Array.isArray(result)) {
			throw new Error("Result should have some have an array but found nothing");
		}

		return true;
	}
}

const _to_export_auto = {
	copy_object,
	_testing
};
export default _to_export_auto;
