import React from 'react';
import { styles } from './NavigationSideBarStyle.js';
import user_lib from '../../api/user.js';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, List, ListItem, Avatar } from '@material-ui/core';
import { color_map, logo_large, logo_small } from '../../assets/app_assets.js';
import {
	Icon
} from '../../lib/ui';
import kaialpha from '../../lib/kaialpha';

class NavigationSideBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userName: '',
			toggleLeftCollapse: true,
			views: {
				"Dashboard": "/",
				"Profile": "/activity/profile",
				"Templates": "/activity/templateselector",
				"Documents": "/activity/docselector",
				"Tasks": "pathToTasks",
				"Projects": "pathToProjects",
				"Folders": "/activity/folders",
			}
		}

		this.colorStateMap = {
			textColor: color_map.navigationSideBarTextColor,
			backgroundColor: color_map.navigationSideBarBackgroundColor
		}

		this.iconStyle = {
			color: this.colorStateMap.textColor
		}
	}

	async componentDidMount() {
		const userInfo = await user_lib.get_user_info();
		let name = userInfo.display_name;

		if (!name) {
			name = userInfo.name;
		}

		this.setState({ userName: name });
	}


	getRouteItems(sideBarRouteElement) {
		const { classes } = this.props;
		const path = sideBarRouteElement[1];
		const name = sideBarRouteElement[0];
		let displayName = <span style={{ marginLeft: '1rem' }}>{name}</span>;

		const icon = <Icon forAction={name} style={this.iconStyle}/>;

		if (!this.props.open) {
			displayName = null;
		}
		return(
			<ListItem className={classes.listItem} key={name}>
				<Link
					className={classes.customLink}
					to={path}
				>
					{icon}
					{displayName}
				</Link>
			</ListItem>
		);
	}

	getLogo() {
		const { classes } = this.props;
		const style = { height: '1.5rem' };
		let iconSource = logo_small;
		let altText = 'A';

		if (this.state.userName) {
			altText = this.state.userName.charAt(0).toUpperCase();
		}

		if (this.props.open) {
			style.height = '3rem';
			iconSource = logo_large;
		}

		return(
			<div className={classes.logoContainer}>
				<img alt={altText} style={style} src={iconSource}></img>
			</div>
		);
	}

	handleToggle() {
		this.props.toggleCallBack();
	}

	async logout_user() {
		const logout_info = await user_lib.logout_user();
		window.location.replace(logout_info.endpoint);
	}

	render() {
		const { classes } = this.props;
		let avatarName = '';
		let avatarClassName = classes.avatarSmall;
		let logoutSpan = '';
		let drawerPaper = classes.drawerPaperClose;
		let collapseDirection = 'expand';

		if (this.props.open) {
			logoutSpan = <span style={{ marginLeft: '1rem' }}>Logout</span>;
			avatarClassName = classes.avatarLarge;
			avatarName = <p className={classes.avatarText}>{this.state.userName}</p>;
			drawerPaper = classes.drawerPaperOpen;
			collapseDirection = 'collapse';
		}
		const collapseIcon = <Icon forUI={`${collapseDirection}-left`} onClick={() => this.handleToggle()} className={classes.toggleIcon} />;

		let versionInfoString = `KaiAlpha v${kaialpha.version.string}`;
		if (this.state.verboseVersionInformation === true) {
			versionInfoString = kaialpha.version.commit;
		}

		return(
			<Drawer
				variant="permanent"
				classes={{ paper: drawerPaper }}
			>
				<div className={classes.drawerContainer}>
					{collapseIcon}
				</div>

				{this.getLogo()}

				<div className={classes.avatarContainer}>
					<Avatar className={avatarClassName} />
					{avatarName}
				</div>

				<List className={classes.routeContainer}>
					{Object.entries(this.state.views).map(sideBarRouteElement => this.getRouteItems(sideBarRouteElement))}
					<ListItem onClick={this.logout_user} className={[classes.listItem, classes.customLink].join(' ')}>
						<Icon forAction='logout'/>
						{logoutSpan}
					</ListItem>
				</List>

				<List style={{ position: 'absolute', bottom: '1.1em', width: '100%' }}>
					<ListItem
						className={[classes.listItem, classes.customLink].join(' ')}
						onClick={() => {
							this.setState((prevState) => {
								return({
									verboseVersionInformation: !prevState.verboseVersionInformation
								});
							});
						}}
					>
						<span style={{ fontSize: '0.5em' }}>{versionInfoString}</span>
					</ListItem>
				</List>
			</Drawer>
		)
	}

}

export default withStyles(styles)(NavigationSideBar);
