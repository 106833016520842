import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Button, ButtonDialog, Prompt, CircularProgress, TextField } from '../../../lib/ui';
import ACLEditor from '../../ACLEditor';
import MaterialTable from '../../MaterialTableStyled';
import ListsUpload from '../Upload';

import { titleCase } from "title-case";

import list from '../../../api/list.js';
import object_utils from '../../../lib/utils/object_utils.js';

class ListsEditor extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.state = {
			list: {},
			save_needed: false,
			loaded: false,
			save_in_progress: false
		};

		this.mounted = false;
		this.load_list();
	}

	async load_list() {
		let list_value;
		try {
			list_value = await list.get_user_list(null, this.type, this.id, this.version);
		} catch (load_error) {
			console.error('Failed to load list:', load_error);
			list_value = {};
		}

		const new_state = {
			save_needed: false,
			save_in_progress: false,
			loaded: true,
			list: list_value
		};

		if (this.mounted) {
			this.setState(new_state);
		} else {
			this.state = {
				...this.state,
				...new_state
			};
		}
	}

	componentDidMount() {
		this.mounted = true;
	}

	_get_param(type) {
		let value;

		if (this.state && this.state.list) {
			value = this.state.list[type];
		}

		if (value === undefined) {
			value = this.props[type];
		}

		if (value === undefined) {
			if (this.props.match && this.props.match.params) {
				value = this.props.match.params[type];
			}
		}

		return(value);
	}

	get id() {
		return(this._get_param('id'));
	}

	get version() {
		return(this._get_param('version'));
	}

	set version(value) {
		this.setState(function(state) {
			return({
				list: {
					...state.list,
					version: value
				}
			});
		});
	}

	get type() {
		return(this._get_param('type'));
	}

	get name() {
		const name = this._get_param('name');
		if (name === undefined) {
			return('Unknown');
		}

		return(name);
	}

	set name(value) {
		if (!this.state.list) {
			return;
		}

		this.setState(function(state) {
			return({
				list: {
					...state.list,
					name: value
				}
			});
		});
	}

	_filter_row(entries, row) {
		const new_entries = entries.filter(function(check_row) {
			if (check_row.key !== row.key) {
				return(true);
			}
			if (check_row.value !== row.value) {
				return(true);
			}
			return(false);
		});

		return(new_entries);
	}

	add_row(row) {
		this.new_changes();
		this.setState(function(state) {
			return({
				save_needed: true,
				list: {
					...state.list,
					entries: [
						...state.list.entries,
						row
					]
				}
			});
		});
	}

	update_row(row, old_row) {
		this.new_changes();
		this.setState((state) => {
			const entries = object_utils.copy_object(state.list.entries);
			const existing_row = entries.find(function(check_row) {
				if (check_row.key !== old_row.key) {
					return(false);
				}
				if (check_row.value !== old_row.value) {
					return(false);
				}
				return(true);
			});

			existing_row.key = row.key;
			existing_row.value = row.value;

			return({
				save_needed: true,
				list: {
					...state.list,
					entries: entries
				}
			});
		});
	}

	remove_row(row) {
		this.new_changes();
		this.setState((state) => {
			return({
				save_needed: true,
				list: {
					...state.list,
					entries: this._filter_row(state.list.entries, row)
				}
			});
		});
	}

	update_permissions(new_permissions, new_id_info) {
		this.version = new_id_info.version;
		this.setState(function(state) {
			return({
				list: {
					...state.list,
					permissions: new_permissions
				}
			});
		});
	}


	save_start() {
		this.setState({
			save_in_progress: true
		});
	}

	save_complete(message, error) {
		this.setState({
			save_status: {
				message,
				error
			},
			save_in_progress: false
		});
	}

	new_changes() {
		this.setState({
			save_status: undefined
		});
	}

	async save_list() {
		let new_list_info;

		this.save_start();

		try {
			new_list_info = await list.update_user_list(null, this.type, this.id, this.state.list);
		} catch (save_error) {
			this.save_complete('Failed', save_error);
			return;
		}

		if (new_list_info) {
			this.version = new_list_info.version;
			this.setState({
				save_needed: false
			});
			this.save_complete('Success');
		} else {
			this.save_complete('Failed', 'Unknown error');
		}
	}

	load_acl_editor() {
		this.setState({
			acl_editor_visible: true
		});
	}

	async complete_acl_editor(new_permissions) {
		this.setState({
			acl_editor_visible: false
		});

		/* This indicates the "cancel" button was pressed */
		if (new_permissions === undefined) {
			return;
		}

		this.save_start();

		let new_info;
		try {
			new_info = await list.apply_diff_user_list(null, this.type, this.id, this.version, 'Updating ACL', {
				change: {
					permissions: new_permissions
				},
				delete: {
					permissions: null
				}
			});
		} catch (ignored_error) {
			this.save_complete('Failed', ignored_error);
			console.error('Saving ACL failed:', ignored_error);
		}

		if (new_info !== undefined) {
			this.save_complete('Success');

			this.update_permissions(new_permissions, new_info);
		}
	}

	rename_list(new_name) {
		this.new_changes();
		this.name = new_name;
		this.setState({
			save_needed: true
		});
	}

	render() {
		if (!this.state.loaded) {
			return(
				<div>
					<CircularProgress/> Loading...
				</div>
			);
		}

		let save_button_disabled = true;

		if (this.state.save_needed === true) {
			save_button_disabled = false;
		}

		if (this.state.save_in_progress) {
			save_button_disabled = true;
		}

		return(
			<>
				<ACLEditor
					visible={this.state.acl_editor_visible}
					permissions={object_utils.copy_object(this.state.list.permissions)}
					onSave={(...args) => { this.complete_acl_editor(...args); }}
					onCancel={(...args) => { this.complete_acl_editor(...args); }}
				/>
				<Grid container>
					<Grid item xs={1}/>
					<Grid item xs={10}>
						<Grid container>
							<Grid item xs={12}>
								{(() => {
									if (this.state.save_in_progress) {
										return(
											<span>
												<CircularProgress/> Saving...
											</span>
										);
									} else {
										if (this.state.save_status === undefined) {
											return;
										}

										let save_status_success;
										if (this.state.save_status.message && !this.state.save_status.error) {
											save_status_success = <div>{this.state.save_status.message}</div>;
										}

										let save_status_error;
										if (this.state.save_status.message && this.state.save_status.error) {
											save_status_error =
												<div>{this.state.save_status.message}: {String(this.state.save_status.error)}</div>
										}

										return(
											<span>
												{save_status_success}
												{save_status_error}
											</span>
										);
									}
								})()}
							</Grid>
							<Grid item xs={12}>
								<MaterialTable
									columns={[
										{title: 'Key', field: 'key'},
										{title: 'Value', field: 'value'},
									]}
									title={`${titleCase(this.type)} List Editor: ${this.name}`}
									data={object_utils.copy_object(this.state.list.entries)}
									editable={{
										onRowAdd: async (...args) => { this.add_row(...args); return(true); },
										onRowUpdate: async (...args) => { this.update_row(...args); return(true); },
										onRowDelete: async (...args) => { this.remove_row(...args); return(true); }
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Button color="primary" disabled={save_button_disabled} onClick={() => { this.save_list() }}>Save Changes</Button>
								<Button disabled={!this.state.list.permissions} onClick={() => { this.load_acl_editor() }}>ACL Editor</Button>
								<ListsUpload to={this.id} type={this.type} button_text='Upload New List'
									onStart={() => {
										this.save_start();
									}}
									onComplete={(new_info) => {
										if (new_info === undefined) {
											this.save_complete('Failed', 'Unknown error');
											return;
										}

										if (new_info.id !== this.id) {
											console.error('[ERROR] Internal consistency error, expected ID', this.id, 'but got:', new_info);
										}

										this.version = new_info.version;
										this.load_list();
									}}
								/>
								<ButtonDialog onClose={(save) => {
									if (save !== true) {
										return;
									}

									this.rename_list(this.rename_to);
									this.rename_to = undefined;
								}} title="Rename" button_title="Rename List">
									<Prompt.Group>
										<Prompt.Item title="From:">{this.name}</Prompt.Item>
										<Prompt.Item title="To:"><TextField onBlur={(event) => {
											this.rename_to = event.target.value;
										}}/></Prompt.Item>
									</Prompt.Group>
								</ButtonDialog>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1}/>
				</Grid>
			</>
		);
	}
}

export default ListsEditor;
