/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/date_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;

/*
 * Format a date for human consumption
 */
function format_date(date, options = {}) {
	options = {
		use_relative: true,
		...options
	};

	/*
	 * Normalize the input, in case it is passed in as a string
	 */
	date = new Date(date);

	if (options.use_relative) {
		const now = new Date();

		const difference = Math.ceil((now - date) / (1000 * 60 * 60 * 24));
		if (difference > 1) {
			return `${difference} days ago`
		} else if (difference < 0) {
			return `In ${-1 * difference} days`
		} else {
			return `Today ${date.toLocaleString().split(',')[1]}`;
		}
	}

	return(date.toISOString());
}

if (_testing) {
	_testing.format_date = function() {
		let result;

		result = format_date(Date.now() - (1000 * 60 * 30));

		/* istanbul ignore if */
		if (!result.match(/Today /)) {
			throw(new Error(`Test 1 failed: ${result} should have contained "Today"`));
		}

		result = format_date(Date.now() - (1000 * 60 * 60 * 48));
		/* istanbul ignore if */
		if (result.match(/Today /)) {
			throw(new Error(`Test 2 failed: ${result} should NOT have contained "Today"`));
		}

		result = format_date(Date.now() + (1000 * 60 * 30));
		/* istanbul ignore if */
		if (!result.match(/Today /)) {
			throw(new Error(`Test 3 failed: ${result} should have contained "Today"`));
		}

		result = format_date(Date.now() + (1000 * 60 * 60 * 48));
		/* istanbul ignore if */
		if (!result.match(/In [0-9] days/)) {
			throw(new Error(`Test 4 failed: ${result} should have contained "In X days"`));
		}

		const date_now = Date.now();
		result = format_date(date_now, { use_relative: false });
		/* istanbul ignore if */
		if (result !== new Date(date_now).toISOString()) {
			throw(new Error(`Test 5 failed: ${result} should have contained ${date_now.toISOString()}`));
		}

		return(true);
	};
}

function sort_dates_compare(version1, version2, direction, object_key) {
	let sortOrder = 1;
	if (direction === 'desc') {
		sortOrder = -1;
	}

	let version1_key, version2_key;
	if (object_key === undefined) {
		version1_key = version1;
		version2_key = version2;
	} else {
		version1_key = version1[object_key];
		version2_key = version2[object_key];
	}

	const version1_date = new Date(version1_key).getTime();
	const version2_date = new Date(version2_key).getTime();

	if (version2_date > version1_date) {
		return(-1 * sortOrder);
	} else if (version2_date < version1_date) {
		return(1 * sortOrder);
	}

	return(0);
}

if (_testing) {
	_testing.sort_dates_compare_1 = function() {
		const checks = [
			{
				version1: '2021-05-25T15:00:00.000Z',
				version2: '2021-06-25T15:00:00.000Z',
				direction: 'asc',
				result: -1
			},
			{
				version1: '2021-06-25T15:00:00.000Z',
				version2: '2021-05-25T15:00:00.000Z',
				direction: 'asc',
				result: 1
			},
			{
				version1: '2021-06-25T15:00:00.000Z',
				version2: '2021-06-25T15:00:00.000Z',
				direction: 'asc',
				result: 0
			},
			{
				version1: '2021-05-25T15:00:00.000Z',
				version2: '2021-06-25T15:00:00.000Z',
				direction: 'desc',
				result: 1
			},
		];

		for (const check of checks) {
			const check_result = sort_dates_compare({
				key: check.version1
			}, {
				key: check.version2
			}, check.direction, 'key');

			/* istanbul ignore if */
			if (check_result !== check.result) {
				const dir_humans_map = {
					'asc': 'increasing',
					'desc': 'decreasing'
				};
				const dir_human = dir_humans_map[check.direction];

				throw(new Error(`While comparing date ${check.version1} vs. ${check.version2} (${dir_human}) we got ${check_result} but expected ${check.result}`));
			}
		}

		return(true);
	};

	_testing.sort_dates_compare_2 = function() {
		const result = '2021-04-25T15:00:00.000Z';
		const check = [
			'2021-05-25T15:00:00.000Z',
			'2021-04-25T15:00:00.000Z',
			'2021-06-25T15:00:00.000Z'
		];

		/*
		 * Validate that arrays of objects and strings can be sorted
		 */
		/**
		 ** Object
		 **/
		(function() {
			const check_copy = check.map(function(item) {
				return({key: item});
			});

			const output = check_copy.sort(function(version1, version2) {
				return(sort_dates_compare(version1, version2, 'asc', 'key'));
			});

			/* istanbul ignore if */
			if (output[0].key !== result) {
				throw(new Error(`While sorting ${JSON.stringify(check)} as Objects we got ${output[0].key} but expected the first value to be ${result}`));
			}
		})();

		/**
		 ** String
		 **/
		(function() {
			const check_copy = kaialpha.lib.object_utils.copy_object(check);

			const output = check_copy.sort(sort_dates_compare);

			/* istanbul ignore if */
			if (output[0] !== result) {
				throw(new Error(`While sorting ${JSON.stringify(check)} as Strings we got ${output[0]} but expected the first value to be ${result}`));
			}
		})();

		return(true);
	};
}

const _to_export_auto = {
	format_date,
	sort_dates_compare,
	_testing
};
export default _to_export_auto;
