import React from 'react';

const uuid = require('uuid');

class StatusBar extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.state = {
			messages: {
				id_list: [],
				items: {}
			}
		};
	}

	static _filter_id_list(list, items) {
		const new_list = [...list].filter(function(item_id) {
			if (items[item_id] === undefined) {
				return(false);
			}
			return(true);
		});
		return(new_list);
	}

	push_status(type, message, id = undefined) {
		if (type === undefined && id === undefined) {
			throw(new Error('May not push an undefined type and an undefined id message'));
		}

		if (id === undefined) {
			id = uuid.v4();
		}

		this.setState(function(newState) {
			const new_items = {...newState.messages.items};
			const new_id_list = StatusBar._filter_id_list(newState.messages.id_list, new_items);

			if (type !== undefined) {
				/*
				 * Add or Update an item
				 */
				const new_item = { id, type, message };

				if (new_items[id] === undefined) {
					/*
					 * Add a new item
					 */
					new_id_list.push(id);
				}

				new_items[id] = new_item;
			} else {
				/*
				 * Delete an item by ID
				 */
				delete new_items[id];
			}

			return({
				messages: {
					id_list: new_id_list,
					items: new_items
				}
			});
		});
	}

	delete_status_by_id(id) {
		this.setState(function(newState) {
			const new_items = {...newState.messages.items};
			delete new_items[id];

			const new_id_list = StatusBar._filter_id_list(newState.messages.id_list, new_items);

			return({
				messages: {
					id_list: new_id_list,
					items: new_items
				}
			});
		});
	}

	render_message(message) {
		if (message === undefined) {
			return;
		}

		return <div style={{border: '1px solid purple', margin: '3px'}} key={`message_${message.id}`}>{message.type} {message.message} <span onClick={() => {
			this.delete_status_by_id(message.id);
		}}>[X]</span></div>
	}

	render() {
		const messages = this.state.messages.id_list.map((message_id) => {
			const message = this.state.messages.items[message_id];
			return(this.render_message(message));
		});

		return(
			<div>
				{messages}
			</div>
		);
	}
}

export default StatusBar;
