import React from 'react';
import './TemplateEditor.css';
import { Button } from '../../lib/ui';
//import 'react-sortable-tree/style.css';
import './CustomContext.css';
import Grid from '@material-ui/core/Grid';

import TemplateDocEditorArea from '../TemplateDocEditorArea';

import DialogBox from '../DialogBox';
import ACLEditor from '../ACLEditor';
import { getTemplateById, getTemplateTreeItem, createTemplate, updateTemplateVersion, getTemplateDiff, mergeTemplateObjects, createTemplateBuffer, getTemplateBuffer, updateTemplateBuffer } from '../../api/template.js';

import ElementsDialogBox from '../ElementsDialogBox';
import Metadata from '../Metadata';
import Variables from '../Variables';
import ConfirmationMessage from '../ConfirmationMessage';

import template_utils from '../../lib/utils/template_utils';
import validation_utils from '../../lib/utils/validation_utils';
import object_utils from '../../lib/utils/object_utils';
import buffer_utils from '../../lib/utils/buffer_utils';
import TemplateNavigation from '../TemplateNavigation';

import Snackbar from '@material-ui/core/Snackbar';
import user_lib from '../../api/user';
/*
 * Icons for radio buttons
 */
import { Link } from 'react-router-dom';
import { TextField, Typography, CircularProgress } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';

import user from '../../api/user';

import CachedIcon from '@material-ui/icons/Cached';
import diff_utils from '../../lib/utils/diff_utils';
import AuditLog from '../AuditLog';
/*
 * Icons for collapsed_sections areas
 */
const expand_collapse_icon = require('../../images/expand-collapse.svg').default;

/*
 * Required libraries
 */
const uuid = require('uuid');
const csv_parse = require('csv-parse/lib/sync');

class TemplateEditor extends React.Component {
	variable_types = validation_utils.structure.variable_types;
	element_types = validation_utils.structure.element_types;

	constructor(props) {
		super(props);
		this.instance_id = props.instance_id ? props.instance_id : uuid.v4();
		this.state = {
			items: [],
			template_id: '',
			body: {},
			variables: {},
			template: {},
			template_name: '',
			metadata: {},
			inputsFilled: false,
			templates: [],
			template_names: [],
			all_variables: {},
			download_clicked: false,
			acl_editor_open: false,
			upload_option: '',
			variables_file: null,
			collapsed_sections: {
				navigation: false,
				variables: false
			},
			refresh: false,
			add_element_dialog_open: false,
			template_element_dialog_open: '',
			left_pane_tab_value: 0,
			right_pane_tab_value: 0,
			snackbar_state: false,
			bufferIntervalTime: user.get_user_pref(undefined, 'user/buffer_save_freq'),
			buffer: {},
			renderBuffer: false,
			showSavingBufferText : false,
			hideBufferText: true,
			audit_log_open: false
		};

		this.addElement = this.addElement.bind(this);
		this.setTemplateInfo = this.setTemplateInfo.bind(this);
		this.saveTemplate = this.saveTemplate.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.save_variables_csv = this.save_variables_csv.bind(this);
		this.close_dialog = this.close_dialog.bind(this);
		this.handle_left_pane_tab_change = this.handle_left_pane_tab_change.bind(this);
		this.handle_right_pane_tab_change = this.handle_right_pane_tab_change.bind(this);
		this.updateBuffer = this.updateBuffer.bind(this);
		this.getCurrentEditorParams = this.getCurrentEditorParams.bind(this);

		/*
		 * Create a persistent reference to the current editor
		 * area
		 */
		this.editor_area = React.createRef();

		/* Create a reference to ConfirmationMessage component */
		this.confirmation_message = React.createRef();
	}

	all_variables_global = [];
	max_variable_index = 0;
	checkForBufferUpdatesInterval = null;

	getTemplateName(event) {
		/* Check if template name has been set */
		if (event.target.value !== null) {
			this.setState({ inputsFilled: true })
		}

		this.setState({ template_name: event.target.value })
	}

	setLocation(props, state) {
		if (!state.template_id) {
			return;
		}

		if (this.url_has_id) {
			return;
		}
		this.url_has_id = true;

		this.props.history.replace(`${this.props.match.path}/${state.template_id}`);
	}

	componentDidUpdate(prevProps) {
		this.setLocation(this.props, this.state);
	}

	componentWillUnmount() {
		if (this.checkForBufferUpdatesInterval) {
			clearInterval(this.checkForBufferUpdatesInterval);
		}
	}

	componentDidMount() {
		if (this.props.location && this.props.location.state && this.props.location.state.buffer) {
			this.setState({ buffer : this.props.location.state.buffer})
		}

		/*
		 * If we are editing a template with a known ID then update the URL
		 * to match it.
		 */
		let template_id;
		if (this.props.match && this.props.match.params && this.props.match.params.template_id) {
			template_id = this.props.match.params.template_id;
			this.url_has_id = true;
		} else if (this.props.location && this.props.location.state && this.props.location.state.id) {
			template_id = this.props.location.state.id;
		}

		if (template_id !== undefined) {
			this.setState({ template_id }, function() {
				this.setTemplateInfoByState();
			});
		} else {
			this.setState({ template_name: 'New Template' }, function() {
				this.saveTemplate()
			});
		}

		this.metadata = React.createRef();
	}

	async checkExistingBuffer() {
		if (this.checkForBufferUpdatesInterval) {
			clearInterval(this.checkForBufferUpdatesInterval);
		}
		if (this.state.bufferIntervalTime < 0) {
			return;
		}
		const current_user = await user.get_user_id();
		const buffer_exists = Object.keys(this.state.buffer);
		if (buffer_exists.length === 0) {
			try {
				const buffer_id = buffer_utils.get_buffer_id(current_user, this.state.template_id, this.state.current_version);
				const buffer = await getTemplateBuffer(buffer_id);
				buffer.id = buffer_id;
				this.setState({ buffer: buffer, renderBuffer: true }, () => {
					if (window.confirm('This Template has buffer. Do you want to resume from Buffer ?')) {
						const template = object_utils.copy_object(buffer);
						template.id = buffer_utils.get_item_id_from_buffer_id(buffer.id);
						template.version = buffer_utils.get_item_version_from_buffer_id(buffer.id);
						this.setState({ template: template, template_name: buffer.name }, () => {
							this.editor_area.current.reset(null, this.state.template);
							this.checkForBufferUpdates();
						});
					} else {
						this.checkForBufferUpdates();
					}
				});
			} catch (err) {
				console.error(err);
				this.checkForBufferUpdates();
			}
		} else {
			try {
				const buffer = await getTemplateBuffer(this.state.buffer.id)
				const template = object_utils.copy_object(buffer);
				template.id = buffer_utils.get_item_id_from_buffer_id(buffer.id);
				template.version = buffer_utils.get_item_version_from_buffer_id(buffer.id);
				this.setState({ template: template, template_name: buffer.name, renderBuffer: true }, () => {
					this.editor_area.current.reset(null, template);
				});
			} catch (err) {
				console.error(err);
			}
			this.checkForBufferUpdates();
		}
	}

	checkForBufferUpdates() {
		if (this.state.bufferIntervalTime <= 0) {
			return;
		}
		this.checkForBufferUpdatesInterval = setInterval(async () => {
			this.updateBufferTemplateState();
		}, this.state.bufferIntervalTime * 1000);
	}

	async updateBufferTemplateState() {
		const retval = await this.updateBuffer();
		if (retval) {
			this.setState({ buffer: retval, renderBuffer: true });
		}
	}

	getCurrentEditorParams() {
		const current_params = {};
		current_params.name = this.state.template_name;
		current_params.metadata = this.metadata.current.get_metadata();
		current_params.template_id = this.state.template_id;
		current_params.permissions = this.state.template.permissions;
		try {
			const template = this.editor_area.current.get_template();
			current_params.template = template;
			this.setState({ metadata: current_params.metadata, errorMsg: '' });
			return current_params;
		} catch (save_error) {
			this.setState({ errorMsg: save_error.toString() });
			return false;
		}
	}

	assignTemplateValuesToBuffer(current_buffer) {
		const existing_template = object_utils.copy_object(this.state.template);
		existing_template.id = current_buffer.id;
		existing_template.version = current_buffer.version;
		existing_template.previous_version = current_buffer.previous_version;
		existing_template.child_resources = current_buffer.child_resources;
		return(existing_template);
	}

	async updateBuffer() {
		let retval = {};
		this.setState({ showSavingBufferText: true });
		const current_params = this.getCurrentEditorParams();
		if (current_params) {
			const buffer_exists = Object.keys(this.state.buffer);
			if (buffer_exists.length !== 0) {
				const current_buffer = await getTemplateBuffer(this.state.buffer.id);
				const existing_buffer = this.assignTemplateValuesToBuffer(current_buffer);
				const diff = diff_utils.diff_objects(existing_buffer, current_buffer);
				if (Object.keys(diff).length === 0) {
					this.setState({ showSavingBufferText: false, hideBufferText: false});
					setTimeout(() => {
						this.setState({hideBufferText : true })
					}, 3000);
					return;
				} else {
					const buffer = await updateTemplateBuffer(this.state.buffer.id, current_buffer.version, current_params);
					this.setState({ buffer: buffer, showSavingBufferText: false, hideBufferText: false})
					retval = buffer;
					setTimeout(() => {
						this.setState({hideBufferText : true })
					}, 3000);
				}
			} else {
				const buffer = await createTemplateBuffer(current_params, this.state.current_version);
				this.setState({ buffer: buffer, showSavingBufferText: false, hideBufferText: false})
				retval = buffer;
				setTimeout(() => {
					this.setState({hideBufferText : true })
				}, 3000);
			}
		}
		return retval;
	}

	async setTemplateInfoByState() {
		const id = this.state.template_id;
		try {
			const current_template = await getTemplateById(id);
			this.setTemplateInfo(current_template);
		} catch(err) {
			console.error(err);
		}
	}

	componentWillReceiveProps(nextProps) {
		/* Reset state if "Create Template or "Edit Template" are clicked */
		if (nextProps && nextProps.location && nextProps.location.state) {
			const command = nextProps.location.state.command;
			if (command === "reinitialize") {

				this.setState({ template_name: '' });
				this.setState({ template_id: '' });
				this.setState({ current_version: undefined });
				this.setState({ metadata: {} });
				this.setState({ inputsFilled: false });

				this.editor_area.current.reset(null, {});
			}
		}
	}

	notifyUserError(action, type, errorMsg) {
		this.confirmation_message.current.notifyUserFailure(action, type, errorMsg);
	}

	notifyUserMessage(message) {
		this.confirmation_message.current.notifyUserMessage(message);
	}

	notifyUserSuccess(action, type, message) {
		this.confirmation_message.current.notifyUserSuccess(action, type, message);
	}

	set_metadata(metadata) {
		this.setState({metadata: metadata});
	}

	async mergeTemplateVersionsAndSave() {
		const template_id = this.state.template_id;
		const template_base = await getTemplateById(template_id, this.state.current_version);
		const template_2 = await getTemplateById(template_id);
		const template_1 = this.editor_area.current.get_template();
		template_1.name = this.state.template_name;
		template_1.metadata = this.metadata.current.get_metadata();

		/*
		 * If the new template is what we already expected, something else must be wrong,
		 * so don't try to merge (it would be pointless).  Instead, re-save without
		 * the merge function being called.
		 */
		if (template_2.version === this.state.current_version) {
			return(await this.saveTemplate({ mergeVersionsOnError: false }));
		}

		/*
		 * If the templates don't actually differ, just update our version (no save required)
		 */
		const diff = getTemplateDiff(template_1, template_2);
		if (Object.keys(diff.changed).length === 0 && Object.keys(diff.added).length === 0 && Object.keys(diff.deleted).length === 0) {
			this.current_version = template_2.version;
			console.debug('Template has nothing to save, done!', diff);
			console.debug('template_1 =', template_1);
			console.debug('template_2 =', template_2);
			console.debug('Diff =', getTemplateDiff(template_1, template_2));
			return(true);
		}

		/*
		 * Construct the new template by merging
		 */
		let new_template;
		try {
			new_template = mergeTemplateObjects(template_1, template_2, template_base);
		} catch (merge_error) {
			console.error('Merge error, will retry in non-strict mode:', merge_error);
			console.debug('Old =', template_1, '; New =', template_2, '; Base =', template_base);
			console.debug('Diff =', diff);
			try {
				new_template = mergeTemplateObjects(template_1, template_2, template_base, { strict: false });
			} catch (worse_merge_error) {
				this.notifyUserError(undefined, undefined, `while saving: Template has been changed and we are unable to merge due to an error: ${worse_merge_error}, click Publish again to forcefully overwrite`);
				this.setState({
					forceSave: true
				});
				setTimeout(() => {
					this.setState({
						forceSave: false
					});
				}, 60000);
				return(false);
			}
		}
		console.debug('Old =', template_1, '; New =', template_2, '; Base =', template_base);
		console.debug('Diff =', diff);
		console.debug('New =', new_template);

	}

	async saveTemplate(options = {}) {
		options = {
			mergeVersionsOnError: true,
			forceSave: false,
			...options
		};

		const name = this.state.template_name;
		const metadata = this.metadata.current.get_metadata();
		let template
		try {
			template = this.editor_area.current.get_template();
		} catch (save_error) {
			this.notifyUserError('Save', 'Template', save_error.toString());

			return;
		}

		template.body = this.findNamesOfExpressionElementAndFormatIt(template.body);

		this.setState({metadata: metadata});

		this.notifyUserMessage('Saving Template');
		try {
			if (this.state.template_id !== "") {
				let save_over_version = this.state.current_version;
				if (options.forceSave) {
					save_over_version = 'HEAD';
				}

				const retval = await updateTemplateVersion(this.state.template_id, save_over_version, name, template.body, template.variables, metadata, this.state.template.permissions)
				this.setState({ current_version: retval.version });
				this.setState({buffer : {}, renderBuffer: false});
				this.notifyUserSuccess('Update', 'Template');
				return retval;
			} else {
				const retval = await createTemplate(name, template.body, template.variables, metadata);
				this.notifyUserSuccess('Create', 'Template');
				this.setState({
					template_id: retval.id,
					current_version: retval.version,
					template: await getTemplateById(retval.id, retval.version)
				});
				this.setState({buffer : {}, renderBuffer: false});
				return retval;
			}
		} catch(error) {
			if (this.state.template_id !== "") {
				if (options.mergeVersionsOnError) {
					return(await this.mergeTemplateVersionsAndSave());
				}
			}

			this.notifyUserError('Save', 'Template', error.message);
		}
	}

	findNamesOfExpressionElementAndFormatIt(bodyElements) {
		if (bodyElements && Array.isArray(bodyElements)) {
			bodyElements.forEach(bodyElement => {
				const key = Object.keys(bodyElement)[0];
				const element = bodyElement[key];
				if (element.type === 'template' && element.expression) {
					element.name = template_utils.checkOrdinalNameAndFormat(element.name);
				} else if (element.type === 'section' && element.body) {
					element.body = this.findNamesOfExpressionElementAndFormatIt(element.body);
				}
				bodyElement[key] = element;
			});
		}
		return(bodyElements);
	}

	async setTemplateInfo(res) {
		const template = res;

		const name = template["name"];
		let inputs_filled = false;
		if (name !== "") {
			inputs_filled = true;
		}

		/* Get metadata */
		let metadata = {};
		if (template["metadata"] !== undefined) {
			metadata = template["metadata"]
		}

		let owner = '';
		if (template.permissions !== undefined) {
			owner = template.permissions.owners[0];
		}

		const template_owner = await user_lib.get_user_username(owner);

		this.setState({
			template_name: name,
			inputsFilled: inputs_filled,
			template: template,
			metadata: metadata,
			current_version: template.version,
			template_owner: template_owner
		}, () => {
			this.checkExistingBuffer();
		});
	}

	addElement(element, variable_name) {
		const default_options = {};
		switch (element) {
			case "variable":
				this.max_variable_index++;
				default_options['name'] = (variable_name === null || variable_name === undefined) ? `Var${this.max_variable_index}` : variable_name;
				default_options['type'] = 'text';
				break;
			case "template":
				this.max_variable_index++;
				default_options['name'] = `SubTempl${this.max_variable_index}`;
				break;
			case "switch":
			case "loop":
				this.max_variable_index++;
				default_options['name'] = `Var${this.max_variable_index}`;
				break;
			default:
				break;
		}

		this.editor_area.current.insert_item(element, default_options);
	}

	async update_all_variables() {
		if (this.state.template_id !== "" && this.all_variables_global.length === 0) {
			const all_variables_tree = await template_utils.get_template_all_variables(null, this.state.template_id, {
				exclude_template_ids: [this.state.template_id]
			});
			const all_variables_global = template_utils.flatten_template_variable_tree(all_variables_tree, ['global']);
			for (const variable_name in all_variables_global) {
				const variable = all_variables_global[variable_name];
				const variable_from_template = variable['from'].slice(-1)[0];
				const template = await getTemplateById(variable_from_template.id, variable_from_template.version);
				variable['from_name'] = template.name;
			}

			this.all_variables_global = all_variables_global;
		}

		if (!this.editor_area.current) {
			return;
		}

		let current_template_try;
		try {
			current_template_try = this.editor_area.current.get_template();
		} catch (current_template_error) {
			return;
		}

		const current_template = current_template_try;
		const local_variables = current_template.variables;

		/*
		 * Indicate the source of local variables as "This Template"
		 */
		for (const local_variable_name in local_variables) {
			const local_variable = local_variables[local_variable_name];
			local_variable['from_name'] = 'This Template';
		}

		const all_variables = Object.assign(local_variables, this.all_variables_global);

		this.setState({ all_variables });
	}

	renderTemplate() {
		this.setState({ download_clicked: true });
	}

	closeDialog() {
		this.setState({ download_clicked: false });
	}

	closeSnackBar() {
		this.setState({snackbar_state : false});
	}

	renderSnackBar() {
		return <Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={this.state.snackbar_state}
			autoHideDuration={3000}
			onClose={this.closeSnackBar}
			message="Error in reading variables"/>
	}

	save_variables_csv(event) {
		this.setState({ variables_file: event.target.files[0] }, () => {
			if (this.state.variables_file === null || this.state.variables_file === undefined) {
				this.setState({snackbar_state : true}, () => {
					this.renderSnackBar();
				});
			} else {
				this.read_csv_file(this.state.variables_file);
			}
		});
	}

	read_csv_file(file) {
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = () => {
			const result = reader.result;
			const parsed_data = csv_parse(result, {
				columns: true
			})
			this.bulk_create_variables(parsed_data);
		}
	}

	bulk_create_variables(parsed_data) {
		const variables = parsed_data;
		variables.forEach((variable) => {
			const constructed_variable = {};

			/* Set values for fields used by all variable types */
			constructed_variable['name'] = variable['name'];
			constructed_variable['type'] = variable['type'];
			constructed_variable['description'] = variable['description'];

			/*
			 * If variable is a dropdown, checkbox, or list, modify object so its options
			 * are structured properly (i.e. options: {items: 'one, two, three'})
			 */
			if (variable.type === 'dropdown' || variable.type === 'checkbox' || variable.type === 'list') {
				const variable_items = variable['options'];
				constructed_variable['options'] = {items: variable_items};
			}

			/*
			 * If variable is a datasource, modify object so datasource info is
			 * nested within the options property
			 */
			if (variable.type === 'datasource') {
				constructed_variable['options'] = {
					source: variable['source'],
					type: variable['datasource_type'],
					row_headers: variable['row_headers'],
					column_headers: variable['column_headers']
				}
			}

			this.max_variable_index++;
			this.editor_area.current.insert_item('variable', constructed_variable);
		})
	}

	updateTemplateAndRefresh(template_id) {
		const path_name = "/activity/templateeditor/" + template_id;
		this.props.history.push(path_name);
	}

	collapsableGridItem(options, contents) {
		const name = options['name'];
		const collapse_direction = options['collapse_direction'];
		const grid_props = {...options};
		delete grid_props['name'];
		delete grid_props['collapse_direction'];

		const collapsed = this.state.collapsed_sections[name];

		/*
		 * If the section is not collapsed, render the contents,
		 * otherwise only render the grid item enough for the
		 * button
		 */
		let render_contents, render_grid_props, alt_text;
		if (!collapsed) {
			render_contents = <div>{contents}</div>;
			render_grid_props = grid_props;
			alt_text = 'Collapse';
		} else {
			render_contents = <div style={{display: 'none'}}>{contents}</div>;
			render_grid_props = { xs: false }
			alt_text = 'Expand';
		}

		/*
		 * Based on the collapse direction, determine how to
		 * orient the collapse area and how to justify it within
		 * the space
		 */
		let rotation, align;
		switch (collapse_direction) {
			case 'left':
				rotation = 180;
				align = 'right';
				break;
			case 'right':
				rotation = 0;
				align = 'left';
				break;
			case 'up':
				rotation = 270;
				break;
			case 'down':
				rotation = 90;
				break;
			default:
				throw(new Error(`Unexpected collapse_direction: ${collapse_direction}`));
		}

		/*
		 * If the section is currently collapsed, rotate the button 180
		 * degrees to indicate it will un-collapse
		 */
		if (collapsed) {
			rotation = (rotation + 180) % 360;
		}

		return(
			<Grid item {...render_grid_props}>
				<div style={{ textAlign: align }} className="collapse-btn" onClick={() => {
					this.setState(function(prevState) {
						/*
							* Invert the state of the collapsed section
							*/
						return({
							collapsed_sections: {
								...prevState['collapsed_sections'],
								[name]: !collapsed
							}
						});
					});
				}}>
					<img className="expand-button" style={{transform: `rotate(${rotation}deg)`, width: '0.9em', height: '0.9em'}} src={expand_collapse_icon} alt={alt_text}/>
				</div>
				{render_contents}
			</Grid>
		);
	}

	open_dialog() {
		this.setState({ add_element_dialog_open: true });
	}

	close_dialog() {
		this.setState({ add_element_dialog_open: false });
	}

	handle_left_pane_tab_change(event, new_value) {
		this.setState({ left_pane_tab_value: new_value })
	}

	handle_right_pane_tab_change(event, new_value) {
		this.setState({ right_pane_tab_value: new_value });
	}

	add_element_dialog(element, id) {
		if (element === 'switch') {
			const element_type = 'switch_case';
			this.setState({ template_element_dialog_open: element_type })
		}
	}

	getActionButtons() {
		return(
			<div>
				<div>
					<Button style={{marginLeft:'10px', color: '#FFFFFF', backgroundColor: '#0052CC', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px'}}variant='contained'  disableElevation onClick={() => this.saveTemplate({forceSave: this.state.forceSave})}>Publish</Button>
					<Button style={{marginLeft:'10px', color: '#FFFFFF', backgroundColor: '#0052CC', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px'}} variant='contained' disableElevation onClick={() => this.saveTemplate()}>Save</Button>
					<Button style={{marginLeft:'10px', color: '#969798', backgroundColor: '#F1F3FA', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px'}} variant='contained' disableElevation component={Link} to={{pathname: "/activity/templateselector"}}>Close</Button>
				</div>
				<div>
					{this.state.showSavingBufferText && Object.keys(this.state.buffer).length !== 0 && <div className='buffer-save-text'><div><CachedIcon style={{fontSize: 24}}/></div><div className='saving-text'>Saving Buffer</div></div>}
					{!this.state.hideBufferText && !this.state.showSavingBufferText && Object.keys(this.state.buffer).length !== 0 && <div className='buffer-save-text'><div className='saving-text'>Saved in Buffer</div></div>}
				</div>
			</div>
		)
	}

	render() {
		let audit_log = undefined;
		const all_variables_flat = Object.keys(this.state.all_variables).map((variable_name) => {
			const variable_info = this.state.all_variables[variable_name];

			variable_info['name'] = variable_name;

			return(variable_info);
		});

		const editor_area =
			<TemplateDocEditorArea
				ref={this.editor_area}
				key={"editorarea_" + this.instance_id}
				type="template"
				template={this.state.template}
				all_variables={this.state.all_variables}
				onChange={() => {
					this.update_all_variables();
				}}
				variables_flat={all_variables_flat}
			/>

		let metadata_pane;
		if (this.state.left_pane_tab_value === 0) {
			metadata_pane =
				<div>
					<Metadata
						ref={this.metadata}
						metadata={this.state.metadata}
					/>
				</div>
		}

		let template_navigation_pane;
		if (this.state.left_pane_tab_value === 2 && this.state.template_id && this.state.current_version) {
			template_navigation_pane =
			<TemplateNavigation currentTemplate={async () => {
				return(await getTemplateTreeItem({
					...this.state.template,
					id: this.state.template_id,
					version: this.state.current_version
				}));
			}} onEditButton={(id) => {
				this.updateTemplateAndRefresh(id);
			}}/>
		}

		if (this.state.audit_log_open) {
			audit_log = <AuditLog
				type="template"
				id={this.state.template_id}
				visible={this.state.audit_log_open}
				onCancel={() => this.setState({ audit_log_open: false })}
				history={this.props.history}
			/>
		}

		return(
			<div key={this.instance_id} className="template-editor">
				<div id="cmenu" style={{ top: this.state.y + "px", left: this.state.x + "px"}}>
					{this.state.visible ? this.returnMenu(this.state.menu) : null}
				</div>
				<div id="vmenu" style={{ top: this.state.y, left: this.state.x}}>
					{this.state.var_menu_visible ? this.returnVariables(this.state.all_variables) : null}
				</div>
				<ConfirmationMessage ref={this.confirmation_message} />
				<Grid container style={{height:'100%'}}>
					{/* Left side collapsible pane - Template Structure */}
					<Grid item style={{width: '20%', padding: '1.5%', paddingTop: '5em', backgroundColor: 'rgba(241, 243, 250, 0.3)'}}>
						<Typography style={{fontSize: '16px', fontWeight: '500', color: '#464646'}}>Template Structure</Typography>
						<Grid container style={{width:'100%', marginTop: '16px'}}>
							<Tabs
								value={this.state.left_pane_tab_value}
								variant="fullWidth"
								onChange={this.handle_left_pane_tab_change}
							>
								<Tab label="Meta" />
								<Tab label="Document" />
								<Tab label="Template" />
							</Tabs>
							{metadata_pane}
							{template_navigation_pane}
						</Grid>
					</Grid>

					{/* Center pane - Template Editor Area */}
					<Grid item style={{width: '56%', padding: '3%', paddingTop: '4em'}}>
						<Grid item style={{height:'60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '32px'}}>
							<Grid style={{display:'flex', alignItems: 'center'}}>
								<Typography style={{float:'left', marginRight: '12px', fontSize: '16px', fontWeight: '500', color: '#464646'}}>Template Editor</Typography><br></br>
								{this.state.refresh && this.state.previewDocContent !== '' &&  <Grid style={{display: 'flex'}}><CircularProgress  thickness='6' style={{height: '16px', width:'16px', marginRight: '8px', color:'#969798'}}/><Typography style={{fontSize: '12px', color: '#969798', fontStyle: 'italic'}}>Refreshing</Typography></Grid>}
							</Grid>
							{<Grid item direction='row' alignItems='right' style={{float:'right'}}>
								{this.getActionButtons()}
							</Grid>}
						</Grid>
						<Grid container style={{width:'100%'}}>
							{/* <SearchBar className='search-template-editor' placeholder='Search Templates, Variables, etc..'/> */}
							<div className="template-content" key="template-content-area">
								{editor_area}
								<div className='add-element-container'>
									<Button className='add-element-btn' onClick={() => this.open_dialog()}><AddIcon fontSize="small" style={{fill: "#FFFFFF"}}/></Button>
								</div>
							</div>
						</Grid>
					</Grid>
					{this.state.add_element_dialog_open === true && <ElementsDialogBox close_dialog={this.close_dialog} add_element={this.addElement}/>}
					{/* Right side collapsible pane - Template Editor Area */}
					<Grid item style={{width: '24%', padding: '1.5%', paddingTop: '4.1em', fontSize: '1.20em', fontWeight: 'bold', backgroundColor: 'rgba(241, 243, 250, 0.3)'}}>
						<TextField key={this.state.template.id} defaultValue={this.state.template_name} placeholder='Template Name' onChange={(event) => {
							this.getTemplateName(event);
						}} />
						<Typography>Owner: {this.state.template_owner}</Typography>
						<Typography>Created on: </Typography>
						<Typography>Last Updated: </Typography>
						<Button style={{marginLeft:'10px', color: '#FFFFFF', backgroundColor: '#0052CC', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px'}} variant='contained' disableElevation onClick={() => this.setState({download_clicked : true})}>Export</Button>
						<Button style={{marginLeft:'10px', color: '#FFFFFF', backgroundColor: '#0052CC', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px', paddingTop: '5px'}} disabled={this.state.template_id === ''} variant="contained" onClick={() => { this.setState({ acl_editor_open: true }); }}>ACL Editor</Button>
						<Button style={{marginLeft:'10px', color: '#FFFFFF', backgroundColor: '#0052CC', minWidth: '100px', fontSize: '14px', textTransform: 'none', height: '40px', paddingTop: '5px'}} disabled={this.state.template_id === ''} variant="contained" onClick={() => { this.setState({ audit_log_open: true }); }}>Audit Logs</Button>
						{audit_log}
						<ACLEditor
							visible={this.state.acl_editor_open}
							permissions={object_utils.copy_object(this.state.template.permissions)}
							onSave={async (new_permissions) => {
								const new_template = object_utils.copy_object(this.state.template);
								new_template.permissions = new_permissions;

								this.setState({
									acl_editor_open: false,
									template: new_template
								});

								/** XXX:TODO: Save the ACL right now or wait ? **/
								if (this.state.template_id && this.state.template_id !== '') {
									this.notifyUserMessage('Saving ACL');
									try {
										const new_template_info = await updateTemplateVersion(this.state.template_id, 'HEAD', undefined, undefined, undefined, undefined, new_permissions);
										this.notifyUserSuccess('Update', 'ACL');

										this.setState({
											current_version: new_template_info.version
										});
									} catch (save_error) {
										this.notifyUserError('Save', 'ACL', save_error.message);
									}
								}

							}}
							onCancel={() => {
								this.setState({ acl_editor_open: false });
							}}
						/>
						<Grid container style={{width:'100%', marginTop: '12px'}}>
							<div style={{marginTop: '32px', display: 'contents'}}>
								<Tabs
									value={this.state.right_pane_tab_value}
									indicatorColor="primary"
									textColor="primary"
									aria-label="disabled tabs example"
									onChange={this.handle_right_pane_tab_change}
								>
									<Tab label="Variables" />
									<Tab label="Comments" />
									<Tab label="Contributors" />
									<Tab label="Tasks" />
								</Tabs>
								<Variables
									type='template'
									variables={all_variables_flat}
									save_variables_csv = {this.save_variables_csv}
								/>
							</div>
						</Grid>
					</Grid>

				</Grid>
				{(this.state.download_clicked === true ) && <DialogBox id={this.state.template_id} type='template' onClick={this.closeDialog} buffer={this.state.buffer} renderBuffer={this.state.renderBuffer}/>}
			</div>
		)
	}
}

export default TemplateEditor;
