import React from 'react';
import './TemplateNavigation.css';
import { Button } from '../../lib/ui';
import LazyTreeView from '../LazyTreeView';
import lib_template from '../../api/template'

class TemplateNavigation extends React.Component {
	static defaultProps = {
		onEditButton: undefined,
		currentTemplate: undefined
	}

	constructor(props) {
		super(props);

		this.state = {
			selected_template_id: null,
			currentTemplate: null,
		};
	}

	componentDidMount() {
		this.getCurrentTemplate();
	}

	async getCurrentTemplate() {
		if (this.props.currentTemplate) {
			const template = await this.props.currentTemplate();
			this.setState({ currentTemplate: template });
		}
	}

	select_template(id, version) {
		this.setState({
			selected_template_id: id
		});
	}

	perform_on_edit_button() {
		if (!this.props.onEditButton) {
			return;
		}

		if (!this.state.selected_template_id) {
			return;
		}

		this.props.onEditButton(this.state.selected_template_id);
	}

	async getTemplateChildren(id = null, version = undefined) {
		return await lib_template.getTemplateChildren(id, version);
	}

	async getParents(id, version = undefined) {
		return await lib_template.getTemplateParents(id, version);
	}

	static render_item_label(item) {
		return(`${item.name}`);
	}

	

	render() {
		let lazy_tree_view;
		if (this.state.currentTemplate) {
			lazy_tree_view = <LazyTreeView
				selected = {this.state.currentTemplate}
				onClick = {(...args) => {
					return(this.select_template(...args));
				}}
				getChildren = {this.getTemplateChildren}
				getParents = {this.getParents}
				renderItemLabel = {TemplateNavigation.render_item_label}
			/>;
		}

		return(
			<div className='template-tree'>
				{lazy_tree_view}
				<Button disabled={!this.state.selected_template_id} onClick={() => this.perform_on_edit_button()}>EDIT</Button>
			</div>
		);
	}
}

export default TemplateNavigation;
