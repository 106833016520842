import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import users from '../../api/user';

class UserListSelect extends React.Component {
	constructor(props) {
		super(props);

		this._isMounted = false;
		this.state = {
			user_id_list: [],
			user_id_name_map: {},
			multiple: false
		};

		if (props.multiple !== undefined) {
			this.state.multiple = props.multiple;
		}

		this.loadUserList();
	}

	async loadUserList() {
		const user_list = await users.get_user_list(['name'])
		const user_id_list = [];
		const user_id_name_map = {};

		user_list.push({
			id: '@all',
			name: 'Every Authenticated User'
		});

		for (const user_info of user_list) {
			user_id_list.push(user_info.id);
			user_id_name_map[user_info.id] = user_info.name;
		}

		if (this._isMounted) {
			this.setState({
				user_id_list,
				user_id_name_map
			});
		} else {
			this.state.user_id_list = user_id_list;
			this.state.user_id_name_map = user_id_name_map;
		}
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	runOnChange(value) {
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	render() {
		/*
		 * XXX:TODO: Right now this downloads the complete user
		 *           database for lookup, but we need to
		 *           implement a remote search mechanism
		 *           to avoid wastefully loading such a
		 *           potentially large database
		 */
		const autocomplete_props = {};
		if (this.props.disabled === true) {
			autocomplete_props['disabled'] = true;
		}

		return(
			<Autocomplete
				{...autocomplete_props}
				fullWidth={true}
				multiple={this.state.multiple}
				value={this.props.value}
				options={this.state.user_id_list}
				getOptionLabel={(option) => {
					let user_name = this.state.user_id_name_map[option];

					if (user_name === undefined) {
						if (option.slice !== undefined && option.slice(0, 1) === '@') {
							user_name = option;
						} else {
							user_name = '<Loading>';
						}
					}

					return(user_name);
				}}
				renderInput={(params) => {
					return(<TextField label={this.props.label} {...params} />);
				}}
				onChange={(event, value, reason) => {
					this.runOnChange(value);
				}}
			/>
		);
	}
}

export default UserListSelect;
