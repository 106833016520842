import React from 'react';
import MUICircularProgress from '@material-ui/core/CircularProgress';

function CircularProgress(props) {
	const new_props = {
		color: "primary",
		size: "1em",
		disableShrink: true,
		...props
	};

	return <MUICircularProgress {...new_props}/>
}

export default CircularProgress;
