import React from 'react';
import './Metadata.css';

import SearchBar from "material-ui-search-bar";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import object_utils from '../../lib/utils/object_utils';
const csv_parse = require('csv-parse/lib/sync');

class Metadata extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			display_meta_fields: false,
			metadata: {},
			current_key: '',
			current_value: '',
			key_editted: '',
			value_editted: '',
			upload_option: ''
		}

		this.save_metadata = this.save_metadata.bind(this);
		this.remove_metadata = this.remove_metadata.bind(this);
		this.edit_metadata = this.edit_metadata.bind(this);
		this.read_csv_file = this.read_csv_file.bind(this);
		this.save_metadata_file = this.save_metadata_file.bind(this);
		this.reset_input_fields = this.reset_input_fields.bind(this);
		this.toggle_top_level = this.toggle_top_level.bind(this);
	}

	metadata_file = null;

	componentDidUpdate(nextProps, nextState) {
		if (nextProps !== this.props) {
			/*
			 * Fetch metadata from template if template already has
			 * metadata when it is loaded in
			 */

			if (Object.keys(this.state.metadata).length === 0) {
				this.setState({metadata: this.props.metadata});
			}
		}
	}

	get_top_level() {
		const metadata = this.state.metadata;

		if (metadata['toplevel'] === 'true') {
			return(true);
		} else {
			return(false);
		}
	}

	toggle_top_level() {
		const is_top_level = this.get_top_level();

		if (is_top_level) {
			this.setState((prevState) => {
				const metadata = prevState.metadata;
				delete metadata['toplevel'];
				return({metadata: metadata});
			})
		} else {
			this.setState((prevState) => {
				const metadata = prevState.metadata;
				metadata['toplevel'] = 'true';
				return({metadata: metadata});
			})
		}
	}

	reset_input_fields() {
		this.setState({
			display_meta_fields: false,
			current_key: '',
			current_value: '',
			key_editted: '',
			value_editted: ''
		})
	}

	save_metadata(event) {
		let current_key = this.state.current_key;
		let current_value = this.state.current_value;

		if (event.target.name === 'key') {
			current_key = event.target.value;
			this.setState({current_key: current_key});
		} else {
			current_value = event.target.value;
			this.setState({current_value: current_value});
		}
	}

	add_metadata() {
		this.setState(function(state) {
			const key = state.current_key;
			const value = state.current_value;
			const metadata = object_utils.copy_object(state.metadata);

			if (state.key_editted !== '' && state.value_editted !== '') {
				delete metadata[state.key_editted];
			}

			metadata[key] = value;
			this.reset_input_fields();

			return({metadata: metadata})
		});
	}

	edit_metadata(key) {
		const value = this.state.metadata[key];

		this.setState({
			current_key: key,
			current_value: value,
			key_editted: key,
			value_editted: value,
			display_meta_fields: true
		});
	}

	remove_metadata(key) {
		this.setState(function(state) {
			const metadata = object_utils.copy_object(state.metadata);
			delete metadata[key];
			this.reset_input_fields();
			return({metadata: metadata});
		});
	}

	search_metadata(search_value) {
		const metadata = this.state.metadata;
		const final_results = {};

		if (search_value === '' || search_value === undefined || search_value === null) {
			this.setState({ metadata: this.props.metadata });

		} else {
			for(const key in metadata) {
				if (key.toLowerCase().includes(search_value.toLowerCase().trim())) {
					final_results[key] = metadata[key];
				}
			}

			this.setState({metadata: final_results});
		}
	}

	get_metadata() {
		return(this.state.metadata);
	}

	save_metadata_file(event) {
		this.metadata_file = event.target.files[0];
	}

	async read_csv_file() {
		const file = this.metadata_file;

		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = () => {
			const result = reader.result;
			const metadata = csv_parse(result, {
				columns: true
			})
			this.bulk_add_metadata(metadata);
		}
	}

	bulk_add_metadata(metadata) {
		const uploaded_metadata = {};

		for (const metadata_pair of metadata) {
			const key_column_name = Object.keys(metadata_pair)[0];
			const value_column_name = Object.keys(metadata_pair)[1];

			const key = metadata_pair[key_column_name];
			const value = metadata_pair[value_column_name];

			uploaded_metadata[key] = value;
		}

		if (this.state.upload_option === 'replace') {
			this.setState({metadata: uploaded_metadata});
		}

		if (this.state.upload_option === 'add') {
			this.bulk_add_to_current_metadata(uploaded_metadata);
		}
	}

	bulk_add_to_current_metadata(uploaded_metadata) {
		const combined_metadata = {
			...this.state.metadata,
			...uploaded_metadata
		};

		this.setState({metadata: combined_metadata});
	}

	render() {

		/*
		 * Checkbox that allows the user to add the key-value pair
		 * "toplevel = true" to the template's metadata to make the
		 * template a toplevel template
		 */
		const toggle_top_level =
			<div className='is-top-level'>
				<label>Can this template be used to build documents? </label>
				<input type='checkbox' checked={this.get_top_level()} onChange={this.toggle_top_level} />
			</div>

		/*
		 * Displays options for adding metadata in bulk from a CSV file.
		 * Either replace all currently existing metadata with key-value
		 * pairs from the CSV, or add the key-value pairs to the existing
		 * metadata
		 */
		const metadata_options =
			<div className='csv-metadata'>
				<span className='upload-metadata-hdr'>Upload Metadata:</span>
				<input type='file' accept='.csv' className='upload-metadata-input ' onChange={this.save_metadata_file}/>
				<div className='metadata-upload-options'>
					<FormControl component='metadata-upload-options'>
						<RadioGroup defaultValue='overwrite' aria-label='metadata' name='metadata-radio-btns' value={this.state.upload_option} onChange={(event) => this.setState({upload_option: event.target.value})}>
							<FormControlLabel value='add' control={<Radio value='add' color='primary'/>} label='Add Metadata' />
							<FormControlLabel value='replace' control={<Radio value='replace' color='primary'/>} label='Replace Metadata' />
						</RadioGroup>
					</FormControl>
				</div>
				<Button variant='contained' className='upload-csv-metadata' disabled={this.metadata_file === null || this.metadata_file === undefined || this.state.upload_option === ''} onClick={this.read_csv_file}>Upload</Button>
			</div>

		/*
		 * Header in metadata pane
		 */
		const metadata_header =
			<div className='meta-header'>
				<Typography style={{fontSize: '13px'}}>Meta Variables</Typography>
				<Tooltip title="Add meta data">
					<IconButton>
						<AddRoundedIcon style={{color:'#0052CC', height: '20px', cursor: 'pointer', marginRight: '4px', marginBottom: '8px'}} onClick={() => this.setState({display_meta_fields: true})}/>
					</IconButton>
				</Tooltip>
			</div>

		let meta_fields;
		let key_placeholder = 'Key';
		let value_placeholder = 'Value';

		if (this.state.current_key !== '') {
			key_placeholder = this.state.current_key;
		}

		if (this.state.current_value !== '') {
			value_placeholder = this.state.current_value;
		}

		/*
		 * Display all metadata key-value pairs with options to
		 * edit or delete metadata
		 */
		if (this.state.display_meta_fields === true) {
			meta_fields = (
				<div className='meta-input-header'>
					<div className='meta-input-holder'>
						<TextField name='key' placeholder={key_placeholder} className='key' variant='outlined' onChange={this.save_metadata}/>
						<img alt="link" src={require('../../images/link.svg').default} />
						<TextField name='value' placeholder={value_placeholder} className='value' variant='outlined' onChange={this.save_metadata}/>
					</div>
					<div className='meta-action-button'>
						<Button className='close-metadata-btn' onClick={this.reset_input_fields}>Close</Button>
						<Button className='add-metadata-btn' onClick={() => this.add_metadata()}>Add</Button>
					</div>
				</div>
			);
		}

		const metadata = this.state.metadata;

		return(
			<div className='metadata-container'>
				{toggle_top_level}
				{metadata_options}
				{metadata_header}
				{meta_fields}
				<div className='search-bar-holder'>
					<SearchBar
						className='search-metadata'
						onChange={(search_value) => this.search_metadata(search_value)}
					/>
				</div>
				<div>
					{metadata && Object.keys(metadata).map((key) =>
						<div className='key-value-container'>
							<p className='metadata-key'>{key}</p>
							<p className='metadata-value'>{metadata[key]}</p>
							<div className='metadata-actions'>
								<Button color='primary' onClick={() => this.edit_metadata(key)} style={{minWidth: '1em'}}><EditIcon fontSize='small'></EditIcon></Button>
								<Button color='primary' onClick={() => this.remove_metadata(key)} style={{minWidth: '1em'}}><DeleteIcon fontSize='small'></DeleteIcon></Button>
							</div>
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default Metadata;
