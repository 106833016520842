/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/nunjucks_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const nunjucks = require('nunjucks');
const uuid = require('uuid');
const escape_string_regexp = require('escape-string-regexp');

/*
 * Enable testing if running the test suite
 */
let _testing;
if (typeof(kaialpha) === 'object' && kaialpha.test_harness === true) {
	_testing = {};
}

/*
 * Idempotent filters can be re-used
 */
const filters = {
	keys: function(object) {
		if (object instanceof Object) {
			return(Object.keys(object));
		}

		return([]);
	},
	normalize_html_id: function(str) {
		return(str.replace(/[^A-Za-z0-9-]/g, '_'));
	},
	merge_datasources: function(tuple) {
		const a = tuple[0];
		const b = tuple[1];

		if (a.type !== b.type) {
			throw(new Error(`Unable to merge datasources of different types: ${a.type}, ${b.type}`));
		}

		const retval = {
			type: a.type
		}

		switch (a.type) {
			case 'columns-rows':
				{
					retval['data'] = {};

					const keys = new Set([...Object.keys(a.data), ...Object.keys(b.data)]);
					for (const key of keys) {
						retval.data[key] = {
							...a.data[key],
							...b.data[key]
						};
					}
				}
				break;
			case 'columns':
				retval.data = [
					...a.data,
					...b.data
				];
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${a.type}" not supported`));
		}

		return(retval);
	}
};

if (_testing) {
	_testing.filters_merge_datasources = function() {
		const checks = [
			{
				a: {
					type: 'columns',
					data: [{a: 1, b: 2}, {a: 3, b: 4}]
				},
				b: {
					type: 'columns',
					data: [{a: 5, b: 6}, {a: 7, b: 8}]
				},
				output: {
					type: 'columns',
					data: [{a: 1, b: 2}, {a: 3, b: 4}, {a: 5, b: 6}, {a: 7, b: 8}]
				}
			},
			{
				a: {
					type: 'columns-rows',
					data: {a: {i: 1, ii: 2}, b: {i: 3, ii: 4}}
				},
				b: {
					type: 'columns-rows',
					data: {a: {iii: 5, iv: 6}, b: {iii: 7, iv: 8}}
				},
				output: {
					type: 'columns-rows',
					data: {a: {i: 1, ii: 2, iii: 5, iv: 6}, b: {i: 3, ii: 4, iii: 7, iv: 8}}
				}
			},
			{
				a: {
					type: 'columns-rows',
					data: {a: {i: 1, ii: 2}, b: {i: 3, ii: 4}}
				},
				b: {
					type: 'columns-rows',
					data: {c: {i: 5, ii: 6}, d: {i: 7, ii: 8}}
				},
				output: {
					type: 'columns-rows',
					data: {a: {i: 1, ii: 2}, b: {i: 3, ii: 4}, c: {i: 5, ii: 6}, d: {i: 7, ii: 8}}
				}
			},
			{
				a: {
					type: 'columns-rows',
					data: {a: {i: 1, ii: 2}, b: {i: 3, ii: 4}}
				},
				b: {
					type: 'columns',
					data: [{a: 5, b: 6}, {a: 7, b: 8}]
				},
				error: true
			}
		];

		for (const check of checks) {
			let check_output;
			try {
				check_output = filters.merge_datasources([check.a, check.b]);
			} catch (merge_error) {
				if (check.error !== true) {
					throw(merge_error);
				}

				continue;
			}

			if (check.error === true) {
				throw(new Error(`When merging ${JSON.stringify(check.a)} and ${JSON.stringify(check.b)} expected to get an error, but instead got: ${JSON.stringify(check_output)}`));
			}

			kaialpha.lib.testing_utils.assert.object_equals(check_output, check.output);
		}

		return(true);
	};
}

const map_abbreviations = function(text, shared_context, options) {
	if (!text) {
		return;
	}

	if (!options) {
		return(text);
	}

	if (!shared_context._abbreviations_state) {
		shared_context._abbreviations_state = {
			seen: {}
		};
	}

	const state = shared_context._abbreviations_state;

	const list_entries = options.abbr_list_entries;
	if (!list_entries) {
		return(text);
	}

	for (const {key, value} of list_entries) {
		/*
		 * Determine if this is the first use of the abbreviated words
		 */
		let first_use = true;
		if (state.seen[key] !== undefined) {
			first_use = false;
		}

		const value_re = new RegExp(`\\b${escape_string_regexp(value)}\\b`, 'gi');
		const check_text_value_replace = text.replace(value_re, `${key}`);

		text = check_text_value_replace;

		/*
		 * If we have already seen the short name, do not bother
		 * trying to replace it.
		 */
		if (!first_use) {
			continue;
		}

		const key_re = new RegExp(`\\b${key}\\b`);

		const check_text_key_replace = text.replace(key_re, `${value} (${key})`);
		if (check_text_key_replace === text) {
			continue;
		}

		state.seen[key] = value;
		text = check_text_key_replace;
	}

	return(text);
}

// Adds all additional KaiAlpha filters
function addKaFilters(env, variables, options = {}) {
	options = {
		parseOnly: false,
		...options
	};

	/*
	 * The "shared context" is accessible to all filters (as a closure),
	 * but is not shared between renders of different documents.  This
	 * means it is an ideal place to stash data which must be slowly
	 * accumulated.
	 */
	let shared_context;
	if (options.shared_context !== undefined) {
		shared_context = options.shared_context;

	} else {
		shared_context = {};
	}

	if (shared_context.counters === undefined) {
		shared_context.counters = {};
	}
	if (shared_context.last_processed === undefined) {
		shared_context.last_processed = [];
	}

	const __ka_incr_id = function(key, type) {
		if (shared_context.counters[type] === undefined) {
			shared_context.counters[type] = {
				current: 0,
				entries: {}
			}
		}

		if (shared_context.counters[type].entries[key] === undefined) {
			shared_context.counters[type].current++;
			const new_index = shared_context.counters[type].current;

			shared_context.counters[type].entries[key] = new_index;
		}

		return(shared_context.counters[type].entries[key]);
	};

	const local_filters = [];
	const local_globals = [];
	const local_functions = [];

	const addFilter = function(name, ...args) {
		if (name[0] !== '_') {
			local_filters.push(name);
		}

		if (!env) {
			return;
		}

		return(env.addFilter(name, ...args));
	}

	const addGlobal = function(name, global, ...args) {
		if (name[0] !== '_') {
			if (global instanceof Function) {
				local_functions.push(name);
			} else {
				local_globals.push(name);
			}
		}

		if (!env) {
			return;
		}

		return(env.addGlobal(name, global, ...args));
	}

	// print string to the console - can be used for debugging template generation
	// ie    {{ x | console }}
	addFilter('__ka_console', function(...args) {
		kaialpha.log(...args)
	});

	addFilter('normalize_html_id', filters.normalize_html_id);
	addFilter('__ka_incr_id', __ka_incr_id);

	addGlobal('cite', function(key) {
		const normalized_key = filters.normalize_html_id(key);
		const citation_id = __ka_incr_id(key, 'citation');
		return(`<sup><a href='#cite_${normalized_key}'>${citation_id}</a></sup>`);
	});

	addFilter('__ka_last_processed_push', function(_ignored, element_id, element_body_b64) {
		shared_context.last_processed.push({
			element_id: element_id,
			element: JSON.parse(Buffer.from(element_body_b64, 'base64'))
		});
	});

	addFilter('__ka_last_processed_pop', function(_ignored, _ignored_2) {
		/* XXX:TODO: Validate element ID */
		shared_context.last_processed.pop();
	});

	addGlobal('__ka_last_processed', function() {
		const last = shared_context.last_processed.slice(-1)[0];
		return(JSON.stringify(last));
	});

	addGlobal('__ka_abbreviations_used', function() {
		if (!shared_context._abbreviations_state) {
			return([]);
		}

		if (!shared_context._abbreviations_state.seen) {
			return([]);
		}

		const abbreviations_map = shared_context._abbreviations_state.seen;

		const retval = [];
		for (const abbreviation in abbreviations_map) {
			retval.push({
				key: abbreviation,
				value: abbreviations_map[abbreviation]
			});
		}

		return(retval);
	})

	addGlobal('__ka_citations_used', function() {
		if (shared_context.counters.citation === undefined || shared_context.counters.citation.entries === undefined) {
			return([]);
		}

		const retval = [];
		for (const short_name in shared_context.counters.citation.entries) {
			const index = shared_context.counters.citation.entries[short_name];

			let long_name;
			if (shared_context.citation_map) {
				long_name = shared_context.citation_map[short_name];
			}
			if (long_name === undefined) {
				long_name = `Unknown citation for ${short_name}`;
			}

			retval.push({
				index: index,
				key: short_name,
				value: long_name
			});
		}

		return(retval);
	});

	addGlobal('__ka_load_citations', function(entries) {
		shared_context.citation_map = {};

		if (Object.keys(entries).length === 0) {
			return('');
		}

		for (const {key, value} of entries) {
			shared_context.citation_map[key] = value;
		}

		return('');
	});

	addFilter('__ka_map_abbrevations', function(input_text) {
		return(map_abbreviations(input_text, shared_context, options));
	});

	// Turn JSON object into string
	// Can be used for debugging, or doing things like adding extra info
	// in HTML comments, ie
	//    <!-- {{ myJsonObj }} -->
	// would include a dump of the JSON object  within a HTML page
	addFilter('stringify', function (obj) {
		return JSON.stringify(obj);
	});

	if (options.parseOnly === true) {
		/* Dummy JSON parse which does not really care if the input is JSON */
		env.addFilter('json_parse', function (str) {
			if (str === undefined) {
				return([]);
			}

			if (str[0] === '[') {
				return([]);
			}

			if (str[0] === '{') {
				return({});
			}

			return({});
		});
	} else {
		addFilter('json_parse', function (str) {
			return JSON.parse(str)
		});
	}

	addFilter('reject', function(items) {
		if (!items.filter) {
			return([]);
		}

		const retval = items.filter(function(item) {
			if (item) {
				return(true);
			} else {
				return(false);
			}
		});
		return(retval);
	});

	addFilter('getattr', function (arr, attr) {
		var rtn = []

		/* XXX:TODO: Does this work ?  What does it do ? */
		// eslint-disable-next-line
		if (_.isString(attr)) {
			arr.forEach(function (o) { rtn.push(o[attr]) })
		}
		return rtn
	});

	addFilter('__ka_setattr', function(object, key, value) {
		object[key] = value;
		return(object);
	});

	addFilter('keys', filters.keys);

	addFilter('column', function (object, column) {
		if (object === undefined) {
			return([]);
		}

		if (Array.isArray(object)) {
			const values = object.map((el) => el[column])
			return(values);
		} else {
			const keys = Object.keys(object[column]);
			const result = keys.map((key) => object[column][key])
			return(result);
		}
	});

	addFilter('row', function (object, row) {
		if (object === undefined) {
			return([]);
		}

		if (Array.isArray(object)) {
			const keys = Object.keys(object[row]);
			const values = keys.map((key) => {
				return(object[row][key]);
			});
			return(values);
		} else {
			const keys = Object.keys(object);
			const result = keys.map((key) => {
				return(object[key][row]);
			});
			return(result);
		}
	});

	addFilter('extendlen', function (arr, len, val) {
		var rtn = arr.slice(0)
		for (let i = arr.length; i < len; i++) rtn.push(val)
		return rtn
	});

	addFilter('is_array', function(obj) {
		return Array.isArray(obj);
	});

	addFilter('flatten', function(base) {
		const retval = [];

		for (const key in base) {
			retval.push(base[key]);
		}

		return(retval);
	});

	addFilter('flatten_datasources', function(object, filter_type = undefined) {
		let result = {};
		for (const key of filters.keys(object)) {
			const datasource = object[key];

			if (filter_type !== undefined) {
				if (datasource.type !== filter_type) {
					continue;
				}
			}

			if (result.type === undefined) {
				result.type = datasource.type;
			} else {
				if (result.type !== datasource.type) {
					/* XXX:TODO: Continue or throw an error ? */
					continue;
				}
			}

			if (result.data === undefined) {
				result = datasource;
			} else {
				result = filters.merge_datasources([result, datasource]);
			}
		}

		return(result);
	});

	addFilter('array_slice', function(base, start = undefined, end = undefined) {
		if (Array.isArray(base)) {
			return(base.slice(start, end));
		}

		const new_array = [];

		for (const key in base) {
			new_array.push(base[key]);
		}

		return(new_array.slice(start, end));
	});

	addFilter('get_column', function(base, column_name) {
		const retval = {};
		if (base === undefined) {
			return(retval);
		}

		const data = base.data;
		if (data === undefined) {
			return(retval);
		}

		switch(base.type) {
			case 'columns-rows':
				Object.assign(retval, {
					...data[column_name]
				});
				break;
			case 'columns':
				{
					let row_index = -1;
					for (const row of data) {
						row_index++;
						retval[row_index] = row[column_name];
					}
				}
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}
		return(retval);
	});

	addFilter('__ka_get_headers', function(table) {
		if (table === undefined) {
			return([]);
		}

		if (table['@metadata'] === undefined) {
			return([]);
		}

		if (table['@metadata']['headers'] === undefined) {
			return([]);
		}

		const headers = table['@metadata']['headers'];
		const ordered_headers = table['@metadata']['ordered_headers'];
		const original_headers = [];

		if (ordered_headers !== undefined) {
			for (const header of ordered_headers) {
				original_headers.push(headers[header]);
			}
		}

		return(original_headers);
	});

	addFilter('sort_rows', function(base, column_name, direction = 'asc', mode = 'human') {
		const retval = {
			type: 'columns',
			data: []
		};

		if (base === undefined) {
			return(retval);
		}

		const data = kaialpha.lib.object_utils.copy_object(base.data);
		if (data === undefined) {
			return(retval);
		}

		let reverse;
		switch (direction) {
			case 'asc':
			case 'ascending':
			case 'increasing':
				reverse = false;
				break;
			case 'desc':
			case 'descending':
			case 'decreasing':
				reverse = true;
				break;
			default:
				throw(new kaialpha.UserError(`Unsupported sort direction ${direction}, must be one of ascending, descending`));
		}

		const sort_function = function(a, b) {
			const a_cell = a[column_name];
			const b_cell = b[column_name];

			let a_val, b_val;
			let retval;
			switch(mode) {
				case "human":
					a_val = a_cell;
					b_val = b_cell;
					break;
				case "int":
					a_val = Math.trunc(Number(a_cell));
					b_val = Math.trunc(Number(b_cell));
					break;
				case "float":
					a_val = Number(a_cell);
					b_val = Number(b_cell);
					break;
				default:
					throw(new kaialpha.UserError(`Unsupported sorting mode ${mode}, must be one of human, int, float`));
			}

			if (a_val === b_val) {
				retval = 0;
			} else if (a_val < b_val) {
				retval = -1;
			} else {
				retval = 1;
			}

			if (reverse) {
				retval *= -1;
			}

			return(retval);
		}

		retval.type = base.type;
		switch(base.type) {
			case 'columns':
				retval.data = data.sort(sort_function);
				break;
			case 'columns-rows':
				/* XXX:TODO: Support sorting column*row data */
				// eslint-disable-next-line
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}
		return(retval);
	});

	addFilter('find_rows', function(base, column_name, column_value) {
		const use_expression_matches = true;

		const retval = {
			type: 'columns',
			invalid: true,
			data: []
		};

		if (base === undefined) {
			return(retval);
		}

		const data = base.data;
		if (data === undefined) {
			return(retval);
		}

		let cell_value_matches = function(cell_value, check_value) {
			if (String(cell_value) === String(check_value)) {
				return(true);
			}

			return(false);
		};

		if (use_expression_matches) {
			cell_value_matches = function(cell_value, check_value) {
				const check = compare_expressions('__cell_value', check_value, {
					'__cell_value': cell_value
				}, {
					rhs_quote_default: true
				});

				return(check);
			};
		}

		retval.type = base.type;
		delete retval['invalid'];
		switch(base.type) {
			case 'columns-rows':
				{
					const matching_row_names = [];
					retval.data = {};

					for (const row_name in data[column_name]) {
						if (cell_value_matches(data[column_name][row_name], column_value)) {
							matching_row_names.push(row_name);
						}
					}

					for (const row_name of matching_row_names) {
						for (const check_column_name in data) {
							const cell_value = data[check_column_name][row_name];
							if (retval.data[check_column_name] === undefined) {
								retval.data[check_column_name] = {};
							}

							retval.data[check_column_name][row_name] = cell_value;
						}
					}
				}
				break;
			case 'columns':
				for (const row of data) {
					if (cell_value_matches(row[column_name], column_value)) {
						retval.data.push(row);
					}
				}
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}
		return(retval);
	});

	addFilter('includes', function(input, str) {
		if (!input || !input.includes) {
			return(false);
		}

		return(input.includes(str));
	});

	addFilter('unique', function(input, options = {}) {
		options = {
			nocase: false,
			trim: false,
			noblank: false,
			...options
		};

		if (Array.isArray(input)) {
			const output_normalized= [];
			const output = [];
			for (const value of input) {
				let value_normalized = String(value);

				if (options.nocase) {
					value_normalized = value_normalized.toLowerCase();
				}
				if (options.trim) {
					value_normalized = value_normalized.trim();
				}

				if (options.noblank) {
					if (value_normalized.trim() === '') {
						continue;
					}
				}

				if (output_normalized.includes(value_normalized)) {
					continue;
				}

				output_normalized.push(value_normalized);
				output.push(value);
			}

			return(output);
		}

		/*
		 * For non-arrays, we currently throw an error, to leave open
		 * the option of expanding this in the future.
		 */
		throw(new kaialpha.UserError('Filter "unique" must only be called on arrays'));
	});

	addFilter('get_footnotes', function(footnotes, current_variables) {
		const footnote_values = [];

		if (!footnotes || !current_variables) {
			return(footnote_values);
		}

		/*
		 * Construct array of all footnote values from
		 * table elements and multi_input variables.
		 */
		for (const footnote of footnotes) {
			if (footnote.variable_name !== undefined && footnote.variable_name !== '') {
				const variable = footnote.variable_name.toLowerCase();

				if (current_variables[variable] !== undefined) {
					const variable_footnotes = current_variables[variable];

					for (const variable_footnote of variable_footnotes) {
						footnote_values.push(variable_footnote);
					}
				}
			} else {
				footnote_values.push(footnote);
			}
		}

		return(footnote_values);
	})

	addFilter('absolute_to_cell', function(footnote, table, options) {
		/* XXX:TODO: Update the way we are handling cell and absolute footnotes */
		const datasource_name = options.datasource;
		const footnote_value = footnote.value;
		const table_data = table.data;
		const absolute_substring = footnote_value.match(/absolute(.*)\)/);
		const match_coordinates = absolute_substring[0].match('\\(([^\\)]*)');

		let coordinates;
		let cleaned_coordinates;
		if (match_coordinates !== null) {
			coordinates = match_coordinates[1].split(',');

			/* Remove unneeded extra quotes from coordinates */
			coordinates[0] = coordinates[0].replace(/['']/g, '');
			coordinates[1] = coordinates[1].replace(/['']/g, '');

			/*
			 * Converted cleaned coordinates from String to Int and
			 * store them in an array
			 */
			cleaned_coordinates = [parseInt(coordinates[0]), parseInt(coordinates[1])]
		}

		/*
		 * Convert absolute references to cell references based
		 * on table type
		 */
		const updated_reference = footnote;
		let construct_value = footnote_value;
		if (table.type === 'columns-rows') {
			let row_coordinate = cleaned_coordinates[0];
			let column_coordinate = cleaned_coordinates[1];

			if (row_coordinate === 0) {
				column_coordinate = column_coordinate - 1;
				const cell_column = Object.keys(table_data)[column_coordinate];
				construct_value = `{{${datasource_name}.data | column('${cell_column}')}}`;
			} else if (column_coordinate === 0) {
				row_coordinate = row_coordinate - 1;
				const column_header = Object.keys(table_data)[0];
				const column = table_data[column_header];
				const cell_row = Object.keys(column)[row_coordinate];
				construct_value = `{{${datasource_name}.data | row('${cell_row}')}}`;
			} else {
				row_coordinate = row_coordinate - 1;
				column_coordinate = column_coordinate - 1;
				const cell_row = Object.keys(table_data)[row_coordinate];
				const selected_column = table_data[cell_row];
				const cell_column = Object.keys(selected_column)[column_coordinate];
				construct_value = `{{${datasource_name}.data['${cell_row}']['${cell_column}']}}`;
			}
		}

		if (table.type === 'columns') {
			let row_coordinate = cleaned_coordinates[0];

			if (row_coordinate === 0) {
				const selected_column = table_data[row_coordinate];
				const column_coordinate = cleaned_coordinates[1];
				const cell_column = Object.keys(selected_column)[column_coordinate];
				construct_value = `{{${datasource_name}.data | column('${cell_column}')}}`
			} else {
				row_coordinate = row_coordinate - 1;
				const selected_column = table_data[row_coordinate];
				const cell_column = Object.keys(selected_column)[row_coordinate];
				construct_value = `{{${datasource_name}.data['${row_coordinate}']['${cell_column}']}}`;
			}
		}

		/* Not implemented yet */
		if (table.type === 'rows') {
			throw(new Error('Unsupported type: rows'));
		}

		updated_reference.value = construct_value;
		return(updated_reference);
	});

	addFilter('absolute', function(base, row, column) {
		const retval = [];

		if (!(base && base.data && base.type && row && column)) {
			return retval;
		}

		switch (base.type) {
			case 'columns-rows':
				/* Eg: Column-rows
				data : {a : {i : 1, ii:2}, b:{i:3, ii:4}}
				*/
				if (String(row) === '0') {
					/* As data is structured in a diffrent way, used Object.keys to generate header elements */
					const value = Object.keys(base.data)[column - 1];
					if (value) {
						retval.push(value);
					}
				} else if (String(row) !== '0' && String(column) === '0') {
					const column_object = base.data[Object.keys(base.data)[column]];
					const value = Object.keys(column_object)[row - 1];
					if (value) {
						retval.push(value);
					}
				} else {
					/* To get the specified column object, first get the specified column object
						eg : {i:3, ii:4}, in that get the value of the that particular row (ie. i or ii);
					*/
					const column_object = base.data[Object.keys(base.data)[column - 1]];
					const key = Object.keys(column_object)[row - 1];
					const value = column_object[key];
					if (value) {
						retval.push(value);
					}
				}
				break;
			case 'columns':
				/* 
					Eg: data :[{A:1, B:2, C:3}];
				*/
				if (String(row) === '0') {
					const value = Object.keys(base.data[0])[column];
					if (value) {
						retval.push(value);
					}
				} else {
					const value = base.data[row - 1][Object.keys(base.data[row - 1])[column]];
					if (value) {
						retval.push(value);
					}
				}
				break
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}

		return retval;
	});

	addFilter('find_columns', function(base, row_name, row_value) {
		const retval = {
			type: 'columns',
			data: []
		};

		if (base === undefined) {
			return(retval);
		}

		const data = base.data;
		if (data === undefined) {
			return(retval);
		}

		retval.type = base.type;
		switch (base.type) {
			case 'columns-rows':
				{
					const return_data = {};
					const matching_column_names = [];

					for (const column_name in data) {
						if (String(data[column_name][row_name]) === String(row_value)) {
							matching_column_names.push(column_name);
						}
					}

					for (const column of matching_column_names) {
						for (const column_values in data[column]) {
							if (return_data[column] === undefined) {
								return_data[column] = {};
							}
							return_data[column][column_values] = data[column][column_values];
						}
					}
					retval.data = return_data;
				}
				break;
			case 'columns':
				{
					const matching_column_names = [];
					for (const column_header in data[row_name]) {
						if (String(data[row_name][column_header]) === String(row_value)) {
							matching_column_names.push(column_header);
						}
					}

					for (const matching_column of matching_column_names) {
						for (const row of data) {
							const cell_value = { [matching_column]: row[matching_column] };
							retval.data.push(cell_value);
						}
					}
				}
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}

		return(retval);
	});

	if (options.parseOnly === true) {
		addFilter('eval', function(_ignored_template) {
			return('');
		});
	} else {
		addFilter('eval', function(template) {
			if (template === undefined || template === null) {
				return(template);
			}

			const result = env.renderString(template, variables);

			return(result);
		});
	}

	return({
		env: env,
		globals: local_globals,
		functions: local_functions,
		filters: local_filters,
		shared_context: shared_context
	});
}

const NullLoader = nunjucks.Loader.extend({
	init: function() {},
	getSource: function() {
		return('');
	}
});

function createEnvironment(variables, options = {}) {
	const env = new nunjucks.Environment(new NullLoader('/'), { autoescape: false });
	addKaFilters(env, variables, options);
	return(env);
}

async function render(file, variables, options = {}) {
	const env = new nunjucks.Environment(new nunjucks.FileSystemLoader('/'), { autoescape: false });

	addKaFilters(env, variables, options);

	let retval;
	try {
		retval = env.render(file, variables);
	} catch (render_error) {
		if (options.parseOnly === true) {
			kaialpha.log.debug('Failed to parse:', file, '; error', render_error);
			return(false);
		}

		const error_info_json = env.renderString('{{__ka_last_processed()}}');

		/*
		 * If the error could not be traced to an element, rethrow the error
		 */
		if (error_info_json === '') {
			throw(render_error);
		}

		const error_info = JSON.parse(error_info_json);

		throw(new kaialpha.NunjucksError(error_info, render_error));
	}
	if (options.parseOnly === true) {
		return(true);
	}

	return(retval);
}

function renderString(template, variables, options = {}) {
	if (template === undefined || template === null) {
		return(template);
	}

	let env;
	if (options.parseOnly === true) {
		env = new nunjucks.Environment(new nunjucks.FileSystemLoader('/'), { autoescape: false });
	} else {
		env = new nunjucks.Environment(new NullLoader('/'), { autoescape: false });
	}

	addKaFilters(env, variables, options);

	let rendered;
	try {
		rendered = env.renderString(template, variables);
	} catch (render_error) {
		if (options.parseOnly === true) {
			kaialpha.log.debug('Failed to parse:', template, '; error:', render_error);

			return(false);
		}

		throw(render_error);
	}

	if (options.parseOnly === true) {
		return(true);
	}

	return(rendered);
}

async function parse(file) {
	return(await render(file, {}, {
		parseOnly: true
	}));
}

async function parseString(template) {
	return(renderString(template, {}, {
		parseOnly: true
	}));
}

if (_testing) {
	_testing.renderString = function() {
		const variables = {
			'var1': {
				'test1': 'true',
				'test2': 'false'
			},
			'var2': '{{var1.test1}}',
			'var3': "See also {{cite('test_citation')}} which is a citation.",
			'abbr': 'testing Adverse Event abbreviations'
		};
		const checks = [
			{
				template: '{{var1 | keys}}',
				result: 'test1,test2'
			},
			{
				template: '{{var2 | eval}}',
				result: 'true'
			},
			{
				template: "Citation1: {{cite('test_citation')}}, Citation2: {{cite('test_citation_2')}}. {{var3 | eval}}",
				result: "Citation1: <sup><a href='#cite_test_citation'>1</a></sup>, Citation2: <sup><a href='#cite_test_citation_2'>2</a></sup>. See also <sup><a href='#cite_test_citation'>1</a></sup> which is a citation."
			},
			{
				template: "{%- set x -%}Test eval AE of {{abbr | eval}} abbreviations Adverse Event{%- endset -%}{{x | __ka_map_abbrevations}}",
				result: "Test eval Adverse Event (AE) of testing AE abbreviations abbreviations AE"
			},
			{
				template: "{%- set x -%}Test eval {{abbr | eval}} with AE abbreviations Adverse Event{%- endset -%}{{x | __ka_map_abbrevations}}",
				result: "Test eval testing Adverse Event (AE) abbreviations with AE abbreviations AE"
			}
		];

		for (const check of checks) {
			const result = renderString(check.template, variables, {
				abbr_list_entries: [
					{key: 'AE', value: 'Adverse Event'},
					{key: 'AB', value: 'Alpha Bravo'}
				]
			});

			/* istanbul ignore if */
			if (result !== check.result) {
				throw(new Error(`Rendering template ${check.template} we got "${result}" but expected "${check.result}"`));
			}
		}

		return(true);
	};
}

function renderStringNoKaiAlpha(...args) {
	const env = new nunjucks.Environment(new NullLoader('/'), { autoescape: false });

	return(env.renderString(...args));
}

if (_testing) {
	_testing.renderStringNoKaiAlpha = function() {
		const variables = {
			'var1': {
				'test1': 'true',
				'test2': 'false'
			}
		};
		const checks = [
			{
				template: '{{var1 | keys}}',
				result: ''
			}
		];

		for (const check of checks) {
			let result = '';
			try {
				result = renderStringNoKaiAlpha(check.template, variables);
			} catch (ignored_error) {
				/*
				 * We just ignore this error
				 */
			}

			/* istanbul ignore if */
			if (result !== check.result) {
				throw(new Error(`Rendering template ${check.template} we got ${result} but expected ${check.result}`));
			}
		}

		return(true);
	};
}

function compute_expression_generator(expression) {
	/*
	 * If the expression is wrapped in Nunjucks expansion operator, remove
	 * that wrapping before evaluating (since we will add it back).
	 */
	if (expression.slice(0, 2) === '{{' && expression.slice(-2) === '}}') {
		expression = expression.slice(2, -2);
	}

	return(expression);
}

function compute_expression(expression, variables, options = {}) {
	options = {
		throw_errors: false,
		...options
	};

	expression = compute_expression_generator(expression);

	let result;
	try {
		result = renderString(`{{${expression}}}`, variables);
	} catch (render_error) {
		if (options.throw_errors === true) {
			throw(render_error);
		}

		result = '';
		kaialpha.log.debug('Failed to render expression:', expression, 'error:', render_error);
	}

	return(result);
}

if (_testing) {
	const check = function (checks, datasource) {
		for (const check of checks) {
			const sorted_json_str = compute_expression(`datasource | ${check.expression} | stringify`, { datasource })
			let sorted;
			if (sorted_json_str === '') {
				sorted = '';
			} else {
				sorted = JSON.parse(sorted_json_str);
			}

			if (check.output_one_of === undefined && check.output === undefined) {
				if (Object.keys(sorted).length === 0) {
					return;
				}

				throw(new Error(`When evaluating an empty dataset with expression ${check.expression} we expected nothing, but got ${JSON.stringify(sorted)}`));
			}

			if (check.output_one_of === undefined && check.output !== undefined) {
				check.output_one_of = [check.output];
			}

			let found = false;
			for (const check_sorted of check.output_one_of) {
				if (check_sorted instanceof Object) {
					if (kaialpha.lib.testing_utils.object_equals(sorted, check_sorted)) {
						found = true;
						break;
					}
				} else {
					if (check_sorted === sorted) {
						found = true;
						break;
					}
				}
			}

			/* istanbul ignore if */
			if (!found) {
				throw(new Error(`When evaluating data ${JSON.stringify(datasource)} with expression ${check.expression} we expected one of ${JSON.stringify(check.output_one_of)} but got ${JSON.stringify(sorted)}`));
			}
		}
	}

	_testing.addKaFilters = function() {
		const checks = [
			{ expression: "[1, 2, 3, 3, 4, 1, 0] | unique", output: "1,2,3,4,0" },
			{ expression: '["a", "b", "c", "c ", "d"] | unique', output: "a,b,c,c ,d" },
			{ expression: '["a", "b", "c", "c ", "d"] | unique({trim: true})', output: "a,b,c,d" },
			{ expression: '["a", "b", "C", "c ", "d"] | unique({trim: true, nocase: true})', output: "a,b,C,d" },
			{ expression: '["a", "b", "C", "", "c ", "d"] | unique({trim: true, nocase: true})', output: "a,b,C,,d" },
			{ expression: '["a", "b", "C", "", "c ", "d"] | unique({trim: true, nocase: true, noblank: true})', output: "a,b,C,d" },
		];

		for (const check of checks) {
			let variables = {};
			if (check.variables) {
				variables = check.variables;
			}

			const check_output = renderString(`{{${check.expression}}}`, variables);
			if (check_output !== check.output) {
				throw(new Error(`When checking filter and expression: ${check.expression} with variables ${JSON.stringify(variables)} we got ${check_output} but expected ${check.output}`));
			}
		}

		return(true);
	};

	_testing.addKaFilters_datasource = function () {
		const datasource = {
			data: [
				{ a: 1, b: 0.5, c: 'Test' },
				{ a: 2, b: -0.5, c: 'Jest' },
				{ a: -1, b: 2, c: 'Best' }
			],
			type: "columns"
		}

		const checks = [
			{ expression: "sort_rows('a', 'asc', 'int') | get_column('a')", output: { "0": -1, "1": 1, "2": 2 } },
			{ expression: "sort_rows('b', 'asc', 'int') | get_column('b')", output_one_of: [{ "0": 0.5, "1": -0.5, "2": 2 }, { "0": -0.5, "1": 0.5, "2": 2 }] },
			{ expression: "sort_rows('b', 'asc', 'float') | get_column('b')", output: { "0": -0.5, "1": 0.5, "2": 2 } },
			{ expression: "sort_rows('c', 'asc') | get_column('c')", output: { "0": "Best", "1": "Jest", "2": "Test" } },
			{ expression: "sort_rows('b', 'asc') | get_column('b')", output: { "0": -0.5, "1": 0.5, "2": 2 } },
			{ expression: "sort_rows('a', 'asc') | get_column('a')", output: { "0": -1, "1": 1, "2": 2 } },
			{ expression: "sort_rows('a', 'desc') | get_column('a')", output: { "0": 2, "1": 1, "2": -1 } },
			{ expression: "sort_rows('a', 'asc') | get_column('b') | flatten | sort | array_slice(1)", output: [0.5, 2] },
			{ expression: "sort_rows('a', 'asc') | get_column('b') | array_slice(1)", output: [0.5, -0.5] },
			{ expression: "find_rows('a', '>0') | sort_rows('a') | get_column('a')", output: { "0": 1, "1": 2 } },
			{ expression: "find_rows('a', '>0') | sort_rows('a') | get_column('a') | first", output: 1 },
			{ expression: "find_rows('a', '<0') | sort_rows('a') | get_column('a') | first", output: -1 },
			{ expression: "find_rows('a', '0') | sort_rows('a') | get_column('a') | first", output: {} },
			{ expression: "find_rows('a', '2') | sort_rows('a') | get_column('a') | first", output: 2 },
			{ expression: "find_rows('c', '~=Te') | get_column('c') | flatten", output: ["Test"] },
			{ expression: "find_rows('c', 'Test') | get_column('c') | flatten", output: ["Test"] },
			{ expression: "absolute('0', '0')", output: ["a"] },
			{ expression: "absolute('1', '0')", output: [1] },
			{ expression: "row('0')", output: [{ "a": 1, "b": 0.5, "c": "Test" }, "c"] },
			{ expression: "find_columns('2', '2')", output: { "type": "columns", "data": [{ "b": 0.5 }, { "b": -0.5 }, { "b": 2 }] } }
		];

		check(checks, datasource);
		check(checks.map(function(check) {
			return({
				...check,
				output_one_of: undefined,
				output: undefined
			});
		}), undefined);

		return(true);
	};

	_testing.addKaFilters_datasource_columns_rows = function() {
		const datasource = {
			data: {
				'a': {
					i: '1',
					ii: '2',
					iii: '3',
				},
				'b': {
					i: '5',
					ii: '6',
					iii: '7',
				}
			},
			type: "columns-rows"
		}

		const checks = [
			{ expression: "absolute('0', '1')", output: ["a"] },
			{ expression: "absolute('1', '0')", output: ["i"] },
			{ expression: "absolute('1', '1')", output: ["1"] },
			{ expression: "find_columns('i', '5')", output: { "type": "columns-rows", "data": { "b": { "i": "5", "ii": "6", "iii": "7" } } } },
			{ expression: "row('a')", output: [{ "i": "1", "ii": "2", "iii": "3" }, null] },
			{ expression: "get_column('a')", output: { "i": "1", "ii": "2", "iii": "3" } },
			{ expression: "is_array()", output: false },
			{ expression: "find_rows('a','1')", output: { "type": "columns-rows", "data": { "a": { "i": "1" }, "b": { "i": "5" } } } },
		];

		check(checks, datasource);
		check(checks.map(function(check) {
			return({
				...check,
				output_one_of: undefined,
				output: undefined
			});
		}), undefined);

		return(true);
	};

	_testing.map_abbreviations = function() {
		const options = {
			abbr_list_entries: [
				{key: 'AE', value: 'Adverse Event'},
				{key: 'AB', value: 'Alpha Bravo'}
			]
		};

		const checks = [
			{
				in: 'The AE were bad.',
				out: 'The Adverse Event (AE) were bad.',
				used: 'AE'
			},
			{
				in: 'The Adverse Event were bad.',
				out: 'The Adverse Event (AE) were bad.',
				used: 'AE'
			},
			{
				in: 'The AE were bad.  Some AE were really bad.',
				out: 'The Adverse Event (AE) were bad.  Some AE were really bad.',
				used: 'AE'
			},
			{
				in: 'The Adverse Event were bad.  Some AE were really bad.',
				out: 'The Adverse Event (AE) were bad.  Some AE were really bad.',
				used: 'AE'
			},
			{
				in: 'The Adverse Event were bad.  Some Adverse Event were really bad.',
				out: 'The Adverse Event (AE) were bad.  Some AE were really bad.',
				used: 'AE'
			},
			{
				in: 'The AE were bad.  Some Adverse Event were really bad.',
				out: 'The Adverse Event (AE) were bad.  Some AE were really bad.',
				used: 'AE'
			},
			{
				in: 'The AE were bad.  Some Adverse Event were really bad.  AE = adverse event',
				out: 'The Adverse Event (AE) were bad.  Some AE were really bad.  AE = AE',
				used: 'AE'
			},
			{
				in: 'TheAE were bad.  Some AE were really bad.',
				out: 'TheAE were bad.  Some Adverse Event (AE) were really bad.',
				used: 'AE'
			},
			{
				in: ['The AE were bad.  Some AE were really bad.', 'This AE is not expanded.'],
				out: 'The Adverse Event (AE) were bad.  Some AE were really bad. This AE is not expanded.',
				used: 'AE'
			},
			{
				in: ['The AE were bad.  Some AE were really bad.', 'This AE is not expanded, this AB is though.'],
				out: 'The Adverse Event (AE) were bad.  Some AE were really bad. This AE is not expanded, this Alpha Bravo (AB) is though.',
				used: 'AB,AE'
			},
			{
				in: ['This Adverse Event is spelled out. This Adverse Event is also spelled out.', 'This Adverse Event is spelled out third.'],
				out: 'This Adverse Event (AE) is spelled out. This AE is also spelled out. This AE is spelled out third.',
				used: 'AE'
			}
		];

		for (const check of checks) {
			const shared_context = {};
			const env = createEnvironment({}, { shared_context });

			let check_input;
			if (Array.isArray(check.in)) {
				check_input = check.in;
			} else {
				check_input = [check.in];
			}

			const check_output_aggregate = [];
			for (const check_input_part of check_input) {
				check_output_aggregate.push(map_abbreviations(check_input_part, shared_context, options));
			}
			const check_output = check_output_aggregate.join(' ');

			/* istanbul ignore if */
			if (check_output !== check.out) {
				throw(new Error(`Failed mapping abbreviations in text "${check.in}", got "${check_output}" but expected "${check.out}"`));
			}

			/*
			 * Ensure that the list of used abbreviations is sane
			 */
			const abbrs_used = JSON.parse(env.renderString('{{__ka_abbreviations_used() | stringify}}')).map(function(item) {
				return(item.key);
			}).sort().join(',');
			const check_used_normalized = check.used.split(',').sort().join(',');

			if (abbrs_used !== check_used_normalized) {
				throw(new Error(`Failed in check for used abbreviations, got ${abbrs_used} but expected ${check_used_normalized}`));
			}
		}

		return(true);
	};

	_testing.compute_expression = function() {
		const variables = {
			var1: 'abc',
			var2: 'def',
			var3: 100,
			var4: 99,
			var5: {
				a: 'test1',
				b: 'test2'
			},
			var6: '100',
			var7: '99',
		};
		const checks = [
			{
				expression: "var1",
				result: "abc"
			},
			{
				expression: "{{var1}}",
				result: "abc"
			},
			{
				expression: "{{var3 + var4}}",
				result: "199"
			},
			{
				expression: "(var6 | int) + (var7 | int)",
				result: "199"
			}
		];

		for (const check of checks) {
			const result = compute_expression(check.expression, variables);

			/* istanbul ignore if */
			if (result !== check.result) {
				throw(new Error(`When computing expression ${check.expression} with variables ${JSON.stringify(variables)} we got ${result} but expected ${check.result}`));
			}
		}

		return(true);
	};
}

function compare_expressions_generator(options = {}) {
	options = {
		lhs: undefined,
		rhs: undefined,
		rhs_quote_default: false,
		variable: undefined,
		bare_if: false,
		if_statement: 'if',
		...options
	};

	if (options.variable === undefined && options.lhs === undefined) {
		throw(new Error('Both parameters "variable" and "lhs" cannot be undefined, and they are'));
	}

	if (options.rhs === undefined) {
		throw(new Error('Parameter "rhs" must not be undefined'));
	}

	if (options.bare_if === true) {
		if (options.true !== undefined || options.false !== undefined) {
			throw(new Error('Parameters "true" and "false" are meaningless if "bare_if" is true'));
		}
	} else {
		if (options.true === undefined && options.false === undefined) {
			throw(new Error('Parameters "true" and "false" may not both be undefined'));
		}
	}

	const token = uuid.v4().replace(/-/g, "");

	if (options.variable === undefined) {
		options.variable = `__test_${token}`;
	}

	if (options.lhs !== undefined) {
		options.lhs_template = `{%- set ${options.variable} = ${compute_expression_generator(options.lhs)} -%}`;
	}

	const case_types = new RegExp('^(<=|>=|==|!=|~=|<|>)(.*$)');
	const rhs_matched = options.rhs.match(case_types);
	let op = '==';
	if (rhs_matched) {
		op = rhs_matched[1];
		options.rhs = rhs_matched[2];
	}
	options.op = op;

	/*
	 * If quoting is enabled by default and an operation is being performed
	 * where quoting makes sense, quote the RHS to treat it as a string.
	 */
	if (options.rhs_quote_default) {
		switch (op) {
			case '==':
			case '!=':
				/* XXX:TODO: This doesn't deal with RHS that have quotes in them already */
				options.rhs = `"${options.rhs}"`;
				break;
			default:
				/* Nothing to do by default */
				break;
		}
	}

	const rhs_template_parts = [];
	switch (op) {
		case '~=':
			rhs_template_parts.push(`{%- ${options.if_statement} (r/(${options.rhs})/).test(${options.variable}) -%}`);
			break;
		default:
			rhs_template_parts.push(`{%- ${options.if_statement} ${options.variable} ${op} ${options.rhs} -%}`);
			break;
	}

	if (options.bare_if !== true) {
		rhs_template_parts.push(options.true)
		rhs_template_parts.push('{%- else -%}');
		rhs_template_parts.push(options.false)
		rhs_template_parts.push('{%- endif -%}');
	}

	options.rhs_template = rhs_template_parts.join('');

	return(options);
}

function compare_expressions(lhs, rhs, variables, options = {}) {
	/*
	 * Ensure that variables is an object
	 */
	variables = {
		...variables
	};

	const template_info = compare_expressions_generator({
		...options,
		lhs: lhs,
		rhs: rhs,
		true: 'fe946d50-a7e1-48b2-8a4b-6228ed5bdf51'
	});
	const template = `${template_info.lhs_template}${template_info.rhs_template}`

	let result;
	try {
		result = renderString(template, variables);
	} catch (render_error) {
		kaialpha.log.debug('Failed to render expression for comparison:', {lhs, rhs}, 'error:', render_error);
	}

	if (result === 'fe946d50-a7e1-48b2-8a4b-6228ed5bdf51') {
		return(true);
	}

	return(false);
}

if (_testing) {
	_testing.compare_expressions = function() {
		const variables = {
			var1: 'abc',
			var2: 'def',
			var3: 100,
			var4: 99,
			var5: {
				a: 'test1',
				b: 'test2'
			}
		};
		const checks = [
			{
				lhs: "var1",
				rhs: "var2",
				result: false
			},
			{
				lhs: "var3",
				rhs: "var4",
				result: false
			},
			{
				lhs: "var3 | int",
				rhs: ">(var4 | int)",
				result: true
			},
			{
				lhs: "(var3 | int) - 1",
				rhs: "var4",
				result: true
			},
			{
				lhs: "var5 | keys",
				rhs: "\"a,b\"",
				result: true
			},
			{
				lhs: "var1",
				rhs: "~=^a",
				result: true
			},
			{
				lhs: "var2",
				rhs: "~=^[b-z]",
				result: true
			},
			{
				lhs: '"test"',
				rhs: '"test"',
				include_variables: false,
				result: true
			},
			{
				lhs: '"test"',
				rhs: '"jest"',
				include_variables: false,
				result: false
			},
		];

		for (const check of checks) {
			let result;
			if (check.include_variables === false) {
				result = compare_expressions(check.lhs, check.rhs);
			} else {
				result = compare_expressions(check.lhs, check.rhs, variables);
			}

			/* istanbul ignore if */
			if (result !== check.result) {
				if (check.include_variables === false) {
					throw(new Error(`When testing expression ${check.lhs} against ${check.rhs} we got ${result} but expected ${check.result}`));
				} else {
					throw(new Error(`When testing expression ${check.lhs} against ${check.rhs} with variables ${JSON.stringify(variables)} we got ${result} but expected ${check.result}`));
				}
			}
		}

		return(true);
	};

	/* istanbul ignore next */
	const exception_handler = function (fun, ...args) {
		try {
			fun(...args);
			return true;
		} catch (err) {
			return false;
		}
	}

	_testing.compare_expressions_generator_exceptions = function () {
		let result = false;

		const options = {
			variable: undefined,
			lhs: undefined,
			rhs: undefined,
			bare_if: true,
			true: 'true',
			false: 'false',
		}

		result = exception_handler(compare_expressions_generator, options);

		/* istanbul ignore if */
		if (result) {
			throw new Error(`While performing exceptions for comapare expression generator, expected an exception while passsing ${JSON.stringify(options)} but passed`);
		}

		options.lhs = 'filter';

		result = exception_handler(compare_expressions_generator, options);

		/* istanbul ignore if */
		if (result) {
			throw new Error(`While performing exceptions for comapare expression generator, expected an exception while passsing ${JSON.stringify(options)} but passed`);
		}

		options.rhs = 'filter';

		result = exception_handler(compare_expressions_generator, options);

		/* istanbul ignore if */
		if (result) {
			throw new Error(`While performing exceptions for comapare expression generator, expected an exception while passsing ${JSON.stringify(options)} but passed`);
		}

		options.true = undefined;
		options.false = undefined;
		options.bare_if = false;

		result = exception_handler(compare_expressions_generator, options);

		/* istanbul ignore if */
		if (result) {
			throw new Error(`While performing exceptions for comapare expression generator, expected an exception while passsing ${JSON.stringify(options)} but passed`);
		}
		return true;
	}
}

function valid_identifier(id) {
	if (id === undefined) {
		return(false);
	}

	if (id === '') {
		return(false);
	}

	if (id.match === undefined) {
		return(false);
	}

	if (id.match(/^[A-Za-z0-9_]+$/) === null) {
		return(false);
	}

	return(true);
}

const builtinFilters = ['abs', 'batch', 'capitalize', 'center', 'dictsort', 'dump', 'escape', 'first', 'float', 'indent', 'groupby', 'int', 'join', 'last', 'length', 'list', 'lower', 'nl2br', 'random', 'reject', 'rejectattr', 'replace', 'reverse', 'round', 'safe', 'select', 'selectattr', 'slice', 'sort', 'string', 'striptags', 'sum', 'title', 'trim', 'truncate', 'upper', 'urlencode', 'urlize', 'wordcount'];
const customFilters = addKaFilters().filters;

const constants = {
	operators: ['|', '+', '-', '/', '//', '%', '*', '**', '==', '===', '!=', '!==', '>', '>=', '<', '<=', '~='],
	filters: [...builtinFilters, ...customFilters],
	custom_filters: customFilters,
	any: 'any_a3d9a401ff07cff669c0ca18636b3fec'
};

const _to_export_auto = {
	createEnvironment,
	render,
	renderString,
	parse,
	parseString,
	filters,
	noKaiAlpha: {
		renderString: renderStringNoKaiAlpha
	},
	compute_expression,
	compare_expressions,
	compute_expression_generator,
	compare_expressions_generator,
	valid_identifier,
	constants,
	_testing
};
export default _to_export_auto;
