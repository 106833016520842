import React from 'react';

import Button from '../Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { CardContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import { get_user_info } from '../../api/user';
import date_utils from '../../lib/utils/date_utils';
import document_lib from '../../api/document';

const uuid = require('uuid');

class Comment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			comments_opened: [],
			collapse_opened: null,
			comment: null,
			reply_opened: null,
			addCommentDisabled: false,
			show_comments: false,
			element_id: '',
			document: {},
			all_comments: {}
		}
		this.add_or_remove_comments_view = this.add_or_remove_comments_view.bind(this);
	}

	async componentDidMount() {
		if (this.props.id !== undefined && this.props.id !== null) {
			const separators = ['\\+', '_'];
			const spliced_id = this.props.id.split(new RegExp(separators.join('|'), 'g'));
			let element_id, document_id, document;
			const document_id_match = /(^\w{8})-(\w{4})-(\w{4})-(\w{4})-(\w{12})/;

			if (spliced_id && spliced_id.length > 1 && spliced_id[1].match(document_id_match) !== null &&
				spliced_id[1].match(document_id_match).length === 6) {
				element_id = spliced_id[0];
				document_id = spliced_id[1];
				document = await document_lib.getDocumentById(document_id);
			} else {
				element_id = this.props.id;
				document = this.props.document;
			}

			this.setState({element_id: element_id});
			this.setState({document: document});
		}
	}

	add_or_remove_comments_view(id, state) {
		const type = this.props.type;

		if (type !== 'document') {
			return null;
		}

		if (state === 'show') {
			this.setState({ show_comments: true });
			this.get_comment_view(id);
		} else if (state === 'hide') {
			this.setState({ show_comments: false });
		} else {
			const ids = this.state.comments_opened;
			if (ids.includes(id)) {
				const index = ids.indexOf(id);
				if (index > -1) {
					ids.splice(index, 1);
				}
				this.setState({ comments_opened: ids });
				this.setState({ show_comments: false });
			} else {
				ids.push(id);
				this.setState({ comments_opened: ids });
				this.setState({ show_comments: true });
			}
			this.get_comment_view(id);
		}
	}

	get_comment_view(id) {
		this.setState({ addCommentDisabled: false });

		if (!(id)) {
			return null;
		}

		if (this.state.reply_opened !== null) {
			this.setState({ addCommentDisabled: true });
		}
	}

	get_individual_comments() {
		const element_id = this.state.element_id;
		const comments = this.state.document.comments;

		if (comments && comments.elements && Array.isArray(comments.elements[element_id])) {
			return(comments.elements[element_id].map((commentElement) => {
				return this.get_comment_structure(commentElement, false, comments.elements, element_id)
			}))
		}
	}

	get_comment_structure(commentElement, isSubComment, parentComments = {}, sectionId) {
		let commentState = ' ';
		if (commentElement.state) {
			commentState = ` - ${commentElement.state}`;
		}
		return(
			<CardContent id={commentElement.id} key={commentElement.id}>
				<Typography variant="subtitle1">{commentElement.author}
					<span style={{ fontSize: '12px', marginLeft: '10px' }}>{date_utils.format_date(commentElement.date) + commentState}</span>
					{this.get_collapse_icon(commentElement)}
				</Typography>
				<Collapse in={((this.state.collapse_opened && this.state.collapse_opened === commentElement.id) || commentElement.state !== 'RESOLVED')}>
					<Typography variant="body2">{commentElement.comment}</Typography>
					{isSubComment ? null : this.get_action_buttons(commentElement, sectionId)}
					{this.get_add_comment_view(commentElement.id)}
					{this.get_sub_comments(parentComments[commentElement.id])}
				</Collapse>
			</CardContent>
		);
	}

	get_collapse_icon(commentElement) {
		if (commentElement.state === 'RESOLVED') {
			return(
				<IconButton
					style={{ marginLeft: '10%' }}
					onClick={() => this.state.collapse_opened ? this.setState({ collapse_opened: null }) : this.setState({ collapse_opened: commentElement.id })}>
					{this.state.collapse_opened && this.state.collapse_opened === commentElement.id ? <ArrowDropUp /> : <ArrowDropDown />}
				</IconButton>
			);
		}
		return null;
	}

	get_action_buttons(comment, sectionId) {
		return(
			<div>
				<Button
					disabled={this.state.reply_opened !== null}
					onClick={() => this.add_comment(comment.id)}
					color="primary"
					style={{
						height: "25px",
						width: 'fit-content', padding: '5px'
					}}>Reply</Button>
				<Button
					disabled={comment.state === 'RESOLVED'}
					onClick={() => this.save_resolved(sectionId, comment)}>Mark Resolved</Button>
				<Button
					onClick={() => this.route_to_document_version(comment.version)}
					disabled={!comment.version}
					color="primary" >View Version</Button>
			</div>
		);
	}

	get_sub_comments(element) {
		if (element && Array.isArray(element)) {
			return element.map(commentElement => {
				return this.get_comment_structure(commentElement, true);
			});
		}
		return null;
	}

	get_add_comment_view(id) {
		if (this.state.reply_opened !== id) {
			return(null);
		}

		return(
			<CardContent>
				<TextField
					id="standard-multiline-flexible"
					label="Add Comment"
					multiline
					rowsMax={4}
					value={this.state.comment ? this.state.comment : ""}
					onChange={(event) => this.add_comment_text(event.target.value)}
					style={{ width: '100%' }}>
				</TextField>
				<Button
					color="primary"
					onClick={() => this.save_comment()}
				>Save
				</Button>
				<Button
					color="secondary"
					onClick={() => this.setState({ reply_opened: null })}
				>Cancel
				</Button>
			</CardContent>
		);
	}

	add_comment(id) {
		this.setState({reply_opened: id, comment: null });
	}

	add_comment_text(text) {
		this.setState({ comment: text });
	}

	async save_comment() {
		if (!this.state.comment) {
			return null;
		}

		let element_id = this.state.reply_opened;
		const spliced_id = element_id.split('+');
		if (spliced_id.length === 2) {
			element_id = spliced_id[0];
		}

		let obj = this.props.type === 'document' ? this.state.document : this.props.template;
		const user = await get_user_info();
		const comment = {
			id: uuid.v4(),
			author: user.name,
			date: new Date().toISOString(),
			comment: this.state.comment,
			version: obj.version,
			state: 'IN REVIEW'
		}

		if (obj.comments && obj.comments.elements) {
			if (obj.comments.elements[element_id] && Array.isArray(obj.comments.elements[element_id])) {
				obj.comments.elements[element_id].push(comment);
			} else {
				obj.comments.elements[element_id] = new Array(comment);
			}
		} else {
			const commentRef = {};
			commentRef[element_id] = new Array(comment);
			const commentObject = {
				comments: {
					elements: commentRef
				}
			}
			obj = Object.assign(obj, commentObject);
		}

		this.check_version_and_updates(obj.id, 'HEAD', obj.comments);

		this.setState({reply_opened: null});
	}

	async check_version_and_updates(id, objVersion, comment) {
		let version = objVersion;

		if (this.props.version && version !== this.props.version) {
			version = this.props.version;
		}

		let document_id = id;
		if (document_id === undefined) {
			document_id = this.props.document.id;
			this.setState({element_id: document_id});
			this.setState({document: this.props.document});
		}

		const response = await document_lib.updateDocumentComments(document_id, version, comment);
		this.setState({ reply_opened: null, comment: null});
		this.props.updateDocumentVersion(response.version);

		/* Update state with updated document */
		this.update_document(document_id, response.version);
	}

	route_to_document_version(versionId) {
		const documentId = this.state.document.id;
		const path = `/activity/doceditor/${documentId}/version/${versionId}`
		this.props.routeVersion(path);
	}

	async save_resolved(sectionId, comment) {
		const document = this.props.type === 'document' ? this.state.document : this.props.template;
		const comments = document.comments;
	
		comment.state = 'RESOLVED';
		let sectionArray = comments.elements[sectionId];
		if (sectionArray) {
			sectionArray = sectionArray.filter(e => e.id !== comment.id);
			sectionArray.push(comment);
			comments.elements[sectionId] = sectionArray;
			this.check_version_and_updates(document.id, document.version, comments);
			this.update_document(document.id, document.version)
		}
	}

	async update_document(id, version) {
		/* Update state with updated document */
		const updated_document = await document_lib.getDocumentById(id, version);
		this.setState({document: updated_document});
	}

	render() {
		let comments_view;
		if (this.state.show_comments === true) {
			comments_view =
				<div>
					<Card variant='outlined'>
						<CardContent>
							<Typography>Comments:</Typography>
							<Button onClick={() => {
								this.add_comment(this.props.id)
							}} disabled={this.state.addCommentDisabled}
							variant='contained'
							color='primary'
							style={{ height: '25px', width: 'fit-content', padding: '5px' }}
							>Add Comment</Button>
							{this.get_add_comment_view(this.props.id)}
							{this.get_individual_comments()}
						</CardContent>
					</Card>
				</div>
		}

		return(
			<div className='comments-view'>
				{comments_view}
			</div>
		);
	}
}

export default Comment;
