import React from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid'
import { Card, CardContent, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Add, ViewAgenda, NotInterested, Description, AccessTime, Search, PlaylistAddCheck } from '@material-ui/icons';

class CardViews extends React.Component {
	constructor(props) {
		super(props);

		this.colorStateMap = {
			textColor: '#004b9c',
			backgroundColor: '#d6eeff'
		};

		this.iconMap = {
			"Template": <ViewAgenda style={{ color: this.colorStateMap.textColor }} />,
			"Document": <Description style={{ color: this.colorStateMap.textColor }} />,
			"In Draft": <AccessTime style={{ fontSize: '2rem' }} />,
			"In Review": <Search style={{ fontSize: '2rem' }} />,
			"Task Remaining": <PlaylistAddCheck style={{ fontSize: '2rem' }} />,
			"No Icon": <NotInterested style={{ color: this.colorStateMap.textColor }} />
		};
	}

	styles = {
		cardIcon: {
			position: 'absolute',
			borderRadius: '4px',
			float: 'right',
			color: 'white',
			fontSize: '1rem',
			height: '15%',
			padding: '2%',
			zIndex: 5,
			right: 60,
			bottom: 55
		}
	};

	getCreateListItems(element) {
		const name = element[0];
		const path = element[1];
		return(
			<ListItem key={v4()} style={{ backgroundColor: this.colorStateMap.backgroundColor, marginTop: '10px', borderRadius: '5px' }}>
				<Link to={path} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', width: '100%' }}>
					<ListItemIcon>
						{this.iconMap[name] ? this.iconMap[name] : this.iconMap['No Icon']}
					</ListItemIcon>
					<ListItemText>
						<span style={{ color: this.colorStateMap.textColor }}>{name}</span>
					</ListItemText>
				</Link>
			</ListItem>
		);
	}

	getIndividualGridItems(item, length) {
		return(
			<Grid item style={{ width: `${70 / length}%` }} key={v4()}>
				<Link style={{ textDecoration: 'none' }} to={item.path}>
					<Card style={{ position: 'relative' }}>
						<CardContent style={{ position: 'relative', heigth: '30%' }}>
							<Typography variant='h3' style={{ color: this.colorStateMap.textColor, marginTop: '10%' }}>
								{item.count}
								<span style={{ fontSize: '0.8rem', marginLeft: '10px' }}>{item.name}</span>
							</Typography>
						</CardContent>
						<CardContent style={Object.assign({}, { backgroundColor: this.colorStateMap.textColor }, this.styles.cardIcon)}>
							{this.iconMap[item.type] ? this.iconMap[item.type] : this.iconMap['No Icon']}
						</CardContent>
						<CardContent style={{ backgroundColor: this.colorStateMap.backgroundColor, position: 'relative' }}>
							<Typography variant="subtitle2" style={{ color: this.colorStateMap.textColor, marginTop: '5%' }}>
								{item.type}
							</Typography>
						</CardContent>
					</Card>
				</Link>
			</Grid>
		);
	}




	render() {
		let create = {};

		let cards = [];

		if (this.props.views) {
			create = this.props.views.create ? this.props.views.create : {};

			cards = this.props.views.cards ? this.props.views.cards : [];
		}



		return(
			<Grid container spacing={2}>
				<Grid item style={{ width: '20%' }}>
					<div style={{ display: "flex" }}>
						<Add /> <span style={{ padding: '2px', fontSize: '1.2rem' }}>Create New</span>
					</div>
					<List>
						{Object.entries(create).map(element => this.getCreateListItems(element))}
					</List>
				</Grid>
				{cards.map(e => this.getIndividualGridItems(e, cards.length))}
			</Grid>
		);
	}

}

export default CardViews;
