import React from 'react';
import RichTextEditor from '../RichTextEditor';
import ExpressionBuilder from '../ExpressionBuilder';
import PreviewDataSource from '../PreviewDataSource';
import Table from '../Table';
import ElementsDialogBox from '../ElementsDialogBox';
import './TemplateDocEditorArea.css';
import './TemplateElements.css';
import './DocumentElements.css';

import Grid from '@material-ui/core/Grid';
import {
	Button
} from '../../lib/ui';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sortly, { ContextProvider, useDrag, useDrop } from 'react-sortly';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TemplateElementDialog from '../TemplateElementDialog';
import MaterialTableStyled from '../MaterialTableStyled';
import CommentView from '../Comment/CommentView';

import document_utils from '../../lib/utils/document_utils';
import recursive_object_utils from '../../lib/utils/recursive_object_utils'
import validation_utils from '../../lib/utils/validation_utils';
import object_utils from '../../lib/utils/object_utils';
import data_utils from '../../lib/utils/data_utils';
import template_lib from '../../api/template';
import document_lib from '../../api/document';
import data_lib from '../../api/data';
import { List, Switch, ListItemText, ListItem } from '@material-ui/core';
import cross_references from '../../lib/cross_references';
import template_utils from '../../lib/utils/template_utils';
import styles from '../../lib/styles';
import nunjucks_utils from '../../lib/utils/nunjucks_utils';

const uuid = require('uuid');

function DragDropItem(props) {
	const ref = React.useRef(null);

	const [, drag] = useDrag();
	const [, drop] = useDrop();

	drag(drop(ref));

	return(
		<div ref={ref} style={{ marginLeft: (props.depth * 2) + 'em' }}>
			{props.children}
		</div>
	);
}

class TemplateDocEditorArea extends React.Component {
	variable_types = validation_utils.structure.variable_types;
	constructor(props) {
		super(props);

		switch (props.type) {
			case 'document':
			case 'template':
				break;
			default:
				throw(new Error(`Invalid type: ${props.type}`));
		}

		this.state = {
			items: null,
			document_variables: undefined,
			expanded_templates_element_id: {},
			subdocument_map: {},
			filter_by_expression_ids: [],
			filtered_templates: [],
			document_elements: {},
			element_dialog_open: true,
			elements_dialog: false,
			datasource_items: [],
			template_footnotes: {},
			document_footnotes: {},
			format: {},
			symbol: {}
		};

		/*
		 * Management of the internal state, not to be confused with
		 * the state that drives the component rendering process
		 */
		/**
		 ** Mounted: Whether the component has been show to the user,
		 ** if so they might have started to interact with it so we
		 ** can start loading in items from the template+document
		 **/
		this.mounted = false;

		/*
		 * Type of editor: document or template
		 */
		this.type = props.type;
		this.writable = props.writable;
		if (this.writable === undefined) {
			if (this.type === 'template') {
				this.writable = true;
			} else {
				this.writable = false;
			}
		}

		/*
		 * Populate the template/document from the user properties
		 */
		this.set_template(props.template);
		this.set_document(props.document);

		/*
		 * Add support for some callback properties
		 */
		/**
		 ** onChange:  When a new element is added or an element is updated
		 **/
		this.onChange = props.onChange;

		/**
		 ** onError:  When interacting with an element produces an error
			**/
		this.onError = props.onError;

		/**
		 ** onRequestSave:  If we need to save the document to perform
		 **                 action (like fetching new data), who do
		 **                 we call
		 **/
		this.onRequestSave = props.onRequestSave;

		/*
		 * For functions passed as callbacks to other components we must
		 * bind "this" to use them directly (i.e., without a wrapping
		 * function)
		 */
		this.set_items = this.set_items.bind(this);
		this.notify_changed = this.notify_changed.bind(this);
		this.set_item_attribute = this.set_item_attribute.bind(this);
		this.insert_item = this.insert_item.bind(this);
		this.EditableElementPart_attribute_input = this.EditableElementPart_attribute_input.bind(this);
		this.close_dialog = this.close_dialog.bind(this);
		this.EditableElementPart_attribute_input = this.EditableElementPart_attribute_input.bind(this);
	}

	comment_ref_map = {};

	/*
	 * React component methods
	 */
	componentDidUpdate(oldProps, oldState) {
		if (this.state.items === null) {
			if (this.props.template) {
				this.set_template(this.props['template'])
			}

			if (this.props.document) {
				this.set_document(this.props['document'])
			}
		}
	}

	componentDidMount() {
		/*
		 * Indicate that we are mounted and should not accept an
		 * updated property containing a Document/Template
		 */
		this.mounted = true;

		/*
		 * Recompute items from any Document/Template loaded before mounting
		 */
		this.recompute_items();
	}


	/*
	 * User methods to interact with this element
	 */
	/**
	 ** Get the current state of the template.  This will convert from the
	 ** internal storage format ("items") to the various parts of the
	 ** template that this editor can change and produce the composed
	 ** template.
	 **/
	get_template() {
		/*
		 * Clean up the variables, and remove extraneous information from a
		 * copy of the items
		 */
		this.template['variables'] = {};

		let current_items = this.state.items;
		if (current_items === null) {
			current_items = [];
		}

		for (const item of current_items) {
			if (item.type !== 'variable') {
				continue;
			}

			const contents = object_utils.copy_object(item.contents);
			delete contents['name'];
			delete contents['$from'];
			delete contents['$variable_descriptor'];
			this.template.variables[item.contents.name] = contents;
		}

		const items_filtered = current_items.map(function(item) {
			switch (item.type) {
				case 'variable':
					return({
						id: item.id,
						type: item.type,
						contents: {
							name: item.contents.name,
							type: item.type
						},
						depth: item.depth
					});
				case 'template':
					if (item.contents.version === undefined) {
						const item_copy = object_utils.copy_object(item);
						item_copy.contents['version'] = 'HEAD';
						return(item_copy);
					}
					return(item);
				default:
					return(item);
			}
		});

		/*
			* Assign the updated body
			*/
		const body = document_utils.assemble_body_sortly_items(items_filtered);
		this.template['body'] = body;

		return(object_utils.copy_object(this.template));
	}

	/**
	 ** As with get_template() above, return the current state of the
	 ** document.  This is simpler because it can only modify variables
	 ** right now.  XXX:TODO: body_extend
	 **/
	get_document() {
		this.document['variables'] = this.state.document_variables;
		return(this.document);
	}

	/**
	 ** Set the Template/Document.  This can only be done if the current one
	 ** is empty or has not been presented to the user.  A new one can be
	 ** loaded by calling the `reset()` method with the new values.
	 **/
	set_template(template) {
		this.template = template;

		return(this.recompute_items());
	}

	set_document(document) {
		this.document = document;

		return(this.recompute_items());
	}

	reset(document, template) {
		this.setState({
			items: null,
			items_error: null,
			items_valid: true,
			document_variables: undefined,
			expanded_templates_element_id: {},
		}, () => {
			this.mounted = false;
			this.set_document(document);
			this.set_template(template);
			this.mounted = true;
			this.recompute_items();
		});
	}

	find_items_to_remove(id, items) {
		let index = 0;
		let count = 1;
		let start_index = 0;

		for (const item of items) {
			if (id === item.id) {

				start_index = index;

				if (items[index + 1] === undefined) {
					return({
						start_index: index,
						item_count: count
					})
				} else {
					while (items[index + 1] !== undefined && items[index + 1].depth > item.depth) {
						count++;
						index++;
					}

					return({
						start_index: start_index,
						item_count: count
					})
				}
			}
			index++;
		}
	}

	/**
	 ** Insert or a remove an item from the editor
	 **/
	remove_item(id) {
		if (!this.writable) {

			/*
			 * Comment this out for now while template action
			 * buttons are being used in the document editor
			 * as well
			 */
			// throw(new Error('Tried to remove from non-writable document'));

			return;
		}

		const items = object_utils.copy_object(this.state.items);
		const item_info = this.find_items_to_remove(id, items);
		const start_index = item_info.start_index;
		const item_count = item_info.item_count;

		if (start_index === -1) {
			throw(new Error(`Internal error, unable to find item ID ${id} from items ${JSON.stringify(items)}`));
		}

		items.splice(start_index, item_count);

		this.set_items(items);
	}

	find_insert_index(id, items) {
		let index = 0;
		for (const item of items) {
			if (id === item.id) {
				if (items[index + 1] === undefined || items[index + 1].depth <= item.depth) {
					return({
						item: item,
						index: index,
						depth: item.depth
					});
				} else {
					/*
					 * Elements inserted in a loop must come before
					 * the else statement (if there is one)
					 */
					if (item.type === 'loop') {
						while (items[index + 1] !== undefined && items[index + 1].depth > item.depth && items[index + 1].type !== '@meta:else') {
							index++;
						}
					} else {
						index++;
						while (items[index + 1] !== undefined && items[index + 1].depth > item.depth) {
							index++;
						}
					}

					return({
						item: item,
						index: index,
						depth: item.depth
					});
				}
			}
			index++;
		}
	}

	insert_item(type, contents = {}, id) {

		this.setState({edit_item_props: {}});

		if (!this.writable) {
			throw(new Error('Tried to insert into non-writable document'));
		}

		const custom_components = [
			'switch', '_meta_value', 'template', 'reference',
			'table', 'style', 'variable', 'loop'
		];

		if (custom_components.includes(type)) {
			this.setState({ element_type : type });
		} else {
			this.setState({ element_type : '' });
		}

		const items = [...this.get_items()];
		const default_contents = {
			type: type
		};
		const new_id = uuid.v4();

		contents = object_utils.copy_object(contents);

		/*
		 * Set default values based on the type of element
		 */
		switch (type) {
			case 'title':
				default_contents['title'] = '';
				break;
			case 'variable':
				/*
				 * Most kinds of elements have their inner type set to
				 * their outer types, variables do not though
				 */
				default_contents['type'] = '';

				break;
			default:
				console.debug('[XXX:TODO] default for', type);
				break;
		}

		/*
		 * Merge the defaults and user-specified values
		 */
		contents = Object.assign(default_contents, contents);

		/*
		* Insert the item.  This currently adds the item at the end, but
		* it may need to insert at some other location (XXX:TODO).
		*/
		const new_item = {
			id: new_id,
			type,
			contents,
		}

		/* Find index to insert item */
		if (this.state.element_type === 'section' || this.state.element_type === 'switch' || this.state.element_type === '@meta:else'
			|| this.state.element_type === 'loop' || this.state.element_type === '@meta:value' || this.state.element_type === '@meta:default' || type === '@meta:else'
			||  this.state.element_type === '_meta_value' ||  this.state.element_type === '_meta_default' || this.state.element_type === '_meta_else') {
			let element_id = this.state.element_id;

			if (id !== undefined) {
				element_id = id;
			}

			const item_info = this.find_insert_index(element_id, items);
			const index = item_info.index;
			const depth = item_info.depth;

			new_item['depth'] = depth + 1;

			/*
			 * Insert item at proper index
			 */
			items.splice(index + 1, 0, new_item);
		} else {
			new_item['depth'] = 0;
			items.push(new_item);
		}

		this.set_items(items);

	}

	/*
	 * Internal methods
	 */
	get_items() {
		if (this.state.items === null) {
			return([]);
		}

		return(this.state.items);
	}

	set_items(new_items) {
		let items_valid = true;
		let items_error = null;

		/*
		 * Determine if the list of items can be assembled into a
		 * syntactically valid body by trying to do so, if an error
		 * is thrown, we know that the current state is invalid.
		 */
		try {
			document_utils.assemble_body_sortly_items(new_items);
		} catch (assembly_error) {
			items_valid = false;
			items_error = assembly_error;
		}

		this.setState({
			items: new_items,
			items_valid,
			items_error
		}, () => {
			if (items_valid) {
				this.run_on_change();
			}
		});
	}

	/**
	 ** Perform an initial load of a "body" into our component's internal state.
	 ** This may only be done once, but is deferred until after the component is
	 ** mounted, so it can be updated a few times before it's "locked".
	 **
	 ** If the body to be initialized is empty, it does not get initialized and
	 ** we are left uninitialized.
	 **/
	recompute_items() {
		/*
		 * If the component is not even mounted yet, then computing
		 * the body is pointless;  It will be recomputed when mounted
		 * to account for this.
		 */
		if (!this.mounted) {
			return(false);
		}

		if (this.state.items !== null) {
			return(false);
		}

		if ((this.template && this.template.body) || (this.document && this.document.body_extend)) {
			const body = document_utils.body_serialize(this.document, this.template);

			if (body && body.length > 0) {
				/*
				 * Convert body to the internal form we use, "items"
				 */
				const items = document_utils.get_body_sortly_items(body);

				/*
				 * Extract variables
				 */
				for (const item_index in items) {
					const item = items[item_index];

					if (item.type !== 'variable') {
						continue;
					}

					if (this.template.variables[item.contents.name]) {
						Object.assign(items[item_index].contents, this.template.variables[item.contents.name]);
					}
				}

				/*
				 * Update component state
				 */
				const new_state = {
					items,
					items_valid: true,
					items_error: null,
					expanded_templates_element_id: {},
				};
				if (this.type === 'document') {
					new_state['document_variables'] = {}
					if (this.document.variables instanceof Object) {
						Object.assign(new_state.document_variables, this.document.variables);
					}
				}

				this.setState(new_state, () => {
					this.refresh_subdocument_map();
					this.run_on_change();
				});


				return(true);
			}
		}

		return(false);
	}

	set_document_variable(name, value) {
		let variables;
		if (this.state.document_variables !== undefined) {
			variables = object_utils.copy_object(this.state.document_variables);
		} else {
			variables = {};
		}

		variables[name] = value;

		/*
		 * Update without re-rendering
		 */
		// eslint-disable-next-line
		this.state.document_variables = variables;
	}

	get_document_variable(name) {
		const variables = this.state.document_variables;
		if (variables === undefined) {
			return(undefined);
		}

		return(variables[name]);
	}

	set_item_attribute(id, attribute, new_value) {
		const items = object_utils.copy_object(this.state.items);
		const item = items.find(function(item) {
			if (item.id === id) {
				return(true);
			}
			return(false);
		});

		if (item === undefined) {
			throw(new Error(`Internal error, unable to find item ID ${id} from items ${JSON.stringify(items)} when trying to set ${attribute} to ${new_value}`));
		}

		if (!(item['contents'] instanceof Object)) {
			item['contents'] = {};
		}

		/*
		 * Allow setting a deeply nested value by specifying an array instead of a string
		 */
		if (attribute instanceof Array) {
			recursive_object_utils.set(item.contents, attribute.slice(0, -1), attribute.slice(-1)[0], new_value);
		} else {
			item.contents[attribute] = new_value;
		}

		/*
		 * Update the items without re-rendering
		 */
		// eslint-disable-next-line
		this.state.items = items;
	}

	delete_item_attribute(id, attributesToBeRemoved = []) {
		const items = object_utils.copy_object(this.state.items);
		const item = items.find(function(item) {
			if (item.id === id) {
				return(true);
			}
			return(false);
		});

		if (item === undefined) {
			throw(new Error(`Internal error, unable to find item ID ${id} from items ${JSON.stringify(items)} when trying to delete ${attributesToBeRemoved}`));
		}

		if (!(item['contents'] instanceof Object)) {
			item['contents'] = {};
		}

		if (attributesToBeRemoved instanceof Array) {
			attributesToBeRemoved.forEach(function(element) {
				if (item.contents[element]) {
					delete item.contents[element];
				}
			})
		}
		/*
		 * Update the items without re-rendering
		 */
		// eslint-disable-next-line
		this.state.items = items;
	}

	get_item_attribute(id, attribute, default_value = undefined) {
		const items = this.state.items;
		const item = items.find(function(item) {
			if (item.id === id) {
				return(true);
			}
			return(false);
		});

		if (item === undefined) {
			return(default_value)
		}

		if (!(item['contents'] instanceof Object)) {
			return(default_value);
		}

		const retval = item.contents[attribute];
		if (retval === undefined) {
			return(default_value);
		}

		return(retval);
	}

	notify_changed() {
		this.force_update_state();
		this.run_on_change();
	}

	force_update_state() {
		this.setState({
			items: this.state.items,
			document_variables: this.state.document_variables
		});
	}

	set_item_attribute_eventhandler(id, attribute, map_function = undefined) {
		return(event, value) => {
			const target = event.target;
			let new_value;
			if (value !== undefined) {
				new_value = value;
			} else {
				new_value = target.value;
			}

			if (map_function) {
				new_value = map_function(new_value);
			}

			return(this.set_item_attribute(id, attribute, new_value));
		}
	}

	async refresh_subdocument_map() {
		if (this.type !== 'document') {
			return;
		}

		if (!this.document.subdocuments) {
			return;
		}

		if (this.refresh_subdocument_map_in_progress === true) {
			return;
		}
		this.refresh_subdocument_map_in_progress = true;

		const subdocument_map = {};
		const subdocument_map_promises = [];
		for (const subdocument_element_id in this.document.subdocuments) {
			const subdocument_info = this.document.subdocuments[subdocument_element_id];
			const subdocument_ids = subdocument_info.document_id;

			for (const subdocument_id of subdocument_ids) {
				subdocument_map_promises.push((async function() {
					const subdocument = await document_lib.get_user_document(null, subdocument_id, 'HEAD');
					subdocument_map[subdocument_id] = subdocument.name;
				})());
			}
		}
		await Promise.all(subdocument_map_promises);

		this.setState({ subdocument_map }, () => {
			this.refresh_subdocument_map_in_progress = false;
		});
	}

	run_on_change() {
		if (this.onChange === undefined) {
			return;
		}

		this.onChange();
	}

	run_on_error(error_string) {
		if (this.onError === undefined) {
			return;
		}

		this.onError(error_string);
	}

	async run_request_save_document() {
		if (this.type !== 'document') {
			throw(new Error('[Internal Error] Requested that we save a document, but we are a template'));
		}

		if (this.onRequestSave === undefined) {
			return;
		}

		return(await this.onRequestSave());
	}

	async update_data_source_value(variable_name) {
		/*
		 * Clear error state
		 */
		this.run_on_error();

		try {
			const data = await document_utils.fetch_document_data_source_value(null, this.document.id, 'HEAD', variable_name);
			data['last_updated'] = Date.now(); /* XXX:TODO: Should we use the user's clock for this ? */
			this.set_document_variable(variable_name, data);
		} catch (fetch_error) {
			/*
			 * Pass error back up to user
			 */
			const error = `Failed to fetch data source: ${fetch_error.toString()}`;

			return(error);
		}

		return(true);
	}

	async upload_image(file, type) {
		const presigned_post_data = await document_lib.create_presigned_post('images');

		/* Construct path to file in S3 bucket and set it as the variable value  */
		const key = presigned_post_data.fields.Key;

		/* Get presigned URL and necessary fields from createPresignedPost() function */
		const url = presigned_post_data.url;
		const fields = presigned_post_data.fields;

		/* Construct a FormData Object to POST */
		const form_data = new FormData();
		Object.keys(fields).forEach(key => form_data.append(key, fields[key]));
		form_data.append('file', file);

		/* Make a POST request to upload the image to S3 */
		const config = {
			method: 'POST',
			body: form_data
		}

		let response;
		try {
			response = await fetch(url, config);
		} catch (fetch_error) {
			this.run_on_error('Error uploading image: ' + fetch_error);

			throw(new Error('Failed to upload image to S3: ' + fetch_error));
		}

		/* If response failed throw error */
		if (!response.ok || (response.status !== 200 && response.status !== 204)) {
			this.run_on_error('Error uploading image: ' + response.json());

			throw(new Error('Failed to upload image to S3: ' + response.json()));
		}
		this.run_on_error();

		return({
			key: key,
			type: type
		});
	}

	/**
	 ** Element Rendering
	 **/

	/***
	 *** Common to both editable and fixed elements
	 ***/
	ElementPart_wrapper_options(props) {
		return({
			id: props.id,
			className: [props.className, "editorarea-item-container"].join(' '),
			style: {
				backgroundColor: "#FFFFFF",
				padding: '5px',
				width: '100%',
				marginTop: '16px',
				marginBottom: '16px',
				paddingBottom: '16px',
				border: '1px dashed #EAE6FF',
				borderRadius: '4px' /* XXX:TODO: Move this to CSS for editorarea-item-container */
			}
		});
	}

	/**
	 ** Custom component dialogs
	 **/
	variable_dialog(props) {
		const content = {
			type: 'dialog',
			element_type: 'variable',
			title: 'Variable Properties',
			content: [
				{
					type: 'textfield',
					label: 'Variable Name',
					attribute: 'name',
					field_overrides: this.EditableElementPart_attribute_input(props, 'name')
				},
				{
					type: 'textfield',
					label: 'Variable Description',
					attribute: 'description',
					field_overrides: this.EditableElementPart_attribute_input(props, 'description')
				},
				{
					type: 'dropdown',
					label: 'Variable Type',
					attribute: 'type',
					values: this.variable_types,
					field_overrides: {
						onChange: (event) => {
							this.set_item_attribute(props.id, 'type', event.target.value);
							this.notify_changed();
						}
					}
				},
				...this.variable_options_dialog(props)
			]
		}
		return(content);
	}

	variable_options_dialog(props) {
		switch (props.data.type) {
			case '':
				return([]);
			case 'text':
			case 'textarea':
			case 'richtextarea':
			case 'image':
			case 'reference':
			case 'multi_input':
				return([]);
			case 'dropdown':
			case 'list':
			case 'checkbox':
				return([
					{
						type: 'textfield',
						label: 'Items',
						attribute: ['options', 'items'],
						field_overrides: this.EditableElementPart_attribute_input(props, ['options', 'items'], this.EditableElementPart_attribute_input_map_func_csv())
					}
				]);
			case 'datasource':
				if (this.state.datasource_items.length === 0) {
					this.populate_datasource_items();
				}

				return([
					{
						type: 'autocomplete',
						label: 'Source',
						values: this.state.datasource_items,
						attribute: ['options', 'source'],
						param: 'url',
						field_overrides: this.EditableElementPart_attribute_input(props, ['options', 'source'], { options: { managed: true } })
					},
					{
						type: 'textfield',
						label: 'Columns',
						attribute: ['options', 'column_headers'],
						field_overrides: this.EditableElementPart_attribute_input(props, ['options', 'column_headers'], this.EditableElementPart_attribute_input_map_func_csv())
					},
					{
						type: 'textfield',
						label: 'Rows',
						attribute: ['options', 'row_headers'],
						field_overrides: this.EditableElementPart_attribute_input(props, ['options', 'row_headers'], this.EditableElementPart_attribute_input_map_func_csv())
					},
					{
						type: 'dropdown',
						label: 'type',
						attribute: ['options', 'type'],
						values: [
							{title: "Automatically Detect", value: "auto"},
							{title: "Rows", value: "rows"},
							{title: "Columns", value: "columns"},
							{title: "Columns x Rows", value: "columns-rows"}
						],
						field_overrides: this.EditableElementPart_attribute_input(props, ['options', 'type'])
					}
				]);
			case 'expression':
				return([
					{
						title: "Expression",
						attribute: ['options', 'expression'],
						type: 'expressionbuilder',
						autoCompleteConfig: [
							{
								trigger: ' ',
								options: nunjucks_utils.constants.operators,
								excludePredecessors: [...nunjucks_utils.constants.operators, undefined]
							},
							{
								trigger: '|',
								options: nunjucks_utils.constants.filters
							},
							{
								trigger: nunjucks_utils.constants.any,
								options: nunjucks_utils.filters.keys(this.props.all_variables)
							}
						],
						field_overrides: this.EditableElementPart_attribute_input(props, ['options', 'expression'])
					}
				]);
			default:
				throw(new Error(`No variable option rendering for variable of type ${props.data.type}`));
		}
	}

	table_dialog(props) {
		const content = {
			type: 'dialog',
			element_type: 'table',
			title: 'Table Properties',
			content: [
				{
					type: 'textfield',
					label: 'Datasource',
					attribute: 'datasource',
					field_overrides: this.EditableElementPart_attribute_input(props, 'datasource')
				},
				{
					type: 'textfield',
					label: 'Title',
					attribute: 'title',
					field_overrides: this.EditableElementPart_attribute_input(props, 'title')
				},
				{
					type: 'textfield',
					label: 'Columns',
					attribute: 'column_headers',
					field_overrides: this.EditableElementPart_attribute_input(props, 'column_headers', this.EditableElementPart_attribute_input_map_func_csv())
				},
				{
					type: 'textfield',
					label: 'Rows',
					attribute: 'row_headers',
					field_overrides: this.EditableElementPart_attribute_input(props, 'row_headers', this.EditableElementPart_attribute_input_map_func_csv())
				}
			]
		}
		return(content);
	}

	/* This has exactly one caller and returns something straight-forward... why is it a method ? */
	style_dialog(props) {
		const style_options = ['Style element', 'Add style', 'Add symbol', 'Add section numbering']

		const content = {
			type: 'dialog',
			element_type: 'style',
			title: 'Style Properties',
			content: [
				{
					label: 'Style Options',
					type: 'dropdown',
					attribute: 'option',
					values: style_options,
					field_overrides: {
						onChange: (event) => {
							this.set_item_attribute(props.id, 'option', event.target.value);
							this.notify_changed();
						}
					}
				},
				...this.EditableElementPart_style_options(props)
			]
		}
		return(content);
	}

	/* This has exactly one caller and returns something straight-forward... why is it a method ? */
	template_dialog(props) {
		if (props.data && props.data.expression && !this.state.filter_by_expression_ids.includes(props.id)) {
			this.setState(function(prevState) {
				return({
					filter_by_expression_ids: [
						...prevState['filter_by_expression_ids'],
						props.id
					]
				});
			});
		}

		let filterCheckBox = '';
		let expressionList;

		if (this.type === 'template') {
			if (this.state.filter_by_expression_ids.includes(props.id)) {
				expressionList =
					{
						type: 'expressionbuilder',
						title: 'meta expression',
						attribute: 'expression',
						autoCompleteConfig: [
							{
								trigger: '|',
								options: nunjucks_utils.constants.filters
							},
							{
								trigger: ' ',
								options: nunjucks_utils.constants.operators,
								includePredecessors: nunjucks_utils.filters.keys(this.props.all_variables).map((variable) => {
									return `{{${variable}}}`
								}),
								excludePredecessors: [...nunjucks_utils.constants.operators, undefined]
							},
							{
								trigger: ' ',
								options: ['='],
								excludePredecessors: ['=', undefined]
							},
							{
								trigger: nunjucks_utils.constants.any,
								options: nunjucks_utils.filters.keys(this.props.all_variables).map((variable) => {
									return `{{${variable}}}`
								}),
								excludePredecessors: [undefined]
							}
						],
						field_overrides: {
							onChange: (event) => {
								let expression = event.target.value;
								expression = expression.replace(new RegExp(/\s*=\s*/, 'g'), '=');
								/* XXX:TODO: checkOrdinalNameAndFormat should probably not be in template_utils */
								const nameFormat = template_utils.checkOrdinalNameAndFormat(props.data.name.trim());
								this.set_item_attribute(props.id, 'name', nameFormat);
								this.set_item_attribute(props.id, 'expression', expression);
								this.setState({ filter_expression_text_id: props.id });
							}
						}
					}
			}

			filterCheckBox =
				(<Grid container>
					<Grid item>Filter By Expression</Grid>
					<Grid item>
						<Switch
							checked={this.state.filter_by_expression_ids.includes(props.id)}
							onChange={() => {
								const filterCheckedItemIds = this.state.filter_by_expression_ids;
								if (this.state.filter_by_expression_ids.includes(props.id)) {
									const index = filterCheckedItemIds.indexOf(props.id);
									if (index > -1) {
										filterCheckedItemIds.splice(index, 1);
									}
									this.delete_item_attribute(props.id, ['expression']);
								} else {
									filterCheckedItemIds.push(props.id);
									this.delete_item_attribute(props.id, ['id', 'version']);
								}
								this.setState({ filter_by_expression_ids: filterCheckedItemIds });
							}}
							color="primary"
						/>
					</Grid>
				</Grid>);
		}

		let selectTemplateView;
		if (!this.state.filter_by_expression_ids.includes(props.id)) {
			selectTemplateView =
				{
					type: 'list',
					description: 'Type - Sub Type',
					field_overrides: {
						onClick: (id, attribute, value) => {
							const expanded_templates_element_id = object_utils.copy_object(this.state.expanded_templates_element_id);
							delete expanded_templates_element_id[props.id];
							this.setState({ expanded_templates_element_id, filter_expression_text_id : null });
							return(this.set_item_attribute(props.id, attribute, value));
						}
					}
				}
		}

		const template_dialog_content = [
			{
				type: 'raw',
				size: 12,
				contents: filterCheckBox
			}
		]

		if (expressionList !== undefined) {
			template_dialog_content.push(expressionList);
		}

		if (selectTemplateView !== undefined) {
			template_dialog_content.push(selectTemplateView);
		}

		const content = {
			type: 'dialog',
			element_type: 'template',
			content: template_dialog_content
		}

		return(content);
	}

	/* This has exactly one caller and returns something straight-forward... why is it a method ? */
	reference_dialog(props) {
		const content = {
			type: 'dialog',
			element_type: 'reference',
			content: [
				{
					type: 'treeview',
					template_id: this.template.id,
					template_name: this.template.name,
					items: this.state.items,
					props: props,
					field_overrides: this.set_item_attribute
				},
			]
		}
		return(content);
	}

	_meta_value_dialog(props) {
		const content = {
			type: 'dialog',
			element_type: '_meta_value',
			title: 'Choose Type',
			content: [
				{
					type: 'image',
					label: 'Case',
					src: 'decision.svg',
					description: 'Add decision statement',
					field_overrides: {
						onClick: (value) => {
							return(this.insert_item(value))
						}
					}
				},
				{
					type: 'image',
					label: 'Default',
					src: 'decision.svg',
					description: 'Renders incase of missing expression',
					field_overrides: {
						onClick: (value) => {
							return(this.insert_item(value))
						}
					}
				},
			]
		}
		return(content);
	}

	dialog_element(props, insert_type) {
		const dialog_type = `${insert_type}_dialog`;
		const dialog_function = this[dialog_type];

		if (!dialog_function) {
			return(null);
		} else {
			const item = this[dialog_type](props);
			return(this.EditableElementPart_grid_item(props, item));
		}
	}

	/***
	 *** Editable Elements
	 ***/
	/****
	 **** Helper functions
	 ****/
	EditableElementPart_delete_button(id) {
		return(
			<Grid item xs={1}>
				<IconButton className="editor-item-button-remove" size="medium" onClick={() => {this.remove_item(id)}}>
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Grid>
		);
	}

	EditableElementPart_attribute_input_map_func_json(attrs) {
		return({
			mapForward: function(data) {
				if (data === undefined || data === null) {
					return({});
				}

				const object = JSON.parse(data);
				const retval = {};
				for (const attr of attrs) {
					if (object[attr] === undefined) {
						continue;
					}

					retval[attr] = object[attr];
				}

				return(retval);
			},
			mapReverse: function(data) {
				const object = {};
				for (const attr of attrs) {
					if (data && data[attr] !== undefined) {
						object[attr] = data[attr];
					}
				}

				const retval = JSON.stringify(object);

				return(retval);
			}
		});
	}

	EditableElementPart_attribute_input_map_func_csv() {
		return({
			mapForward: function(data) {
				if (!data) {
					return([]);
				}

				return(data.split(','));
			},
			mapReverse: function(data) {
				if (!data) {
					return('');
				}

				if (data instanceof Array) {
					return(data.join(','));
				} else {
					/* XXX
					 * There was a bug at one point where items were not split
					 * so for now accept a CSV string as input unchanged
					 */
					return(data);
				}

			}
		});
	}

	EditableElementPart_attribute_input(props, attribute, field_overrides = {}) {
		if (props.data === undefined) {
			props.data = {};
		}

		/*
		 * Allow using an array as an attribute, to handle deeply nested values
		 */
		let attribute_string;
		let defaultValue;
		if (props.elementInformation.type === 'variable' && attribute === 'value') {
			defaultValue = this.get_document_variable(props.data.name);
		} else if (attribute instanceof Array) {
			attribute_string = attribute.join('|');
			defaultValue = recursive_object_utils.get(props.data, attribute.slice(0, -1), attribute.slice(-1)[0]);
		} else {
			attribute_string = attribute;
			defaultValue = props.data[attribute];
		}

		/*
		 * Function to map data between different formats, such as
		 * list and a CSV text entry
		 *
		 * mapForward: user->internal
		 * mapReverse: internal->user
		 */
		let map_forward_func;
		if (field_overrides.mapForward) {
			map_forward_func = field_overrides.mapForward;

			delete field_overrides['mapForward'];
		}
		if (field_overrides.mapReverse) {
			const map_func = field_overrides.mapReverse;
			delete field_overrides['mapReverse'];

			defaultValue = map_func(defaultValue);
		}

		const retval = {
			key: `input_${attribute_string}_${props.id}`,
			defaultValue: defaultValue,
			onBlur: this.notify_changed,
			onChange: this.set_item_attribute_eventhandler(props.id, attribute, map_forward_func)
		};

		if (props.read_only) {
			retval['readOnly'] = true;
			delete retval['onBlur'];
			delete retval['onChange'];
		}

		Object.assign(retval, field_overrides);

		return(retval);
	}

	add_table_row(data, attribute, id, state_variable) {
		if (id === undefined) {
			return;
		}

		if (attribute === undefined) {
			return;
		}

		this.setState(function(prevState) {
			const footnotes = object_utils.copy_object(prevState[state_variable]);
			footnotes[id].push(data);
			return({[state_variable]: footnotes});
		}, () => {
			const new_value = this.state[state_variable][id];
			if (this.type === 'template') {
				this.set_item_attribute(id, attribute, new_value);
			}

			if (this.type === 'document') {
				this.set_document_variable(attribute, new_value);
			}
		})
	}

	lookup_index(values, data) {
		const index = values.findIndex(function(value) {
			for (const check of ['name', 'value', 'variable_name']) {
				if (value[check] !== data[check]) {
					return(false);
				}
			}
			return(true);
		});
		return(index);
	}

	update_table_row(data, old_data, attribute, id, state_variable) {
		if (id === undefined) {
			return;
		}

		if (attribute === undefined) {
			return;
		}

		this.setState(function(state, props) {
			const footnotes = object_utils.copy_object(state[state_variable]);

			const values = footnotes[id];
			const index = this.lookup_index(values, old_data);
			if (index < 0) {
				return;
			}

			values[index] = data;

			return {
				[state_variable]: footnotes
			}
		},  () => {
			const new_value = this.state[state_variable][id];
			if (this.type === 'template') {
				this.set_item_attribute(id, attribute, new_value);
			}

			if (this.type === 'document') {
				this.set_document_variable(attribute, new_value);
			}
		})
	}

	remove_table_row(data, attribute, id, state_variable) {
		if (id === undefined) {
			return;
		}

		this.setState(function(state, props) {
			const footnotes = object_utils.copy_object(state[state_variable]);

			const values = footnotes[id];
			const index = this.lookup_index(values, data);
			if (index < 0) {
				return;
			}

			values.splice(index, 1);

			return {
				[state_variable]: footnotes
			}
		}, () => {
			const new_value = this.state[state_variable][id];
			if (this.type === 'template') {
				this.set_item_attribute(id, attribute, new_value);
			}

			if (this.type === 'document') {
				this.set_document_variable(attribute, new_value);
			}
		})
	}

	EditableElementPart_grid_item(props, item, options) {
		if (item.type === undefined) {
			item.type = 'text';
		}

		if (item.hide_if_read_only && props.read_only) {
			return;
		}

		/*
		 * Set item to read only if props have read_only set
		 * to true and item's read_only property is not set to false
		 */
		const read_only = props.read_only && item.read_only !== false;

		switch (item.type) {
			case 'label':
				{
					let type = props.data.type;

					if (type === '@meta:value') {
						type = '_meta_value';
					}

					if (type === '@meta:default') {
						type = '_meta_default';
					}

					if (type === '@meta:else') {
						type = '_meta_else';
					}

					let attribute = item.attribute;

					if (typeof attribute === 'object') {
						attribute = item.attribute.join('-');
					}

					/* XXX:TODO Class */
					return(<label className={'editor-item-label-' + type + '-' + attribute}>{item.value}</label>);
				}
			case 'text':
				{
					const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
					let textValue = element_attrs.defaultValue;

					if (typeof textValue === 'object') {
						textValue = textValue.name;
					}

					let placeholder = 'Enter ' + item.attribute + ' here';

					/*
					 * Override placeholder if one is provided
					 */
					if (item.placeholder !== undefined) {
						placeholder = item.placeholder;
					}

					if (read_only) {
						return(<div>{textValue}</div>);
					} else {
						return(<input className="editor-item-text-input"
							placeholder={placeholder}
							key={element_attrs.key}
							value={textValue}
							onChange={(event, new_value, ...args) => {
								element_attrs.onChange(event, new_value, ...args);
								this.force_update_state();
							}}
						/>);
					}
				}
			case 'textarea':
				{
					const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
					const default_value = element_attrs.defaultValue;

					if (read_only) {
						return(<div>{default_value}</div>);
					} else {
						return(<textarea
							placeholder="Type here"
							key={element_attrs.key}
							onChange={element_attrs.onChange}
							onBlur={element_attrs.onBlur}
							className='editor-item-textarea-input'
						>{default_value}</textarea>);
					}
				}
			case 'expressionbuilder':
				{
					const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
					const default_value = element_attrs.defaultValue;

					if (read_only) {
						return(<div>{default_value}</div>);
					} else {
						return(
							<ExpressionBuilder
								className='editor-item-autocomplete-input'
								value={default_value}
								variables={this.props.all_variables}
								autoCompleteConfig={item.autoCompleteConfig}
								contextType={item.contextType}
								onChange={element_attrs.onChange}
								onBlur={element_attrs.onBlur}
								{...element_attrs}
							/>
						);
					}
				}
			case 'richtextarea':
				{
					const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
					const default_value = element_attrs.defaultValue;

					if (read_only) {
						return(<div className='editor-item-text-input' dangerouslySetInnerHTML={{__html: default_value}}/>);
					} else {
						let valid = true;
						let render_error;

						if (item.parse_check === true) {
							try {
								nunjucks_utils.renderString(default_value);
							} catch (nunjucks_render_error) {
								render_error = nunjucks_render_error;
								valid = false;
							}
						}

						let error_element;
						if (!valid) {
							error_element = (
								<div style={{border: '2px solid red'}}>
									<tt>{render_error.toString()}</tt>
								</div>
							);
						}
						return(
							<>
								{error_element}
								<RichTextEditor
									placeholder="Type here"
									className='editor-item-text-input'
									defaultValue={default_value}
									all_variables={this.props.all_variables}
									format={this.state.format}
									symbol={this.state.symbol}
									{...element_attrs}
								/>
							</>
						);
					}
				}
			case 'autocomplete':
				{
					const element_options = item.field_overrides;
					const param = item.param;
					const default_value = element_options.defaultValue;

					if (read_only) {
						return(<div className='editor-item-text-input'>{default_value}</div>);
					} else {
						return(
							<Autocomplete
								{...element_options}
								freeSolo={true}
								clearOnBlur={false}
								options={item.values.map(function(item) {
									return(item[param]);
								})}
								getOptionLabel={function(item) {
									return(item);
								}}
								renderInput={(params) => {
									return(<TextField
										onChange={element_options.onChange}
										{...params}
										margin="normal"
									/>)
								}}
							/>
						)
					}
				}
			case 'editable_table':
				{
					let editable_properties;

					/*
					 * If the table cannot be editted (i.e. table element
					 * footnotes in the Document Editor), pass in an
					 * empty object for MaterialTableStyled "editable"
					 */
					if (item.read_only) {
						editable_properties = {};
					} else {
						editable_properties = item.editable_properties;
					}

					return(
						<MaterialTableStyled
							columns={item.columns}
							data={item.values}
							title='Footnotes'
							editable={editable_properties}
						/>
					)
				}
			case 'select':
				{
					const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
					const default_value = element_attrs.defaultValue;
					let default_value_item_found = false;
					let default_option;

					const default_value_item = item.values.map(function(option) {
						if (option.value === default_value) {
							return(option);
						}
						return(undefined);
					}).find(function(option) {
						if (option === undefined) {
							return(false);
						}
						return(true);
					});
						default_value_item_found = false;
						if (default_value_item !== undefined || default_value === undefined || default_value === null) {
							default_value_item_found = true;
						}

						/*
						* If value has not been selected, display empty (undefined) option
						* to indicate variable value is undefined.
						*/
						if (default_value === undefined) {
							default_option = <option value={default_value}></option>;
						}

						if (item.defaultValue !== undefined) {
							default_option = <option value={item.defaultValue}>{item.defaultValue}</option>;
						}

					if (item.defaultValue !== undefined) {
						default_option = <option value={item.defaultValue}></option>;
					}

					if (read_only) {
						if (default_value_item_found && default_value_item) {
							return(<div>{default_value_item.title}</div>);
						} else {
							return(<div>{default_value}</div>);
						}
					} else {
						if (item.values.length > 0) {
							if (!default_value_item_found && default_value !== undefined) {
								default_option = <option value={default_value}>Inacessible Item {default_value}</option>
							}
							return(
								<div>
									<span className='editor-item-dropdown-title'>{item.title}: </span>
									<select className='editor-item-text-input' {...element_attrs}>
										{default_option}
										{item.values.map(function(option) {
											return(<option value={option.value}>{option.title}</option>);
										})}
									</select>
								</div>
							);
						}
						return(<div>Loading...</div>);
					}
				}
			case 'button':
				return(
					<Button onClick={item.onClick}>{item.label}</Button>
				);
			case 'grid':
				return(
					<Grid container spacing={1} alignItems="center">
						{this.EditableElementPart_grid(props, '', item.items, {
							...options,
							depth: options.depth + 1
						})}
					</Grid>
				);
			case 'dialog':
				{
					let show_dialog = false;

					if (this.state.edit_item_props === undefined || Object.keys(this.state.edit_item_props).length === 0) {
						show_dialog = true;
					}

					if (this.state.edit_item_props !== undefined && props.id === this.state.edit_item_props.id) {
						show_dialog = true;
					}

					return(
						<div>
							{this.state.element_type === item.element_type && show_dialog &&
								this.get_template_element_dialog(props, item)
							}
						</div>
					)
				}
			case 'raw':
				return(item.contents);
			case 'title':
			case 'subtitle':
				return(<div className={"editor-item-" + item.type + "-label"}>{item.title}:</div>);
			case 'component':
				return(item.body)
			case 'link':
			{
				const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
				const default_value = element_attrs.defaultValue;
				return(<a href={'#' + item.element_id}>{default_value}</a>)
			}
			case 'file':
				{
					const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
					return(
						<div>
							<input key={element_attrs.key} onChange={element_attrs.onChange} onBlur={element_attrs.onBlur} className={'editor-item-' + item.type + '-file'} type='file' accept='image/png, image/jpg, image/pdf, image/jpeg'/>
						</div>
					);
				}
			case 'checkbox':
				{
					const element_attrs = this.EditableElementPart_attribute_input(props, item.attribute, item.field_overrides);
					let onChange = element_attrs.onChange;

					/*
					 * calling notify_changed() immedietely after set_item_attribute in onChange to reflect changes in ui.
					 * Or else we see the checkbox selection change only after onBlur
					 */
					if (!item.field_overrides || (item.field_overrides && !item.field_overrides.onChange)) {
						onChange = (event, checked) => {
							this.set_item_attribute(props.id, item.attribute, checked);
							this.notify_changed();
						}
					}

					if (read_only) {
						const default_value = element_attrs.defaultValue;
						console.debug('item label =', item.label, item);

						return(<FormControlLabel control={<Checkbox checked={default_value} disabled />} label={item.label}/>);
					} else {
						return(<FormControlLabel
							control={
								<Checkbox
									key={element_attrs.key}
									checked={element_attrs.defaultValue}
									onChange={onChange}
									color='variant'
								/>}
							label={item.label}
						/>);
					}
				}
			case 'treeview':
			{
				const elements = this.state.document_elements;

				const renderTree = (nodes) => {
					let children;
					if (Array.isArray(nodes.children)) {
						children = nodes.children.map((node) => renderTree(node));
					} else {
						children = null;
					}

					return(
						<TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} onBlur={this.notify_changed}
							onLabelClick= {(event) => {
								item.value['value'] = nodes.value;
								item.value['type'] = nodes.type;
								item.value['element_id'] = nodes.id;
								this.set_document_variable(item.name, item.value);
							}}>
							{children}
						</TreeItem>
					)
				};

				return(
					<TreeView
						className='tree-view'
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
					>
						{renderTree(elements)}
					</TreeView>
				)
			}
			case 'sortly':
				{
					return(
						<DndProvider backend={HTML5Backend}>
							<ContextProvider>
								<Sortly className="sortly-elements" items={[{
									id: '1234567890',
									type: 'section',
									depth: 1,
									contents: {
										type: 'section'
									}
								}]}>
									{(props) => {
										return(this.item_renderer({
											data: {
												contents: {
													type: 'section'
												},
												depth: 0,
												id: '1234567890',
												type: 'section'
											},
											depth: 0,
											index: 0,
											id: '1234567890'
										}));
									}}
								</Sortly>
							</ContextProvider>
						</DndProvider>
					);
				}
			default:
				throw(new Error(`Unable to handle item with type "${item.type}" in grid item`));
		}
	}

	get_template_element_dialog(props, item) {
		return(<TemplateElementDialog
			item_props={props}
			item={item}
			variables_flat={this.props.variables_flat}
			close_dialog={() => this.setState({ element_type: '' })}
		/>)
	}

	get_elements_dialog_box() {
		return(<ElementsDialogBox
			close_dialog={this.close_dialog}
			add_element={this.insert_item}
		/>)
	}

	construct_comment_ref(id) {
		if (!this.comment_ref_map[id]) {
			this.comment_ref_map[id] = React.createRef();
		}
	}

	get_comment_view(id) {
		this.construct_comment_ref(id);
		return(<CommentView
			ref={this.comment_ref_map[id]}
			id={id}
			document={this.document}
			template={this.template}
			type={this.type}
			action={this.props.action}
			read_only={this.props.readOnly}
			updateDocumentVersion={this.props.updateDocumentVersion}
			routeVersion={this.props.routeVersion}
		/>)
	}

	add_custom_style(props, type) {
		const updated_style = type;
		const element_id = props.id;

		if (updated_style[element_id] === undefined) {
			updated_style[element_id] = {};
		}

		const value = props.data.value;
		for (const key in value) {
			updated_style[element_id][key] = value[key];
		}

		if (Object.keys(type).length !== Object.keys(updated_style).length) {
			this.setState({ type: updated_style })
		}
	}

	EditableElementPart_grid(props, title, data, options = {}) {
		options = Object.assign({
			depth: 1
		}, options);

		/*
		 * Determine how to interact with the data
		 */
		const items = [];
		if (data.length === 1) {
			/*
			 * If only one value is desired, prompt for it directly
			 */
			const item = data[0];

			if (!item.size) {
				item.size = true;
			}

			items.push(<Grid item xs={12}>{this.EditableElementPart_grid_item(props, item, options)}</Grid>);
		} else {
			/*
			 * Otherwise the data has multiple fields, we need to
			 * create a sub-grid and render each item
			 */
			items.push(
				<Grid item xs>
					<Grid container spacing={1} alignItems="center">
						{data.map((item) => {
							if (item.size === undefined) {
								item.size = 11;
							}
							return(this.EditableElementPart_grid(props, item.title, [item], {
								...options,
								depth: options.depth + 1
							}));
						})}
					</Grid>
				</Grid>
			);
		}

		if (options.depth === 1) {
			let template_action_buttons;
			if (this.writable) {
				template_action_buttons =
				<div id="action-button-container">
					<div id="action-buttons">
						<div id='drag' class="action-button toolbar-button template-action-button-1"></div>
						<div id="add" class="action-button toolbar-button template-action-button-2" onClick={() => this.add_action_clicked(props, props.data.type)}></div>
						<div id="edit" class="action-button toolbar-button template-action-button-3"  onClick={() => this.edit_action_clicked(props, props.elementInformation.type)}></div>
						<div id="copy" class="action-button toolbar-button template-action-button-4"></div>
						<div id="comment" class="action-button toolbar-button template-action-button-5"></div>
						<div id="remove" class="action-button toolbar-button template-action-button-6" onClick={() => {this.remove_item(props.id)}}></div>
					</div>
				</div>
			}

			let document_action_buttons;
			if (!this.writable) {
				this.construct_comment_ref(props.id);

				document_action_buttons =
				<div id="action-button-container">
					<div id="action-buttons">
						<div id="history" class="toolbar-button document-action-button-1"></div>
						<div id="review" class="action-button toolbar-button document-action-button-2"></div>
						<div id="comment" class="action-button toolbar-button document-action-button-3" onClick={() => {this.comment_ref_map[props.id].current.add_or_remove_comments_view(props.id)}}></div>
					</div>
				</div>
			}

			return(
				<div {...this.ElementPart_wrapper_options(props)}>
					<Grid container spacing={1} alignItems="center">
						<div className='element-container' id={props.id}>
							<div className='element-detail-container'>
								<div className='element-title-container'>
									<div className='element-title'>{title ? title : 'HTML'}</div>
								</div>
								<div>
									{template_action_buttons}
									{document_action_buttons}
								</div>
							</div>
							<div className='element'>{items}</div>
							<div>{this.get_comment_view(props.id)}</div>
						</div>
					</Grid>
				</div>
			);
		} else {
			return(
				<>
					{items}
				</>
			);
		}
	}

	add_action_clicked(props, type) {
		switch(type) {
			case 'loop':
				{
					this.setState({element_id: props.id});
					this.setState({element_type: 'loop'});
					this.setState({elements_dialog: true});
					break;
				}
			case 'switch':
				{
					this.setState({element_id: props.id});
					this.setState({element_type: '_meta_value'});
					const dialog = this.dialog_element(props, '_meta_value');
					this.setState({dialog: dialog});
					break;
				}
			case 'section':
				this.setState({element_id: props.id});
				this.setState({element_type: 'section'});
				this.setState({elements_dialog: true});
				break;
			case '@meta:else':
				this.setState({element_id: props.id});
				this.setState({element_type: '_meta_else'});
				this.setState({elements_dialog: true});
				break;
			case '@meta:default':
				this.setState({element_id: props.id});
				this.setState({element_type: '_meta_default'});
				this.setState({elements_dialog: true});
				break;
			case '@meta:value':
				this.setState({element_id: props.id});
				this.setState({element_type: '_meta_value'});
				this.setState({elements_dialog: true});
				break;
			default:
				break;
		}
	}

	edit_action_clicked(props, type) {
		this.setState({ element_type: type });

		if (type === '_meta_value') {
			return(null);
		} else {
			this.setState({edit_item_props: props});
			const dialog = this.dialog_element(props, type);
			this.setState({dialog: dialog});
		}
	}

	/****
	 **** The Elements
	 ****/
	EditableElement_title(props) {
		return(this.EditableElementPart_grid(props, 'Title', [
			{attribute: 'title'}
		]));
	}

	EditableElement_html(props) {
		return(this.EditableElementPart_grid(props, '', [
			{
				type: 'grid',
				size: 12,
				items: [
					{
						type: 'checkbox',
						label: 'Merge with preceding block',
						attribute: ['merge', 'start'],
						size: 6
					},
					{
						type: 'checkbox',
						label: 'Merge with following block',
						attribute: ['merge', 'end'],
						size: 6
					}
				]
			},
			{
				type: 'richtextarea',
				attribute: 'text',
				parse_check: true
			}
		]));
	}

	EditableElement_section(props) {
		return(this.EditableElementPart_grid(props, 'Section', [
			{attribute: 'name'}
		]));
	}

	EditableElement_comment(props) {
		return(this.EditableElementPart_grid(props, template_lib.computeElementDisplayName('comment'), [
			{
				type: 'richtextarea',
				attribute: 'text'
			}
		]));
	}

	async populate_datasource_items() {
		if (!this.datasource_items_populated) {
			this.datasource_items_populated = true;

			const result = await data_lib.list_data();
			this.setState({
				datasource_items: result
			});
		}
	}

	EditableElementPart_variable_options(props) {
		switch (props.data.type) {
			case '':
			case 'text':
			case 'textarea':
			case 'richtextarea':
			case 'image':
			case 'reference':
			case 'list':
			case 'multi_input':
				return([]);
			case 'dropdown':
			case 'checkbox':
				{
					let items;
					if (props.data.options !== undefined) {
						items = props.data.options.items;
					}
					return([
						{
							type: 'label',
							attribute: 'dropdown',
							value: `Items: ${items}`
						}
					]);
				}
			case 'datasource':
				{
					let column_headers, row_headers, type, source;
					if (props.data.options !== undefined) {
						source = props.data.options.source;
						column_headers = props.data.options.column_headers;
						row_headers = props.data.options.row_headers;
						type = props.data.options.type;
					}
					this.populate_datasource_items();
					return([
						{
							type: 'label',
							attribute: ['options', 'source'],
							value: `Source: ${source}`
						},
						{
							type: 'label',
							attribute: 'columns',
							value: `Columns: ${column_headers}`
						},
						{
							type: 'label',
							attribute: 'rows',
							value: `Rows: ${row_headers}`
						},
						{
							type: 'label',
							attribute: 'datasource_type',
							value: `Type: ${type}`
						},
						{
							title: 'Preview',
							type: 'component',
							hide_if_read_only: true,
							body: <PreviewDataSource datasource_info={props} own_variable_name={props.data.name}/>
						}
					]);
				}
			case 'expression':
				{
					let expression = '';
					if (props.data.options !== undefined) {
						expression = props.data.options.expression;
					}

					return([
						{
							title: "Expression",
							attribute: ['options', 'expression'],
							type: 'label',
							value: expression
						}
					]);
				}
			default:
				throw(new Error(`No variable option rendering for variable of type ${props.data.type}`));
		}
	}

	EditableElement_variable(props) {
		return(this.EditableElementPart_grid(props, 'Variable', [
			this.variable_dialog(props),
			{
				type: 'label',
				attribute: 'name',
				value: props.data.name
			},
			{
				type: 'label',
				attribute: 'type',
				value: props.data.type
			},
			{
				type: 'label',
				attribute: 'description',
				value: props.data.description
			},
			...this.EditableElementPart_variable_options(props)
		]));
	}

	EditableElement_header(props) {
		return(this.EditableElementPart_grid(props, 'Header', [
			{ attribute: 'value' }]
		));
	}

	EditableElement_footer(props) {
		return(this.EditableElementPart_grid(props, 'Footer', [
			{ attribute: 'value' }]
		));
	}

	EditableElement_switch(props) {
		let expression = 'Expression';

		if (props.data.expression !== undefined) {
			expression = props.data.expression;
		}

		let options = {};
		if (this.props.all_variables !== undefined) {
			options = nunjucks_utils.filters.keys(this.props.all_variables)
		}

		const content = [
			{
				type: 'textfield',
				label: 'Switch Name',
				attribute: 'name',
				field_overrides: this.EditableElementPart_attribute_input(props, 'name')
			},
			{
				type: 'expressionbuilder',
				title: 'Switch Expression',
				attribute: 'expression',
				autoCompleteConfig: [
					{
						trigger: ' ',
						options: nunjucks_utils.constants.operators,
						excludePredecessors: [...nunjucks_utils.constants.operators, undefined]
					},
					{
						trigger: '|',
						options: nunjucks_utils.constants.filters
					},
					{
						trigger: nunjucks_utils.constants.any,
						options: options
					}
				],
				field_overrides: this.EditableElementPart_attribute_input(props, 'expression')
			}
		];

		return(this.EditableElementPart_grid(props, 'Switch', [
			this.name_expression_dialog('switch', 'Switch Properties', content),
			{
				type: 'grid',
				size: 12,
				items: [
					{
						value: `Switch ${props.data.name} = ${expression}`,
						attribute: 'name',
						type: 'label',
						size: 2
					}
				]
			}
		]));
	}

	EditableElement__meta_value(props) {
		let options = {};
		if (this.props.all_variables !== undefined) {
			options = nunjucks_utils.filters.keys(this.props.all_variables)
		}

		return(this.EditableElementPart_grid(props, 'Switch Case', [
			{
				type: 'label',
				attribute: 'name',
				value: 'Expression evaluates to'
			},
			{
				type: 'expressionbuilder',
				attribute: 'value',
				autoCompleteConfig: [
					{
						trigger: ' ',
						options: options,
						includePredecessors: [...nunjucks_utils.constants.operators],
						excludePredecessors: ['|']
					},
					{
						trigger: '|',
						options: nunjucks_utils.constants.filters
					},
					{
						// match all
						trigger: nunjucks_utils.constants.any,
						options: nunjucks_utils.constants.operators,
						excludePredecessors: [...nunjucks_utils.constants.operators]
					}
				]
			}
		]));
	}

	name_expression_dialog(element_type, title, content) {
		const dialog_content = {
			type: 'dialog',
			element_type: element_type,
			title: title,
			content: content
		}
		return(dialog_content);
	}

	EditableElement__meta_default(props) {
		return(this.EditableElementPart_grid(props, 'Switch Case', [
			{
				type: 'label',
				attribute: 'name',
				value: 'Default'
			}
		]));
	}

	EditableElement_loop(props) {
		let expression = 'Expression';

		if (props.data.expression !== undefined) {
			expression = props.data.expression;
		}

		const content = [
			{
				type: 'textfield',
				label: 'Loop Name',
				attribute: 'name',
				field_overrides: this.EditableElementPart_attribute_input(props, 'name')
			},
			{
				type: 'textfield',
				label: 'Loop Expression',
				attribute: 'expression',
				field_overrides: this.EditableElementPart_attribute_input(props, 'expression')
			}
		];

		return(this.EditableElementPart_grid(props, 'Loop', [
			this.name_expression_dialog('loop', 'Loop Properties', content),
			{
				type: 'grid',
				size: 12,
				items: [
					{
						value: `Loop ${props.data.name} = ${expression}`,
						attribute: 'name',
						type: 'label',
						size: 2
					},
					{
						label: 'Else',
						type: 'button',
						size: 2,
						hide_if_read_only: true,
						onClick: () => {
							this.insert_item('@meta:else', {}, props.id);
						}
					}
				]
			}
		]));
	}

	EditableElement__meta_else(props) {
		return(this.EditableElementPart_grid(props, 'Loop', [
			{
				type: 'label',
				attribute: 'name',
				value: 'Else'
			}
		]));
	}

	EditableElement_template(props) {
		const id = this.get_item_attribute(props.id, 'id');
		const version = this.get_item_attribute(props.id, 'version', 'HEAD');

		let template_name = '';
		if (props.data.name !== undefined) {
			template_name = props.data.name;
		}

		let expand_collapse_template = '';
		if (id !== undefined && this.type === 'template') {
			expand_collapse_template =
				<Button onClick={async () => {
					const new_template = await template_lib.get_user_template(null, id, version);
					this.setState(function(prevState) {
						return({
							expanded_templates_element_id: {
								...prevState['expanded_templates_element_id'],
								[props.id]: new_template
							}
						});
					});
				}}>View Template</Button>
		}

		if (this.state.expanded_templates_element_id[props.id]) {
			expand_collapse_template =
				<>
					<Button onClick={() => {
						const expanded_templates_element_id = object_utils.copy_object(this.state.expanded_templates_element_id);
						delete expanded_templates_element_id[props.id];
						this.setState({ expanded_templates_element_id });
					}}>Collapse Template</Button>
					<div>
						<TemplateDocEditorArea type="template" template={this.state.expanded_templates_element_id[props.id]} writable={false} />
					</div>
				</>
		}

		let expression = '';
		if (props.data.name !== undefined) {
			expression = props.data.expression;
		}

		return(this.EditableElementPart_grid(props, 'Template', [
			this.template_dialog(props),
			{
				title: 'Template Name',
				attribute: 'name',
				value: template_name,
			},
			{
				title: 'Expression',
				type: 'label',
				attribute: 'expression',
				value: expression,
			},
			{
				type: 'raw',
				size: 12,
				contents: expand_collapse_template
			}
		]));

	}

	EditableElement_table_of_contents(props) {
		return(this.EditableElementPart_grid(props, 'Table of Contents', []));
	}

	EditableElement_citations_list(props) {
		return(this.EditableElementPart_grid(props, 'List of Citations', []));
	}

	EditableElement_abbreviations_list(props) {
		return(this.EditableElementPart_grid(props, 'List of Abbreviations', []));
	}

	// Image
	EditableElement_image(props) {
		return(this.EditableElementPart_grid(props, 'IMAGE (INCOMPLETE)', []));
	}

	// Table
	EditableElement_table(props) {
		let title, datasource, columns, rows;

		if (props.data !== undefined) {
			title = props.data.title;
			datasource = props.data.datasource;
			columns = props.data.column_headers;
			rows = props.data.row_headers;
		}

		let table_element_footnotes = [];

		if (props.data.footnotes !== undefined) {
			table_element_footnotes = props.data.footnotes;
		}

		const footnotes = this.state.template_footnotes;
		if (footnotes[props.id] === undefined) {
			footnotes[props.id] = table_element_footnotes;
		}

		if (footnotes[props.id] !== undefined) {
			table_element_footnotes = footnotes[props.id];
		}

		let read_only = false;
		if (this.type === 'document') {
			read_only = true;
		}

		return(this.EditableElementPart_grid(props, 'Table', [
			this.table_dialog(props),
			{
				type: 'grid',
				size: 12,
				items: [
					{
						type: 'label',
						attribute: 'title',
						value: `Table - ${title}`,
						size: 2
					},
					{
						type: 'label',
						attribute: 'datasource',
						value: `Datasource: ${datasource}`,
					},
					{
						type: 'label',
						attribute: 'columns',
						value: `Columns: ${columns}`,
					},
					{
						type: 'label',
						attribute: 'rows',
						value: `Rows: ${rows}`,
					},
					{
						type: 'raw',
						contents: `If entering a variable name, values for the row/column/cell or name/description fields
						are not required. They will be entered in the document editor.`
					},
					{
						type: 'editable_table',
						attribute: 'footnotes',
						values: table_element_footnotes,
						read_only: read_only,
						columns: [
							{title: 'Row, Column or Cell', field: 'value'},
							{title: 'Name or Description', field: 'name'},
							{title: 'Variable Name', field: 'variable_name'}
						],
						editable_properties: {
							onRowAdd: async (data) => {this.add_table_row(data, 'footnotes', props.id, 'template_footnotes')},
							onRowUpdate: async (data, old_data) => {this.update_table_row(data, old_data, 'footnotes', props.id, 'template_footnotes')},
							onRowDelete: async (data) => {this.remove_table_row(data, 'footnotes', props.id, 'template_footnotes')}
						}
					}
				]
			}
		]));

	}

	// Reference
	EditableElement_reference(props) {
		const selected_value = (props.data.value === undefined) ? '' : props.data.value.name;
		const formats = [
			'{{value}}',
			'{{type}} {{value}}',
			'{{link}}'
		];

		return(this.EditableElementPart_grid(props, 'Reference', [
			this.reference_dialog(props),
			{
				attribute: 'name',
			},
			{
				type: 'label',
				attribute: 'selected_value',
				value: selected_value
			},
			{
				title: 'Select a Format',
				attribute: ['value', 'format'],
				type: 'select',
				values: formats.map(function(format) {
					return({
						value: format,
						title: format
					});
				}),
				field_overrides: this.EditableElementPart_attribute_input(props, ['value', 'format']),
				read_only: false
			}
		]))
	}

	EditableElement_style(props) {
		let option;
		if (props.data.option !== undefined) {
			option = props.data.option;
		}

		const content = [
			this.style_dialog(props),
			{
				type: 'label',
				attribute: 'option',
				value: `Option: ${option}`
			}
		];

		if (option === 'Style element') {
			let style_element = '';
			if (props.data.value !== undefined) {
				style_element = props.data.value.element_type;
			}

			content.push({
				type: 'label',
				attribute: ['value', 'element_type'],
				value: `Element to style: ${style_element}`
			});
		}

		if (option === 'Add style') {
			let style_name = '';
			if (props.data.value !== undefined) {
				style_name = props.data.value.style_name;
			}

			content.push({
				type: 'label',
				attribute: ['value', 'style_name'],
				value: `Style name: ${style_name}`
			});
		}

		if (option === 'Add symbol') {
			let symbol_name;
			let glyph;
			if (props.data.value !== undefined) {
				symbol_name = props.data.value.symbol_name;
				glyph = props.data.value.glyph;
			}

			content.push(
				{
					type: 'label',
					attribute: ['value', 'symbol_name'],
					value: `Symbol name: ${symbol_name}`
				},
				{
					type: 'label',
					attribute: ['value', 'symbol_glyph'],
					value: `Glyph: ${glyph}`
				}
			);
		}

		if (option === 'Add section numbering') {
			let numbering_scheme
			if (props.data.value !== undefined) {
				numbering_scheme = props.data.value.numbering_scheme;
			}

			content.push(
				{
					type: 'label',
					attribute: ['value', 'numbering_scheme'],
					value: `Numbering Scheme: ${numbering_scheme}`
				}
			);
		}

		return(this.EditableElementPart_grid(props, 'Style', content))
	}

	EditableElementPart_style_options(props) {
		const rendered_element_types = ['', 'section', 'title', 'html', 'table', 'default'];
		const section_numbering = [{title: 'default', value: '1 --> 1.1 --> 1.1.1'}];

		switch(props.data.option) {
			case 'Style element':
				{
					const style_properties = styles.get_style_properties(props, this.EditableElementPart_attribute_input);
					style_properties.unshift({
						title: 'Element Type',
						type: 'dropdown',
						attribute: ['value', 'element_type'],
						values: rendered_element_types,
						field_overrides: this.EditableElementPart_attribute_input(props, ['value', 'element_type'])
					})

					const table_properties = styles.get_table_style_properties(props, this.EditableElementPart_attribute_input);

					if (props.data.value !== undefined && props.data.value.element_type === 'table') {
						for (const prop of table_properties) {
							style_properties.push(prop);
						}
					}

					return(style_properties);
				}
			case 'Add style':
				{
					this.add_custom_style(props, this.state.format);

					const style_properties = styles.get_style_properties(props, this.EditableElementPart_attribute_input);
					style_properties.unshift({
						label: 'Style name',
						type: 'textfield',
						param: 'name',
						attribute: ['value', 'style_name'],
						field_overrides: this.EditableElementPart_attribute_input(props, ['value', 'style_name'], { options: { managed: true } })
					})

					return(style_properties);
				}
			case 'Add symbol':

				this.add_custom_style(props, this.state.symbol);

				return([
					{
						label: 'Symbol Name',
						type: 'textfield',
						param: 'name',
						attribute: ['value', 'symbol_name'],
						field_overrides: this.EditableElementPart_attribute_input(props, ['value', 'symbol_name'], { options: { managed: true } })
					},
					{
						label: 'Glyph',
						attribute: ['value', 'glyph'],
						type: 'textfield',
						field_overrides: this.EditableElementPart_attribute_input(props, ['value', 'glyph'])
					}
				])
			case 'Add section numbering':
				return([
					{
						label: 'Section Numbering Scheme',
						type: 'dropdown',
						attribute: ['value', 'numbering_scheme'],
						defaultValue: 'N/A',
						values: section_numbering,
						field_overrides: this.EditableElementPart_attribute_input(props, ['value', 'numbering_scheme'])
					}
				])
			default:
				return([]);
		}
	}

	/***
	 *** Fixed Elements
		***/
	FixedElement_title(props) {
		return(this.EditableElement_title({
			...props,
			read_only: true
		}));
	}

	FixedElement_section(props) {
		return(this.EditableElement_section({
			...props,
			read_only: true
		}));
	}

	FixedElement_comment(props) {
		return(this.EditableElement_comment({
			...props,
			read_only: true
		}));
	}

	FixedElement_header(props) {
		return(this.EditableElement_header({
			...props,
			read_only: true
		}));
	}

	FixedElement_footer(props) {
		return(this.EditableElement_footer({
			...props,
			read_only: true
		}));
	}

	FixedElement_html(props) {
		return(this.EditableElement_html({
			...props,
			read_only: true
		}));
	}

	FixedElementPart_variable_options(props) {
		if (this.type === 'document') {
			/*
			 * For documents, prompt for a value to store in the document
			 */

			const variable_name = props.data.name;
			const current_value = this.get_document_variable(variable_name);

			let items;
			switch (props.data.type) {
				case 'dropdown':
				case 'checkbox':
				case 'list':
					items = [];
					if (props.data && props.data.options && props.data.options.items && props.data.options.items.map) {
						items = props.data.options.items;
					}
					break;
				default:
					/* Nothing to do for items for most things */
					break;
			}

			switch (props.data.type) {
				case 'text':
					return([
						{
							title: 'Value',
							type: 'text',
							defaultValue: this.get_document_variable(variable_name),
							attribute: 'value',
							field_overrides: {
								onChange: (event) => {
									const new_value = event.target.value;
									return(this.set_document_variable(variable_name, new_value));
								}
							},
							read_only: false
						}
					]);
				case 'dropdown':
					{
						let items = [];

						if (props.data.options !== undefined && props.data.options.items !== undefined) {
							items = props.data.options.items;
						}

						return([
							{
								title: 'Value',
								attribute: 'value',
								type: 'select',
								values: items.map(function(item) {
									return({
										value: item,
										title: item
									});
								}),
								field_overrides: {
									onChange: (event) => {
										const new_value = event.target.value;
										return(this.set_document_variable(variable_name, new_value));
									}
								},
								read_only: false
							},
						]);
					}
				case 'checkbox':
					return([
						{
							title: 'Value',
							attribute: 'value',
							type: 'checkbox',
							values: items.map(function(item) {
								return({
									label: item
								})
							}),
							field_overrides: {
								onChange: (event) => {
									/* XXX:TODO */
								}
							},
							read_only: false
						}
					]);
				case 'list':
					return([
						{
							title: 'Value',
							attribute: 'value',
							type: 'select',
							values: items.map(function(item) {
								return({
									value: item,
									title: item
								});
							}),
							field_overrides: {
								onChange: (event) => {
									const new_value = event.target.value;
									return(this.set_document_variable(variable_name, new_value));
								}
							},
							read_only: false
						},
					]);
				case 'multi_input':
					{
						let variable_footnotes = [];
						const variable_value = this.get_document_variable(variable_name);

						if (variable_value !== undefined) {
							variable_footnotes = variable_value;
						}

						const footnotes = this.state.document_footnotes;
						if (footnotes[props.id] === undefined) {
							footnotes[props.id] = variable_footnotes
						}

						if (footnotes[props.id] !== undefined) {
							variable_footnotes = footnotes[props.id];
						}

						return([
							{
								type: 'editable_table',
								attribute: variable_name,
								values: variable_footnotes,
								columns: [
									{title: 'Row, Column or Cell', field: 'value'},
									{title: 'Name or Description', field: 'name'},
								],
								editable_properties: {
									onRowAdd: async (data) => {this.add_table_row(data, variable_name, props.id, 'document_footnotes')},
									onRowUpdate: async (data, old_data) => {this.update_table_row(data, old_data, variable_name, props.id, 'document_footnotes')},
									onRowDelete: async (data) => {this.remove_table_row(data, variable_name, props.id, 'document_footnotes')}
								}
							}
						]);
					}
				case 'datasource':
					{
						/*
						 * There are two kinds of data sources,
						 * one where the source is defined in
						 * the template and one where the
						 * source is left to the document
						 * author to define.  If the data
						 * source is to be defined by the user
						 * they may also just upload data.
						 */
						let user_defined_data_source = true;
						if (!props.data.options) {
							props.data.options = {};
						}
						if (props.data.options.source !== undefined && props.data.options.source !== "") {
							user_defined_data_source = false;
						}

						if (user_defined_data_source) {
							/* XXX:TODO */
							console.error('User defined data source detected, but not supported.');
							return([]);
						} else {
							return([
								{
									title: "Source",
									attribute: ['options', 'source']
								},
								{
									title: "Columns",
									attribute: ['options', 'column_headers']
								},
								{
									title: "Rows",
									attribute: ['options', 'row_headers']
								},
								{
									title: "Type",
									attribute: ['options', 'type'],
									type: 'select',
									values: [
										{title: "Automatically Detect", value: "auto"},
										{title: "Rows", value: "rows"},
										{title: "Columns", value: "columns"},
										{title: "Columns x Rows", value: "columns-rows"}
									]
								},
								{
									label: "Get Latest Data",
									type: 'button',
									onClick: async () => {
										const save_success = await this.run_request_save_document();
										if (!save_success) {
											return;
										}
										const update_success = await this.update_data_source_value(variable_name);
										if (!update_success) {
											return;
										}
										await this.run_request_save_document();
									},
									read_only: false
								}
							])
						}
					}
				case 'image':
					/* XXX:TODO: Shouldn't this get used somewhere ? */
					/** XXX:TODO let current_value_set_msg; **/
					if (current_value !== undefined) {
						/** XXX:TODO current_value_set_msg = { **/
						/** XXX:TODO title: 'File has been selected', **/
						/** XXX:TODO } **/
					}
					return([
						{
							title: 'Image',
							type: 'file',
							attribute: 'value',
							field_overrides: {
								onChange: async (event) => {
									const file = event.target.files[0];
									const type = file.type;

									const file_info = await this.upload_image(file, type);
									this.set_document_variable(variable_name, JSON.stringify(file_info));
								}
							},
							read_only: false
						}
					]);
				case 'textarea':
					return([
						{
							title: 'Value',
							type: 'textarea',
							attribute: 'value',
							field_overrides: {
								onChange: (event) => {
									const new_value = event.target.value;
									this.set_document_variable(variable_name, new_value);
								}
							},
							read_only: false
						}
					]);
				case 'richtextarea':
					return([
						{
							title: 'Value',
							type: 'richtextarea',
							attribute: 'value',
							field_overrides: {
								onChange: (event) => {
									const new_value = event.target.value;
									this.set_document_variable(variable_name, new_value);
								}
							},
							read_only: false
						}
					]);
				case 'reference':
					{
						const new_value = (current_value === undefined) ? {} : current_value;
						const formats = ['{{value}}', '{{type}} {{value}}', '{{link}}'];
						const selected_element = ((current_value === undefined) ? 'None' : current_value.value);
						const selected_format = ((current_value === undefined) ? '' : current_value.format);

						return([
							{
								title: 'Selected Value',
								type: 'label',
								value: `Selected element: ${selected_element}`
							},
							{
								title: 'Filter',
								placeholder: 'Search for element',
								type: 'text',
								field_overrides: {
									onChange: async (event) => {
										const search_results = cross_references.filter_reference_data('document', event.target.value);
										this.setState({ document_elements: search_results });
									}
								},
								read_only: false
							},
							{
								title: 'Formats',
								attribute: ['value', 'format'],
								type: 'select',
								defaultValue: selected_format,
								values: formats.map(function(format) {
									return({
										value: format,
										title: format
									});
								}),
								field_overrides: {
									onChange: (event) => {
										new_value['format'] = event.target.value;
										return(this.set_document_variable(variable_name, new_value));
									}
								},
								read_only: false
							},
							{
								title: 'Get Elements',
								type: 'button',
								label: 'Get Document Elements',
								onClick: async () => {
									const document_elements = await cross_references.get_document_elements(this.document.id);
									this.setState({document_elements: document_elements});
								},
								read_only: false
							},
							{
								title: 'Values',
								type: 'treeview',
								value: new_value,
								name: variable_name
							},
						]);
					}
				case 'expression':
					{
						let expression;
						if (props.data && props.data.options) {
							expression = props.data.options.expression;
						}

						return([
							{
								title: 'Expression',
								type: 'label',
								value: expression
							}
						]);
					}
				default:
					throw(new Error(`No variable option rendering for variable of type ${props.data.type} within fixed element`));
			}
		} else {
			/* For templates, only print out the information */
			return([
				{
					title: 'Options',
					attribute: ['options', 'row_headers']
				}
			])
		}
	}

	FixedElement_variable(props) {
		return(this.EditableElementPart_grid({...props, read_only: true}, 'Variable', [
			{
				title: 'Type',
				attribute: 'type',
			},
			{
				title: 'Name',
				attribute: 'name',
			},
			{
				title: 'Description',
				attribute: 'description',
			},
			...this.FixedElementPart_variable_options(props)
		]));
	}

	FixedElement_template(props) {
		let kind_name_info, kind_name, list;
		if (this.type === 'document') {
			const element_id = props.id;

			let subdocument_id;
			if (this.document && this.document.subdocuments) {
				const subdocument_info = this.document.subdocuments[element_id];
				if (subdocument_info && subdocument_info.document_id) {
					subdocument_id = subdocument_info.document_id[0];
				}
			}

			let document_name;
			if (subdocument_id !== undefined) {
				document_name = this.state.subdocument_map[subdocument_id];
			}

			if (document_name === undefined) {
				this.refresh_subdocument_map();
				document_name = 'Loading...';
			}

			kind_name_info = 'Document';
			kind_name = document_name;

			const template_list = this.state.filtered_templates[props.id];

			const collapse_button = (
				<Button onClick={() => {
					const filtered_templates = object_utils.copy_object(this.state.filtered_templates);
					delete filtered_templates[props.id]
					this.setState({ filtered_templates });
					const expanded_templates_element_id = object_utils.copy_object(this.state.expanded_templates_element_id);
					delete expanded_templates_element_id[props.id];
					this.setState({ expanded_templates_element_id });
				}}>Collpase</Button>
			)

			if (this.state.expanded_templates_element_id[props.id]) {
				if (template_list === undefined || template_list.length === 0) {
					list = <div>No templates to show</div>
				} else {
					list = (
						<>
							<List>
								{
									template_list.map((template, index) => {
										return(
											<ListItem key={template.id}>
												<ListItemText>{index++}. {template.name}</ListItemText>
											</ListItem>
										)
									})
								}
							</List>
							{collapse_button}
						</>
					)
				}
			}
		} else {
			const subtemplate_info = undefined;

			let template_name;
			if (subtemplate_info && subtemplate_info['name']) {
				template_name = subtemplate_info['name'];
			} else {
				template_name = props.data.id;
			}

			kind_name_info = 'Template'
			kind_name = template_name;
		}

		const items = [
			{
				title: 'Name',
				attribute: 'name',
				type: 'text',
			},
			{
				title: 'Subtemplate',
				type: 'button',
				label: `${kind_name_info}: ${kind_name}, ID: ${props.data.name}`
			}
		];

		if (props.data.expression){
			items.push(...[
				{
					title: 'expression',
					type: 'text',
					attribute: 'expression',
					defaultValue: props.data.expression
				},
				{
					type: 'button',
					label: 'Fetch templates by expression',
					onClick: async () => {
						this.setState(function(prevState) {
							return({
								expanded_templates_element_id: {
									...prevState['expanded_templates_element_id'],
									[props.id]: 'true'
								}
							});
						});
						const templates = await this.getFilteredTemplatesByExpression(props.data.expression.trim());
						this.setState(function (prevState) {
							return({
								filtered_templates: {
									...prevState['filtered_templates'],
									[props.id]: templates
								}
							});
						});
					}
				},
				{
					type: 'raw',
					contents: (list)
				}
			])
		}

		return(this.EditableElementPart_grid({ ...props, read_only: true }, 'Template', items));
	}

	FixedElement_switch(props) {
		return(this.EditableElement_switch({
			...props,
			read_only: true
		}));
	}

	FixedElement__meta_value(props) {
		return(this.EditableElement__meta_value({
			...props,
			read_only: true
		}));
	}

	FixedElement__meta_default(props) {
		return(this.EditableElement__meta_default({
			...props,
			read_only: true
		}));
	}

	FixedElement_loop(props) {
		return(this.EditableElement_loop({
			...props,
			read_only: true
		}))
	}

	FixedElement__meta_else(props) {
		return(this.EditableElement__meta_else({
			...props,
			read_only: true
		}));
	}

	FixedElement_table_of_contents(props) {
		return(this.EditableElement_table_of_contents({
			...props,
			read_only: true
		}));
	}

	FixedElement_citations_list(props) {
		return(this.EditableElement_citations_list({
			...props,
			read_only: true
		}));
	}

	FixedElement_abbreviations_list(props) {
		return(this.EditableElement_abbreviations_list({
			...props,
			read_only: true
		}));
	}

	FixedElement_table(props) {
		let table_info;

		/*
		 * Tables may be numbered
		 */
		/** XXX:TODO **/

		/*
		 * Tables should have Titles
		 */
		let table_title = 'Table';
		if (props.data.title) {
			table_title = props.data.title;
		}

		/*
		 * Tables may be backed by data sources, or be backed by...?
		 */
		let table_data_info, datasources = [];
		if (props.data.datasource) {
			try {
				const datasource_details = data_utils.get_datasource_name(props.data.datasource);
				const datasource = datasource_details[0];
				const key = datasource_details[1];

				const datasource_value = this.get_document_variable(datasource);

				const table_values = data_utils.sanitize_datasource_values(datasource_value, key);

				table_data_info = table_values[0];
				datasources = table_values[1];
			} catch (_ignored_error) {
				console.error('ERROR:', _ignored_error);
			}
		}

		if (table_data_info) {
			if (Object.keys(table_data_info).length > 1) {
				table_info =
					<div>
						{datasources.map((table_data) => {
							return(
								<Table
									table_title={`${table_title} (from ${props.data.datasource}, file: ${table_data.name})`}
									datasource_name={props.data.datasource}
									table_data_info={table_data}
									column_headers={props.data.column_headers}
									row_headers={props.data.row_headers}
									type={table_data.type}
								/>
							);
						})}
					</div>
			}
		}

		const retval =
			<div {...this.ElementPart_wrapper_options(props)}>
				{this.EditableElement_table({
					...props,
					read_only: true,
				})}
				{table_info}
			</div>;

		return(retval);
	}

	FixedElement_image(props) {
		return(this.EditableElement_image({
			...props,
			read_only: true
		}));
	}

	FixedElement_reference(props) {
		let element_id = '';
		if (props.data.value !== undefined) {
			element_id = props.data.value.element_id;
		}

		return(this.EditableElementPart_grid({...props, read_only: true}, 'Reference', [
			{
				title: 'Name',
				attribute: 'name',
				type: 'link',
				element_id: element_id
			},
		]));
	}

	FixedElement_style(props) {
		return(this.EditableElement_style({
			...props,
			read_only: true
		}));
	}

	async substituteVariableInExpression(expression) {
		const document = this.document;
		const variables = await document_utils.process_document_variables(undefined, {
			document_id: document.id,
			version_id: document.version
		}, {
			get_document_obj: function(id, version) {
				if (id === document.id && version === document.version) {
					return document;
				}
			}
		});
		return nunjucks_utils.renderString(expression, variables);
	}

	async getFilteredTemplatesByExpression(expression) {
		let templates = {};
		expression = await this.substituteVariableInExpression(expression);
		templates = await template_lib.getTemplates(`metadata.${expression}`, ['name']);
		templates.sort(function(a, b) {
			if (a.id < b.id) {
				return(-1);
			} else if (a.id > b.id) {
				return(1);
			} else {
				return(0);
			}
		});
		return templates;
	}
	/**
	 ** Dispatch the right element to be rendered
	 **/
	render_item(type, id, contents) {
		/*
		 * Determine if this element should be writable.  This will be
		 * based on if the element came from a template or a document
		 * (via body_extend) and if this area is for a document or a
		 * template.
		 *
		 * Note that this only applies to editing the Element itself,
		 * not some other part of the document.  For example, variable
		 * elements are not editable in the Document (since you can't
		 * change their name, etc) but you may change the value of the
		 * variable (which does not affect the variable element at all).
		 *
		 */
		const writable = this.writable;

		/*
		 * Based on writability and the type of element, find the
		 * function to render that element as part of this editor.
		 */
		let dispatch_function_name;
		const type_sanitized = type.replace(/[^A-Za-z]/g, "_");
		if (writable) {
			dispatch_function_name = `EditableElement_${type_sanitized}`
		} else {
			dispatch_function_name = `FixedElement_${type_sanitized}`
		}

		const dispatch_function = this[dispatch_function_name];

		/*
		 * If no function is found, use a fallback
		 */
		if (!dispatch_function) {
			/*
			 * XXX:TODO: Fallback for unknown elements
			 */
			return(<div style={{
				padding: "0.2em",
				margin: "0.1em",
				background: "#f0f0f0"
			}}>[Fallback: {dispatch_function_name}] Element:<pre>{JSON.stringify({id, type, contents}, undefined, 4)}</pre></div>);
		}

		/*
		 * Dispatch to the element function
		 */
		if (writable || dispatch_function_name === 'FixedElement_variable' || this.type === 'template') {
			return this[dispatch_function_name]({
				id: id,
				data: contents,
				className: "item-" + dispatch_function_name.toLowerCase().replace(/_/g, "-"),
				elementInformation: {
					type: type,
					writable: writable
				}
			})
		}
	}

	/**
	 ** Dispatch the right element to be rendered in a Drag-and-Drop wrapper,
	 ** if appropriate
	 **/
	item_renderer(item_props) {
		const data = item_props['data'];

		const inner = this.render_item(data.type, data.id, data.contents);

		let retval;
		if (this.type === 'template') {
			retval = (
				<DragDropItem depth={data.depth}>
					{inner}
				</DragDropItem>
			);
		} else {
			retval = (
				<div style={{ marginLeft: (data.depth * 2) + 'em' }}>
					{inner}
				</div>
			);
		}

		return(retval);
	}

	close_dialog() {
		this.setState({ elements_dialog: false })
	}

	/**
	 ** Render the editor area
	 **/
	render() {
		/*
		 * XXX:TODO: Move this to CSS instead of an in-line style ?
		 */
		let element_container_style = {
			border: '3px solid white'
		};
		if (this.state.items_valid !== true) {
			element_container_style = {border: '3px solid red'};
		}

		let dialog;
		if (this.state.dialog !== undefined) {
			dialog = this.state.dialog;
		}

		let elements_dialog;
		if (this.state.elements_dialog === true) {
			elements_dialog = this.get_elements_dialog_box();
		}

		let fetch_all_datasources;
		if (this.type === 'document') {
			fetch_all_datasources = <Button onClick={async () => {
				const error = '';
				const top_document_info = await document_utils.get_toplevel_document(null, this.document.id, 'HEAD');
				if (!top_document_info) {
					throw(new Error(`Failed to get top document info for document_id=${this.document.id}`));
				}
				const top_level_document = await document_lib.get_user_document(null, this.document.id, 'HEAD');

				const update_result = await document_utils.fetch_all_datasource_values(top_level_document, error);
				if (update_result) {
					await this.props.updateDocumentVersion(update_result.version_id);

					if (update_result.error !== '') {
						this.run_on_error(update_result.error);
					} else {
						this.run_on_change();
					}
				}
			}}>Fetch All Datasources</Button>;
		}

		const document = this.props.document;
		let id = null;

		if (document) {
			id = document.id;
		}

		const comment_view = this.get_comment_view(id);

		/*
		 * Render the items
		 */
		return <div style={element_container_style}>
			<DndProvider backend={HTML5Backend}>
				<ContextProvider>
					<Sortly className="sortly-elements" items={this.get_items()} onChange={this.set_items}>
						{(props) => {
							return(this.item_renderer(props));
						}}
					</Sortly>
				</ContextProvider>
			</DndProvider>
			{dialog}
			{elements_dialog}
			{comment_view}
			{fetch_all_datasources}
		</div>
	}
}

export default TemplateDocEditorArea;
