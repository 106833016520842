import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button } from '../../../lib/ui';

import list from '../../../api/list.js';
import list_utils from '../../../lib/utils/list_utils.js';
import user from '../../../api/user.js';

const uuid = require('uuid');
const csv = {
	parse: require('csv-parse/lib/sync')
};

class ListsUpload extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		let button_text = `IMPORT MASTER ${props.type.toUpperCase()} LIST`;
		if (props.button_text) {
			/* XXX:TODO: Move this to getDerivedStateFromProps(sp?) */
			button_text = props.button_text;
		}

		this.state = {
			id: `id_${uuid.v4()}`,
			button_text: button_text
		};

		/*
		 * Find the current user ID
		 */
		this.current_uid = user.get_user_id();
	}

	async upload_list(file) {
		const csv_data = await file.text();
		const data = csv.parse(csv_data, {/* No options right now */});

		let existing_list_info = undefined;
		let is_default = true;
		try {
			if (this.props.to === '@default' || this.props.to === undefined) {
				/*
				 * Get the default list ID and version
				 */
				existing_list_info = await list_utils.get_user_default_list(null, this.props.type, {
					fields: ['id', 'version', 'permissions']
				});
			} else {
				existing_list_info = await list.get_user_list(null, this.props.type, this.props.to);
				is_default = existing_list_info.default;
			}
		} catch (ignored_error) {
			/* This error is ignored and we assume it means there is no existing list */
		}

		if (existing_list_info === undefined) {
			existing_list_info = {};
		}

		const new_list = {
			name: `Master ${this.props.type} list`,
			default: is_default,
			permissions: {
				owners: [await this.current_uid],
				acl: {read: ['@role:readers']},
				roles: {
					readers: ['@all']
				}
			},
			...existing_list_info,
			id: existing_list_info.id,
			version: existing_list_info.version,
			type: this.props.type,
			entries: []
		}

		for (const row of data) {
			new_list.entries.push({
				key: row[0],
				value: row[1]
			});
		}

		let retval;
		if (existing_list_info.id === undefined) {
			retval = await list.new_user_list(null, this.props.type, new_list);
		} else {
			retval = await list.update_user_list(null, this.props.type, existing_list_info.id, new_list);
		}

		return(retval);
	}

	render() {
		let uploading_indicator;
		switch (this.state.uploading) {
			case 'working':
				uploading_indicator = <CircularProgress size={24} style={{ margin: '0 1rem' }} />
				break;
			case 'error':
				uploading_indicator = '[ERROR]';
				break;
			default:
				/* Nothing to do by default */
				break;
		}

		const pass_props = {
			color: this.props.color
		};

		if (pass_props.color === undefined) {
			delete pass_props['color'];
		}

		return(<>
			<input
				accept='.csv'
				style={{ display: 'none' }}
				id={this.state.id}
				type="file"
				onChange={async (event) => {
					const files = event.target.files;

					if (this.props.onStart) {
						this.props.onStart();
					}

					this.setState({
						uploading: 'working'
					});

					let new_info;
					try {
						new_info = await this.upload_list(files[0]);

						this.setState({
							uploading: 'complete'
						});
					} catch (upload_error) {
						/* XXX:TODO: We should notify the user of this error */
						console.error('FAILED TO UPLOAD SHOULD NOTIFY USER!', upload_error);

						this.setState({
							uploading: 'error'
						});

						new_info = undefined;
					}

					if (this.props.onComplete) {
						this.props.onComplete(new_info);
					}

				}}
			/>
			<label htmlFor={this.state.id}>
				<Button {...pass_props} component="span" disabled={this.props.disabled}>
					{this.state.button_text}
				</Button>
				{uploading_indicator}
			</label>
		</>);
	}
}

export default ListsUpload;
