import React from 'react';

class PromptGroup extends React.Component {
	render() {
		return(
			<table>
				<tbody>
					{this.props.children}
				</tbody>
			</table>
		);
	}
}

export default PromptGroup;
