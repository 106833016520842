/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src451518703/src/src/kaialpha/lib/validation_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import document_utils from './document_utils';
import object_utils from './object_utils';
const json_schema = require('ajv');
const _testing = undefined;

const element_info = [
	{
		name: 'Template',
		description: 'Place Sub-Templates',
		icon: 'template-outline.svg'
	},
	{
		name: 'Html',
		description: 'Place Editable Text',
		icon: 'text-filled.svg'
	},
	{
		name: 'Variable',
		description: 'Access Dynamic Data',
		icon: 'variable-outline.svg'
	},
	{
		name: 'Table',
		description: 'Add Data',
		icon: 'table-filled.svg'
	},
	{
		name: 'Title',
		description: 'Add Heading',
		icon: 'title-filled.svg'
	},
	{
		name: 'Reference',
		description: 'Add Reference',
		icon: 'link-outline.svg'
	},
	{
		name: 'Image',
		description: 'Place Image',
		icon: 'image-outline.svg'
	},
	{
		name: 'Section',
		description: 'Container',
		icon: 'section-outline.svg'
	},
	{
		name: 'Table of Contents',
		description: 'Add TOC of sections',
		icon: 'toc-outline.svg'
	},
	{
		name: 'Switch',
		description: 'Decide the case',
		icon: 'decision.svg'
	},
	{
		name: 'Instructional Text',
		description: 'Instructional Text',
		icon: 'instruction-outline.svg'
	},
	{
		name: 'Style',
		description: 'Styles the elements',
		icon: 'instruction-outline.svg'
	},
	{
		name: 'Header',
		description: 'Tops the Page',
		icon: 'header-outline.svg'
	},
	{
		name: 'Footer',
		description: 'Bottom of Page',
		icon: 'footer-outline.svg'
	},
	{
		name: 'Loop',
		description: 'Loop through expressions',
		icon: 'footer-outline.svg'
	},
	{
		name: 'Citations List',
		description: 'Add document citations',
		icon: 'footer-outline.svg'
	},
	{
		name: 'Abbreviations List',
		description: 'Add document abbreviations',
		icon: 'footer-outline.svg'
	}
];

const variable_types = ['text', 'dropdown', 'checkbox', 'list', 'multi_input', 'datasource', 'image', 'textarea', 'richtextarea', 'reference', 'expression']
// const element_types = ['html', 'template', 'table', 'image', 'table_of_contents', 'header', 'footer', 'signature', 'label', 'reference', 'variable', 'section', 'switch', 'title'];
const element_types = ["section", "html", "title", "table_of_contents", "template", "variable", "reference", "switch", "image", "table", "header", "footer", "style", "comment", "loop", "citations_list", "abbreviations_list"];

const validation_config = {
	/*
	 * Strict validation here means that the validator will only accept
	 * documents that KaiAlpha itself produces.  Additionally, it enables
	 * checks for other situations that are not forbidden, but are probably
	 * a bad idea.
	 */
	strict_validation: true,

	/*
	 * Extra validation performs validation beyond just the syntax validation
	 */
	extra_validation: true
};

function init(options = {}) {
	Object.assign(validation_config, options);

	return(validation_config);
}

function generate_schemas() {
	let element_id_format_regexp = '^[A-Za-z0-9@_-]+$';
	let item_id_format_regexp = '^[A-Za-z0-9@_-]+$';
	let item_version_format_regexp = '^[A-Za-z0-9@_-]+$';
	const variable_name_format_regexp = '^[A-Za-z0-9_]+$';
	if (validation_config.strict_validation) {
		/*
		 * We only use UUIDs as element IDs, so for strict validation
		 * ensure that all element IDs are UUIDs
		 */
		element_id_format_regexp = '^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$'

		/*
		 * Item IDs and versions are also currently UUIDs
		 */
		item_id_format_regexp = '^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$'
		item_version_format_regexp = '^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$'
	}

	const permissions_schema = {
		type: 'object',
		properties: {
			'owners': {
				type: 'array',
				minItems: 1
			},
			'roles': {
				type: 'object'
			},
			'acl': {
				type: 'object'
			},
			'inherit_from': {
				description: 'Inherit ACLs (null means explicitly do not inherit)',
				oneOf: [
					{
						type: 'null'
					},
					{
						type: 'array',
						items: {
							type: 'object',
							properties: {
								'id': {
									type: 'string',
									description: 'ID of the item to inherit ACLs from'
								},
								'type': {
									type: 'string',
									description: 'Type of item (with the specified ID) to inherit ACLs from'
								}
							},
							additionalProperties: false,
							required: ['id', 'type']
						}
					}
				]
			}
		},
		additionalProperties: false,
		required: ['owners']
	};

	const body_schema_elements = {
		template: {
			properties: {
				'id': {
					type: 'string',
					description: 'Template ID for which this element should include.',
					regex: item_id_format_regexp,
				},
				'version': {
					type: 'string',
					description: 'Version of the template ID for which this element should include, specify "HEAD" for the latest version.',
					regex: item_version_format_regexp
				},
				'name': {
					type: 'string',
					description: 'Name of the template element within the structure of documents.  This name must be in the same format as variable names, since it will be used to form the variable tree structure.',
					regex: variable_name_format_regexp
				},
				'global_name': {
					type: 'string',
					description: 'Name of the template element within the structure of documents.  This name must be in the same format as variable names, since it will be used to form the variable tree structure.  This name will be created as a reference from the top-level of the tree of documents.',
					regex: variable_name_format_regexp
				},
				'expression': {
					type: 'string',
					description: 'Expression that can be evaluated to find the respective templates'
				}
			},
			required: ['name'],
			oneOf: [
				{
					required: ['expression']
				},
				{
					required: ['id', 'version']
				},
			],
			description: 'The "template" element specifies that another template should be included at this point in the document.  When instantiated to a Document, the referenced template will also be converted to a Document and referred to as a "subdocument".'
		},
		variable: {
			properties: {
				'name': {
					type: 'string',
					regex: variable_name_format_regexp
				},
			},
			required: ['name'],
			description: 'The "variable" element specifies a variable should be created with a given name, additionally it specifies that at the position within the body it should be prompted for.  The variable information, such as type of variable and description are stored in the "variables" section of the template.'
		},
		html: {
			properties: {
				'text': {type: 'string'},
				'merge': {
					'start': {type: 'boolean'},
					'end': {type: 'boolean'},
					'description': 'Whether to merge with whitespace to start/end of this block'
				}
			},
			required: ['text'],
			description: 'The "variable" element specifies a variable should be created with a given name, additionally it specifies that at the position within the body it should be prompted for.'
		},
		section: {
			properties: {
				'name': {type: 'string'},
				'body': {
					'$ref': '#/definitions/body'
				}
			},
			required: ['name'],
			description: 'The "section" element specifies a new numbered section within a document.  Its name is the title of that section.  The "body" attribute is a sub-body and processed the same way as the rest of the "body".'
		},
		title: {
			properties: {
				"title": {type: 'string'}
			},
			required: ['title'],
			description: 'The "title" element specifies a document title should be inserted at this position within the document.'
		},
		comment: {
			properties: {
				"text": {type : 'string'}
			},
			description: 'The "comment" element specifies a comment should be inserted at this position in the document'
		},
		header: {
			properties: {
				"value": {type: 'string'}
			},
			description: 'The "header" element specifies a document header should be set or changed at this position within the document.'
		},
		footer: {
			properties: {
				"value": {type: 'string'}
			},
			description: 'The "footer" element specifies a document footer should be set or changed at this position within the document.'
		},
		reference: {
			properties: {
				'name': {type: 'string'},
				'value': {
					type: 'object',
					properties: {
						'element_id': {
							type: 'string',
							regex: element_id_format_regexp,
							description: 'The element ID to which this reference is meant to refer to'
						},
						'name': {
							type: 'string'
						},
						'template_id': {
							type: 'string',
							regex: item_id_format_regexp,
							description: 'The unique ID of a Template.  The template will contain the element_id.  If it is not supplied, the reference is to the current template.'
						},
						'template_version': {
							type: 'string',
							regex: item_version_format_regexp,
							description: 'The specific version of the ID of a Template.  The template will contain the element_id.  If it is not supplied, the current version of the template will used.'
						},
						'format': {
							type: 'string',
							description: 'The format that the reference should be rendered in documents'
						}
					},
					additionalProperties: false,
					required: ['name', 'element_id']
				}
			},
			required: ['name'],
			description: 'The "reference" element specifies a reference to another element in the current template or one of its parent/child templates'
		},
		switch: {
			properties: {
				'name': {
					type: 'string',
					regex: variable_name_format_regexp
				},
				'expression': {type: 'string'},
				'values': {
					type: 'object',
					patternProperties: {
						'^.*$': {
							type: 'object',
							properties: {
								'body': {
									'$ref': '#/definitions/body'
								}
							},
							additionalProperties: false
						}
					},
					additionalProperties: false
				},
				'default': {
					type: 'object',
					properties: {
						'body': {
							'$ref': '#/definitions/body'
						}
					},
					additionalProperties: false
				}
			},
			description: 'The "switch" element allows the user to insert logic into the document.  It evaluates the "expression" parameter at rendering time, and based on the result then uses the "body" of the matching "values" tree.  If no matching key is found, the "default" tree will be used.'
		},
		loop: {
			properties: {
				'name': {
					type: 'string',
					regex: variable_name_format_regexp,
				},
				'expression': {
					type: 'string'
				},
				'body': {
					'$ref': '#/definitions/body'
				},
				'else': {
					type: 'object',
					properties: {
						'body': {
							'$ref': '#/definitions/body'
						}
					},
					additionalProperties: false
				}
			},
			required: ['name'],
			description: 'The "loop" element allows the user to insert logic into the document.  It evaluates the "expression" parameter at rendering time, and based on the result then uses the "body" of the element.  If no matching key is found, the "else" tree will be used.'
		}
	};

	const body_schema = {
		type: 'array',
		items: {
			type: 'object',
			patternProperties: {
				[element_id_format_regexp]: {
					type: 'object',
					properties: {
						'type': {
							type: 'string',
							enum: element_types
						},
					},
					allOf: element_types.map(function(element_type) {
						let element_schema = body_schema_elements[element_type];
						if (!element_schema) {
							element_schema = {};
						} else {
							element_schema['properties']['type'] = {
								type: 'string',
								const: element_type
							}
							element_schema['additionalProperties'] = false;
						}

						return({
							if: {
								properties: {'type': {const: element_type}}
							},
							then: element_schema
						});
					}),
					required: ['type']
				}
			},
			minProperties: 1,
			maxProperties: 1,
			additionalProperties: false
		},
		description: 'The "body" of a template is an ordered set of elements.  These elements will be presented to the Document author in this order, as well as rendered in the final Content following the flow of the order of this array.'
	};

	/*
	 * XXX:TODO
	 */
	const comments_schema = {};

	const body_extend_schema = { ...body_schema };

	const template_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:15c2ec2d-a361-4c6e-b3fc-c676f0e26c34',
		type: 'object',
		definitions: {
			'body': body_schema,
			'comments': comments_schema,
			'permissions': permissions_schema
		},
		properties: {
			'id': {
				type: 'string',
				regex: item_id_format_regexp,
				description: 'The unique ID of a Template.  This will be the primary key for accessing this template.'
			},
			'version': {
				type: 'string',
				regex: item_version_format_regexp,
				description: 'The unique version ID of a template.  This uniquely identifies a concrete version of a template.'
			},
			'previous_version': {
				type: 'string',
				regex: item_version_format_regexp,
				description: 'If there is a previous version ID of a template, this will identify it.  This is only used in responses, and if provided in a request to store a template will be ignored.'
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this template.'
			},
			'metadata': {
				type: 'object',
				patternProperties: {
					[variable_name_format_regexp]: {
						type: 'string'
					}
				},
				additionalProperties: false,
				description: 'Metadata for this template.  Metadata is treated the same as variables, except the values are supplied at Template editing time, instead of Document editing time.'
			},
			'variables': {
				type: 'object',
				patternProperties: {
					[variable_name_format_regexp]: {
						type: 'object',
						properties: {
							'type': {
								type: 'string',
								enum: variable_types,
								description: 'Type of variable'
							},
							'description': {
								type: 'string',
								description: 'A description of this variable'
							},
							'default': {
								type: ['string', 'object'],
								description: 'A default value for this variable, must be a string unless the type is datasource' /* XXX:TODO Encode this as a validation rule */
							},
							'options': {
								type: 'object',
								/* XXX:TODO: Encode the valid options for the type of variable */
							}
						},
						required: ['type'],
						additionalProperties: false
					}
				},
				additionalProperties: false,
				description: 'Variables are the parameters of the Template that are not known when creating it.  Instead they are supplied for a particular instance of a Document.'
			},
			'body': {
				'$ref': '#/definitions/body'
			},
			'comments': {
				'$ref': '#/definitions/comments'
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			},
			'data_sources': { /* XXX:TODO */ },
			'workflow': { /* XXX:TODO */ }
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions']
	};

	const document_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:6d5c96c5-7c94-4caa-93ad-7fd0ea24c3f7',
		type: 'object',
		definitions: {
			'comments': comments_schema,
			'permissions': permissions_schema,
			'body_extend': body_extend_schema,
			'body': body_schema
		},
		properties: {
			'id': {
				type: 'string',
				regex: item_id_format_regexp,
				description: 'The unique ID of a Document.  This will be the primary key for accessing this document.'
			},
			'version': {
				type: 'string',
				regex: item_version_format_regexp,
				description: 'The unique version ID of a Document.  This uniquely identifies a concrete version of a document.'
			},
			'previous_version': {
				type: 'string',
				description: 'If there is a previous version ID of a document, this will identify it.  This is only used in responses, and if provided in a request to store a document will be ignored.'
			},
			'state': {
				type: 'string',
				description: 'The current "State" of the document, this is used to manage reviews and the lifecycle of the document.'
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this document.'
			},
			'variables': {
				type: 'object',
				patternProperties: {
					[variable_name_format_regexp]: {
						type: ['string', 'object', 'array'],
						description: 'A default value for this variable, must be a string unless the type is datasource or type is multi_input' /* XXX:TODO Encode this as a validation rule */
					}
				},
				additionalProperties: false,
				description: 'Variables hold the mapping of variable names to values'
			},
			'template': {
				type: 'object',
				properties: {
					'id': {
						type: 'string',
						regex: item_id_format_regexp
					},
					'version': {
						type: 'string',
						regex: item_version_format_regexp
					},
				},
				additionalProperties: false,
				required: ['id', 'version'],
				description: 'The "template" element indicates which template ID, and which version, the document is built from.'
			},
			'superdocument': {
				type: 'object',
				properties: {
					'document_id': {
						type: 'string',
						regex: item_id_format_regexp
					}
				},
				additionalProperties: false,
				description: 'The "superdocument" element, if present, indicates that some larger document includes the current document.'
			},
			'subdocuments': {
				type: 'object',
				patternProperties: {
					[element_id_format_regexp]: {
						type: 'object',
						properties: {
							'document_id': {
								type: 'array',
								regex: item_id_format_regexp
							}
						},
						additionalProperties: false,
						required: ['document_id']
					}
				},
				additionalProperties: false,
				description: 'Specify the mapping of Element IDs from the "body" which contains a "template" element to a the Document ID which has been instantiated for this document tree'
			},
			'comments': {
				'$ref': '#/definitions/comments'
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			},
			'body_extend': {
				'$ref': '#/definitions/body_extend'
			}
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions']
	};

	const list_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:6d5c96c5-7c94-4caa-93ad-7fd0ea24c3f7',
		type: 'object',
		definitions: {
			'permissions': permissions_schema
		},
		properties: {
			'id': {
				type: 'string',
				regex: item_id_format_regexp,
				description: 'The unique ID of a List.  This will be the primary key for accessing this list.'
			},
			'version': {
				type: 'string',
				regex: item_version_format_regexp,
				description: 'The unique version ID of a List.  This uniquely identifies a concrete version of a list.'
			},
			'previous_version': {
				type: 'string',
				description: 'If there is a previous version ID of a list, this will identify it.  This is only used in responses, and if provided in a request to store a list will be ignored.'
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this list.'
			},
			'default': {
				type: 'boolean',
				description: 'An indication on whether this is the default list for this type or not'
			},
			'type': {
				type: 'string',
				description: 'The type of list this list belongs to -- this is internal to the system'
			},
			'entries': {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						'key': {
							type: 'string',
							description: 'Key for the list item'
						},
						'value': {
							type: 'string',
							description: 'Value for the list item'
						}
					},
					additionalProperties: false,
					required: ['key', 'value']
				},
				description: 'List items'
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			}
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions', 'name', 'type', 'entries']
	};

	return({
		document: document_schema,
		template: template_schema,
		list: list_schema
	});
}

function generate_schema(type) {
	const schemas = generate_schemas();
	const schema = schemas[type];

	return(schema);
}

/*
 * Helper functions for common things
 */
/**
 ** Determine if an array has any duplicate elements
 **/
function array_has_duplicates(array) {
	const new_array = [...(new Set(array))];
	if (array.length === new_array.length) {
		return(false);
	}

	return(true);
}

/*
 * Cache the generated validators for the various types of known schemas
 */
const validators = {};

function validate_against_schema(type, record, options = {}) {
	options = {
		debug: true,
		...options
	}

	if (!validators[type]) {
		/*
		 * Generate the schema for this type of record
		 */
		const schema = generate_schema(type);

		const validator = new json_schema();
		validators[type] = validator.compile(schema);
	}

	const schema_validator = validators[type];

	const result = schema_validator(record);

	if (!result) {
		const schema = generate_schema(type);

		if (options.debug === true) {
			kaialpha.log.debug(`Validation Schema for ${type}: ${JSON.stringify(schema, undefined, 4)}`);
			kaialpha.log.debug(`Validation of ${type} record failed:`, JSON.stringify(record, undefined, 4), '; because:', schema_validator.errors);
		}

		return(schema_validator.errors);
	}

	return([]);
}

async function validate_template(user_id, check_template, options = {}) {
	/*
	 * Validate that the syntax is correct
	 */
	const syntax_correct = validate_against_schema('template', check_template, options);
	if (syntax_correct.length !== 0) {
		return(syntax_correct);
	}

	if (validation_config.extra_validation) {
		/*
		 * Validate that no body element IDs are duplicated
		 */
		const all_element_ids = document_utils.body_element_ids(check_template.body);
		if (array_has_duplicates(all_element_ids)) {
			return([`Duplicate Body Element IDs: ${JSON.stringify(all_element_ids)}`]);
		}

		/*
		 * Validate that no variable elements are duplicated -- or do we care ?
		 * It's not strictly forbidden, but it's probably a bad idea right now
		 */
		if (validation_config.strict_validation) {
			const all_variable_elements = document_utils.body_by_element_tag('variable', check_template.body);
			const all_variable_names = []
			for (const element of all_variable_elements) {
				all_variable_names.push(element.contents.name);
			}
			if (array_has_duplicates(all_variable_names)) {
				return([`Duplicate variable Body Elements found: JSON.stringify(all_variable_elements)`]);
			}
		}

		/*
		 * Validate that every template specified exists
		 */
		const all_template_elements = document_utils.body_by_element_tag('template', check_template.body);
		for (const element of all_template_elements) {
			if (element.contents.id !== undefined && element.contents.version !== undefined) {
				const template_id = element.contents.id;
				const template_version = element.contents.version;
				const template = await kaialpha.lib.template.get_user_template(user_id, template_id, template_version);
				if (template.id !== template_id) {
					return([`Fetched referenced template and got differing ID expected: ${template_id}, got: ${JSON.stringify(template)}`]);
				}
			}
		}
	}

	/*
	 * Validate the rendered version of this template
	 */
	if (options.skip_parse_check !== true) {
		const template_body = kaialpha.lib.document_utils.body_serialize(undefined, check_template);
		const template_nunjucks = await kaialpha.lib.generator_utils.generateNunjucksValueFromBody(template_body, {
			validate_elements: false,
			recurse_into_templates: false,
			user_id: user_id
		}, kaialpha.lib.generator.mapping_function.default);
		const template_nunjucks_str = template_nunjucks.join('\n');
		const template_nunjucks_parseable = await kaialpha.lib.nunjucks_utils.parseString(template_nunjucks_str);
		if (!template_nunjucks_parseable) {
			return([`Failed to parse rendered template`]);
		}
	}

	return([]);
}

async function validate_document(user_id, check_document, options = {}) {
	/*
	 * Validate that the syntax is correct
	 */
	const syntax_correct = validate_against_schema('document', check_document, options);
	if (syntax_correct.length !== 0) {
		return(syntax_correct);
	}

	if (validation_config.extra_validation) {
		/*
		 * Validate that template specified exists
		 */
		let template = null;
		if (check_document.template) {
			template = await kaialpha.lib.document.get_user_template_from_document(user_id, check_document);

			if (!template) {
				return([`Template could not be found from document`]);
			}
		}
		/*
		 * Validate that no body element IDs are duplicated
		 */
		const body = document_utils.body_serialize(check_document, template);
		const all_element_ids = document_utils.body_element_ids(body);
		if (array_has_duplicates(all_element_ids)) {
			return([`Duplicate Body Element IDs: ${JSON.stringify(all_element_ids)}`]);
		}

		/*
		 * Validate that subdocuments map to elements that exist
		 */
		const all_template_elements = document_utils.body_by_element_tag('template', body);
		const all_template_elements_map = {};
		for (const element of all_template_elements) {
			all_template_elements_map[element.id] = element.contents;
		}
		if (check_document.subdocuments) {
			for (const element_id of Object.keys(check_document.subdocuments)) {
				if (!all_template_elements_map[element_id]) {
					return([`Subdocument with element ID ${element_id} not found in body: ${JSON.stringify(all_template_elements_map, undefined, 4)}`]);
				}
			}
		}

		/*
		 * Validate that all template elements have subdocument entries,
		 * if it's an expression, ignore if it results in 0 templates.
		 */
		for (const element of all_template_elements) {
			if (!check_document.subdocuments[element.id]) {
				if (element.contents.expression !== undefined) {
					const options = { fields: ['name'], filter: `metadata.${element.contents.expression}` };
					const template_info = await kaialpha.lib.template.get_user_templates(user_id, options);
					if (template_info.templates.length === 0) {
						continue;
					}
				}
				return([`Template Element with element ID ${element.id} not found in subdocuments: ${JSON.stringify(all_template_elements, undefined, 4)}`]);
			}
		}

		/*
		 * Validate that variable values are for variables that exist
		 */
		/** XXX:TODO **/

		/*
		 * Validate that variable values are acceptable for each variable and their type
		 */
		/** XXX:TODO **/
	}

	return([]);
}

async function validate_list(user_id, check_list) {
	if (check_list.type === undefined) {
		return([`Invalid type (no type)`]);
	}

	if (!kaialpha.lib.list_utils.known_lists.includes(check_list.type)) {
		return([`Invalid type: ${check_list.type}`]);
	}

	const syntax_correct = validate_against_schema('list', check_list);
	if (syntax_correct.length !== 0) {
		return(syntax_correct);
	}

	return([]);
}

if (_testing) {
	const template = {
		"name": "Demo Template ",
		"id": "8c6ab078-c592-43bb-a62b-aa6dc291b96a",
		"version": "9ce2b4cb-af90-4cba-8218-453c854b35fd",
		"body": [
			{
				"f8fbf596-ae3b-4c3e-bcd8-8e577a2952dc": {
					"type": "section",
					"name": "This is a section ",
					"body": [
						{
							"e54f09e1-a020-4ab5-b795-a807f7958157": {
								"type": "html",
								"text": "<p>This is a html</p>"
							}
						}
					]
				}
			},
			{
				"87d4d4c6-9a13-4344-81af-ef56433c9f3e": {
					"type": "html",
					"text": "test"
				}
			}
		],
		"permissions": {
			"owners": [
				"f620bdb1-4721-418a-8bd3-734d9cab1499"
			]
		},
		"previous_version": "2f75a454-5767-4b26-85de-a2f3bbe65f55"
	}

	_testing.validate_against_schema_template = async function () {
		const result = await validate_template('@system', template);

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error("Validation Error please add valid template");
		}

		return true;
	}

	_testing.validate_template_parse_errors = async function () {
		const temp_template = object_utils.copy_object(template);
		const html_element = temp_template.body.find(function(element_info) {
			const element_id = Object.keys(element_info)[0];
			const element = element_info[element_id];
			if (element.type !== 'html') {
				return(false);
			}

			return(true);
		});

		html_element[Object.keys(html_element)[0]].text = 'Parse error: {%-';

		const result = await validate_template('@system', temp_template);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw(new Error('When validating template with parse error, we did not get any errors'));
		}

		return(true);
	}

	_testing.validate_against_schema_template_without_id = function () {
		const temp_template = object_utils.copy_object(template);
		delete temp_template['id'];

		const result = validate_against_schema('template', temp_template);

		/* istanbul ignore if */
		if (!result[0].params.missingProperty === 'id') {
			throw new Error("Id not specified should be thrown")
		}

		return true;
	}

	_testing.validate_against_schema_document = function () {
		const document = object_utils.copy_object(template);
		document['body_extend'] = template.body;
		delete document['body'];
		delete document['metadata'];
		const result = validate_against_schema('document', document);

		/* istanbul ignore if */
		if (!result.length === 0) {
			throw new Error("Validation Error please add valid document");
		}

		return true;
	}

	_testing.validate_against_schema_document_without_id = function () {
		const document = object_utils.copy_object(template);
		document['body_extend'] = template.body;
		delete document['body'];
		delete document['metadata'];
		delete document['id']
		const result = validate_against_schema('document', document);

		/* istanbul ignore if */
		if (!result[0].params.missingProperty === 'id') {
			throw new Error("Id not specified should be thrown")
		}

		return true;
	}

	_testing.init_test = function () {
		const result = init({ 'something': true });

		/* istanbul ignore if */
		if (!(result.strict_validation && result.extra_validation && result.something)) {
			throw new Error("Expected params in validation config which is missing");
		}
		return true;
	}

	_testing.array_has_duplicates = function () {
		const arr = ['a', 'b', 'c'];
		const result = array_has_duplicates(arr);

		/* istanbul ignore if */
		if (result) {
			throw new Error("Array does not have duplicates");
		}
		return true;
	}

	_testing.array_has_duplicates_with_duplicates = function () {
		const arr = ['a', 'b', 'b'];
		const result = array_has_duplicates(arr);

		/* istanbul ignore if */
		if (!result) {
			throw new Error("Array has duplicated but not found in logic");
		}
		return true;
	}
}

const _to_export_auto = {
	init,
	validate_document,
	validate_template,
	validate_list,
	generate_schemas,
	generate_schema,
	structure: {
		variable_types,
		element_types,
		element_info
	},
	_testing
}
export default _to_export_auto;
